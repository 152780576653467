import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, useLocation, useMatch, useResolvedPath } from 'react-router-dom';
import './HeaderCenterMenu.scss';
import { currentCountryWithPermissionsSelector } from "@selectors/countries.selector";
import { deepCopy } from "@core/old_helpers";
import { Routes } from '@routes';
import cn from "classnames";
import { IconSearch } from '@icons';

const titleMenu = [
    {
        title: 'profiles',
        path: '/food-profiles',
        id: 'profiles',
    },
    {
        title: 'top trends',
        path: '/toptrends',
        id: 'topTrends',
    },
    {
        title: 'reports',
        path: '/reports',
        id: 'reports',
    },
    {
        title: 'instant charts',
        path: '/instant-charts',
        id: 'instantCharts',
    },
];
const HeaderCenterMenuItem = ({ title, to }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
    return (
        <NavLink
            to={to}
            className={cn(
                "headerCenterMenu__item",
                "is-wrap",
                match && "headerCenterMenu__item--active",
            )}>
            {title}
        </NavLink>
    );
};

const HeaderCenterMenu = ({ isActive, setIsActiveCentreMenu, currentCountryPermissions = {}, isAdminPage, isThemeLightEnable, setTheme, hasAdminAccess }) => {
    const location = useLocation();
    const {
        hasFoodProfile,
        hasRtuReport,
        hasInsertChart
    } = currentCountryPermissions;

    let items = deepCopy(titleMenu);

    if (!hasRtuReport) {
        items = items.filter(({ id }) => id !== 'reports');
    }
    if (!hasFoodProfile) {
        items = items.filter(({ id }) => id !== 'profiles');
    }
    if (!hasInsertChart) {
        items = items.filter(({ id }) => id !== 'instantCharts');
    }

    useEffect(() => {
        setTimeout(() => {
            setIsActiveCentreMenu(false)
        }, 4000)
    }, []);


    const headerCenterMenu = !isAdminPage ? (
        items.map(el => {
            const itemClassList = ["headerCenterMenu__item"];

            if (el.path === location.pathname) {
                itemClassList.push("headerCenterMenu__item--active");
            }
            return (
                <Link to={el.path} key={el.id} className={itemClassList.join(" ")}>
                    {el.title}
                </Link>
            );
        })
    ) : (
        <>
            <HeaderCenterMenuItem to={Routes.AdminKeywords} title="KEYWORD MANAGEMENT"/>
            <HeaderCenterMenuItem to={Routes.AdminInstantCharts} title="INSTANT CHARTS"/>
            <HeaderCenterMenuItem to={Routes.AdminSummaryReports} title="SUMMARY REPORTS"/>
            <HeaderCenterMenuItem to={Routes.AdminCategoryReports} title="CATEGORY REPORTS"/>
        </>
    );


    return (
        <div className={cn(
            'headerCenterMenu',
            isActive && "headerCenterMenu__active",
            hasAdminAccess && "headerCenterMenu__has-admin-button",
            isAdminPage && "is-max-width",
        )}>
            {
                !isAdminPage && (
                    <Link
                        to={Routes.Home}
                        className="headerCenterMenu__item headerCenterMenu__search"
                    >
                        <IconSearch className='headerCenterMenu__search-icon' />
                    </Link>
                )
            }

            {headerCenterMenu}
        </div>
    )
};

export default connect(
    state => ({
        currentCountryPermissions: currentCountryWithPermissionsSelector(state)
    }),
    {},
)(HeaderCenterMenu);
