import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Cell from "@components/NewAwesomeTable/Cell";
import HightlightChars from "@pages/Admin/Common/HightlightChars/HightlightChars";
import Flag from "@components/Flag/Flag";
import serializeParams from "@core/serializeParams";
import { Routes } from "@routes";
import { IconDelete, IconCheckSuccess, IconCloseFailure, IconEdit } from "@icons";
import { GlobalContext } from "@components/App/App.context";
import Icon from "@components/Icon/Icon";
import Popup from "@components/Popup/Popup";
import { SummaryReportsContext } from "@pages/Admin/Reports/ReportsContainer";

function AdminReportsTableRow({ data }) {
    const { getRem } = useContext(GlobalContext);
    const { onItemRemove } = useContext(SummaryReportsContext);
    const [ isDeleting, setIsDeleting ] = useState(false);
    const {
        id,
        renderName,
        name,
        childReportsCount,
        countryCode,
        isActive,
        flag,
        countryName,
    } = data;

    return (
        <>
            <Cell className="AdminTable__col AdminTable__col--fluid AdminTable__col--max-half">
                <span className="AdminTable__truncated" title={name}>
                    <HightlightChars name={renderName.toUpperCase()} />
                </span>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--large">
                {childReportsCount}
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--large">
                <Flag height={getRem(24)} compact component={flag}/>
                <span className="AdminTable__truncated">
                    {countryName}
                </span>
            </Cell>
            <Cell
                className="AdminTable__col AdminTable__col--medium"
                options="center no-pl"
            >
                {isActive ? <Icon><IconCheckSuccess/></Icon> : <Icon><IconCloseFailure/></Icon>}
            </Cell>
            <Cell
                className="AdminTable__col AdminTable__col--small AdminTable__col--no-pl"
                options="no-p center"
            >
                <Link
                    to={{
                        pathname: Routes.AdminSummaryReportEdit,
                        search: serializeParams({
                            id,
                            country: countryCode
                        })
                    }}
                    className="AdminTable__btn">
                    <Icon type="small">
                        <IconEdit/>
                    </Icon>
                </Link>
                <button
                    className="AdminTable__btn"
                    onClick={() => setIsDeleting(true)}
                >
                    <Icon type="small">
                        <IconDelete/>
                    </Icon>
                </button>
                <Popup
                    shown={isDeleting}
                    closeModal={() => setIsDeleting(false)}
                    title={`Delete a category report "${name.toUpperCase()}"`}
                    modifier="confirm"
                    btnModifier="red"
                    applyBtnTitle="Delete"
                    onConfirm={() => onItemRemove(id)}
                >
                    <div className="Popup__text">
                        Are you sure you want to delete <span>{name.toUpperCase()}</span>?
                    </div>
                </Popup>
            </Cell>
        </>
    );
}

export default AdminReportsTableRow;
