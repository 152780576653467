import { ID, ReportTypeEnum } from "@models/index";
import { components } from "@apiSchema";
import { getRoundValue } from "@core/old_helpers";
import { Routes } from "@routes";

export interface iSearchResultsItem {
    id: ID;
    name: string;
    isFoodProfile?: boolean;
    penetration?: number;
    categoryName?: string;
    wordsCount?: number;
    reportType?: ReportTypeEnum;
    searchResultGroup?: 0 | 1;
}

export interface iSearchResultsSection {
    id: ItemTypeEnum;
    title: string;
    items: iSearchResultsItem[];
}

export interface iSearchResults {
    data: iSearchResultsSection[];
}

type API__BASE_MODEL = components['schemas']['MenuTrends.Application.Contracts.Models.BaseModel'];

interface ApiModelItem extends API__BASE_MODEL {
    id: number;
    name: string;
    isFoodProfile?: boolean;
    penetration?: number;
    categoryName?: string;
    wordsCount?: number;
    reportType?: ReportTypeEnum;
    searchResultGroup?: 0 | 1;
}

export enum ItemTypeEnum {
    NotSet,
    FoodProfile,
    Report,
    InstantChart,
    Chains,
    CategoryReport,
}

type API__SEARCH_RESULTS = components["schemas"]["Application.GlobalSearch.GlobalSearchResult.Entities"];

interface ApiModelCategory extends API__SEARCH_RESULTS {
    itemType: string;
    itemTypeId: ItemTypeEnum;
    items: ApiModelItem[];
}

export type ApiModel = ApiModelCategory[];
interface iData {
    apiModel?: ApiModel;
}

const RESULTS_LIMIT = 15;

export class SearchResults implements iSearchResults {
    data: iSearchResultsSection[] = [];

    constructor(data?: iData) {
        if (data) {
            if (data.apiModel) {
                this.mapFromApi(data.apiModel);
            }
        }
    }

    private setData(data: iSearchResults) {
        ({
            data: this.data,
        } = data);
    }

    private mapFromApi (rawData: ApiModel) {
        this.setData({
            data: rawData.map(section => ({
                id: section.itemTypeId || 0,
                title: section.itemType || '',
                items: (section.items || []).slice(0, RESULTS_LIMIT).map((item, itemIndex) => ({
                    ...item,
                    id: item.id,
                    name: item.categoryName ? `${item.categoryName}: ${item.name}` : item.name,
                })),
            }))
        });
    }

    static getItemSubTitle(item: iSearchResultsItem, itemIndex: number, itemTypeId: ItemTypeEnum): string {
        switch(itemTypeId) {
            case ItemTypeEnum.FoodProfile: {
                return `(${getRoundValue(+(item.penetration || 0))}${itemIndex === 0 ? " penetration" : ''})`;
            }
            default: return '';
        }
    }

    static getItemLink(item: iSearchResultsItem, itemTypeId: ItemTypeEnum): string {
        switch(itemTypeId) {
            case ItemTypeEnum.FoodProfile: {
                return `${Routes.byo}?words=${encodeURIComponent(item.name)}`;
            }
            case ItemTypeEnum.InstantChart: {
                return `${Routes.byo}?instantChartId=${encodeURIComponent(item.id)}&wordsCount=${item.wordsCount || 0}`;
            }
            case ItemTypeEnum.CategoryReport:
            case ItemTypeEnum.Report: {
                return `${Routes.reports}`;
            }
            default: return '';
        }
    }
}