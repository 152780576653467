import React from "react";
import Cell from "@components/NewAwesomeTable/Cell";
import Select from "@components/Select/Select";

const TableHeader = ({
    handleChangeCategories,
    categoriesToOptions,
    subCategoriesToOptions,
    selectedCategories,
    selectedSubCategories,
}) => {
    return (
        <>
            <Cell className="AdminTable__col AdminTable__col--small"></Cell>
            <Cell className="AdminTable__col AdminTable__col--fluid AdminTable__col--no-p">Instant chart name</Cell>
            <Cell className="AdminTable__col AdminTable__col--fluid" options="hv">
                <div className="AdminTable__header-dropdown">
                    <Select
                        options={categoriesToOptions}
                        value={selectedCategories}
                        onChange={value => handleChangeCategories("category", value)}
                        isCheckboxHidden
                        wrapContent
                        stopPropagation
                        dropdownAlwaysBottom
                        prefix="Interactive category"
                        placeholder=""
                        inheritedStyles
                        showModifierIcon
                        customScrollbar
                    />
                </div>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--fluid" options="hv">
                <div className="AdminTable__header-dropdown">
                    <Select
                        options={subCategoriesToOptions}
                        value={selectedSubCategories}
                        onChange={value => handleChangeCategories("subcategory", value)}
                        isCheckboxHidden
                        wrapContent
                        stopPropagation
                        dropdownAlwaysBottom
                        prefix="Interactive category"
                        placeholder=""
                        inheritedStyles
                        showModifierIcon
                        customScrollbar
                    />
                </div>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--medium" options="center no-pl">
                Status
            </Cell>
        </>
    );
};

export default TableHeader;
