import { NOTIFICATIONS } from '../core/actionNames';

const { ADD, REMOVE } = NOTIFICATIONS;

const defaultState = {
    data: [],
};

export default function notifications(state = defaultState, action) {
    switch (action.type) {
        case ADD: {
            return {
                ...state,
                data: [
                    ...state.data,
                    action.notification
                ]
            };
        }
        case REMOVE: {
            return {
                ...state,
                data: action.notifications
            };
        }
        default:
            return state;
    }
}