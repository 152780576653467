import { components } from "../apiSchema";

export enum PermissionLevelEnum {
    None,
    Limited,
    Full,
}

export function isBlockedAccessible (permissionSection: iPermissionFields): boolean {
    return permissionSection.hasData && permissionSection.permissionLevel === PermissionLevelEnum.None;
}

export function isAvailable (permissionSection: iPermissionFields): boolean {
    return permissionSection.hasData && permissionSection.permissionLevel !== PermissionLevelEnum.None;
}

export interface iPermissionFields {
    permissionLevel: PermissionLevelEnum;
    hasData: boolean;
}

export class PermissionFields implements iPermissionFields {
    permissionLevel = PermissionLevelEnum.None;
    hasData = true;
}

export interface iPermissionSection<T> extends iPermissionFields {
    data?: T;
}

export class PermissionSection<T> implements iPermissionSection<T> {
    permissionLevel = PermissionLevelEnum.Full;
    hasData = true;
    data?: T = undefined;

    constructor(data?: iPermissionSection<T>) {
        if (data) {
            this.setData(data);
        }
    }

    private setData (data: iPermissionSection<T>) {
        ({
            data: this.data,
            hasData: this.hasData,
            permissionLevel: this.permissionLevel,
        } = data);
    }

    static getPermissionLevel(level: components['schemas']['MenuTrends.Application.Contracts.Enums.AccessLevel'] = 0): PermissionLevelEnum {
        switch (level) {
            case 0: return PermissionLevelEnum.None;
            case 1: return PermissionLevelEnum.Limited;
            case 2: return PermissionLevelEnum.Full;
            default: return PermissionLevelEnum.None;
        }
    }
}