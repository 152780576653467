import React, { useMemo, useRef, useState } from "react";
import PT from "prop-types";
import "./AutoWidthInput.scss";
import cn from "classnames";

const AutoWidthInput = ({
    defaultValue = "",
    value = 0,
    onChange,
    onFocus = undefined,
    onBlur = undefined,
    mask = '',
    maxLength = 0,
    log = false,
    clearDefaultValueOnFocus = false,
    testId,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);
    const textRef = useRef(null);
    const extendedValue = useMemo(
        () => clearDefaultValueOnFocus && isFocused ? (value || '') : (value || defaultValue),
        [clearDefaultValueOnFocus, isFocused, value, defaultValue]
    );
    if (log) {
        console.log(value, extendedValue);
    }

    const handleFocus = (event) => {
        setIsFocused(true);
        if (onFocus) onFocus(event);
    };
    const handleBlur = (event) => {
        setIsFocused(false);
        if (onBlur) onBlur(event);
    };

    return (
        <div
            className={cn(
                'AutoWidthInput',
                extendedValue !== defaultValue && extendedValue !== '' && 'AutoWidthInput--highlighted',
            )}
            data-testid={testId}
        >
            <span
                ref={textRef}
                className="AutoWidthInput__fake AutoWidthInput__fake--left"
                data-testid="textAutoWidthInputMask"
            >
                {mask}
            </span>
            <span ref={textRef} className="AutoWidthInput__fake AutoWidthInput__fake--right">
                {extendedValue || <>&nbsp;</>}
                <input
                    ref={inputRef}
                    value={extendedValue}
                    onChange={onChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    type="text"
                    className="AutoWidthInput__real"
                    maxLength={maxLength || 5}
                    data-testid="inputAutoWidthInputField"
                />
            </span>
        </div>
    );
};

AutoWidthInput.propTypes = {
    value: PT.oneOfType([PT.string, PT.number]),
    onChange: PT.func,
    className: PT.string,
    testId: PT.string,
};

export default AutoWidthInput;
