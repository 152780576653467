import React, { useReducer, useState, useEffect } from "react";
import { connect } from "react-redux";
import VenuesForm from "@components/VenuesForm/VenuesForm";
import MenuItems from "@components/MenuItems/MenuItems";
import Attributes from "@components/Attributes/Attributes";
import { deepEqual } from "@core/old_helpers";
import AdditionalFilters from "../FiltersContainer/AdditionalFilters";
import SubTitle from "@components/SubTitle/SubTitle";
import { chartWordsSelector, filtersChartSelector } from "@selectors/adminInstantChart.selectors";
import "./AdminFiltersContainer.style.scss";
import Tags from "@containers/FiltersContainer/Tags";

const AdminFiltersContainer = ({
    filters,
    keywords,
    restaurants,
    currentCurrency,
    getFiltersData,
    tagCategories,
    tags,
}) => {
    const [localFilters, setLocalFilters] = useReducer(reducer, initialLocalFilters);
    const [isFiltersBeenChanged, setIsFiltersBeenChanged] = useState(false);
    console.log(localFilters);

    useEffect(() => {
        setIsFiltersBeenChanged(!deepEqual(filters, localFilters));
    }, [filters, localFilters]);

    useEffect(() => {
        setLocalFilters(filters);
    }, [isFiltersBeenChanged]);


    useEffect(() => {
        getFiltersData(localFilters);
    }, [localFilters]);

    const handleChange = (section, fieldName, newValue) => {
        setLocalFilters({
            ...localFilters,
            [section]: {
                ...localFilters[section],
                [fieldName]: newValue,
            },
        });
    };

    const handleCheckboxGroupChange = (section, fieldName, id, isGroup) => {
        const field = localFilters[section][fieldName];
        let newValue;
        if (id === "all") {
            newValue = field.map(option => ({
                ...option,
                isChecked: !field.every(option => option.isChecked),
            }));
        } else if (isGroup) {
            const groupValue = !field
                .filter(option => option.groupId === id)
                .every(option => option.isChecked);
            newValue = field.map(option => ({
                ...option,
                isChecked: option.groupId === id ? groupValue : option.isChecked,
            }));
        } else {
            newValue = field.map(option => ({
                ...option,
                isChecked: option.id === id ? !option.isChecked : option.isChecked,
            }));
        }
        handleChange(section, fieldName, newValue);
    };

    const handleCheckAllMenuItems = () => {
        const isAllChecked =
            localFilters.menuItems.appetizers.every(option => option.isChecked) &&
            localFilters.menuItems.entrees.every(option => option.isChecked) &&
            localFilters.menuItems.sides.every(option => option.isChecked) &&
            localFilters.menuItems.desserts.every(option => option.isChecked) &&
            localFilters.menuItems.beverages.every(option => option.isChecked);
        setLocalFilters({
            ...localFilters,
            menuItems: {
                ...localFilters.menuItems,
                appetizers: localFilters.menuItems.appetizers.map(option => ({
                    ...option,
                    isChecked: !isAllChecked,
                })),
                entrees: localFilters.menuItems.entrees.map(option => ({
                    ...option,
                    isChecked: !isAllChecked,
                })),
                sides: localFilters.menuItems.sides.map(option => ({
                    ...option,
                    isChecked: !isAllChecked,
                })),
                desserts: localFilters.menuItems.desserts.map(option => ({
                    ...option,
                    isChecked: !isAllChecked,
                })),
                beverages: localFilters.menuItems.beverages.map(option => ({
                    ...option,
                    isChecked: !isAllChecked,
                })),
            },
        });
    };

    const addItems = (section, fieldName, items) => {
        const field = localFilters[section][fieldName];
        handleChange(section, fieldName, [...field, ...items]);
    };

    const removeItem = (section, fieldName, itemName) => {
        const field = localFilters[section][fieldName];
        const itemIndex = field.findIndex(
            item => item.name.toLowerCase() === itemName.toLowerCase(),
        );

        handleChange(section, fieldName, [
            ...field.slice(0, itemIndex),
            ...field.slice(itemIndex + 1),
        ]);
    };

    return (
        <div className="AdminFiltersContainer">
            <div className="AdminFiltersContainer__row">
                <div className="AdminFiltersContainer__title">
                    <SubTitle textColor="yellow">Venues settings</SubTitle>
                </div>
                {Object.keys(localFilters.venues).length > 0 && (
                    <VenuesForm
                        data={localFilters.venues}
                        restaurants={restaurants}
                        addRestaurants={items =>
                            addItems("venues", "restaurants", items)
                        }
                        removeRestaurant={index =>
                            removeItem("venues", "restaurants", index)
                        }
                        handleChange={(...args) => handleChange("venues", ...args)}
                        handleCheckboxGroupChange={(...args) =>
                            handleCheckboxGroupChange("venues", ...args)
                        }
                        isFiltersBeenChanged={isFiltersBeenChanged}
                        fullLayout
                    />
                )}
            </div>
            <div className="AdminFiltersContainer__row AdminFiltersContainer__row--has-border">
                <div className="AdminFiltersContainer__title">
                    <SubTitle textColor="yellow">Attributes settings</SubTitle>
                </div>
                {Object.keys(localFilters.attributes).length > 0 && (
                    <Attributes
                        data={localFilters.attributes}
                        handleChange={(...args) =>
                            handleChange("attributes", ...args)
                        }
                        handleCheckboxGroupChange={(...args) =>
                            handleCheckboxGroupChange("attributes", ...args)
                        }
                        fullLayout
                    />
                )}
            </div>

            <div className="AdminFiltersContainer__row AdminFiltersContainer__row--has-border">
                {Object.keys(localFilters.menuItems).length > 0 && (
                    <>
                        <div className="AdminFiltersContainer__title">
                            <SubTitle textColor="yellow">Menu items settings</SubTitle>
                        </div>
                        <MenuItems
                            data={localFilters.menuItems}
                            handleChange={(...args) => handleChange("menuItems", ...args)}
                            handleCheckAll={handleCheckAllMenuItems}
                            handleCheckboxGroupChange={(...args) => handleCheckboxGroupChange("menuItems", ...args)}
                            fullLayout
                            hideFooter
                        />
                    </>
                )}
            </div>

            <div className="AdminFiltersContainer__row AdminFiltersContainer__row--has-border">
                <div className="AdminFiltersContainer__title">
                    <SubTitle textColor="yellow">Tags settings</SubTitle>
                </div>
                <Tags
                    categories={tagCategories}
                    tags={tags}
                    value={localFilters.attributes.tags || []}
                    setValue={(value) => handleChange("attributes", 'tags', value)}
                />
            </div>

            <div className="AdminFiltersContainer__row AdminFiltersContainer__row--has-border">
                <div className="AdminFiltersContainer__title">
                    <SubTitle textColor="yellow">Other settings</SubTitle>
                </div>
                {Object.keys(localFilters.menuItems).length > 0 && (
                    <AdditionalFilters
                        addKeywords={(words) =>
                            addItems("menuItems", "words", words)
                        }
                        removeKeyword={(...args) =>
                            removeItem("menuItems", "words", ...args)
                        }
                        keywords={keywords}
                        words={localFilters.menuItems.words}
                        fullLayout
                        handleChange={(...args) => handleChange("menuItems", ...args)}
                        minPrice={localFilters.menuItems.minPrice}
                        maxPrice={localFilters.menuItems.maxPrice}
                        currentCurrency={currentCurrency}
                    />
                )}
            </div>
        </div>
    );
};

const initialLocalFilters = {
    venues: {},
    menuItems: {},
    attributes: {},
};

const reducer = (state, action) => {
    return { ...state, ...action };
};

const mapStateToProps = state => ({
    filters: filtersChartSelector(state),
    keywords: chartWordsSelector(state),
    restaurants: state.adminInstantChart.currentRestaurants,
    tags: state.tags.items,
    tagCategories: state.tags.categories,
});


export default connect(mapStateToProps)(AdminFiltersContainer);
