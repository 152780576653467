import { components } from "@apiSchema";
import { getFoodProfileLTO } from "@core/api";
import { useData } from "@hooks/useData";
import { FoodProfileLTOData, iFoodProfileLTO } from "@models/FoodProfileLTO";

interface iArgs {
    id: string,
    countryCode: string,
}

type Response = components['schemas']['Application.FoodProfile.Models.MenuItem'][];

async function getFoodProfileLTOData({ id, countryCode }: iArgs): Promise<iFoodProfileLTO[]> {
    const response: Response = await getFoodProfileLTO(countryCode, id);
    return response.map(l => new FoodProfileLTOData(l));
}

const useFoodProfileLTO = (params: iArgs) => {
    return useData([], getFoodProfileLTOData, params);
}

export default useFoodProfileLTO;