import React, { useEffect, useReducer, useState } from 'react';
import { connect } from "react-redux"
import './TableHeaderCell.style.scss';
import Tooltip from "@components/Tooltip/Tooltip";
import { tableAvailableSlicesSelector } from "@selectors/byo.selectors";
import { IconArrowBottom, IconSortASC, IconSortDESC } from '@icons';
import Icon from '@components/Icon/Icon';
import cn from "classnames";

function TableHeaderCell({
    content,
    sortIconType,
    tooltipData,
    slices = [],
    options = [],
    onOptionClick = () => null,
}) {
    const [isDropDownShown, setIsDropDownShown] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState);

    const closeTooltip = () => {
        dispatch({
            isOpened: false
        })
    };

    const openTooltip = (e) => {
        const activeTabId = slices.find(tab => tab.isActive);

        if (activeTabId && (activeTabId.id !== 1002 && activeTabId.id !== 1003 && activeTabId.id !== 1006)) return;

        const boundingRect = e.target.getBoundingClientRect();

        dispatch({
            x: boundingRect.left + boundingRect.width / 2,
            y: boundingRect.top - 15,
            isOpened: true
        })
    };

    useEffect(() => {
        setIsDropDownShown(false);
    }, [options]);

    const cellContent = (
        <>
            {sortIconType && sortIconType === 'asc' &&
                <Icon className="TableHeaderCell__icon" type='small'><IconSortASC/></Icon>}
            {sortIconType && sortIconType === 'desc' &&
                <Icon className="TableHeaderCell__icon" type='small'><IconSortDESC/></Icon>}
            {content}
            {tooltipData &&
                <Tooltip
                    close={closeTooltip}
                    data={tooltipData}
                    state={state}
                />
            }
        </>
    );

    return (
        <div
            onMouseLeave={closeTooltip}
            onMouseEnter={openTooltip}
            className="TableHeaderCell__wr"
        >
            <div className={cn("TableHeaderCell", options.length > 0 && 'is-vertical')}>
                {options.length > 0 ? (
                    <div className="TableHeaderCell__label">
                        {cellContent}
                    </div>
                ) : cellContent}
            </div>
            {options.length > 0 && (() => {
                const activeOption = options.find(option => option.isActive);
                return (
                    <div
                        className="TableHeaderCell__dd-thumb"
                        onClick={(event) => {
                            if (options.length > 1) {
                                event.stopPropagation();
                                setIsDropDownShown(!isDropDownShown);
                            }
                        }}
                    >
                        <div className="TableHeaderCell__dd-thumb-inner">
                            {activeOption.title}
                            {options.length > 1 && (
                                <div className={cn(
                                    'TableHeaderCell__dd-icon',
                                    isDropDownShown && 'TableHeaderCell__dd-icon--opened',
                                )}>
                                    <Icon type='small'>
                                        <IconArrowBottom/>
                                    </Icon>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })()}
            {isDropDownShown && options.length > 0 && (
                <div
                    className="TableHeaderCell__dropdown"
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                {options.filter(i => !i.isActive).map(i => (
                        <div
                            key={i.id}
                            className="TableHeaderCell__dropdown-item"
                            onClick={() => onOptionClick(i.id)}
                        >
                            {i.title}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

const initialState = {
    x: 0,
    y: 0,
    isShown: false
};

const reducer = (state, action) => ({
    ...state,
    ...action
});

export default connect(state => ({
    slices: tableAvailableSlicesSelector(state),
}))(TableHeaderCell);

TableHeaderCell.propTypes = {};
