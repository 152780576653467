import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SettingsDropdown from "@components/Select/Select";
import { countriesSelector } from "@selectors/countries.selector";
import Flag from "@components/Flag/Flag";
import './ManageCountriesPopup.styles.scss';

function ManageCountriesPopup(
    {
        countries,
        values,
        options,
        setDataToConfirm,
    }
) {
    const [localValues, setLocalValues] = useState(null);

    useEffect(() => {
        setLocalValues(values);
    }, [JSON.stringify(values)]);

    useEffect(() => {
        setDataToConfirm(localValues);
    }, [JSON.stringify(localValues)]);

    const handleDropDownChange = (countryCode, optionId) => {
        setLocalValues({
            ...localValues,
            [countryCode]: optionId,
        })
    };

    return (
        <div className="manage-countries">
            {countries.map((country) => {
                return (
                    <div className={`manage-country ${country.countryCode === "USA" ? 'manage-country--disabled' : ''}`} key={country.countryCode}>
                        <Flag {...country}/>
                        {localValues && (
                            <SettingsDropdown
                                options={options}
                                value={localValues[country.countryCode]}
                                onChange={(optionId) => handleDropDownChange(country.countryCode, optionId)}
                                isWide
                                dropdownSelect
                                isCheckboxHidden
                                isCutLabel={true}
                            />
                        )}
                    </div>
                )
            })}
        </div>
    );
}

const mapState = state => ({
    countries: countriesSelector(state),
});

export default connect(mapState)(ManageCountriesPopup);