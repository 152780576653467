import { ID } from '@models';
import { useData } from '@hooks/useData';
import { getFilters as fetchFilters } from '@core/api';
import { components } from '@apiSchema';

type Response = components['schemas']['Application.Common.Models.MainFilterOutput'];

async function getFiltersData (countryCode: ID): Promise<Response> {
    const result: Response = await fetchFilters(countryCode);
    
    return result;
}

export default function useFiltersData (countryCode: ID) {
    return useData(null, getFiltersData, countryCode);
}