import { ADMIN_KEYWORDS } from '../core/actionNames';
import { getAdminKeywordNames } from "../core/api";
import { toggleInArray } from "@core/old_helpers";

const { SET_FIELD, SET_DATA } = ADMIN_KEYWORDS;

export function setData(data) {
    return {
        type: SET_DATA,
        data,
    };
}
export function setField(key, value) {
    return {
        type: SET_FIELD,
        key,
        value,
    };
}

export function toggleKeywordExpanded(id) {
    return (dispatch, getState) => {
        const { expandedKeywordsIds } = getState().adminKeywords;

        dispatch(setField('expandedKeywordsIds', toggleInArray(expandedKeywordsIds, id)));
    };
}

export const getKeywordNames = () => {
   return dispatch => {
       getAdminKeywordNames().then(data => {
           dispatch(setField('adminKeywordNames', data));
       })
   }
};