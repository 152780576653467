import { useCallback, useEffect, useMemo, useState } from "react";
import apiConfig from "@core/apiConfig";
import { useDebouncedCallback } from "@core/customHooks";
import http from "@core/http";

export const ROW_HEIGHT = 60;
export const TAKE = 100;
const INITIAL_TOTAL_COUNT_EXAMPLES = 30;
const DEFAULT_SORT_PARAMS = {
    direction: "asc",
    column: 0,
};

let requestExamples;
let requestExport;

export const useMenuExamplesData = ({
    fetch,
    addNotification,
    isShown,
    fetchExport,
    downloadUrl,
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [totalCountExamples, setTotalCountExamples] = useState(INITIAL_TOTAL_COUNT_EXAMPLES);
    const [sort, setSort] = useState(DEFAULT_SORT_PARAMS);
    const [searchQuery, setSearchQuery] = useState("");
    const notify = useCallback(notify => addNotification(notify), []);

    const fetchOptions = useMemo(
        () => ({
            sortColumn: sort.column,
            search: searchQuery,
            take: TAKE,
            skip: tableData.length,
            sortDir: sort.direction,
        }),
        [sort, searchQuery, tableData.length],
    );

    const abortRequestExamples = useCallback(() => {
        if (requestExamples) {
            requestExamples.abort();
        }
    }, [requestExamples]);

    const handleSortColumn = useCallback(
        columnIndex => {
            if (columnIndex === sort.column) {
                setSort(prevState => ({
                    ...prevState,
                    direction: sort.direction === "asc" ? "desc" : "asc",
                }));
            } else {
                setSort({
                    direction: "asc",
                    column: columnIndex,
                });
            }
        },
        [sort],
    );

    const handleChangeSearchQuery = useCallback(value => setSearchQuery(value), []);

    const getMenuExamples = useCallback(async (options = fetchOptions) => {
        if (!isShown) return;

        abortRequestExamples();
        requestExamples = fetch(options);
        setIsLoading(true);
        const { data, totalRecords } = await requestExamples;
        setTableData(prevState => [...prevState, ...data]);
        setTotalCountExamples(totalRecords);
        setIsLoading(false);
    }, [fetch, fetchOptions, abortRequestExamples, isShown]);

    const downloadExamples = useCallback(async () => {
        const notifyId = new Date().getTime();
        const notifyDuration = 2000;
        notify({
            id: notifyId,
            text: "Downloading...",
        });

        if (fetchExport) {
            requestExport && requestExport.abort();
            requestExport = fetchExport(fetchOptions);
            const token = await requestExport;
            http.downloadFileXHRFromUrl(
                'GET',
                apiConfig.get(downloadUrl, { key: token })
            ).then(() => {
                notify({
                    text: "Download was successful",
                    duration: notifyDuration,
                    id: notifyId,
                });
            }).catch(err => {
                notify({
                    text: "Something went wrong",
                    id: notifyId,
                    error: true,
                    duration: notifyDuration,
                });
            });
        }
    }, [fetchExport, notify, fetchOptions, downloadUrl]);

    const resetData = useCallback(() => {
        abortRequestExamples();
        setSearchQuery("");
        setTableData([]);
        setIsLoading(true);
        setTotalCountExamples(INITIAL_TOTAL_COUNT_EXAMPLES);
        setSort(DEFAULT_SORT_PARAMS);
    }, []);

    const reFetchMenuExamples = useCallback(() => {
        setTotalCountExamples(INITIAL_TOTAL_COUNT_EXAMPLES);
        setTableData([]);
        getMenuExamples({
            ...fetchOptions,
            skip: 0
        });
    }, [fetchOptions]);

    const debouncedGetMenuExamples = useDebouncedCallback(() => {
        reFetchMenuExamples();
    }, 150);

    useEffect(() => {
        if ( isShown ) {
            getMenuExamples();
        } else {
            resetData();
        }
    }, [isShown]);

    useEffect(() => {
        debouncedGetMenuExamples();
    }, [searchQuery]);

    useEffect(() => {
        reFetchMenuExamples();
    }, [sort]);

    useEffect(() => {
        setIsLoading(true);
    }, [sort, searchQuery]);

    return {
        download: downloadExamples,
        tableData,
        sort,
        isLoading,
        totalCountExamples,
        getMenuExamples,
        handleSortColumn,
        searchQuery,
        handleChangeSearchQuery,
    };
};
