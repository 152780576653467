export const MQ_VIEWPORT_SIZE = 1920;
export const rootSize = 16;

export function getResponsiveScale(num: number, viewportWidth: number) {
    const scale = viewportWidth > MQ_VIEWPORT_SIZE ? Math.round((1000 * (viewportWidth / MQ_VIEWPORT_SIZE))) / 1000 : 1;
    return num * scale;
}

export function rem(value: number): string {
    return `${value / rootSize}rem`;
}