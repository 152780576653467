import React from 'react';
import {connect} from 'react-redux'
import Modal from '../Modal/Modal'
import './HiddenCountriesPopup.scss'
import Flag from "../Flag/Flag";
import {countriesSelector} from "@selectors/countries.selector";

const HiddenCountriesPopup = ({x, y, shown, closeModal, countryCodes = [], title = '', countries = []}) => {

    return (
        <Modal closeModal={closeModal} shown={shown}>
            <div
                className='HiddenCountriesPopup'
                style={{left: x, top: y}}>
                <div className='HiddenCountriesPopup__title'>
                    {title}
                </div>
                <div className='HiddenCountriesPopup__flags'>
                    {countryCodes.map(({countryCode}) => {
                        const country = countries.find(country => country.countryCode === countryCode)

                        return <Flag
                                key={countryCode}
                                {...country}/>

                    })}
                </div>
            </div>
        </Modal>
    )

};


export default connect(state => ({
    countries: countriesSelector(state),
}))(HiddenCountriesPopup)