import { FC, memo, useMemo } from "react";
import { DISABLED_OPACITY, getPath, iPoint, iViewBoxProps } from "@components/Chart/Chart.helpers";

const ChartArea: FC<{
    points: iPoint[];
    color: string;
    viewport: iViewBoxProps;
    isActive: boolean;
}> = (
    {
        points,
        color,
        viewport,
        isActive,
    }
) => {
    const d: string = useMemo(() => {
        if (points.length === 0) return '';

        return getPath([
            { x: points[0].x, y: viewport.height, },
            ...points,
            { x: points[points.length - 1].x, y: viewport.height, },
        ]) + ' Z';
    }, [points, viewport]);

    const gradientId = `color-${color}`;

    return (
        <g className="Chart__area">
            <linearGradient
                id={gradientId}
                viewBox={`0 0 ${viewport.width} ${viewport.height}`}
                gradientTransform="rotate(90)"

            >
                <stop offset="0%" stopColor={color}/>
                <stop offset="100%" stopColor="transparent"/>
            </linearGradient>
            <path
                fillOpacity={isActive ? 1 : DISABLED_OPACITY}
                d={d}
                fill={`url(#${gradientId})`}
                style={{ opacity: 0.3 }}
            />
        </g>
    );
};

export default memo(ChartArea);