import { KEYWORD_SETTINGS } from '../core/actionNames';

const { SET_FIELD, SET_DATA, RESET } = KEYWORD_SETTINGS;

const getInitialState = () => ({
    appetizers: [],
    entrees: [],
    sides: [],
    desserts: [],
    beverages: [],
    dayParts: [],
    descriptors: [],
    flavors: [],
    ingredients: [],
    menuItems: [],
    topTrendsCategory: [
        { id: 1, name: 'Alcoholic Beverages', categoryDisplayNameValue: 'ALCOHOLS AND BEVERAGES' },
        { id: 2, name: 'Bread', categoryDisplayNameValue: 'BREADS' },
        { id: 3, name: 'Cheese', categoryDisplayNameValue: 'CHEESES' },
        { id: 4, name: 'Food & Bev Descriptors', categoryDisplayNameValue: 'Food&Beverage Attributes'},
        { id: 5, name: 'Desserts', categoryDisplayNameValue: 'Desserts' },
        { id: 6, name: 'Dessert Flavors & Ingred.', categoryDisplayNameValue: 'Dessert Flavors & Ingredients' },
        { id: 7, name: 'Foods & Dishes', categoryDisplayNameValue: 'FOODS & DISHES' },
        { id: 8, name: 'Global Foods', categoryDisplayNameValue: 'Global Foods' },
        { id: 9, name: 'Nuts, Seeds, & Specialty', categoryDisplayNameValue: 'Foods, Beverages & Ingredients' },
        { id: 10, name: 'Non-Alcoholic Beverages', categoryDisplayNameValue: 'Beverages' },
        { id: 11, name: 'Pasta', categoryDisplayNameValue: 'PASTAS' },
        { id: 12, name: 'Meat & Seafood', categoryDisplayNameValue: 'MEATS & SEAFOOD' },
        { id: 13, name: 'Flavors, Sauces & Spices', categoryDisplayNameValue: 'Flavors, Spices & Sauces' },
        { id: 14, name: 'Side Dishes', categoryDisplayNameValue: 'SIDE DISHES' },
        { id: 15, name: 'Snack Items', categoryDisplayNameValue: 'Snack Items' },
        { id: 16, name: 'Fruits & Veggies', categoryDisplayNameValue: 'Fruits & Veggies' },
        { id: 17, name: 'Brands', categoryDisplayNameValue: 'Brands/ Products' },
        { id: 18, name: 'Alcohol Brands', categoryDisplayNameValue: 'Brands/Varieties of Alcohol' },

    ],
    macStage: [
        { id: 1, name: 'Inception' },
        { id: 2, name: 'Adoption' },
        { id: 3, name: 'Proliferation' },
        { id: 4, name: 'Ubiquity' },
    ],
    tagCategories: [],
});

export default function keywordSettings(state = getInitialState(), action) {
    switch (action.type) {
        case SET_FIELD: {
            return {
                ...state,
                [action.name]: action.value
            };
        }
        case SET_DATA: {
            return {
                ...state,
                ...action.data
            };
        }
        case RESET: {
            return getInitialState();
        }
        default: {
            return { ...state };
        }
    }
}