import React, { FC } from 'react';
import MultipleInput from '@components/MultipleInput/MultipleInput';
import { FilterSettings, iFilterSettings } from '@models/FilterSettings';
import { iCategoryReport } from '@models/CategoryReport';
import { iOption } from '@models';
import cn from 'classnames';

const SelectKeyword: FC<{
    data: iCategoryReport;
    handleChange: (field: keyof iCategoryReport) => (value: iOption[]) => void;
    filtersSettingsData: iFilterSettings;
}> = ({
    data,
    handleChange,
    filtersSettingsData,
}) => {
    const keyword = data.keywords[0];
    return (
        <div className="AdminFilters__section">
            <div className="AdminFilters__column size-xl p20">
                <MultipleInput
                    isDisabled={data.keywords.length > 0}
                    submittedItems={filtersSettingsData.words.filter(i => data.keywords.some(ii => ii.id === i.id))}
                    onSubmit={(words: FilterSettings['words']) => {
                        handleChange('keywords')([{
                            id: words[0].id!,
                            title: '',
                        }])
                    }}
                    removeItem={() => handleChange('keywords')([])}
                    keywords={filtersSettingsData.words}
                    modifiers={["filters", 'light', 'medium']}
                    onlyFromList
                    placeholder={data.keywords.length > 0 ? "You can add only ONE keyword" : "Type a keyword name"}
                    isUpperCase
                />
            </div>
            {!!keyword && (
                <div className="AdminFilters__column size-xl">
                    <label className="AdminContainer__input-wrap">
                        <span className={cn(
                            "AdminContainer__input-label",
                            keyword.title.length > 0 ? "is-upper" : "is-bottom",
                        )}>
                            Display name
                        </span>
                        <input
                            type="text"
                            className="AdminContainer__input"
                            value={keyword.title}
                            onChange={({ target: { value }}) => handleChange('keywords')([{
                                ...keyword,
                                title: value,
                            }])}
                        />
                    </label>
                </div>
            )}
        </div>
    );
};

export default SelectKeyword;