import React from "react";
import PT from "prop-types";
import "./Button.scss";
import { Link } from "react-router-dom";

const Button = ({
    children,
    url = '',
    onClick,
    isDisabled = false,
    className = "",
    modifiers = [],
    shouldOpenNewTab = false,
}) => {
    const classList = new Set([
        "Button",
        ...className.split(' '),
        ...modifiers.map(i => `Button--${i}`),
    ]);

    let Option = 'button';
    const optionProps = {};
    optionProps.className = Array.from(classList).join(" ");


    if(url) {
        Option = Link;
        optionProps.to = url;
        optionProps.target = shouldOpenNewTab ? '_blank' : ''
    } else {
        optionProps.onClick = onClick;
        optionProps.disabled = isDisabled;
    }

    return (
        <Option {...optionProps}>
            {children}
        </Option>
    );
};

Button.propTypes = {
    onClick: PT.func,
    isDisabled: PT.bool,
    className: PT.string,
    modifiers: PT.array,
};

export default Button;
