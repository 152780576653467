import React, {useState, useEffect} from 'react';
import './TableInfo.scss';
import { getFormattedThousand } from '@core/old_helpers';
import { CSSTransition } from "react-transition-group";

const defaultAdditionalText = ', that contain each of the following terms';

const TableInfo = (
    {
        handleDeleteItem,
        totalRecords,
        drillWords = [],
        className,
    }
) => {
    const [additionalText, setAdditionalText] = useState('');
    const [isAdditionalTextShown, setIsAdditionalTextShown] = useState(false);

    useEffect(() => {
        if (drillWords.length > 0 && additionalText === '') {
            setIsAdditionalTextShown(true);
        }

        if (drillWords.length === 0 && additionalText.length > 0) {
            setIsAdditionalTextShown(false);
        }
    }, [drillWords]);

    useEffect(() => {
        if (isAdditionalTextShown && additionalText.length !== defaultAdditionalText.length) {
            setTimeout(() => {
                setAdditionalText(defaultAdditionalText.substr(0, additionalText.length + 1));
            }, 30);
        }
        if (!isAdditionalTextShown && additionalText !== '') {
            setAdditionalText('');
        }
    }, [isAdditionalTextShown, additionalText]);

    return (
        <div className={`tableInfo ${className}`}>
            <div className={`
                tableInfo__info
                ${totalRecords === 0 ? 'tableInfo__info--loading' : ''}
                ${drillWords.length === 0 ? 'tableInfo__info--bottom' : ''}
            `}>
                Analysis of&nbsp;
                <span
                    className='tableInfo__count'
                >
                    {getFormattedThousand(totalRecords)}
                </span>
                &nbsp;menu items
                <span>{additionalText}</span>
            </div>
            <CSSTransition
                in={additionalText.length === defaultAdditionalText.length}
                classNames="appears2"
                timeout={3000}
                unmountOnExit
            >
                <div className='tableInfo__drilled-list'>
                    {drillWords.map((word) =>
                        <span
                            key={word.id}
                            className='tableInfo__drilled-item'
                        >
                            {word.name}
                            <span
                                onClick={() => handleDeleteItem(word.id)}
                            >
                                <span className='tableInfo__icon-container'/>
                            </span>
                        </span>
                    )}
                </div>
            </CSSTransition>
        </div>
    )
};

export default TableInfo;