import React, { createContext, FC, useEffect, useMemo, useState } from 'react';
import Page from '@components/Page/Page';
import Loader from '@components/Loader/Loader';
import { Routes } from '@routes';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { COUNTRIES_ARRAY } from '@reducers/countries.reducer';
import { equalInLC, toggleInArray } from '@core/old_helpers';
import useFilterSettings from '@apiHooks/useFiltersSettings';
import useCategoryReport from '@apiHooks/useCategoryReport';
import AdminFilters, { SectionTitle } from '@pages/Admin/Common/AdminFilters/AdminFilters';
import { iCategoryReport } from '@models/CategoryReport';
import { createAdminCategoryReport, updateAdminCategoryReport } from '@core/api';
import { FilterValue, iFilterValue } from '@models/FilterValue';
import Section from '@pages/Admin/CategoryReport/Section';
import OptionalSections from '@pages/Admin/CategoryReport/OptionalSections';
import MainSettings from '@pages/Admin/CategoryReport/MainSettings';
import SelectKeyword from '@pages/Admin/CategoryReport/SelectKeyword';
import SelectInstantChart  from '@pages/Admin/CategoryReport/SelectInstantChart';
import useAdminInstantChartsData from '@apiHooks/useAdminInstantChartsList';
import { InstantChartContext } from '@pages/Admin/Common/SelectInstantCharts/SelectInstantCharts';
import { isInRange } from '@core/helpers';
import { useDispatch } from 'react-redux';
import { addNotification } from '@actions/notifications.actions';
import Flag from '@components/Flag/Flag';
import cn from 'classnames';
import { ID } from '@models';
import Filters from '@components/Filters/Filters';
import useAdminDictionaries from '@apiHooks/useAdminDictionaries';

export const SectionsControlContext = createContext<{
    savesCount: number;
}>({
    savesCount: 0,
})

const AdminCategoryReport: FC = () => {
    const location = useLocation();
    const history = useNavigate();
    const query = new URLSearchParams(location.search);
    const dispatch = useDispatch();
    const currentCountryCode = query.get("country") || 'USA';
    const currentReportId = query.get("id") || 0;
    const currentCountry = COUNTRIES_ARRAY.find(item => equalInLC(item.countryCode, currentCountryCode))!;
    const isNewReport = useMemo(() => {
        return location.pathname === Routes.AdminCategoryReportAdd
    }, [location]);

    const pushNotification = (notification: any) => dispatch(addNotification(notification));

    const {
        data: filtersSettingsData,
        isLoading: isFiltersSettingsLoading,
    } = useFilterSettings(currentCountryCode);

    const {
        data: dictionariesData,
    } = useAdminDictionaries();

    const {
        data,
        isLoading: isDataLoading,
        setData,
        handleChange,
    } = useCategoryReport(currentReportId);

    const {
        data: instantChartsData,
    } = useAdminInstantChartsData(currentCountryCode);

    const [isSaving, setIsSaving] = useState(false);
    const [savesCount, setSavesCount] = useState(0);
    const [isValidationShown, setIsValidationShown] = useState(false);
    const [enabledOptionalSections, setEnabledOptionalSections] = useState<Array<keyof iCategoryReport>>([]);

    const errorFields: Array<keyof iCategoryReport> = useMemo(() => {
        const result: Array<keyof iCategoryReport> = [];

        if (!data.name.trim()) result.push('name');
        if (data.keywords.length !== 1) result.push('keywords');
        if (data.instantCharts.length !== 1) result.push('instantCharts');

        if (data.ingredientsMAC.length === 0 && enabledOptionalSections.includes('ingredientsMAC')) {
            result.push('ingredientsMAC');
        }
        if (data.differentVarietiesMAC.length === 0 && enabledOptionalSections.includes('differentVarietiesMAC')) {
            result.push('differentVarietiesMAC');
        }
        if (!isInRange(Object.keys(data.mostPopularInstantCharts).length, 2, 5) && enabledOptionalSections.includes('mostPopularInstantCharts')) {
            result.push('mostPopularInstantCharts');
        }
        if (!isInRange(data.topTrendCategories.length, 2, 5) && enabledOptionalSections.includes('topTrendCategories')) {
            result.push('topTrendCategories');
        }
        if (data.insiderCategories.length === 0 && enabledOptionalSections.includes('insiderCategories')) {
            result.push('insiderCategories');
        }
        if (data.flavorCategories.length === 0 && enabledOptionalSections.includes('flavorCategories')) {
            result.push('flavorCategories');
        }

        return result;
    }, [data, enabledOptionalSections]);

    const isLoading = useMemo(
        () => isDataLoading || isFiltersSettingsLoading || isSaving,
        [isDataLoading, isFiltersSettingsLoading, isSaving]
    );

    const save = () => {
        setSavesCount(savesCount + 1);
        if (errorFields.length === 0) {
            setIsSaving(true);
            const method = isNewReport ? createAdminCategoryReport : updateAdminCategoryReport;
            method(data.mapForApi())
                .then(() => {
                    history(Routes.AdminCategoryReports);
                    pushNotification({
                        success: true,
                        text: `Category Report was successfully ${isNewReport ? 'created' : 'updated'}.`,
                        duration: 4000,
                    });
                })
                .catch(() => {
                    pushNotification({
                        error: true,
                        text: "Something went wrong.",
                        duration: 4000,
                    });
                }).finally(() => {
                    setIsSaving(false);
                })
        }
        else {
            pushNotification({
                error: true,
                text: "Please check validation.",
                duration: 4000,
            });
            setIsValidationShown(true);
        }
    };

    const handleChangeUniverse = (data: iFilterValue) => {
        handleChange('universe')(new FilterValue({ model: data }));
    };

    useEffect(() => {
        if (isNewReport && !isFiltersSettingsLoading) {
            setData({
                restaurantListId: filtersSettingsData.restaurantListId,
                countryCode: currentCountryCode,
                universe: new FilterValue({
                    model: FilterValue.getDefaultFilterValue(filtersSettingsData),
                }),
            });
        }
    }, [isLoading, isNewReport]);

    const instantChartsSelectionTitle: string = useMemo(() => {
        return data.instantCharts.map(item => {
            if (!!item.title) {
                return item.title;
            }
            const itemFromList = instantChartsData.items.find(i => i.id === item.id)
            return `${itemFromList?.title}`;
        }).join(', ');
    }, [instantChartsData, data.instantCharts]);

    const keywordSelectionTitle: string = useMemo(() => {
        return data.keywords.map(item => {
            if (!!item.title) {
                return item.title;
            }
            const word = filtersSettingsData.words.find(i => i.id === item.id);
            return word?.name;
        }).join(' ');
    }, [filtersSettingsData, data.keywords]);

    return (
        <SectionsControlContext.Provider value={{
            savesCount,
        }}>
            <Page isWrapped title={isNewReport ? "New report" : "Edit report"}>
                <div className="AdminContainer AdminContainer--no-pt">
                    <Loader className="is-fixed" isShown={isLoading}/>

                    <div className="AdminContainer__content">
                        <div className="AdminContainer__title">
                            {isNewReport ? "Add new" : "Edit"} Category report
                            <div className="AdminContainer__title-info">
                            <span>
                                <Flag compact component={currentCountry.component} />
                            </span>
                                <span>{currentCountry.countryCode}</span>
                            </div>
                        </div>
                        <div className="AdminContainer__top-title is-fixed">
                            <div className="AdminContainer__top">
                                <div className="AdminContainer__top-name">
                                    <label className="AdminContainer__input-wrap">
                                        <span className={cn(
                                            "AdminContainer__input-label",
                                            "is-required",
                                            data.name.length > 0 ? "is-upper" : "is-bottom",
                                        )}>
                                            Category report name
                                        </span>
                                        <input
                                            type="text"
                                            className="AdminContainer__input"
                                            value={data.name.toUpperCase()}
                                            onChange={(event) => {
                                                handleChange('name')(event.target.value.toUpperCase());
                                            }}
                                        />
                                        {(isValidationShown && errorFields.includes('name')) && (
                                            <div className="AdminContainer__error">
                                                The category report's name can't be blank
                                            </div>
                                        )}
                                    </label>
                                </div>

                                <div className="AdminContainer__btns">
                                    <Link to={Routes.AdminCategoryReports} className="btn btn--link">
                                        Discard
                                    </Link>
                                    <button
                                        className={`btn btn--filled`}
                                        onClick={save}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                        <InstantChartContext.Provider value={instantChartsData}>
                            <div className="AdminContainer__main">
                                <div className="AdminContainer__body">
                                    <MainSettings data={data} handleChange={handleChange}/>
                                    {!isLoading && (
                                        <>
                                            <Section
                                                title="Select which universe to use:"
                                                isCollapsedByDefault={!isNewReport}
                                            >
                                                <AdminFilters
                                                    settings={filtersSettingsData}
                                                    data={data.universe}
                                                    onChange={handleChangeUniverse}
                                                    disabledFields={['regions']}
                                                    currentCurrency={currentCountry.currency}
                                                />
                                                <SectionTitle>Top Trends Categories</SectionTitle>
                                                <Filters
                                                    filtersArray={dictionariesData.topTrendsCategories.map(item => ({
                                                        id: item.id,
                                                        name: item.name,
                                                        isSelected: data.topTrendCategoriesUniverse.includes(item.id!),
                                                    }))}
                                                    toggleFilter={(id: ID) => handleChange('topTrendCategoriesUniverse')(toggleInArray(data.topTrendCategoriesUniverse, id))}
                                                />
                                            </Section>
                                            <Section
                                                title="Select a Category Keyword (Category Overview):"
                                                isCollapsedByDefault={!isNewReport}
                                                hasError={isValidationShown && errorFields.includes('keywords')}
                                                headerContent={(
                                                    <div>
                                                        {data.keywords.length > 0 ? (
                                                            <>
                                                                selected keyword:&nbsp;
                                                                <b>{keywordSelectionTitle}</b>
                                                            </>
                                                        ) : (
                                                            <>select a keyword</>
                                                        )}
                                                    </div>
                                                )}
                                            >
                                                <SelectKeyword
                                                    data={data}
                                                    handleChange={handleChange}
                                                    filtersSettingsData={filtersSettingsData}
                                                />
                                            </Section>

                                            <Section
                                                title="Select an Instant Chart for Analysis (Category Overview):"
                                                isCollapsedByDefault={!isNewReport}
                                                hasError={isValidationShown && errorFields.includes('instantCharts')}
                                                headerContent={(
                                                    <div>
                                                        {data.instantCharts.length > 0 ? (
                                                            <>
                                                                selected chart:&nbsp;
                                                                <b>{instantChartsSelectionTitle}</b>
                                                            </>
                                                        ) : (
                                                            <>select an instant chart</>
                                                        )}
                                                    </div>
                                                )}
                                            >
                                                <SelectInstantChart
                                                    value={data.instantCharts}
                                                    handleChange={handleChange('instantCharts')}
                                                />
                                            </Section>

                                            <OptionalSections
                                                data={data}
                                                handleChange={handleChange}
                                                handleToggleEnabledSections={setEnabledOptionalSections}
                                                errorFields={errorFields}
                                                isValidationShown={isValidationShown}
                                                dictionariesData={dictionariesData}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </InstantChartContext.Provider>
                    </div>
                </div>
            </Page>
        </SectionsControlContext.Provider>
    );
};

export default AdminCategoryReport;