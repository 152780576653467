import { FC, ReactNode, useState } from "react";
import { IconLock } from "@icons";
import './PermissionOverlay.scss';
import cn from "classnames";

const DEFAULT_TITLE = "Learn what consumers think";


const DEFAULT_DESCRIPTION = "of these foods and flavors";

const PermissionOverlay: FC<{
    title?: string | ReactNode;
    description?: string | ReactNode;
    handleButtonClick: () => void;
    variant?: 'string';
    hideIcon?: boolean;
}> = (
    {
        title = DEFAULT_TITLE,
        description = DEFAULT_DESCRIPTION,
        handleButtonClick,
        variant = 'regular',
        hideIcon,
    }
) => {
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const handleClick = () => {
        setIsButtonClicked(true);
        handleButtonClick();
    };

    return (
        <div className={cn(
            "PermissionOverlay",
            ...variant.split(' ').map(i => `PermissionOverlay--${i}`),
        )}>
            <div className="PermissionOverlay__content">
                {!hideIcon && <IconLock className="PermissionOverlay__icon"/>}
                <div className="PermissionOverlay__text">
                    <div className="PermissionOverlay__title">
                        {title}
                    </div>
                    <div className="PermissionOverlay__description">
                        {description}
                    </div>
                </div>
                <button
                    disabled={isButtonClicked}
                    className="PermissionOverlay__button"
                    onClick={handleClick}
                >
                    {isButtonClicked ? '✓ Request sent' : 'Contact Us'}
                </button>
            </div>
        </div>
    );
};

export default PermissionOverlay;