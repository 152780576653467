import { ActionBlock } from "@core/actionNames";

export const Actions = new ActionBlock('OPTIONS', ['ADD', 'EDIT', 'EDIT_BROADCAST', 'EDIT_FIELD', 'REMOVE', 'COPY', 'SET_DATA']);

export const reducer = (state = [], action) => {
    switch (action.type) {
        case Actions.ADD: {
            return [
                ...state,
                action.data,
            ];
        }
        case Actions.SET_DATA: {
            return action.data;
        }
        case Actions.EDIT: {
            const { index, data } = action;

            return [
                ...state.slice(0, index),
                {
                    ...state[index],
                    ...data,
                },
                ...state.slice(index + 1),
            ];
        }
        case Actions.EDIT_BROADCAST: {
            const { data } = action;

            return state.map((option, index) => ({
                ...option,
                ...data[index],
            }));
        }
        case Actions.EDIT_FIELD: {
            const { index, sectionName, fieldName, value } = action;

            return [
                ...state.slice(0, index),
                {
                    ...state[index],
                    [sectionName]: {
                        ...state[index][sectionName],
                        [fieldName]: value,
                    },
                },
                ...state.slice(index + 1),
            ];
        }
        case Actions.REMOVE: {
            const { index } = action;
            return [
                {
                    ...state[0],
                    countryCodes: [
                        ...state[0].countryCodes,
                        ...state[index].countryCodes,
                    ],
                },
                ...state.slice(1, index),
                ...state.slice(index + 1),
            ]
        }

        case Actions.COPY: {
            const { originOptionIndex, targetOptionIndex } = action;
            const {
                main,
                foodProfile,
                topTrends,
                flavor,
            } = state[originOptionIndex];

            return [
                ...state.slice(0, targetOptionIndex),
                {
                    ...state[targetOptionIndex],

                    main,
                    foodProfile,
                    topTrends,
                    flavor,
                },
                ...state.slice(targetOptionIndex + 1),
            ];
        }
        default: {
            return [...state];
        }
    }
};
