import React from "react";
import { ActionBlock } from "@core/actionNames";
import { getChildCategories } from "./Report.helpers";

export const Actions = new ActionBlock("OPTIONS", [
    "SET_DATA",
    "ADD",
    "EDIT",
    "EDIT_FIELD",
    "SET_FIELD",
    "REMOVE",
    "UPDATE_ROWS",
    "UPDATE_GENERATOR_FILTER",
    "ADD_CHILD_REPORTS",
    "REMOVE_CHILD_REPORT",
]);
export const ReportContext = React.createContext();

export const reportOptions = (state = {}, action) => {
    const FIELD_CHILDS = "childReports";
    const FIELD_CHARTS = "childInstantCharts";
    const FIELD_SELECTED_CATEGORIES = "selectedChildReportsCategories";
    const FIELD_SELECTED_SUBCATEGORIES = "selectedChildReportsSubCategories";
    const FIELD_GENERATOR_FILTER = "generatorFilter";
    const FIELD_SELECTED_CHARTS_CATEGORIES = "selectedInstantChartsCategories";
    const FIELD_SELECTED_CHARTS_SUBCATEGORIES = "selectedInstantChartsSubCategories";

    const removeItem = (arr, removedIdx) => {
        return [...arr.slice(0, removedIdx), ...arr.slice(removedIdx + 1)];
    };

    switch (action.type) {
        case Actions.SET_DATA: {
            return action.data;
        }

        case Actions.ADD: {
            return {
                ...state,
                ...action.data,
            };
        }

        case Actions.EDIT: {
            const { sectionName, index, data } = action;

            return {
                ...state,
                [sectionName]: [
                    ...state[sectionName].slice(0, index),
                    {
                        ...state[sectionName][index],
                        ...data,
                    },
                    ...state[sectionName].slice(index + 1),
                ],
            };
        }

        case Actions.REMOVE_CHILD_REPORT: {
            const { id } = action;

            const getIdx = (data, item, field) => {
                return data.findIndex(({ name }) => name === item[field]);
            };

            const matchItems = (data, field, removedItem) => {
                return data.find(item => item[field] === removedItem[field]) ? false : true;
            };

            const removedItemIdx = state[FIELD_CHILDS].findIndex(item => item.id === id);
            const removedItem = state[FIELD_CHILDS][removedItemIdx];
            const childReports = removeItem(state[FIELD_CHILDS], removedItemIdx);
            const childInstantCharts = [...state[FIELD_CHARTS], removedItem];
            const childReportsCategories = getChildCategories(childReports);
            const removedCategoryIdx = getIdx(state.selectedChildReportsCategories, removedItem, "category");
            const removedSubCategoryIdx = getIdx(state.selectedChildReportsSubCategories, removedItem, "subCategory");
            const matchCategory = matchItems(childReports, "category", removedItem);
            const matchSubCategory = matchItems(childReports, "subCategory", removedItem);

            const updateItems = (idx, matched, field) => {
                if (idx >= 0 && matched) {
                    return removeItem(field, idx);
                } else {
                    return [...field];
                }
            };
            const instantChartsCategories = getChildCategories(childInstantCharts);

            return {
                ...state,
                [FIELD_CHILDS]: childReports,
                [FIELD_CHARTS]: childInstantCharts,
                childReportsCategories,
                [FIELD_SELECTED_CATEGORIES]: updateItems(
                    removedCategoryIdx,
                    matchCategory,
                    state[FIELD_SELECTED_CATEGORIES],
                ),
                [FIELD_SELECTED_SUBCATEGORIES]: updateItems(
                    removedSubCategoryIdx,
                    matchSubCategory,
                    state[FIELD_SELECTED_SUBCATEGORIES],
                ),
                instantChartsCategories,
                [FIELD_SELECTED_CHARTS_CATEGORIES]: instantChartsCategories.categories,
                [FIELD_SELECTED_CHARTS_SUBCATEGORIES]: instantChartsCategories.subCategories
            };
        }

        case Actions.EDIT_FIELD: {
            const { sectionName, fieldName, value } = action;
            return {
                ...state,
                [sectionName]: {
                    ...state[sectionName],
                    [fieldName]: value,
                },
            };
        }

        case Actions.UPDATE_ROWS: {
            const { data } = action;

            return {
                ...state,
                [FIELD_CHILDS]: data.map(item => ({
                    ...state.childReports.find(report => report.id === item),
                })),
            };
        }

        case Actions.ADD_CHILD_REPORTS: {
            const { data } = action;
            const ids = data;
            const childReportsItems = [];
            const childInstantChartsItems = [];

            const matchItems = (data, items, field) => {
                return data.filter(item => items.map(el => el[field]).includes(item.name));
            };

            state.childInstantCharts.forEach(item => {
                if (ids.includes(item.id)) {
                    childReportsItems.push({
                        ...item,
                        name: item.instantChartName,
                        generatorFilter: item.generatorFilter.map(el => ({...el, isChecked: false})),
                        isExpanded: true
                    });
                } else {
                    childInstantChartsItems.push(item);
                }
            });

            const matchedCategory = !!matchItems(state.childReportsCategories.categories, childReportsItems, "category")
                .length;
            const matchedSubCategory = !!matchItems(
                state.childReportsCategories.subCategories,
                childReportsItems,
                "subCategory",
            ).length;

            const childReports = [...state.childReports, ...childReportsItems];
            const childReportsCategories = getChildCategories(childReports);
            const instantChartsCategories = getChildCategories(childInstantChartsItems);
            const newCategories = matchItems(childReportsCategories.categories, childReportsItems, "category");
            const newSubCategories = matchItems(childReportsCategories.subCategories, childReportsItems, "subCategory");

            return {
                ...state,
                [FIELD_CHARTS]: childInstantChartsItems,
                childReports,
                childReportsCategories,
                [FIELD_SELECTED_CATEGORIES]: [
                    ...state.selectedChildReportsCategories,
                    ...(matchedCategory ? [] : newCategories),
                ],
                [FIELD_SELECTED_SUBCATEGORIES]: [
                    ...state.selectedChildReportsSubCategories,
                    ...(matchedSubCategory ? [] : newSubCategories),
                ],
                instantChartsCategories,
                [FIELD_SELECTED_CHARTS_CATEGORIES]: instantChartsCategories.categories,
                [FIELD_SELECTED_CHARTS_SUBCATEGORIES]: instantChartsCategories.subCategories
            };
        }

        case Actions.UPDATE_GENERATOR_FILTER: {
            const {
                data: { parentId, index, value },
            } = action;
            const childIndex = state[FIELD_CHILDS].findIndex(item => item.id === parentId);

            return {
                ...state,
                [FIELD_CHILDS]: [
                    ...state[FIELD_CHILDS].slice(0, childIndex),
                    {
                        ...state[FIELD_CHILDS][childIndex],
                        [FIELD_GENERATOR_FILTER]: [
                            ...state[FIELD_CHILDS][childIndex][FIELD_GENERATOR_FILTER].slice(0, index),
                            {
                                ...state[FIELD_CHILDS][childIndex][FIELD_GENERATOR_FILTER][index],
                                isChecked: value,
                            },
                            ...state[FIELD_CHILDS][childIndex][FIELD_GENERATOR_FILTER].slice(index + 1),
                        ],
                    },
                    ...state[FIELD_CHILDS].slice(childIndex + 1),
                ],
            };
        }

        case Actions.SET_FIELD: {
            const { sectionName, id, value } = action;

            const index = state[sectionName].findIndex(item => item.id === id);

            return {
                ...state,
                [sectionName]: [
                    ...state[sectionName].slice(0, index),
                    {
                        ...state[sectionName][index],
                        ...value,
                    },
                    ...state[sectionName].slice(index + 1),
                ],
            };
        }

        default: {
            return { ...state };
        }
    }
};
