import React from "react";
import { connect } from 'react-redux';
import { setKeywordSettings } from '@actions/byo.actions';
import KeywordSettingsComponent from '@components/KeywordSettings/KeywordSettings';

const KeywordSettings = (props) => {
    return (
        <KeywordSettingsComponent {...props}/>
    );
};

const mapState = state => ({
    keywords: state.keywords.data,
});

const mapDispatch = dispatch => ({
    applySettings: (id, data, name) => dispatch(setKeywordSettings(id, data, name)),
});

export default connect(mapState, mapDispatch)(KeywordSettings);
