import React, { useCallback, useContext, useReducer } from 'react'
import { connect } from "react-redux"
import ConfirmationPopup from "@components/ConfirmationPopup/ConfirmationPopup";
import LoadByoSettingsContainer from "@containers/LoadByoSettingsContainer/LoadByoSettingsContainer";
import SaveByoSettingsPopup from "@components/SaveByoSettingsPopup/SaveByoSettingsPopup";
import {
    filtersDataSelector,
    filtersForBackEndSelector,
    restaurantsListsSelector
} from "@selectors/filters.selectors";
import { get as getByoFilters, setActiveRestaurantList } from "@actions/filters.actions";
import { clearByoSetting, save as saveByoSettings } from "@actions/byoSettings.actions";
import KeywordMenu from "@components/KeywordMenu/KeywordMenu";
import { exportInstantChartReport, exportLongTermReport, exportMenuMatrix, exportTrendDetails } from "@core/api";
import { addNotification } from "@actions/notifications.actions";
import apiConfig from "@core/apiConfig";
import { currentCountryWithPermissionsSelector } from "@selectors/countries.selector";
import ExportButton from '@components/ExportButton/ExportButtonContainer'
import { GlobalContext } from "@components/App/App.context";
import { IconPpt, IconXls } from "@icons";
import http from "@core/http";

function getFileIcon(iconType = '') {
    switch (iconType.toLowerCase()) {
        case 'ppt': return <IconPpt/>;
        default: return <IconXls/>;
    }
}

const FooterRightContent = (
    {
        currentByoSetting,
        saveByoSettings,
        clearByoSetting,
        keywords,
        filtersForBackEnd,
        rawFiltersData,
        addNotification,
        permission = {},
    }
) => {
    const { getRem } = useContext(GlobalContext);
    const [ state, dispatch ] = useReducer(reducer, initialState);
    const {hasLongTermTrend, isTrial} = permission;
    const {
        isClearMenuVisible,
        isLoadByoSettingsOpened,
        isSaveByoSettingsOpened,
        isClearByoSettingsOpened,
        isExportMenuShown,
        exportMenuX,
        exportMenuY,
    } = state;
    const clearMenuClassList = new Set([ "page-footer__btn-list" ]);

    const clearBtnClassList = new Set([
        "page-footer__link",
        isClearMenuVisible ? "page-footer__link--clicked" : "",
    ]);

    const handleClickClear = () => {
        clearByoSetting();
    };

    const exportData = {
        customerFilter: {
            mainFilter: {
                filters: filtersForBackEnd,
                words: keywords,
            },
        },
        fullFilter: {
            ...rawFiltersData,
            countryCode: filtersForBackEnd.countryCode,
        },
    };

    // TODO: refactor this shit!!!
    const exportMenuOptions = [
        {
            title: 'Trend Details',
            onClick: () => {
                const id = new Date().getTime();
                addNotification({
                    text: "Downloading...",
                    id,
                });
                exportTrendDetails(exportData)
                    .then((token) => {
                        http.downloadFileXHRFromUrl(
                            'GET',
                            apiConfig.get('export trend details', {key: token})
                        ).then(() => {
                            addNotification({
                                text: "Download was successful",
                                duration: 4000,
                                id,
                            });
                        });
                    })
                    .catch((error) => addNotification({
                        text: `Something went wrong `,
                        duration: 4000,
                        id,
                        error: true,
                    }));
            },
        },
        {
            title: 'MenuMatrix Report',
            id: 1,
            onClick: () => {
                const id = new Date().getTime();
                addNotification({
                    text: "Downloading...",
                    id,
                });
                exportMenuMatrix(exportData)
                    .then((token) => {
                        http.downloadFileXHRFromUrl(
                            'GET',
                            apiConfig.get('export menu matrix', {key: token})
                        ).then(() => {
                            addNotification({
                                text: "Download was successful",
                                duration: 4000,
                                id,
                            });
                        }).catch((error) => addNotification({
                            text: `Something went wrong `,
                            duration: 4000,
                            id,
                            error: true,
                        }));
                    })
                    .catch((error) => addNotification({
                        text: `Something went wrong `,
                        duration: 4000,
                        id,
                        error: true,
                    }));
            },
        },
        {
            title: 'Instant Chart Report',
            iconType: 'ppt',
            onClick: () => {
                const id = new Date().getTime();
                addNotification({
                    text: "Downloading...",
                    id,
                });
                exportInstantChartReport({
                    ...exportData.customerFilter.mainFilter,
                    instantChartId: 0,
                })
                    .then((token) => {
                        http.downloadFileXHRFromUrl(
                            'GET',
                            apiConfig.get('export BYO instant chart with flavor', {key: token})
                        ).then(() => {
                            addNotification({
                                text: "Download was successful",
                                duration: 4000,
                                id,
                            });
                        }).catch((error) => addNotification({
                            text: `Something went wrong `,
                            duration: 4000,
                            id,
                            error: true,
                        }));
                    })
                    .catch((error) => addNotification({
                        text: `Something went wrong `,
                        duration: 4000,
                        id,
                        error: true,
                    }));
            },
        },
    ];

    if (hasLongTermTrend) {
        exportMenuOptions.unshift({
            title: 'Long Term Trend',
            onClick: () => {
                const id = new Date().getTime();
                addNotification({
                    text: "Downloading...",
                    id,
                });
                exportLongTermReport(exportData)
                    .then((token) => {
                        http.downloadFileXHRFromUrl(
                            'GET',
                            apiConfig.get('export long term report', {key: token})
                        ).then(() => {
                            addNotification({
                                text: "Download was successful",
                                duration: 4000,
                                id,
                            });
                        }).catch((error) => addNotification({
                            text: `Something went wrong `,
                            duration: 4000,
                            id,
                            error: true,
                        }));
                    })
                    .catch((error) => addNotification({
                        text: `Something went wrong `,
                        duration: 4000,
                        id,
                        error: true,
                    }));
            },
        });
    }

    const handleExportMenuClick = event => {
        if (hasLongTermTrend) {
            const boundingRect = event.currentTarget.getBoundingClientRect();
            dispatch({
                exportMenuX: boundingRect.right - getRem(210),
                exportMenuY: boundingRect.top - getRem(10) - exportMenuOptions.length * getRem(40),
                isExportMenuShown: true,
            });
        } else {
            exportMenuOptions.find(({title}) => title === 'Trend Details').onClick();
        }

    };

    return (
        <div className="page-footer__btn-container">
            <div className="page-footer__btn-wr">
                <button
                    onClick={() =>
                        dispatch({isClearByoSettingsOpened: true})
                    }
                    className={Array.from(clearBtnClassList).join(" ")}
                >
                    Clear
                </button>
                <div
                    className={Array.from(clearMenuClassList).join(" ")}
                >
                    <button
                        className="page-footer__list-item"
                        onClick={() =>
                            dispatch({isLoadByoSettingsOpened: true})
                        }
                    >
                        Load
                    </button>
                    <button
                        className="page-footer__list-item"
                        onClick={() =>
                            dispatch({isSaveByoSettingsOpened: true})
                        }
                    >
                        Save
                    </button>
                </div>
            </div>
            <ExportButton
                isTrial={isTrial}
                handleExportMenuClick={handleExportMenuClick} />
            <KeywordMenu
                x={exportMenuX}
                y={exportMenuY}
                closeModal={() => dispatch({isExportMenuShown: false})}
                shown={isExportMenuShown}
                closeOnOptionClick
                options={exportMenuOptions.map(option => ({
                    ...option,
                    title: <>
                        {getFileIcon(option.iconType)}
                        &nbsp;&nbsp;
                        {option.title}
                    </>
                }))}
                className="ByoExportMenu"
            />
            <ConfirmationPopup
                closeModal={() => dispatch({isClearByoSettingsOpened: false})}
                shown={isClearByoSettingsOpened}
                title="Clear keywords and filters"
                text="Are you sure you want to clear all keywords and filters?"
                onConfirm={handleClickClear}
            />
            <LoadByoSettingsContainer
                closeModal={() => dispatch({isLoadByoSettingsOpened: false})}
                shown={isLoadByoSettingsOpened}
            />
            <SaveByoSettingsPopup
                closeModal={() => dispatch({isSaveByoSettingsOpened: false})}
                shown={isSaveByoSettingsOpened}
                onSave={saveByoSettings}
                currentByoSetting={currentByoSetting}
            />
        </div>
    )
};

const initialState = {
    isExportMenuShown: false,
    exportMenuX: null,
    exportMenuY: null,
    isLoadByoSettingsOpened: false,
    isSaveByoSettingsOpened: false,
    isClearByoSettingsOpened: false,
};

const reducer = (state, action) => {
    return {...state, ...action};
};

const mapStateToProps = state => ({
    restaurantLists: restaurantsListsSelector(state),
    currentByoSetting: state.byoSettings.currentByoSetting,
    byoSettingsData: state.byoSettings.data,
    isUsa: state.countries.current === 'USA',
    isFiltersPopupShown: state.filters.isFiltersPopupShown,
    rawFiltersData: filtersDataSelector(state),
    filtersForBackEnd: filtersForBackEndSelector(state),
    keywords: state.byo.data.map(({keyword}) => keyword),
    permission: currentCountryWithPermissionsSelector(state),

});

const mapDispatchToProps = dispatch => ({
    getFilters: country => dispatch(getByoFilters(country)),
    setActiveRestaurantListId: id => dispatch(setActiveRestaurantList(id)),
    saveByoSettings: reportModel => dispatch(saveByoSettings(reportModel)),
    clearByoSetting: () => dispatch(clearByoSetting()),
    addNotification: notification => dispatch(addNotification(notification)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FooterRightContent);