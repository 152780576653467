import React, { FC, ReactNode } from "react";
import cn from "classnames";
import "./ChartTooltip.styles.scss";
import {
    iTooltipRow,
    iTooltipColumnsGroup,
} from "@components/Chart/ChartTooltip/ChartTooltip.helpers";

const ChartTooltip: FC<{
    title: ReactNode;
    rows: iTooltipRow[];
    data: iTooltipColumnsGroup[];
    x: number;
    y: number;
    position: 'left' | 'right';
}> = (
    {
        title,
        data,
        rows,
        x,
        y,
        position,
    }
) => {
    return (
        <div
            className="ChartTooltip__container"
            style={{
                transform: `translate(${x}px, ${y}px)`,
            }}
        >
            <div className={cn("ChartTooltip", `ChartTooltip--${position}`)}>
                <div className="ChartTooltip__table">
                    <div className="ChartTooltip__group ChartTooltip__group--fluid">
                        <div className="ChartTooltip__head" style={{ zIndex: 2 }}>
                            <div className="ChartTooltip__cell ChartTooltip__cell--head">
                                {title}
                            </div>
                        </div>
                        <div className="ChartTooltip__body">
                            {rows.map((row) => (
                                <div key={row.color} className="ChartTooltip__row">
                                    <div className="ChartTooltip__cell ChartTooltip__cell--fluid">
                                        <div className="ChartTooltip__colors">
                                            <div className="TooltipPoint" style={{ color: row.color }}/>
                                        </div>
                                        <div className="ChartTooltip__name">{(row.title || '').toUpperCase()}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {data.length > 1 && (
                            <div className="ChartTooltip__footer">
                                <div className="ChartTooltip__footer-label"/>
                            </div>
                        )}
                    </div>

                    {data.map((group, groupIndex) => (
                        <div key={group.title} className={cn([
                            "ChartTooltip__group",
                            groupIndex % 2 === 1 && "ChartTooltip__group--muted",
                        ])}>
                            <div className="ChartTooltip__head">
                                {group.columns.map((column) => (
                                    <div
                                        className="ChartTooltip__cell"
                                    >
                                        {column.title}
                                    </div>
                                ))}
                            </div>

                            <div className="ChartTooltip__body">
                                {rows.map((row, rowIndex) => (
                                    <div className="ChartTooltip__row">
                                        {group.columns.map((column) => (
                                            <div className="ChartTooltip__cell">
                                                {row.values[column.index]}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>

                            {data.length > 1 && (
                                <div className="ChartTooltip__footer">
                                    <div className={cn([
                                        "ChartTooltip__footer-label",
                                        groupIndex % 2 === 1 && "is-muted",
                                    ])}>
                                        {group.title}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ChartTooltip;
