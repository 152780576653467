import React, { FC, useContext, useState } from 'react';
import { iCategoryReport } from '@models/CategoryReport';
import { ID } from '@models';
import SelectInstantCharts, { InstantChartContext } from '@pages/Admin/Common/SelectInstantCharts/SelectInstantCharts';
import AdminTable from '@pages/Admin/Common/AdminTable';
import Cell from '@components/NewAwesomeTable/Cell';
import Icon from '@components/Icon/Icon';
import { IconCheckSuccess, IconCloseFailure, IconDelete } from '@icons';

const SelectInstantChart: FC<{
    value: iCategoryReport['mostPopularInstantCharts'];
    handleChange: (value: iCategoryReport['mostPopularInstantCharts']) => void;
}> = ({
    value,
    handleChange,
}) => {
    const { items, categories, subCategories } = useContext(InstantChartContext);
    const [isAddPopupShown, setIsAddPopupShown] = useState(false);

    const valueLength = Object.keys(value).length;

    const applySelection = (ids: ID[]) => {
        handleChange([
            ...value,
            ...ids.map(id => ({
                id,
                title: '',
            }))
        ]);
    }

    const handleChangeDisplayName = (id: ID, title: string) => {
        const itemIndex = value.findIndex(i => i.id === id);
        handleChange([
            ...value.slice(0, itemIndex),
            { id, title, },
            ...value.slice(itemIndex + 1),
        ]);
    };

    const deleteItem = (id: ID) => {
        const itemIndex = value.findIndex(i => i.id === id);

        handleChange([
            ...value.slice(0, itemIndex),
            ...value.slice(itemIndex + 1),
        ]);
    };

    const Row = ({ index, style }: { index: number, style: any }) => {
        const item = value[index];
        const itemFromList = items.find(i => i.id === item.id);

        if (item && itemFromList) {
            const { title, categoryId, subCategoryId, isActive } = itemFromList;
            const category = categories.find(i => i.id === categoryId);
            const subCategory = subCategories.find(i => i.id === subCategoryId);
            return (
                <div style={style} className={`AdminTable__row`}>
                    <Cell className="AdminTable__col AdminTable__col--large-extra AdminTable__col--no-pr">
                        <label
                            className="AdminContainer__input-wrap"
                            style={{
                                width: '100%'
                            }}
                        >
                            <input
                                type="text"
                                className="AdminContainer__input"
                                value={item.title}
                                onChange={(event => handleChangeDisplayName(item.id, event.target.value))}
                                placeholder="Type custom name"
                                style={{
                                    textTransform: 'none',
                                    width: '100%'
                                }}
                                title={item.title}
                            />
                        </label>
                    </Cell>
                    <Cell className="AdminTable__col AdminTable__col--fluid">
                        <span className="AdminTable__truncated" title={title}>
                            {title}
                        </span>
                    </Cell>
                    <Cell className="AdminTable__col AdminTable__col--fluid">
                        <span className="AdminTable__truncated" title={category?.title}>
                            {category?.title}
                        </span>
                    </Cell>
                    <Cell className="AdminTable__col AdminTable__col--fluid">
                        <span className="AdminTable__truncated" title={subCategory?.title}>
                            {subCategory?.title}
                        </span>
                    </Cell>
                    <Cell
                        className="AdminTable__col AdminTable__col--small AdminTable__col--no-pl AdminTable__col--no-pr"
                        options="center"
                    >
                        <Icon>
                            {isActive ? <IconCheckSuccess/> : <IconCloseFailure/>}
                        </Icon>
                    </Cell>
                    <Cell className="AdminTable__col AdminTable__col--small AdminTable__col--no-pl">
                        <button
                            className="AdminTable__btn"
                            onClick={() => deleteItem(item.id)}
                        >
                            <Icon type="small">
                                <IconDelete/>
                            </Icon>
                        </button>
                    </Cell>
                </div>
            );
        }

        return <div style={style} className={`AdminTable__row`}/>
    };

    return (
        <div>
            <button
                className="btn btn--filled"
                onClick={() => setIsAddPopupShown(true)}
            >
                Select more charts
            </button>
            <br/>
            {valueLength > 0 && (
                <AdminTable
                    header={(
                        <>
                            <Cell className="AdminTable__col AdminTable__col--large-extra">
                                Display name
                            </Cell>
                            <Cell className="AdminTable__col AdminTable__col--fluid">
                                Instant chart name
                            </Cell>
                            <Cell className="AdminTable__col AdminTable__col--fluid" options="hv">
                                Interactive category
                            </Cell>
                            <Cell className="AdminTable__col AdminTable__col--fluid" options="hv">
                                Interactive subcategory
                            </Cell>
                            <Cell
                                className="AdminTable__col AdminTable__col--small AdminTable__col--no-pl AdminTable__col--no-pr"
                                options="center"
                            >
                                Status
                            </Cell>
                            <Cell className="AdminTable__col AdminTable__col--small AdminTable__col--no-pl"/>
                        </>
                    )}
                    row={Row}
                    length={valueLength}
                    disableRowTransition
                    hasInnerScroll={false}
                />
            )}
            <SelectInstantCharts
                isOpened={isAddPopupShown}
                close={() => setIsAddPopupShown(false)}
                onSelect={(ids) => applySelection(ids)}
                optionsIdsToExclude={Object.keys(value).map(i => +i)}
                isMultiple
            />
        </div>
    );
};

export default SelectInstantChart;