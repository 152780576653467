import React, { FC } from 'react';
import SubTitle from '@components/SubTitle/SubTitle';
import { iCategoryReport } from '@models/CategoryReport';
import ToggleInput from '@components/ToggleInput/ToggleInput';

const MainSettings: FC<{
    data: iCategoryReport;
    handleChange: (field: keyof iCategoryReport) => (value: any) => void;
}> = ({
    data,
    handleChange,
}) => {
    return (
        <>
            <div className="AdminContainer__row AdminContainer__row--right">
                <div className="AdminContainer__top-actions">
                    <div className="AdminContainer__top-actions-item">
                        <ToggleInput
                            labelText="Published"
                            onToggle={() => {
                                handleChange('isActive')(!data.isActive);
                            }}
                            isChecked={data.isActive}
                        />
                    </div>
                </div>
            </div>
            <div className="AdminContainer__row">
                <div className="AdminContainer__section">
                    <div className="AdminContainer__column">
                        <div className="AdminContainer__section-title">
                            <SubTitle textColor="yellow">Description</SubTitle>
                        </div>
                        <div>
                            <label className="AdminContainer__input-wrap">
                                <input
                                    type="text"
                                    className="AdminContainer__input"
                                    placeholder={"Description"}
                                    value={data.description}
                                    onChange={(event) => {
                                        handleChange('description')(event.target.value);
                                    }}
                                />
                            </label>
                        </div>
                        <br/>
                    </div>
                    <div className="AdminContainer__column">
                        <div className="AdminContainer__section-title">
                            <SubTitle textColor="yellow">Note</SubTitle>
                        </div>
                        <div>
                            <label className="AdminContainer__input-wrap">
                                <input
                                    type="text"
                                    className="AdminContainer__input"
                                    placeholder={"Note"}
                                    value={data.note}
                                    onChange={(event) => {
                                        handleChange('note')(event.target.value);
                                    }}
                                />
                            </label>
                        </div>
                        <br/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MainSettings;
