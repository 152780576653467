import React, { useRef, MouseEvent, useMemo, ReactNode } from "react";
import cn from "classnames";
import "./CheckboxInput.scss";
import Flag from "../Flag/Flag";
import { IconArrowRight } from "@icons";
import { BsCheck } from "react-icons/bs";
import Icon from "@components/Icon/Icon";
import { FCX } from "@datassential/platform-ui-lib";

const CheckboxInput: FCX<{
    isChecked: boolean;
    isPartialChecked?: boolean;
    onChange: () => void;
    onClickIcon?: (event: MouseEvent) => void;
    labelText?: ReactNode;
    labelClass?: string;
    isSmall?: boolean;
    isMedium?: boolean;
    isBold?: boolean;
    isUnderlined?: boolean;
    noIcon?: boolean;
    onClick?: () => void;
    hasArrowIcon?: boolean;
    stopPropagation?: boolean;
    isArrowDown?: boolean;
    isBlueCheck?: boolean;
    disabled?: boolean;
    isRadioBtn?: boolean;
    isCutLabel?: boolean;
    labelIcon?: any;
    showModifierIcon?: boolean;
    modifiers?: string[];
    onMouseEnter?: (event: MouseEvent) => void;
    onMouseLeave?: (event: MouseEvent) => void;
}> = (
    {
        isChecked,
        isPartialChecked = false,
        onChange,
        onClickIcon = () => {},
        labelText = '',
        labelClass = '',
        isSmall = false,
        isMedium = false,
        isBold = false,
        isUnderlined = false,
        noIcon = false,
        onClick,
        hasArrowIcon = false,
        stopPropagation,
        isArrowDown = false,
        isBlueCheck = false,
        disabled = false,
        className = '',
        isRadioBtn = false,
        isCutLabel = false,
        labelIcon = null,
        showModifierIcon = false,
        modifiers = [],
        onMouseEnter = undefined,
        onMouseLeave = undefined,
        testId = undefined,
        style,
    }
) => {
    const checkboxRef = useRef(null);

    const handleClickCheckbox = (event: MouseEvent) => {
        stopPropagation && event.stopPropagation();
        onChange();
    };
    const handleIconClick = (event: MouseEvent) => {
        stopPropagation && event.stopPropagation();
        onClick ? onClick() : onChange();
    };

    const icon = useMemo(
        () => {
            if (isRadioBtn) {
                return  (
                    <span
                        className={cn(
                            'checkbox-input__fake-check-small',
                            'is-radio',
                            isBlueCheck && 'is-blue',
                        )}
                    />
                );
            }

            if (isPartialChecked) {
                return  (
                    <span
                        className={cn(
                            'checkbox-input__fake-check-small',
                            isBlueCheck && 'is-blue',
                        )}
                    />
                );
            }

            if (isChecked) {
                return  (
                    <Icon
                        type="small"
                        className={cn(
                            "checkbox-input__icon",
                            isBlueCheck && "is-blue"
                        )}
                    >
                        <BsCheck/>
                    </Icon>
                );
            }
        },
        [isChecked, isPartialChecked, isRadioBtn, isBlueCheck]
    );

    const handleChangeOnIcon = (event: MouseEvent) => {
        handleClickCheckbox(event);
        onClickIcon(event);
    }

    return (
        <div
            className={cn(
                "checkbox-input",
                className,
                (isChecked || isPartialChecked) && "is-checked",
                isSmall && "is-small",
                isMedium && "is-medium",
                isBold && "is-bold",
                isUnderlined && "is-underlined",
                disabled && "is-disabled",
                ...modifiers.map(i => `is-${i}`)
            )}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            data-testid={testId}
            style={style}
        >
            <div
                onClick={handleChangeOnIcon}
                className="checkbox-input__wrapper"
            >
                <input
                    ref={checkboxRef}
                    type="checkbox"
                    onChange={() => null}
                    checked={isChecked}
                    className="checkbox-input__real-check"
                    disabled={disabled}
                    data-testid="inputCheckbox"
                />
                {!noIcon && (
                    <button
                        className={cn(
                            'checkbox-input__fake-check',
                            isPartialChecked && 'is-partial',
                            isBlueCheck && 'is-blue',
                            isRadioBtn && 'is-radio',
                        )}
                        data-testid="buttonCheckboxIcon"
                    >
                        {icon}
                    </button>
                )}
                {labelIcon && (
                    <div className="checkbox-input__label-icon">
                        <Flag compact component={labelIcon}/>
                    </div>
                )}
                <button
                    onClick={handleClickCheckbox}
                    className={cn(
                        "checkbox-input__label",
                        labelClass,
                        isCutLabel && 'checkbox-input__label-cut',
                        showModifierIcon && "checkbox-input__label--has-modifier",
                    )}
                    data-testid="textCheckboxLabel"
                >
                    {labelText}
                </button>
            </div>
            {hasArrowIcon && (
                <button
                    onClick={handleIconClick}
                    className={`checkbox-input__arrow${isArrowDown ? " checkbox-input__arrow--down" : ""}`}
                    data-testid="buttonCheckboxDropdownToggle"
                >
                    <Icon type="small" className="checkbox-input__arrow-icon">
                        <IconArrowRight/>
                    </Icon>
                </button>
            )}
        </div>
    );
};

export default CheckboxInput;
