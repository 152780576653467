import { ADMIN_INSTANT_CHARTS } from "../core/actionNames";

const { SET_FIELD, SET_DATA, RESET } = ADMIN_INSTANT_CHARTS;

export function setData(data) {
  return {
      type: SET_DATA,
      data,
  };
}
export function setField(key, value) {
  return {
      type: SET_FIELD,
      key,
      value,
  };
}
export function resetData(key) {
  return {
      type: RESET,
      key,
  };
}
