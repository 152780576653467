import React, { useContext } from "react";
import Scrollbar from "react-scrollbars-custom";
import PT from "prop-types";
import Cell from "../NewAwesomeTable/Cell";
import "./Table.scss";
import { GlobalContext } from "@components/App/App.context";
import PermissionOverlay from "@components/PermissionOverlay/PermissionOverlay";

function Table(
    {
        data,
        isFirstColumnFixed,
        hasFlavorPermission,
        activeSliceId,
        requestFlavorAccess,
    }
) {
    const { getRem } = useContext(GlobalContext);
    return (
        <>
            <Scrollbar
                className="table__scrollbar"
                trackXProps={{
                    renderer: props => {
                        const { elementRef, ...restProps } = props;
                        return <span
                            {...restProps}
                            ref={elementRef}
                            style={{
                                ...restProps.style,
                                width: `calc(100% - ${getRem(isFirstColumnFixed ? 350 : 20)}px)`,
                                left: `${getRem(isFirstColumnFixed ? 340 : 0)}px`,
                                height: getRem(10),
                            }}
                        />;
                    }
                }}
            >
                <div className="table">
                    {data.map((column, columnIndex) => {
                        if (!column) return null;
                        const colClassList = new Set([
                            "table__col",
                            column.className,
                        ]);
                        return (
                            <div
                                key={columnIndex}
                                className={Array.from(colClassList).join(" ")}
                            >
                                {column.cells.map((cell, index) => {
                                    if (!cell) return null;

                                    const classList = ['table__cell'];

                                    let options = [];

                                    if (cell.options) {
                                        options = options.concat(cell.options);
                                    }
                                    if (cell.textAlign) {
                                        classList.push(`table__cell--text-${cell.textAlign}`);
                                    }
                                    if (cell.isHeader) {
                                        options.push('head');
                                        classList.push('table__cell--header');
                                    }
                                    if (cell.isFooter) {
                                        options.push('head');
                                        classList.push('table__cell--footer');
                                    }
                                    return (
                                        <Cell
                                            key={index}
                                            className={classList.join(" ")}
                                            options={options.filter(option => option !== '').join(' ')}
                                            onClick={cell.onClick || (() =>{})}
                                            onMouseEnter={cell.onMouseEnter || (() => {})}
                                            onMouseLeave={cell.onMouseLeave || (() => {})}
                                        >
                                            {cell.content}
                                        </Cell>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </Scrollbar>
            {!hasFlavorPermission && activeSliceId === 7 && (
                <div style={{
                    position: 'absolute',
                    top: getRem(40),
                    bottom: getRem(10),
                    right: getRem(10),
                    width: `calc(calc(100% - ${getRem(340)}px - ${getRem(10)}px) * 0.6)`,
                    overflow: 'hidden',
                }}>
                    <PermissionOverlay
                        handleButtonClick={requestFlavorAccess}
                        variant={data[0].cells.length < 8 ? 'row' : 'regular'}
                    />
                </div>
            )}
        </>
    );
}

export default Table;

Table.propTypes = {
    isLoading: PT.bool,
    data: PT.arrayOf(
        PT.shape({
            cells: PT.arrayOf(
                PT.shape({
                    isHeader: PT.bool,
                    isFooter: PT.bool,
                    content: PT.oneOfType([PT.string, PT.number, PT.element]),
                })
            ),
        })
    ),
    hasVisibilitySensor: PT.bool,
};
