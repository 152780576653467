import { BYO } from '../core/actionNames';
import { SlicesEnum } from "@models/ByoTableData";

const { SET_FIELD, SET_DATA, RESET_COLORS, RESET_DATA } = BYO;

export const COLORS = [
    // '#F44336', '#2196F3', '#FF9800', '#9C27B0', '#009688'
    // '#E14324', '#50B1F9', '#F19B38', '#C028D0', '#498023'
    '#66DEB2',
    '#7B16F5',
    '#F19B38',
    '#50B1F9',
    '#D82E85',
];

const defaultState = {
    isLoaded: false,
    searchQuery: '',
    searchError: false,
    activeSliceIndex: 0,
    activeAnalyticsType: 1, /* 1, 2, 3 */
    chartDataType: 1, /* 1 for actual, 2 for indexed */
    activeValuesType: 1, /* 1 for %, 2 for # */
    sortingColumn: null,
    sortingDirection: 'desc', /* asc, desc */
    sortingType: 'num', /* num, abc */
    data: [],
    availableColors: [...COLORS],
    isLoading: true,
    activeKeywordId: null,
    noData: false,
    instantChartId: null,
    isInstantChartLoading: false,
    pastGrowthSliceId: SlicesEnum.FourYearTrend,
};
export default function byo(state = defaultState, action) {
    switch (action.type) {
        case SET_FIELD: {
            return {
                ...state,
                [action.name]: action.value,
            };
        }
        case SET_DATA: {
            return {
                ...state,
                ...action.data,
            }
        }
        case RESET_COLORS: {
            return {
                ...state,
                availableColors: [...COLORS],
            }
        }
        case RESET_DATA: {
            return { ...defaultState };
        }
        default: {
            return state;
        }
    }
}