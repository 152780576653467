import React from 'react'
import { connect } from 'react-redux'
import {setField} from '@actions/filters.actions'
import FiltersContainer from "@containers/FiltersContainer/FiltersContainer";
import { filtersTextSelector } from "@selectors/filters.selectors";
import { IconFilterFourth, IconFilterSecond, IconFilterSixth } from '@icons';

const LeftFooterContent = ({ isFiltersPopupShown, setField, filtersText, isThemeLightEnable }) => {
    return (
        <>
            <button
                onClick={
                    () => setField('isFiltersPopupShown', !isFiltersPopupShown)
                }
                className="page-footer__filter-btn"
            >
                {filtersText ? (
                    <IconFilterSecond className="page-footer__icon 2"/>
                ) : (
                    isThemeLightEnable ? (
                        <IconFilterSixth className="page-footer__icon 6"/>
                    ) : (
                        <IconFilterFourth className="page-footer__icon 4"/>
                    )
                )}
            </button>
            <div className="page-footer__text">
                {filtersText}
            </div>
            <FiltersContainer
                shown={isFiltersPopupShown}
                onClose={() => setField('isFiltersPopupShown', !isFiltersPopupShown)}
            />
        </>
    )
};

export default connect(state => ({
    isFiltersPopupShown: state.filters.isFiltersPopupShown,
    filtersText: filtersTextSelector(state),
    isThemeLightEnable: state.app.isThemeLightEnable,
}), {setField})(LeftFooterContent);