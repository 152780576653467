import Icon from "@components/Icon/Icon";
import { IconArrowBottom } from "@icons";
import React, { useReducer } from "react";
import { connect } from "react-redux";
import KeywordMenu from "../KeywordMenu/KeywordMenu";
import "./LowPenetrationsDropdown.scss";

const LowPenetrationDropdown = ({ includeLowPenetrationItems, includeLessTenMenusCount, setIncludeOptions }) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        isLowPenetrationDropdownShown,
        lowPenetrationX,
        lowPenetrationY,
    } = state;

    const handleLowPenetrationClick = event => {
        const boundingRect = event.currentTarget.getBoundingClientRect();
        dispatch({
            lowPenetrationX: boundingRect.left,
            lowPenetrationY: boundingRect.top + 30,
            isLowPenetrationDropdownShown: true,
        });
    };

    const optionsLowPenetration = [
        {
            title: "Items > 1%",
            onClick: () => {
                dispatch({ isLowPenetrationDropdownShown: false });
                setIncludeOptions({
                    includeLowPenetrationItems: false,
                    includeLessTenMenusCount: true
                });
            }
        }, {
            title: "Items > 10 menus",
            onClick: () => {
                dispatch({ isLowPenetrationDropdownShown: false });
                setIncludeOptions({
                    includeLowPenetrationItems: true,
                    includeLessTenMenusCount: false
                });
            },
        }, {
            title: "All items",
            onClick: () => {
                dispatch({ isLowPenetrationDropdownShown: false });
                setIncludeOptions({
                    includeLowPenetrationItems: true,
                    includeLessTenMenusCount: true
                });
            },
        },
    ];

    const title = includeLowPenetrationItems && includeLessTenMenusCount ? optionsLowPenetration[2].title : includeLessTenMenusCount ? optionsLowPenetration[0].title : optionsLowPenetration[1].title

    return (
        <div
            className="TopTrendsSidebar__radio toggle-input"
        >
            <button
                onClick={handleLowPenetrationClick}
                className="low-penetration-btn"
            >
                {title}
                <Icon
                    type="small"
                    className="low-penetration-btn__icon-arrow"
                >
                    <IconArrowBottom />
                </Icon>
            </button>
            <div
                className="low-penetration-dropdown"
            >
                <KeywordMenu
                    x={lowPenetrationX}
                    y={lowPenetrationY}
                    closeModal={() => dispatch({ isLowPenetrationDropdownShown: false })}
                    shown={isLowPenetrationDropdownShown}
                    options={optionsLowPenetration}
                />
            </div>
        </div>
    )
};

const initialState = {
    isLowPenetrationDropdownShown: false,
    lowPenetrationDropdownX: null,
    lowPenetrationDropdownY: null,
};

const reducer = (state, action) => {
    return {...state, ...action};
};

export default connect()(LowPenetrationDropdown);