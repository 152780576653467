import Icon from "@components/Icon/Icon";
import { IconArrowLeft, IconArrowRight } from "@icons";
import React, { useState, useEffect } from "react";
import { CSSTransition } from 'react-transition-group';
import "./ProgressPanel.scss";

const ProgressCircle = () => (
    <div className="progress-circle">
        <div className="pie spinner"/>
        <div className="pie filler"/>
        <div className="mask"/>
    </div>
);

let timer;

const ProgressPanel = (
    {
        supTitle = "Paired with",
        isLoading,
        data,
    }
) => {

    const [ activeWordIndex, setActiveWordIndex ] = useState(-1);
    const nextWordIndex = activeWordIndex === data.length - 1 ? 0 : activeWordIndex + 1;
    const prevWordIndex = activeWordIndex === 0 ? data.length - 1 : activeWordIndex - 1;

    const handleNextClick = () => setActiveWordIndex(nextWordIndex);
    const handlePrevClick = () => setActiveWordIndex(prevWordIndex);

    useEffect(() => {
        if (data.length > 0) {
            setActiveWordIndex(0);
        }
    }, [data.length]);

    useEffect(() => {
        if (activeWordIndex !== nextWordIndex) {
            timer = setTimeout(handleNextClick, 5000);
            return () => clearTimeout(timer);
        }
    }, [nextWordIndex]);

    let title = '';
    let items = [];

    if (!isLoading && data[activeWordIndex]) {
        title = data[activeWordIndex].title;
        items = data[activeWordIndex].items;
    }

    return (
        <div className="progress-panel">
            <div className="progress-panel__header">
                <div className="progress-panel__header-top">
                    <div
                        className={`progress-panel__sup-title ${isLoading ? 'progress-panel__sup-title--loading' : ''}`}
                    >
                        {!isLoading && supTitle}
                    </div>
                    <CSSTransition
                        in={data.length > 1 && !isLoading}
                        classNames={`appears`}
                        timeout={2000}
                        unmountOnExit
                    >
                        <div className="progress-panel__navigation">
                            <button
                                className="progress-panel__arrow"
                                onClick={handlePrevClick}
                            >
                                <Icon className="progress-panel__arrow-icon-container">
                                    <IconArrowLeft className="progress-panel__arrow-icon"/>
                                </Icon>
                            </button>
                            <button
                                className="progress-panel__arrow"
                                onClick={handleNextClick}
                            >
                                <Icon className="progress-panel__arrow-icon-container">
                                    <IconArrowRight className="progress-panel__arrow-icon"/>
                                </Icon>
                                <ProgressCircle
                                    key={`${activeWordIndex}:${nextWordIndex}:${data.length}`}/>
                            </button>
                        </div>
                    </CSSTransition>
                </div>
                <div
                    className={`progress-panel__title ${isLoading ? 'progress-panel__title--loading' : ''}`}
                >
                    {title.toUpperCase()}
                </div>
            </div>
            <div className="progress-panel__content">

                {items.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className={`progress-panel-item ${isLoading  ? 'progress-panel-item--loading' : ''}`}
                        >

                            <CSSTransition
                                in={!isLoading}
                                classNames={`appears`}
                                timeout={2000} unmountOnExit
                            >
                                <div>
                                    <div className="progress-panel-item__top">
                                        <div className="progress-panel-item__title">{item.title.toUpperCase()}</div>
                                        <div className="progress-panel-item__percents">{item.value}</div>
                                    </div>
                                    <div className="progress-panel-item__bar-wrap">
                                        <div
                                            className="progress-panel-item__bar"
                                            style={{width: `${item.progress}%`}}
                                        />
                                    </div>
                                </div>
                            </CSSTransition>
                        </div>
                    )
                })}

            </div>
        </div>
    )
};

export default ProgressPanel;