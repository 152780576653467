import React from 'react';
import './SubTitle.styles.scss';

function SubTitle({ children, textColor }) {
    return (
        <div className={`SubTitle SubTitle--${textColor}`}>
            {children}
        </div>
    );
}

export default SubTitle;