import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { deepEqual, prepareForSorting } from "@core/helpers";
import { IconSortASC, IconSortDESC } from "@icons";
import { getStringMatchRank, StringMatchRanks } from '@core/old_helpers';
import { SortingDirection } from '@models';

export type ColumnIconType = <T>(
    fieldName: keyof T,
    sortingField: keyof T | null,
    sortingDirection: string,
) => ReactNode;

const SKIPPING_VALUES: Array<any> = [
    null,
    undefined,
];

function useTableSort<T>(
    data: T[] = [],
    defaultSortingField?: keyof T,
    search?: {
        query: string;
        fields: Array<keyof T>;
    },
) {
    const [sortingDirection, setSortingDirection] = useState<SortingDirection>("asc");
    const [sortingField, setSortingField] = useState<keyof T | null>(defaultSortingField || null);
    const sortingFactor = useMemo(() => (sortingDirection === "asc" ? 1 : -1), [sortingDirection]);
    const [sortedData, setSortedData] = useState(data);

    const toggleSortingDirection = useCallback(
        () => setSortingDirection(sortingDirection === "asc" ? "desc" : "asc"),
        [sortingDirection],
    );

    const getColumnIcon: ColumnIconType = useCallback(
        (fieldName, sortingField, sortingDirection) => {
            if (sortingField === fieldName) {
                return sortingDirection === "asc" ? <IconSortDESC /> : <IconSortASC />;
            } else {
                return null;
            }
        },
        [],
    );

    const handleSort = useCallback(
        (fieldName: keyof T) =>
            () => sortingField === fieldName ? toggleSortingDirection() : setSortingField(fieldName),
        [sortingField, toggleSortingDirection],
    );

    const handleResetSorting = useCallback(() => {
        setSortingDirection("asc");
        setSortingField(null);
    }, []);

    const sortData = useCallback(
        function <T>(data: T[], sortingField: keyof T | null) {
            let temp: T[] = [...data];
            if (search && search.query.trim() !== '' && search.fields.length > 0) {
                temp = temp.filter(
                    item => search.fields.some(
                        field => getStringMatchRank(item[field as unknown as keyof T], search.query.trim()) >= StringMatchRanks.Includes
                    ),
                );

                temp.sort((a, b) => {
                    const aRank = getStringMatchRank(a[search.fields[0] as unknown as keyof T], search.query.trim());
                    const bRank = getStringMatchRank(b[search.fields[0] as unknown as keyof T], search.query.trim());

                    return aRank < bRank ? 1 : aRank > bRank ? -1 : 0;
                });
            }
            else if (sortingField) {
                const field = sortingField;
                temp.sort((a, b) => {
                    const aValue = prepareForSorting(a[field]);
                    const bValue = prepareForSorting(b[field]);
                    if (SKIPPING_VALUES.includes(aValue)) return 1;
                    else if (SKIPPING_VALUES.includes(bValue)) return -1;
                    else return sortingFactor * (aValue <= bValue ? -1 : 1);
                });
            }
            return temp;
        },
        [sortingFactor, search],
    );

    useEffect(() => {
        const newSortedData = sortData(data, sortingField);
        if ( deepEqual(sortedData, newSortedData) ) return;
        setSortedData(newSortedData);
    }, [data, sortData, sortingField, sortedData]);

    useEffect(() => {
        if ( defaultSortingField ) {
            setSortingField(defaultSortingField);
        }
    }, [defaultSortingField]);

    return {
        handleSort,
        sortingFactor,
        sortingDirection,
        sortingField,
        getColumnIcon,
        handleResetSorting,
        setSortingDirection,
        sortData,
        sortedData
    };
}

export default useTableSort;
