import React, { useState, useEffect } from "react";
import PT from "prop-types";
import { capitalize } from "@core/old_helpers";
import Modal from "../Modal/Modal";
import MultipleInput from "../MultipleInput/MultipleInput";
import Button from "../Button/Button";
import "./KeywordSettings.scss";

const KeywordSettings = (
    {
        shown,
        closeModal,
        x,
        y,
        keywords,
        includes = [],
        excludes = [],
        applySettings,
        id,
        readOnly = false,
        searchingKeyword,
    }
) => {
    const [isChanged, setIsChanged] = useState(false);
    const [localIncludes, setLocalIncludes] = useState(includes);
    const [localExcludes, setLocalExcludes] = useState(excludes);

    useEffect(() => {
        setLocalIncludes(includes);
        setLocalExcludes(excludes);
        setIsChanged(false);
    }, [shown]);

    const handleSubmitKeyword = (type) => (keywords) => {
        switch (type) {
            case "includes": {
                setLocalIncludes([...keywords, ...localIncludes, ]);
                break;
            }
            case "excludes": {
                setLocalExcludes([...keywords, ...localExcludes, ]);
                break;
            }
            default:
                break;
        }
        setIsChanged(true);
    };

    const handleRemoveKeyword = (type) => (keywordName) => {
        switch (type) {
            case "includes": {
                setLocalIncludes(localIncludes.filter(i => i.name.toLowerCase() !== keywordName.toLowerCase()));
                break;
            }
            case "excludes": {
                setLocalExcludes(localExcludes.filter(i => i.name.toLowerCase() !== keywordName.toLowerCase()))
                break;
            }
            default:
                break;
        }
        setIsChanged(true);
    };

    return (
        <Modal hasOverlay closeModal={closeModal} shown={shown} closeOnOutsideClick={false}>
            <div
                style={{
                    left: x,
                    top: y,
                }}
                className= "keyword-settings"
            >
                <div className="keyword-settings__top">
                    <div className="keyword-settings__top-title">
                        When searching for <span>{searchingKeyword ? searchingKeyword.toUpperCase() : ''}:</span>
                    </div>
                    <div className="keyword-settings__content">
                        <div className="keyword-settings__include">
                            <div className="keyword-settings__title">
                                also
                                <span className="keyword-settings__title--green">
                                     include
                                </span>
                            </div>
                            <div className="keyword-settings__input-wrap">
                                <MultipleInput
                                    readOnly={readOnly}
                                    submittedItems={localIncludes}
                                    onSubmit={handleSubmitKeyword('includes')}
                                    removeItem={handleRemoveKeyword('includes')}
                                    keywords={keywords}
                                    modifiers={['small']}
                                    placeholder="Type keyword"
                                    words={[
                                        ...localIncludes,
                                        ...localExcludes,
                                    ]}
                                    isUpperCase
                                />
                            </div>
                        </div>
                        <div className="keyword-settings__exclude">
                            <div className="keyword-settings__title">
                                but
                                <span className="keyword-settings__title--yellow">
                                     exclude
                                </span>
                            </div>
                            <div className="keyword-settings__input-wrap">
                                <MultipleInput
                                    readOnly={readOnly}
                                    submittedItems={localExcludes}
                                    onSubmit={handleSubmitKeyword('excludes')}
                                    removeItem={handleRemoveKeyword('excludes')}
                                    keywords={keywords}
                                    modifiers={['small']}
                                    placeholder="Type keyword"
                                    words={[
                                        ...localIncludes,
                                        ...localExcludes,
                                    ]}
                                    isUpperCase
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {!readOnly && (
                    <div className="keyword-settings__bot">
                        <Button
                            onClick={closeModal}
                            className="keyword-settings__cancel"
                            modifiers={["small"]}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                applySettings(id, [
                                    ...localIncludes.map((word) => ({ ...word, wordType: 1, })),
                                    ...localExcludes.map((word) => ({ ...word, wordType: 2, })),
                                ], searchingKeyword);
                                closeModal();
                            }}
                            className="keyword-settings__apply"
                            isDisabled={!isChanged}
                            modifiers={["small"]}
                        >
                            Apply
                        </Button>
                    </div>
                )}

            </div>
        </Modal>
    );
};

KeywordSettings.propTypes = {
    x: PT.number,
    y: PT.number,
    closeModal: PT.func,
    shown: PT.bool,
    includes: PT.array,
    excludes: PT.array,
    keywords: PT.array,
};

export default KeywordSettings;
