import React, { useEffect, useState } from "react";
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import "./RestaurantsMenuPopup.styles.scss";
import Tabs from "@components/Tabs/Tabs";
import Search from "@components/Search/Search";
import Table from "../RestaurantsMenuTable/Table";
import { dynamicSort } from "@core/old_helpers";
import { useSort } from "@core/customHooks";

const TABS_OPTIONS = [
    { id: 0, name: "My restaurant lists" },
    { id: 1, name: "All company lists" },
];

const ACCESS_IDS = {
    PUBLIC: 0,
    PRIVATE: 1,
};

const ACCESS_OPTIONS = [
    { id: ACCESS_IDS.PUBLIC, name: "Public" },
    { id: ACCESS_IDS.PRIVATE, name: "Private" },
];

const ALL_ACCESS_IDS = ACCESS_OPTIONS.map(({ id }) => id);

function RestaurantsMenuPopup({
                                  closeModal,
                                  shown,
                                  data,
                                  setSearchQuery,
                                  searchQuery,
                                  handleItemClick,
                                  handleShowManagePopup,
                                  handleShowViewPopup,
                                  isLoadingCustomRestaurantLists,
                                  handleRemoveItem,
                              }) {
    const [selectedRestaurantId, setSelectedRestaurantId] = useState(null);
    const [activeTabId, setActiveTabId] = useState(0);
    const [tableData, setTableData] = useState([]);
    const sort = useSort();
    const [dataFilters, setDataFilters] = useState({
        access: ACCESS_OPTIONS,
        selectedAccessIds: ALL_ACCESS_IDS,

        createdBy: [],
        selectedCreatedByIds: [],
    });

    useEffect(() => {
        const activePersonalTab = activeTabId === 0;
        const { selectedAccessIds, selectedCreatedByIds } = dataFilters;
        let filteredData = data.filter(({ isCreatedByCurrentUser }) => (activePersonalTab ? isCreatedByCurrentUser : !isCreatedByCurrentUser));

        const filterDataByAccess = data => {
            if (!activePersonalTab) return data;
            return data.filter(
                item =>
                    (selectedAccessIds.includes(ACCESS_IDS.PUBLIC) && item.isPublic) ||
                    (selectedAccessIds.includes(ACCESS_IDS.PRIVATE) && !item.isPublic),
            );
        };

        const filterDataByAuthor = data => {
            if (activePersonalTab) return data;

            return data.filter(item => selectedCreatedByIds.includes(item.createByFullName));
        };

        filteredData = filterDataByAuthor(filterDataByAccess(filteredData));
        filteredData.sort(dynamicSort(sort.column, sort.direction));
        setTableData(filteredData);
    }, [data, activeTabId, sort.column, sort.direction, dataFilters]);

    useEffect(() => {
        const activePersonalTab = activeTabId === 0;
        const setDefaultAuthors = data => {
            if (activePersonalTab) return data;

            const authors = [...new Set(
                data.filter(({ isCreatedByCurrentUser }) => !isCreatedByCurrentUser)
                    .map(({ createByFullName }) => createByFullName)
            )];

            handleChangeFilters(
                "createdBy",
                authors.map(item => ({ id: item, name: item })),
            );
            handleChangeFilters("selectedCreatedByIds", authors);
        };

        setDefaultAuthors(data);
    }, [data, activeTabId]);

    useEffect(() => {
        setSelectedRestaurantId(null);
    }, [activeTabId]);

    const handleConfirm = () => {
        handleItemClick(selectedRestaurantId);
        closeModal();
    };

    useEffect(() => {
        return () => {
            setSelectedRestaurantId(null);
            setSearchQuery("");
        };
    }, [shown]);

    const handleChangeFilters = (type, ids) => {
        setDataFilters(prevState => ({
            ...prevState,
            [type]: ids,
        }));
    };

    return (
        <Modal closeModal={closeModal} shown={shown} hasOverlay>
            <div className="Popup__wrapper">
                <div className="Popup Popup--large">
                    <div className="Popup__title Popup__title--small">Restaurant lists</div>
                    <div className="RestaurantsMenuPopup">
                        <div className="RestaurantsMenuPopup__header">
                            <div className="RestaurantsMenuPopup__header-row">
                                <div className="RestaurantsMenuPopup__tabs">
                                    <Tabs data={TABS_OPTIONS} handleClick={setActiveTabId} activeItemId={activeTabId}/>
                                </div>

                                <div className="RestaurantsMenuPopup__actions">
                                    <Button
                                        onClick={() => handleShowManagePopup(null)}
                                        shouldOpenNewTab={false}
                                        modifiers={["rounded", "bordered"]}
                                    >
                                        Add custom list
                                    </Button>
                                </div>
                            </div>
                            <div className="RestaurantsMenuPopup__header-row">
                                <div className="RestaurantsMenuPopup__search">
                                    <Search
                                        key="search"
                                        searchQuery={searchQuery}
                                        setSearchQuery={setSearchQuery}
                                        placeholder="Find a restaurant list"
                                        showIcon
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="RestaurantsMenuPopup__content">
                            <Table
                                data={tableData}
                                showAll={activeTabId === 1}
                                handleSelectedRow={setSelectedRestaurantId}
                                selectedRow={selectedRestaurantId}
                                isSortDisabled={!!searchQuery}
                                sort={sort}
                                handleChangeFilters={handleChangeFilters}
                                dataFilters={dataFilters}
                                handleShowManagePopup={handleShowManagePopup}
                                handleShowViewPopup={handleShowViewPopup}
                                isLoadingCustomRestaurantLists={isLoadingCustomRestaurantLists}
                                handleRemoveItem={handleRemoveItem}
                            />
                        </div>
                    </div>

                    <div className="Popup__btn-container">
                        <Button onClick={closeModal} modifiers={["transparent"]}>
                            Cancel
                        </Button>
                        <Button onClick={handleConfirm} modifiers={["blue", "rounded"]}
                                isDisabled={!selectedRestaurantId}>
                            Apply
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default RestaurantsMenuPopup;
