import { FILTERS } from '../core/actionNames';
// import { FILTERS_TEST_DATA } from '../core/data';
const { SET_DATA, SET_FIELD, RESET_DATA } = FILTERS;

const defaultState = {
    // testData: FILTERS_TEST_DATA,
    isDataLoaded: false,
    isFiltersPopupShown: false,
    raw: [],
    defaultRestaurantListId: 0,
    activeRestaurantListId: 0,
    restaurantLists: [],
    customRestaurantLists: [],
    activeRestaurantList: [],
    active: null,
    activeToApply: null,
    tooltipsData: [],
    isMenuItemsFilterBeenChanged: true,
    isVenuesFilterBeenChanged: true,
};

export default function filters(state = defaultState, action) {
    switch (action.type) {
        case SET_FIELD: {
            return {
                ...state,
                [action.name]: action.value,
            };
        }
        case SET_DATA: {
            return {
                ...state,
                ...action.data,
            };
        }
        case RESET_DATA: {
            return { ...defaultState };
        }
        default: {
            return {...state};
        }
    }
}