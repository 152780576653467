import { useCallback, useEffect, useMemo, useState } from "react";
import { components } from '@apiSchema';
import { ID } from "@datassential/platform-ui-lib";
import { exportMenuExamples, fetchMenuExamples } from "@core/api";
import { iUseData, useData } from "@hooks/useData";
import http, { AbortablePromise } from "@core/http";
import apiConfig from "@apiConfig";
import { addNotification } from "@actions/notifications.actions";
import { useDispatch } from "react-redux";


export type Response = components["schemas"]["Application.Byo.Models.MenuExamplesOutputModel"];
export type MenuExample = components["schemas"]["Application.Byo.Models.MenuExamplesOutputModel.MenuItemExample"];
type Payload = components["schemas"]["Application.MenuExamples.Models.MenuExamplesFilterModel"];

const TAKE = 100;
let request: AbortablePromise<Response> = new Promise(() => {
});

async function getMenuExamplesData(payload: Payload): Promise<Response> {
    if (request && request.abort) request.abort();

    request = fetchMenuExamples(payload);

    return request;
}

function useMenuExamplesData(payload: Payload): iUseData<Response> {
    let fn = getMenuExamplesData;
    if (payload.searchWord!.trim() === '' || payload.tableParameters!.take! <= 0) {
        fn = () => {
            return new Promise(() => {
            })
        };
    }
    return useData({
        totalRecords: undefined,
        data: [],
    }, fn, payload);
}

export interface iUseMenuExamplesParams {
    search: string;
    statesIds: ID[];
    selectedSegmentId: ID;
    includeChains: boolean;
    tableSearch: string;
    countryId: ID;
}

let requestExport: AbortablePromise | null = null;

export function useMenuExamples(params: iUseMenuExamplesParams) {
    const dispatch = useDispatch();
    const pushNotification = useCallback(
        (notification: any) => dispatch(addNotification(notification)),
        [dispatch]
    );
    const {
        search,
        statesIds,
        selectedSegmentId,
        includeChains,
        tableSearch,
        countryId,
    } = params;

    const [items, setItems] = useState<MenuExample[]>([]);
    const [totalRecords, setTotalRecords] = useState<number | undefined>(undefined);
    const [skip, setSkip] = useState(0);
    const [isLoadingDebounced, setIsLoadingDebounced] = useState(false);

    const isLoaded = useMemo(
        () => totalRecords !== undefined && items.length >= totalRecords,
        [items, totalRecords]
    );

    const reset = useCallback(
        () => {
            setItems([]);
            setTotalRecords(undefined);
            setSkip(0);
        },
        []
    );

    const payload = useMemo(
        (): Payload => {
            return {
                includeChains,
                searchWord: search,
                segmentId: selectedSegmentId as number,
                states: statesIds as number[],
                countryCode: countryId as string,
                tableParameters: {
                    skip,
                    take: TAKE,
                    search: tableSearch,
                },
            };
        },
        [search, statesIds, selectedSegmentId, includeChains, tableSearch, countryId, skip]
    );

    const { isLoading, data } = useMenuExamplesData(payload);

    const inc = useCallback(
        () => {
            if (!isLoaded && !isLoading) {
                setSkip((skip) => skip + TAKE);
            }
        },
        [isLoaded, isLoading]
    );

    useEffect(() => {
        if (!isLoading) {
            setItems((items) => [...items, ...(data.data || [])]);
            setTotalRecords(data.totalRecords);
        }
    }, [data, isLoading]);

    useEffect(() => {
        reset();
    }, [params, reset]);

    useEffect(() => {
        setIsLoadingDebounced(isLoading);
    }, [isLoading]);

    const download = useCallback(
        async () => {
            const notifyId = new Date().getTime();
            const notifyDuration = 2000;
            pushNotification({
                id: notifyId,
                text: "Downloading...",
            });

            requestExport && requestExport.abort && requestExport.abort();
            requestExport = exportMenuExamples(payload);
            const token = await requestExport;

            http.downloadFileXHRFromUrl(
                'GET',
                apiConfig.get('export menu examples', { key: token })
            ).then(() => {
                pushNotification({
                    text: "Download was successful",
                    duration: notifyDuration,
                    id: notifyId,
                });
            }).catch(err => {
                pushNotification({
                    text: "Something went wrong",
                    id: notifyId,
                    error: true,
                    duration: notifyDuration,
                });
            });
        },
        [payload, pushNotification]
    );

    return {
        isLoading: isLoadingDebounced,
        isLoaded,
        inc,
        data: items,
        download,
    };
}