import React from "react";
import PT from "prop-types";
import Modal from "../Modal/Modal";
import "./MultipleInputWordExtensions.scss";
import Scrollbar from "react-scrollbars-custom";

const MultipleInputWordExtensions = ({
    wordExtensions,
    onClose,
    shown,
    x,
    y,
}) => {
    const includes = wordExtensions.filter(i => i.wordType === 1 || i.wordType === 3);
    const excludes = wordExtensions.filter(i => i.wordType === 2);
    const renderItems = items => (
        <div className="MultipleInputWordExtensions__words">
            <Scrollbar>
                {items.map(({ id, name }, index) => (
                    <div
                        key={`${id}-${index}`}
                        className="MultipleInputWordExtensions__word"
                    >
                        {name.toUpperCase()}
                    </div>
                ))}
            </Scrollbar>
        </div>
    );

    return (
        <Modal closeModal={onClose} shown={shown} closeOnOutsideClick={false}>
            <div
                style={{
                    left: `${x}px`,
                    top: `${y}px`,
                }}
                className="MultipleInputWordExtensions"
            >
                <div className="MultipleInputWordExtensions__include">
                    <div className="MultipleInputWordExtensions__title MultipleInputWordExtensions__title--green">
                        Included keywords
                    </div>
                    {renderItems(includes)}
                </div>
                <div className="MultipleInputWordExtensions__exclude">
                    <div className="MultipleInputWordExtensions__title MultipleInputWordExtensions__title--yellow">
                        Excluded keywords
                    </div>
                    {renderItems(excludes)}
                </div>
            </div>
        </Modal>
    );
};

MultipleInputWordExtensions.propTypes = {
    wordExtensions: PT.array,
    onClose: PT.func,
    shown: PT.bool,
    x: PT.number,
    y: PT.number,
};

export default MultipleInputWordExtensions;
