import React, {useState} from 'react';
import { IconDownload } from "@icons";
import TooltipTabs from "../TooltipTabs/TooltipTabs";
import './DownloadBtn.styles.scss';
import Icon from '@components/Icon/Icon';

const DownloadBtn = ({onClick,}) => {

    const [isTooltipShown, setIsTooltipShown] = useState(false);

    return (
        <>
            <button
                className="download-btn"
                onClick={onClick}
                onMouseOver={() => setIsTooltipShown(true)}
                onMouseOut={() => setIsTooltipShown(false)}
            >
                <Icon>
                    <IconDownload/>
                </Icon>
            </button>
            <TooltipTabs isShown={isTooltipShown} isSmall><span>Download</span></TooltipTabs>
        </>
    )
};

export default DownloadBtn;