import React, { useState, useReducer, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Sidebar from "./Sidebar/Sidebar";
import Settings from "./Settings/Settings";
import Popup from "@components/Popup/Popup";
import SettingsDropdown from "@components/Select/Select";
import ManageCountriesPopup from "./ManageCountriesPopup/ManageCountriesPopup";
import CompareOptionsPopup from "./CompareOptionsPopup/CompareOptionsPopup";
import Page from "@components/Page/Page";
import { countriesSelector } from "@selectors/countries.selector";
import { Routes } from "@routes";
import {
    getEmptyOptionData,
    MASTER_OPTION_NAME, normalizeForManageCountries,
    normalizeForDropDown,
    normalizeForDropDownWithoutActive,
    normalizeForSidebar, normalizeOptionFromBackEnd, normalizeOptionForBackEnd, normalizeOptionForCompare
} from "./Keyword.helpers";
import { Actions, reducer } from "./keywordOptionsReducer";
import SidebarHeader from "./Sidebar/SidebarHeader";
import './Keyword.styles.scss';
import { usePrevious } from "@core/customHooks";
import { addAdminKeyword, getAdminKeyword, setAdminKeyword } from "@core/api";
import { equalInLC } from "@core/old_helpers";
import Loader from "@components/Loader/Loader";
import { useNavigate, useLocation } from 'react-router-dom';

function Keyword({ countries, selectsData, keywordNames }) {
    const [id, setId] = useState(null);
    const [name, setName] = useState('');
    const [oldName, setOldName] = useState('');
    const [isKeywordInputValid, setIsKeywordInputValid] = useState(false);
    const [isComparePopupShown, setIsComparePopupShown] = useState(false);
    const [isCopyAttributesPopupShown, setIsCopyAttributesPopupShown] = useState(false);
    const [isManageCountriesPopupShown, setIsManageCountriesPopupShown] = useState(false);
    const [isLoaderShown, setIsLoaderShown] = useState(false);

    const [activeOptionIndex, setActiveOptionIndex] = useState(0);
    const [optionsData, dispatchOptionsAction] = useReducer(reducer, []);
    const previousOptionsDataLength = usePrevious(optionsData.length);
    const location = useLocation();
    let history = useNavigate();

    useEffect(() => {
        if (previousOptionsDataLength < optionsData.length) {
            setActiveOptionIndex(0);
        }
    }, [optionsData.length]);

    useEffect(() => {
        if (location.pathname === Routes.AdminKeywordAdd) {
            const masterOptionData = getEmptyOptionData(MASTER_OPTION_NAME);
            dispatchOptionsAction({
                type: Actions.ADD,
                data: {
                    ...masterOptionData,
                    countryCodes: countries.map(country => country.countryCode),
                },
            });
        } else {
            const searchParams = new URLSearchParams(location.search);
            const id = searchParams.get('id');
            const name = searchParams.get('name');

            if (id) {
                setId(id);
                setName(name || '');
                setOldName(name || '');
                getAdminKeyword(id).then((data) => {
                    const optionsData = data.keywordOptions.map(normalizeOptionFromBackEnd);
                    dispatchOptionsAction({
                        type: Actions.SET_DATA,
                        data: optionsData,
                    });
                });
            } else {
                history(Routes.AdminKeywords);
            }
        }
    }, []);

    const handleChangeOptionData = (index, data) => {
        dispatchOptionsAction({
            type: Actions.EDIT,
            index,
            data,
        });
    };

    const handleChangeOptionField = (sectionName, fieldName, value) => {
        dispatchOptionsAction({
            type: Actions.EDIT_FIELD,
            index: activeOptionIndex,
            sectionName,
            fieldName,
            value,
        });
    };

    const handleRemoveOption = (index) => {
        if (activeOptionIndex === optionsData.length - 1) {
            setActiveOptionIndex(activeOptionIndex - 1);
        }

        dispatchOptionsAction({
            type: Actions.REMOVE,
            index,
        });
    };

    const handleAddNewOption = () => {
        dispatchOptionsAction({
            type: Actions.ADD,
            data: getEmptyOptionData(),
        });
    };

    const handleCopyAttributesFromOption = (optionId) => {
        const optionIndex = optionsData.findIndex(option => option.id === optionId);
        dispatchOptionsAction({
            type: Actions.COPY,
            originOptionIndex: optionIndex,
            targetOptionIndex: activeOptionIndex,
        });
    };

    const handleOnChange = (e) => {
        setName(e.target.value.toUpperCase());
    };

    useEffect(() => {
        const matchesSomeOtherName = keywordNames.some(n => equalInLC(n, name) && !equalInLC(n, oldName));

        setIsKeywordInputValid(!matchesSomeOtherName);
    }, [name, oldName, setIsKeywordInputValid, keywordNames])

    const handleSave = () => {
        setIsLoaderShown(true)

        const dataToSave = { name }
        dataToSave.keywordOptions = optionsData.map(option => normalizeOptionForBackEnd(option, selectsData));
        let sendData;

        if (id) {
            dataToSave.id = id;
            sendData = setAdminKeyword;
        } else {
            sendData = addAdminKeyword;
        }

        sendData(dataToSave)
            .then(() => {
                history(Routes.AdminKeywords);
                setIsLoaderShown(false)
            })
            .catch(data => {
                setIsLoaderShown(false)
                alert('Saving Error: ' + data.responseText);
            });
    };

    const handleApplyManageCountries = (countriesPairedWithOptions) => {
        const dataForUpdate = optionsData.map(
            option => ({
                countryCodes: Object.keys(countriesPairedWithOptions).filter(
                    countryCode => countriesPairedWithOptions[countryCode] === option.id
                )
            })
        );

        dispatchOptionsAction({
            type: Actions.EDIT_BROADCAST,
            data: dataForUpdate,
        });
    };
    return (
        <Page title={location.pathname === Routes.AdminKeywordAdd ? "Add Keyword" : "Edit Keyword" }>
            <div className="admin-tool">
                <Loader isShown={isLoaderShown} />
                <div className="Keyword__header">
                    <div className="admin-tool__sidebar">
                        <SidebarHeader
                            optionsLength={optionsData.length}
                            addNewOption={handleAddNewOption}
                            openComparePopup={() => setIsComparePopupShown(true)}
                            openManageCountriesPopup={() => setIsManageCountriesPopupShown(true)}
                        />
                    </div>
                    <div className="admin-tool__wrapper">
                        <div className="admin-tool__top-title">
                            <div className="admin-tool__top">
                                <label className="admin-tool__input-wrap">
                                    <span className="admin-tool__input-label">
                                        Keyword name
                                    </span>
                                    <input
                                        type="text"
                                        className={`admin-tool__input admin-tool__input--${name.length > 0 ? 'filled' : 'empty'}`}
                                        value={name}
                                        placeholder={'Type keyword name'}
                                        onChange={(e) => handleOnChange(e)}
                                    />
                                    {!isKeywordInputValid && (
                                        <div className="admin-tool__error">
                                            The word already exist
                                        </div>
                                    )}

                                </label>

                                <div className="admin-tool__btns">
                                    <Link to={Routes.AdminKeywords} className="btn btn--link">Cancel</Link>
                                    <button
                                        onClick={handleSave}
                                        className={`btn btn--filled ${!name.trim().length || !isKeywordInputValid ? 'admin-tool__btn--disabled' : ''}`}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="Keyword__content">
                    <div className="admin-tool__sidebar">
                        <Sidebar
                            data={normalizeForSidebar(optionsData, activeOptionIndex)}
                            removeOption={handleRemoveOption}
                            handleChangeOptionName={(index, name) => handleChangeOptionData(index, { name })}
                            setActiveOptionIndex={setActiveOptionIndex}
                        />
                    </div>
                    <div className="admin-tool__wrapper">
                        {optionsData.length > 0 && optionsData[activeOptionIndex] && (
                            <Settings
                                data={optionsData[activeOptionIndex]}
                                handleChangeField={handleChangeOptionField}
                                openCopyAttributesPopup={() => setIsCopyAttributesPopupShown(true)}
                                isCopyAttributesButtonShown={optionsData.length > 1}
                            />
                        )}
                    </div>
                </div>
            </div>

            <Popup
                shown={isCopyAttributesPopupShown}
                closeModal={() => setIsCopyAttributesPopupShown(false)}
                title="Choose country group for import"
                btnModifier="blue"
                applyBtnTitle="Apply"
                hasConfirmData
                onConfirm={handleCopyAttributesFromOption}
            >
                {({ dataToConfirm, setDataToConfirm }) => (
                    <div className="adminToolPopup__dropdown">
                        <SettingsDropdown
                            options={normalizeForDropDownWithoutActive(optionsData, activeOptionIndex)}
                            value={dataToConfirm}
                            onChange={setDataToConfirm}
                            title="Choose group"
                            isWide
                            isCheckboxHidden
                        />
                    </div>
                )}
            </Popup>
            <Popup
                modifier="manage"
                shown={isManageCountriesPopupShown}
                closeModal={() => setIsManageCountriesPopupShown(false)}
                title="Manage countries for all groups"
                btnModifier="blue"
                applyBtnTitle="Save"
                hasConfirmData
                onConfirm={handleApplyManageCountries}
            >
                {({ dataToConfirm, setDataToConfirm }) => (
                    <ManageCountriesPopup
                        values={normalizeForManageCountries(optionsData)}
                        options={normalizeForDropDown(optionsData)}
                        dataToConfirm={dataToConfirm}
                        setDataToConfirm={setDataToConfirm}
                    />
                )}
            </Popup>

            <CompareOptionsPopup
                shown={isComparePopupShown}
                closeModal={() => setIsComparePopupShown(false)}
                title="Compare keyword settings"
                optionsData={optionsData.map(option => normalizeOptionForCompare(option, selectsData))}
                countries={countries}
                name={name}
            />
        </Page>
    );
}

const mapState = state => ({
    countries: countriesSelector(state),
    selectsData: state.keywordSettings,
    keywordNames: state.adminKeywords.adminKeywordNames,
});

export default connect(mapState)(Keyword);