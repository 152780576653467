import React, { FC, useContext, useMemo } from "react";
import Icon from "@components/Icon/Icon";
import { IconCheckSuccess, IconCloseFailure } from "@icons";
import CheckboxInput from "@components/CheckboxInput/CheckboxInput";
import Cell from "@components/NewAwesomeTable/Cell";
import HightlightChars from "@pages/Admin/Common/HightlightChars";
import { highlightMatches } from '@core/helpers';
import { InstantChartContext } from './SelectInstantCharts';
import { iAdminInstantChart } from '@apiHooks/useAdminInstantChartsList';

const TableRow: FC<{
    data: iAdminInstantChart;
    isChecked: boolean;
    searchQuery: string;
    toggleIsChecked: () => void;
    isMultipleSelect: boolean;
}> = ({
    data,
    isChecked,
    searchQuery,
    toggleIsChecked,
    isMultipleSelect,
}) => {
    const { categories, subCategories } = useContext(InstantChartContext);
    const { title, categoryId, subCategoryId, isActive } = data;

    const category = useMemo(() => {
        return categories.find(i => i.id === categoryId);
    }, [categories, categoryId]);

    const subCategory = useMemo(() => {
        return subCategories.find(i => i.id === subCategoryId);
    }, [subCategories, subCategoryId]);

    return (
        <>
            <Cell className="AdminTable__col AdminTable__col--small">
                <CheckboxInput
                    isRadioBtn={!isMultipleSelect}
                    isBlueCheck
                    isChecked={isChecked}
                    onChange={toggleIsChecked}
                />
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--fluid AdminTable__col--no-p">
                <span className="AdminTable__truncated" title={title}>
                    <HightlightChars name={highlightMatches(title, searchQuery)} />
                </span>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--fluid">
                <span className="AdminTable__truncated" title={category?.title}>
                    {category?.title}
                </span>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--fluid">
                <span className="AdminTable__truncated" title={subCategory?.title}>
                    {subCategory?.title}
                </span>
            </Cell>
            <Cell
                className="AdminTable__col AdminTable__col--medium"
                options="center no-pl"
            >
                <Icon>
                    {isActive ? <IconCheckSuccess/> : <IconCloseFailure/>}
                </Icon>
            </Cell>
        </>
    );
}

export default TableRow;
