import {deepCopy} from "@core/old_helpers";
import { getResponsiveScale, rem } from "@core/getResponsiveScale";

// const STEPS = [0.1, 0.2, 0.25, 0.5, 1, 2, 2.5, 5, 10, 20, 25, 50, 100, 200, 250, 500, 1000, 2000, 2500, 5000, 10000];
const STEPS = [ 1, 2, 5, 10, 20, 25, 50, 100, 200, 250, 500, 1000, 2000, 2500, 5000, 10000 ];

export const getMinMax = (chartObj, height, getRem) => {
    let maxValue = -Infinity;
    let minValue = Infinity;

    chartObj.data.forEach(chartData => {
        chartData.values.forEach(value => {
            maxValue = Math.max(maxValue, value);
            minValue = Math.min(minValue, value);
        });
    });


    let min = minValue;
    let max = maxValue;

    if (maxValue >= 100) {
        max = maxValue + 10;
    } else if (maxValue >= 40) {
        max = Math.min(100, maxValue + 8);
        min = Math.max(-0.1, minValue - 8);
    } else if (maxValue >= 10) {
        max = maxValue + 4;
        min = Math.max(-0.1, minValue - 4);
    } else if (maxValue >= 3) {
        max = maxValue + 2;
        min = Math.max(-0.1, minValue - 2);
    } else if (maxValue >= 1) {
        max = maxValue + 1;
        min = Math.max(-0.1, minValue - 1);
    } else {
        max = 1;
        min = Math.max(-0.1, min - 1);
    }

    let diff = max - min;
    const letStep = diff / 4; // show approx 6 labels
    const step = STEPS.find(step => step >= letStep);

    // avoid javascript bit issue
    min = min - min % step;
    if (max % step > 0) {
        max = max - max % step + step;
    } else {
        max = max - max % step;
    }
    // if (min === minValue) {
    //     min -= step;
    // }

    const proportion = (height - getRem(50)) / (max - min);

    function getCoordinate(value) {
        return getRem(15) + (max - value) * proportion;
    }

    return {
        min,
        max,
        getCoordinate,
        step,
        labelsCount: Math.round((max - min) / step + 1),
    };
};

export const getPath = (
    coordinates,
    height,
    width,
    hasPrediction,
    type,
    verticalPadding,
    leftPadding,
    rightPadding
) => {
    const acc = coordinates.map(() => []);
    coordinates.forEach((curCoordinates, idx) => {
        curCoordinates.forEach((point, _idx) => {
            if (_idx === 0) {
                // pseudo-predict before first point
                const nextPointsDiff =
                    curCoordinates[1].y - curCoordinates[0].y;
                acc[idx].push("M");
                acc[idx].push(leftPadding);
                acc[idx].push(point.y - nextPointsDiff / 2);

                acc[idx].push("L");
                acc[idx].push(point.x);
                acc[idx].push(point.y);
            } else if (_idx === curCoordinates.length - 1) {
                acc[idx].push("L");
                acc[idx].push(point.x);
                acc[idx].push(point.y);

                // pseudo-predict after last point
                const prevPointsDiff =
                    curCoordinates[curCoordinates.length - 2].y -
                    curCoordinates[curCoordinates.length - 1].y;
                acc[idx].push("L");
                acc[idx].push(width - rightPadding / 2);
                acc[idx].push(point.y - prevPointsDiff / 2);
            } else {
                acc[idx].push("L");
                acc[idx].push(point.x);
                acc[idx].push(point.y);
            }
        });

        // split all points onto 2 Main and Additional
        const arrayLength = acc[idx].length;

        const pathElementsForPredict = hasPrediction ? 16 : 0;
        const delimeter = type === "half" ? 3 : 2;
        const mainPath = acc[idx].filter(
            (path, idx) => idx <= arrayLength - pathElementsForPredict
        );
        const additionalPath = acc[idx].filter(
            (path, idx) => idx > arrayLength - pathElementsForPredict
        );

        if (additionalPath.length > 0) {
            // path-line to Haiku vertical delimeter
            const lastMainPathX = mainPath[mainPath.length - 2];
            const lastMainPathY = mainPath[mainPath.length - 1];
            const firstAdditionalPathX = additionalPath[1];
            const firstAdditionalPathY = additionalPath[2];
            mainPath.push("L");
            mainPath.push(
                lastMainPathX +
                (firstAdditionalPathX - lastMainPathX) / delimeter
            );
            mainPath.push((lastMainPathY + firstAdditionalPathY) / 2);

            // add "M" with coordinates of last Main point to the head of Additional points
            additionalPath.unshift(mainPath[mainPath.length - 1]);
            additionalPath.unshift(mainPath[mainPath.length - 2]);
            additionalPath.unshift("M");
        }

        acc[idx] = {
            mainPath: mainPath.join(" "),
            additionalPath: additionalPath.join(" "),
            wholePath: [
                acc[idx][0],
                leftPadding,
                height,
                "L",
                ...acc[idx].slice(1),
                "L",
                width - rightPadding / 2,
                height,
                "L",
                leftPadding,
                height,
            ].join(" "),
        };
    });
    return acc;
};

export const getXGridCoordinates = (
    charts,
    hasPrediction,
    type,
    width,
    leftPadding,
    rightPadding
) => {
    const colNumber = charts.data.length;
    const additionalColumns = type === "half" && hasPrediction ? 4 : 0;
    const widthWithoutPaddings = width - leftPadding - rightPadding;
    const colWidth = widthWithoutPaddings / (colNumber + additionalColumns);

    const coordinatesArray = Array(
        charts.data.length + 1 + additionalColumns
    ).fill(colWidth);

    // we have padding and that's why need to start from padding only,
    // without adding width
    let prevCoordinate = leftPadding - colWidth;
    const resultArray = coordinatesArray.map(
        (x, idx) => +parseFloat((prevCoordinate += x)).toFixed(2)
    );
    const indexesToBeRemoved = [
        resultArray.length - 2,
        resultArray.length - 4,
        resultArray.length - 6,
        resultArray.length - 8,
    ];

    return type === "half" && hasPrediction
        ? resultArray.filter((res, idx) => !indexesToBeRemoved.includes(idx))
        : resultArray;
};

export const getXCoordinates = gridXCoordinates => {
    return gridXCoordinates.map(
        (coord, idx) => (gridXCoordinates[idx] + gridXCoordinates[idx + 1]) / 2
    );
};

export const getCoordinatesArray = (
    xCoordinates,
    getCoordinate,
    charts,
) => {
    const acc = charts.chartInfo.map(() => []);

    charts.data.forEach((curChartData, idx) => {
        curChartData.values.forEach((val, valIdx) => {
            const value = getCoordinate(val);
            acc[valIdx].push({
                x: xCoordinates[idx],
                y: value,
                isPrediction: curChartData.isPrediction,
            });
        });
    });

    return acc;
};

export const recalculateHoverColumn = (
    containerRef,
    xGridCoordinates,
    activeColumnX1,
    activeColumnX2,
    hasCapture,
    tooltipWidth,
    dispatch,
    charts,
    chartDataType,
    activeTabId
) => ({clientX, clientY}) => {
    const chartElementRect = containerRef.current.getBoundingClientRect();
    const relativeClientX = clientX - chartElementRect.left;
    const relativeClientY = clientY - chartElementRect.top;
    const colEnd = xGridCoordinates.find(coord => coord >= relativeClientX);
    const colEndIdx = xGridCoordinates.findIndex(coord => coord === colEnd);

    // case with first and last columns
    if (colEndIdx === 0 || !colEnd) {
        dispatch({
            hasCapture: false,
        });
        return;
    } else {
        if (!hasCapture) {
            dispatch({hasCapture: true});
        }
    }

    const colStartIdx = colEndIdx - 1;

    // only when we change activeColumn
    // if (xGridCoordinates[colStartIdx] !== activeColumnX1) {
    const options = {
        activeColumnX1: xGridCoordinates[colStartIdx],
        activeColumnX2: xGridCoordinates[colEndIdx],
        clientY: relativeClientY,
        tooltipWidth: tooltipWidth,
    };
    setTooltipData(
        charts,
        activeTabId,
        chartDataType,
        colStartIdx,
        options,
        dispatch
    );
    // }

    dispatch({
        activeColumnX1: xGridCoordinates[colStartIdx],
        activeColumnX2: xGridCoordinates[colEndIdx],
        clientX: relativeClientX,
        clientY: relativeClientY,
    });
};

export const setTooltipData = (
    charts,
    activeTabId,
    chartDataType,
    activeColIdx,
    options,
    dispatch
) => {
    const activeColData = charts.data[activeColIdx];
    const title =
        activeColData.label === Math.ceil(activeColData.label)
            ? `${activeColData.label}`
            : `${Math.floor(activeColData.label)} - 2nd half`;

    const labelValues = activeColData.valuesLabels ? activeColData.valuesLabels : activeColData.values;

    const tooltipData = {
        title,
        values: labelValues
            .map((value, idx) => ({
                color: charts.chartInfo[idx].color,
                value,
                name: charts.chartInfo[idx].name,
            }))
            .sort((a, b) =>
                parseFloat(a.value.replace("/%$/g", "")) >
                parseFloat(b.value.replace("/%$/g", ""))
                    ? -1
                    : 1
            ),
        options,
    };
    const dotIndex = activeColIdx;

    dispatch({tooltipData, dotIndex});
};

//--------------
// drawing
//--------------

export const drawGrid = (
    ctx,
    coefficient,
    height,
    hasPrediction,
    xGridCoordinates,
    xCoordinates,
    isLightTheme
) => {
    xGridCoordinates.forEach((x, idx) => {
        if (hasPrediction) {
            // add non prediction area background
            ctx.globalAlpha = .5;
            ctx.fillStyle = `rgba(${
                isLightTheme ? "255, 255, 255" : "35, 45, 59"
            }, 0.5)`;
            ctx.rect(0, 0, xGridCoordinates[xCoordinates.length - 5], height);
            ctx.fill();
            ctx.globalAlpha = 1;
        }


        const isLastMainLine = hasPrediction && idx === xCoordinates.length - 5;
        ctx.lineWidth = 0.5;
        ctx.beginPath();
        if (!isLastMainLine) {
            // ctx.setLineDash([1, 15]);
            // ctx.strokeStyle = `rgba(${
            //     isLightTheme ? "0, 0, 0" : "255, 255, 255"
            // }, 0.6)`;
            // ctx.moveTo(x, 15 * coefficient);
        } else {
            ctx.setLineDash([]);
            ctx.strokeStyle = `rgba(${
                isLightTheme ? "255, 255, 255" : "0, 0, 0"
            }, 0.7)`;
            // ctx.strokeStyle = `rgba(${
            //     isLightTheme ? "0, 0, 0" : "255, 255, 255"
            // }, 0.7)`;
            ctx.moveTo(x, 0);
        }
        ctx.lineTo(x, height - 40);
        // ctx.stroke();
    });
    ctx.setLineDash([]);
};

export const drawHaikuText = (ctx, width, height, xGridCoordinates, xCoordinates) => {
    const path = new Path2D();
    const paths = [
        new Path2D('M37.722 6.32822V51.0002H28.762V31.9922H9.62602V51.0002H0.666016V6.32822H9.62602V24.6962H28.762V6.32822H37.722Z'),
        new Path2D('M44.237 33.1442C44.237 29.5602 44.941 26.3816 46.349 23.6082C47.7997 20.8349 49.741 18.7016 52.173 17.2082C54.6477 15.7149 57.3997 14.9682 60.429 14.9682C63.0743 14.9682 65.3784 15.5016 67.341 16.5682C69.3464 17.6349 70.9463 18.9789 72.141 20.6002V15.5442H81.165V51.0002H72.141V45.8162C70.989 47.4802 69.389 48.8669 67.341 49.9762C65.3357 51.0429 63.0103 51.5762 60.365 51.5762C57.3783 51.5762 54.6477 50.8082 52.173 49.2722C49.741 47.7362 47.7997 45.5816 46.349 42.8082C44.941 39.9922 44.237 36.7709 44.237 33.1442ZM72.141 33.2722C72.141 31.0962 71.7143 29.2402 70.861 27.7042C70.0077 26.1256 68.8557 24.9309 67.405 24.1202C65.9543 23.2669 64.397 22.8402 62.733 22.8402C61.069 22.8402 59.533 23.2456 58.125 24.0562C56.717 24.8669 55.565 26.0616 54.669 27.6402C53.8157 29.1762 53.389 31.0109 53.389 33.1442C53.389 35.2776 53.8157 37.1549 54.669 38.7762C55.565 40.3549 56.717 41.5709 58.125 42.4242C59.5757 43.2776 61.1117 43.7042 62.733 43.7042C64.397 43.7042 65.9543 43.2989 67.405 42.4882C68.8557 41.6349 70.0077 40.4402 70.861 38.9042C71.7143 37.3256 72.141 35.4482 72.141 33.2722Z'),
        new Path2D('M94.46 11.3202C92.8813 11.3202 91.5587 10.8296 90.492 9.84822C89.468 8.82422 88.956 7.56555 88.956 6.07222C88.956 4.57889 89.468 3.34155 90.492 2.36022C91.5587 1.33622 92.8813 0.824219 94.46 0.824219C96.0387 0.824219 97.34 1.33622 98.364 2.36022C99.4307 3.34155 99.964 4.57889 99.964 6.07222C99.964 7.56555 99.4307 8.82422 98.364 9.84822C97.34 10.8296 96.0387 11.3202 94.46 11.3202ZM98.876 15.5442V51.0002H89.916V15.5442H98.876Z'),
        new Path2D('M128.721 51.0002L116.689 35.8962V51.0002H107.729V3.64022H116.689V30.5842L128.593 15.5442H140.241L124.625 33.3362L140.369 51.0002H128.721Z'),
        new Path2D('M178.576 15.5442V51.0002H169.552V46.5202C168.4 48.0562 166.885 49.2722 165.008 50.1682C163.173 51.0216 161.168 51.4482 158.992 51.4482C156.218 51.4482 153.765 50.8722 151.632 49.7202C149.498 48.5256 147.813 46.7976 146.576 44.5362C145.381 42.2322 144.784 39.5016 144.784 36.3442V15.5442H153.744V35.0642C153.744 37.8802 154.448 40.0562 155.856 41.5922C157.264 43.0856 159.184 43.8322 161.616 43.8322C164.09 43.8322 166.032 43.0856 167.44 41.5922C168.848 40.0562 169.552 37.8802 169.552 35.0642V15.5442H178.576Z'),
    ];

    // Create transformation matrix that moves to the required position
    let m = document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGMatrix();
    m.a = 1;
    m.b = 0;
    m.c = 0;
    m.d = 1;
    m.e = xGridCoordinates[xCoordinates.length - 5] + 10;
    m.f = height * .65;

    ctx.globalAlpha = .15;
    ctx.fillStyle = '#4F637F';

    // path.rect(100, 60, 25, 50);
    paths.forEach(letter => path.addPath(letter, m));
    ctx.fill(path);
    ctx.globalAlpha = 1;
};

export const drawYearLabels = (
    ctx,
    xGridCoordinates,
    charts,
    height,
    isLightTheme,
    getRem,
) => {
    ctx.font = `bold ${getRem(12)}px "Nunito"`;
    ctx.fillStyle = `${isLightTheme ? "#3A4B61" : "rgba(255, 255, 255, 0.5)"}`;
    ctx.textAlign = "center";
    const colWidth = xGridCoordinates[1] - xGridCoordinates[0];

    charts.data.forEach((chartData, idx) => {
        if (chartData.label === Math.ceil(chartData.label)) {
            const xCoordinate = xGridCoordinates[idx] + colWidth / 2;
            ctx.fillText(chartData.label, xCoordinate, height - getRem(15));
        }
    });
};

export const drawValuesLabels = (ctx, charts, height, prefix, suffix, isLightTheme, getRem) => {
    ctx.font = `bold ${getRem(12)}px "Nunito"`;
    ctx.fillStyle = `${isLightTheme ? "#3A4B61" : "rgba(255, 255, 255, 0.5)"}`;
    ctx.textAlign = "right";
    ctx.textBaseline = "middle";
    const {max, getCoordinate, step, labelsCount} = getMinMax(charts, height, getRem);

    for (let index = 0; index < labelsCount; index++) {
        const value = (step * 100 * index) / 100;
        let label = (parseInt(max * 100) - parseInt(value * 100)) / 100;

        if (label >= 1000000) {
            label = `${+(label / 1000000).toFixed(1)}M `;
        } else if (label >= 1000) {
            label = `${+(label / 1000).toFixed(1)}K `;
        }


        if (value >= 0) {
            ctx.fillText(
                `${prefix}${label}${suffix}`,
                getRem(40),
                getCoordinate(max - value),
            );
        }
        ctx.lineWidth = .15;
        ctx.beginPath();
        ctx.globalAlpha = .5;
        ctx.moveTo(50, getCoordinate(max - value));
        ctx.lineTo(2000, getCoordinate(max - value));
        ctx.stroke();
        ctx.closePath();
        ctx.globalAlpha = 1;
    }
    ctx.textBaseline = "alphabetic";
};

export const drawMarker = (ctx, coordinatesArray, colors, isLightTheme, dotIndex) => {
    (dotIndex !== null) && coordinatesArray.forEach((coordinates, idx) => {
        if (coordinates[dotIndex]) {
            const x = coordinates[dotIndex].x;
            const y = coordinates[dotIndex].y;
            // outer circle
            ctx.fillStyle = isLightTheme ? "#FDFDFD" : "#1D202A";
            ctx.strokeStyle = colors[idx];
            ctx.globalAlpha = .2;
            ctx.beginPath();
            ctx.arc(x, y, 4.5, 0, 2 * Math.PI);
            ctx.stroke();
            ctx.fill();
            ctx.closePath();

            // inner circle
            ctx.beginPath();
            ctx.fillStyle = colors[idx];
            ctx.globalAlpha = 1;
            ctx.arc(x, y, 2.5, 0, 2 * Math.PI);
            ctx.fill();
            ctx.closePath();
        }
    });
};

export const drawLines = (
    ctx,
    coordinatesArray,
    colors,
    opacities,
    height,
    width,
    hasPrediction,
    type,
    verticalPadding,
    leftPadding,
    rightPadding,
    onlyLine,
    isLightTheme,
    getRem,
) => {
    const pathsArray = getPath(
        coordinatesArray,
        height,
        width,
        hasPrediction,
        type,
        verticalPadding,
        leftPadding,
        rightPadding,
    );

    pathsArray.forEach((path, idx) => {
        ctx.lineWidth = getRem(3);
        ctx.strokeStyle = colors[idx];
        ctx.globalAlpha = opacities[idx];

        ctx.setLineDash([ getRem(5), getRem(9) ]);
        ctx.lineCap = 'round';
        const additionalPath = new Path2D(path.additionalPath);
        ctx.stroke(additionalPath);

        ctx.setLineDash([]);
        ctx.lineWidth = getRem(onlyLine ? 5 : 3);
        const mainPath = new Path2D(path.mainPath);
        ctx.stroke(mainPath);
        if (!onlyLine) {
            const wholePath = new Path2D(path.wholePath);
            const coords = deepCopy(coordinatesArray[idx]);
            coords.sort((a, b) => a.y < b.y ? -1 : 1);
            // const yMax = coords[0].y;
            const gr = ctx.createLinearGradient(0, 0, 0, height);

            if (colors[idx]) {
                gr.addColorStop(0, colors[idx]);
            }
            gr.addColorStop(1, isLightTheme ? "rgba(255,255,255,0)" : "rgba(0,0,0,0)");
            ctx.globalAlpha = 0.6 * opacities[idx];
            ctx.fillStyle = gr;
            ctx.fill(wholePath);
        }
        ctx.globalAlpha = 1;
    });
};

export const drawActiveColumn = (
    ctx,
    height,
    activeColumnX1,
    activeColumnX2,
    isLightTheme,
    charts
) => {
    if (isLightTheme) {
        ctx.fillStyle = "rgba(242, 246, 250, 1)";
    } else {
        ctx.fillStyle = "rgba(39, 51, 68, 1)";
    }

    const path = new Path2D(
        `M ${activeColumnX1} 0 L ${activeColumnX2} 0 L ${activeColumnX2} ${height} L ${activeColumnX1} ${height}`
    );
    ctx.fill(path);
    ctx.fillStyle = "#F55353";
    let borderBottom = new Path2D();
    borderBottom.rect(activeColumnX1, height - 3, activeColumnX2 - activeColumnX1, 3);
    ctx.fill(borderBottom);
};
