import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { customRestaurantListsSelector, restaurantsListsSelector } from "@selectors/filters.selectors";
import { setActiveRestaurantList, setCustomRestaurantLists } from "@actions/filters.actions";
import RestaurantsMenuPopup from "./RestaurantsMenuPopup/RestaurantsMenuPopup";
import DropdownMenu from "../DropdownMenu/DropdownMenu";
import { CSSTransition } from "react-transition-group";
import RestaurantsMenuManage from "./RestaurantsMenuManage/RestaurantsMenuManage";
import { currentCountrySelector } from "@selectors/countries.selector";
import { useSearch } from "@core/customHooks";
import { addNotification } from "@actions/notifications.actions";
import {
    addCustomRestaurantList,
    deleteCustomRestaurantList,
    getCustomRestaurantLists,
    updateCustomRestaurantList
} from "@core/api";
import { IconArrowTop } from "@icons";
import useOutsideClick from "@hooks/useOutsideClick";
import ConfirmationPopup from "@components/ConfirmationPopup/ConfirmationPopup";
import Icon from "@components/Icon/Icon";

const RestaurantsMenu = ({
    setActiveRestaurantListId,
    restaurantLists,
    isUsa,
    customRestaurantLists,
    currentCountry,
    addNotification,
    setCustomRestaurantLists
}) => {
    const [isRestaurantsMenuShown, setIsRestaurantsMenuShown] = useState(false);
    const [isRestaurantsMenuPopupShown, setIsRestaurantsMenuPopupShown] = useState(false);
    const [isManagePopupShown, setIsManagePopupShown] = useState(false);
    const [isViewPopupShown, setIsViewPopupShown] = useState(false);
    const [restaurantListId, setRestaurantListId] = useState(null);
    const [searchQuery, setSearchQuery, filteredSearchData] = useSearch(customRestaurantLists);
    const selectedRestaurant = restaurantLists.find(r => r.isActive);
    const defaultRestaurantLists = restaurantLists.filter(({ isCustom }) => isCustom === false);

    const [isLoadingCustomRestaurantLists, setIsLoadingCustomRestaurantLists] = useState(true);
    const [isRemoveConfirmationShown, setIsRemoveConfirmationShown] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const [isAdding, setIsAdding] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isAdded, setIsAdded] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);

    useEffect(() => {
        setIsOpen(false);

        getCustomRestaurantLists(currentCountry).then(data => {
            setCustomRestaurantLists(data);
        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => {
            setIsLoadingCustomRestaurantLists(false);
        });
    }, []);

    const handleRestaurantsToggle = () => {
        setIsOpen(!isOpen);
        setIsRestaurantsMenuShown(!isRestaurantsMenuShown);
    };

    const handleShowPopup = () => {
        setIsOpen(true);
        setIsRestaurantsMenuShown(false);
        setIsRestaurantsMenuPopupShown(true);
    };

    const handleShowManagePopup = id => {
        setRestaurantListId(id);
        setIsOpen(true);
        setIsRestaurantsMenuPopupShown(false);
        setTimeout(() => {
            setIsManagePopupShown(true);
        });
    };

    const handleShowViewPopup = id => {
        setRestaurantListId(id);
        setIsOpen(true);
        setIsRestaurantsMenuPopupShown(false);
        setTimeout(() => {
            setIsViewPopupShown(true);
        });
    };

    const handleCloseManagePopup = backward => {
        setRestaurantListId(null);
        setIsManagePopupShown(false);

        if (backward) {
            setIsRestaurantsMenuPopupShown(true);
        }
    };

    const handleCloseViewPopup = backward => {
        setRestaurantListId(null);
        setIsViewPopupShown(false);

        if (backward) {
            setIsRestaurantsMenuPopupShown(true);
        }
    };

    const showNotification = (message, type) => {
        addNotification({
            text: message,
            duration: 5000,
            [type]: true
        });
    };

    useEffect(() => {
        if ( isUpdated && isOpen ) {
            showNotification(`Saved custom restaurant list`, "success");
            setIsLoadingCustomRestaurantLists(true);
            handleCloseManagePopup(true);
            getCustomRestaurantLists(currentCountry)
                .then(data => setCustomRestaurantLists(data))
                .catch(err => console.log(err))
                .finally(() => {
                    setIsLoadingCustomRestaurantLists(false);
                    setIsUpdated(false);
                });
        } else {
            setIsUpdated(false);
        }

    }, [isUpdated, isOpen]);

    useEffect(() => {
        if ( isAdded && isOpen ) {
            showNotification(`Created custom restaurant list`, "success");
            setIsLoadingCustomRestaurantLists(true);
            handleCloseManagePopup(true);
            getCustomRestaurantLists(currentCountry)
                .then(data => setCustomRestaurantLists(data))
                .catch(err => console.log(err))
                .finally(() => {
                    setIsLoadingCustomRestaurantLists(false);
                    setIsAdded(false);
                });
        } else {
            setIsAdded(false);
        }
    }, [isAdded, isOpen]);

    const handleUpdateCustomList = (data, isEdit) => {
        if ( isEdit ) {
            setIsUpdating(true);
            updateCustomRestaurantList(data)
                .then(() => {
                    setIsUpdating(false);
                    setIsUpdated(true);
                })
                .catch(() => showNotification(`Save failed`, "error"));
        }

        if ( !isEdit ) {
            setIsAdding(true);
            addCustomRestaurantList(data)
                .then(() => {
                    setIsAdding(false);
                    setIsAdded(true)
                })
                .catch(() => {
                    showNotification(`Сreating a new list failed`, "error");
                })
        }
    }
    const element = useRef(null);
    useOutsideClick({
        ref: element,
        isDisabled: !isRestaurantsMenuShown,
        fn: () => handleRestaurantsToggle(),
    });

    const handleRemoveItemConfirm = () => {
        setIsLoadingCustomRestaurantLists(true);
        deleteCustomRestaurantList(currentCountry, restaurantListId)
            .then(() => {
                getCustomRestaurantLists(currentCountry)
                    .then(data => setCustomRestaurantLists(data))
                    .catch(err => console.log(err))
                    .finally(() => {
                        setIsLoadingCustomRestaurantLists(false);
                        setRestaurantListId(null);
                    });
            })
            .catch(err => console.log(err));
    }

    const handleRemove = id => () => {
        setIsRemoveConfirmationShown(true);
        setRestaurantListId(id);
    };

    return (
        <div ref={element}>
            {(isUsa && !isLoadingCustomRestaurantLists) && selectedRestaurant && (
                <button onClick={handleRestaurantsToggle} className="page-footer__restaurants">
                    {selectedRestaurant.name.toUpperCase()}
                    <Icon className={`page-footer__icon-arrow ${isRestaurantsMenuShown ? 'is-open': ''}`}>
                        <IconArrowTop />

                    </Icon>
                </button>
            )}

            <RestaurantsMenuPopup
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                data={filteredSearchData}
                shown={isRestaurantsMenuPopupShown}
                handleItemClick={setActiveRestaurantListId}
                closeModal={() => {setIsRestaurantsMenuPopupShown(false); setIsOpen(false)}}
                handleShowManagePopup={handleShowManagePopup}
                handleShowViewPopup={handleShowViewPopup}
                isLoadingCustomRestaurantLists={isLoadingCustomRestaurantLists}
                handleRemoveItem={handleRemove}
            />

            <RestaurantsMenuManage
                shown={isManagePopupShown}
                closeModal={handleCloseManagePopup}
                restaurantListId={restaurantListId}
                currentCountry={currentCountry}
                showNotification={showNotification}
                handleUpdateCustomList={handleUpdateCustomList}
                isUpdating={isUpdating || isAdding}
            />

            {/* view CRL restaurants */}
            <RestaurantsMenuManage
                shown={isViewPopupShown}
                closeModal={handleCloseViewPopup}
                restaurantListId={restaurantListId}
                currentCountry={currentCountry}
                showNotification={showNotification}
                handleUpdateCustomList={() => null}
                isUpdating={false}
                isViewMode
            />
            {/* view CRL restaurants */}

            <DropdownMenu
                isWide
                data={defaultRestaurantLists}
                closeModal={() => setIsRestaurantsMenuShown(false)}
                shown={isRestaurantsMenuShown}
                handleItemClick={setActiveRestaurantListId}
            />

            <ConfirmationPopup
                shown={isRemoveConfirmationShown}
                closeModal={() => setIsRemoveConfirmationShown(false)}
                title="Are you sure you want to delete this restaurant list?"
                onConfirm={handleRemoveItemConfirm}
                submitButtonText="Delete"
            />


            <CSSTransition in={isRestaurantsMenuShown} timeout={250} unmountOnExit={true}>
                <button onClick={handleShowPopup} className="page-footer__dropdown-menu-button">
                    Custom restaurant lists
                </button>
            </CSSTransition>

        </div>
    );
};

const mapState = state => ({
    restaurantLists: restaurantsListsSelector(state),
    isUsa: state.countries.current === "USA",
    customRestaurantLists: customRestaurantListsSelector(state),
    currentCountry: currentCountrySelector(state),
});

const mapDispatch = dispatch => ({
    setActiveRestaurantListId: id => dispatch(setActiveRestaurantList(id)),
    addNotification: notification => dispatch(addNotification(notification)),
    setCustomRestaurantLists: data => dispatch(setCustomRestaurantLists(data))
});

export default connect(mapState, mapDispatch)(RestaurantsMenu);
