export enum HorizontalBars {
    HowItUsed,
    DifferentMenuTypes,
    PairedFlavors,
}

export const getHorizontalBarBackground = (type: HorizontalBars, isThemeLightEnable: boolean) => {
    switch (type) {
        case HorizontalBars.HowItUsed:
            return isThemeLightEnable ?
                'linear-gradient(269.28deg, #F6789C -0.09%, rgba(130, 92, 252, 0.83) 99.77%)' :
                'linear-gradient(269.28deg, rgba(246, 120, 156, 0.94) -0.09%, rgba(130, 92, 252, 0.1034) 99.77%)'
        case HorizontalBars.DifferentMenuTypes:
            return isThemeLightEnable ?
                'linear-gradient(286.88deg, #FF9800 1.84%, #DB6109 126.78%)' :
                'linear-gradient(286.88deg, #FC9F5C 1.84%, #3A190F 126.78%)';
        case HorizontalBars.PairedFlavors:
            return isThemeLightEnable ?
                'linear-gradient(280.02deg, #64E1D2 0.9%, #0A8C7C 113.81%)' :
                'linear-gradient(280.02deg, #64E1D2 0.9%, #092615 113.81%)';
        default:
            return 'none'
    }
}