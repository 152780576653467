import { useCallback, useEffect, useState } from 'react';
import apiConfig from "@core/apiConfig";
import { addNotification } from '@actions/notifications.actions';
import useFoodProfileData from '@apiHooks/useFoodProfileData';
import { useDispatch, useSelector } from 'react-redux';
import { currentCountrySelector } from "@selectors/countries.selector";
import { iCurrentChart, iPenetration } from "@models/FoodProfile";
import { getHorizontalBarBackground, HorizontalBars } from "./FoodProfile.helpers";
import { getChartData } from "@components/Chart/Chart.helpers";
import http from "@core/http";

const DEFAULT_CHART = {
    columns: [],
    charts: [],
}

const useFoodProfile = ({ id, name }: { id: string, name: string }) => {
    const countryCode = useSelector(currentCountrySelector);
    const { isLoading, ...foodProfile } = useFoodProfileData({ id, countryCode });
    const isThemeLightEnable = useSelector((state: any) => state.app.isThemeLightEnable);
    const [currentChart, setCurrentChart] = useState<iCurrentChart>(DEFAULT_CHART);
    const [barCharts, setBarCharts] = useState<{title: string; data: iPenetration[]; background: string;}[]>([]);

    useEffect(() => {
        if ( isLoading ) return;

        const { chart } = foodProfile.data;

        const chartData = getChartData([{
            ...chart,
            title: (foodProfile.data.name || name).toUpperCase(),
            color: isThemeLightEnable ? "#F55353" : "#4794FF",
            isActive: false,
        }]);

        setCurrentChart(chartData);
    }, [foodProfile.data, isLoading, isThemeLightEnable, name]);

    useEffect(() => {
        const { howItUsed, onDifferentMenuItems, pairedFlavors } = foodProfile.data;

        const getBarBackground = (type: HorizontalBars) => getHorizontalBarBackground(type, isThemeLightEnable);

        setBarCharts([
            {
                title: 'How it’s used',
                data: howItUsed,
                background: getBarBackground(HorizontalBars.HowItUsed)
            },
            {
                title: 'On different menu types',
                data: onDifferentMenuItems,
                background: getBarBackground(HorizontalBars.DifferentMenuTypes)
            },
            {
                title: 'Paired flavors',
                data: pairedFlavors,
                background: getBarBackground(HorizontalBars.PairedFlavors)
            }
        ]);
    }, [foodProfile.data, isThemeLightEnable]);

    const dispatch = useDispatch();
    const addNotify = useCallback(notification => dispatch(addNotification(notification)), [dispatch]);

    const handleExportProfile = useCallback(() => {
        const msgId = Date.now();
        addNotify({
            text: "Downloading...",
            id: msgId,
        });
        http.downloadFileXHRFromUrl(
            'GET',
            apiConfig.get('export food profile', {id})
        ).then(() => {
            addNotify({
                text: "Download was successful",
                id: msgId,
                duration: 4000,
            });
        }).catch(err => {
            addNotify({
                text: "Something went wrong.",
                error: true,
                id: msgId,
                duration: 4000,
            });
        });
    }, [addNotify, id]);

    return {
        isLoading,
        foodProfile: foodProfile.data,
        handleExportProfile,
        currentChart,
        barCharts,
    }
}

export default useFoodProfile;