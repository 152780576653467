import React from "react";
import { Route, Routes as Switch, Navigate as Redirect, useLocation } from "react-router-dom";
import Byo from "./pages/Byo/Byo";
import TopTrends from "./pages/TopTrends/TopTrends";
import HeaderContainer from "./containers/HeaderContainer/HeaderContainer";
import FoodProfiles from "./pages/FoodProfiles/FoodProfiles";
import TestPage from "./pages/TestPage";
import Reports from "@pages/Reports/Reports";
import InstantCharts from "./pages/InstantCharts/InstantCharts";
import AdminKeywords from "./pages/Admin/Keywords/Keywords";
import AdminKeyword from "./pages/Admin/Keyword/KeywordWrapper";
import AdminInstantCharts from "./pages/Admin/InstantCharts/InstantCharts";
import AdminInstantChart from "./pages/Admin/InstantChart/InstantChartWrapper";
import AdminReportsContainer from "./pages/Admin/Reports/ReportsContainer";
import AdminReportContainer from "./pages/Admin/Report/ReportContainer";
import { useSelector } from "react-redux";
import FoodProfile from "@pages/FoodProfile/FoodProfile";
import AdminCategoryReports from "@pages/Admin/CategoryReports/CategoryReports";
import AdminCategoryReport from "@pages/Admin/CategoryReport/CategoryReport";
import Home from "@pages/Home/Home";
import MenuExamples from "@pages/MenuExamples/MenuExamples";


const PrivateRoute = ({ children }) => {
    let location = useLocation();
    const isAdmin = useSelector(state => state.app.hasAdminAccess);

    if (!isAdmin) {
        return <Redirect to={Routes.Home} state={{ from: location }} />;
    }

    return children;
};

// TODO: add react-helmet for document.title

export const Routes = {
    Home: "/",
    AdminKeywords: "/admin/keywords",
    AdminKeywordAdd: "/admin/keyword/new",
    AdminKeywordEdit: "/admin/keyword/edit",
    AdminInstantCharts: "/admin/instant-charts",
    AdminInstantChartAdd: "/admin/instant-chart/new",
    AdminInstantChartEdit: "/admin/instant-chart/edit",
    AdminSummaryReports: "/admin/summary-reports",
    AdminSummaryReportAdd: "/admin/summary-report/new",
    AdminSummaryReportEdit: "/admin/summary-report/edit",
    AdminCategoryReports: "/admin/category-reports",
    AdminCategoryReportAdd: "/admin/category-report/new",
    AdminCategoryReportEdit: "/admin/category-report/edit",
    menuExamples: "/menu-examples",
    reports: "/reports",
    instantCharts: "/instant-charts",
    foodProfile: "/food-profile",
    test: "/test",
    foodProfiles: "/food-profiles",
    toptrends: "/topTrends",
    byo: "/byo",
};

export const ADMIN_ROUTES = [
    Routes.AdminKeywords,
    Routes.AdminKeywordAdd,
    Routes.AdminKeywordEdit,
    Routes.AdminInstantCharts,
    Routes.AdminInstantChartAdd,
    Routes.AdminInstantChartEdit,
    Routes.AdminSummaryReports,
    Routes.AdminSummaryReportAdd,
    Routes.AdminSummaryReportEdit,
    Routes.AdminCategoryReports,
    Routes.AdminCategoryReportAdd,
    Routes.AdminCategoryReportEdit,
];

const ByoContainer = () => {
    const location = useLocation();

    return <Byo key={`${location.search}`} />;
}

const RoutesContainer = () => {
    return (
        <div>
            <HeaderContainer />
            <Switch>
                <Route path={Routes.Home} exact element={<Home />} />
                <Route path={Routes.byo} element={<ByoContainer />} />
                <Route path={Routes.toptrends} exact element={<TopTrends />} />
                <Route path={Routes.foodProfiles} element={<FoodProfiles />} />
                <Route path={Routes.test} element={<TestPage />} />
                <Route path={Routes.foodProfile} element={<FoodProfile />} />
                <Route path={Routes.instantCharts} exact element={<InstantCharts />} />
                <Route path={Routes.menuExamples} exact element={<MenuExamples />} />

                <Route path={Routes.reports} element={<Reports />} />
                <Route
                    path={Routes.AdminKeywords}
                    element={
                        <PrivateRoute>
                            <AdminKeywords />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Routes.AdminKeywordEdit}
                    element={
                        <PrivateRoute>
                            <AdminKeyword />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Routes.AdminKeywordAdd}
                    element={
                        <PrivateRoute>
                            <AdminKeyword />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Routes.AdminInstantCharts}
                    element={
                        <PrivateRoute>
                            <AdminInstantCharts />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Routes.AdminInstantChartAdd}
                    element={
                        <PrivateRoute>
                            <AdminInstantChart />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Routes.AdminInstantChartEdit}
                    element={
                        <PrivateRoute>
                            <AdminInstantChart />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Routes.AdminSummaryReportAdd}
                    element={
                        <PrivateRoute>
                            <AdminReportContainer />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Routes.AdminSummaryReportEdit}
                    element={
                        <PrivateRoute>
                            <AdminReportContainer />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Routes.AdminSummaryReports}
                    element={
                        <PrivateRoute>
                            <AdminReportsContainer />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Routes.AdminCategoryReportAdd}
                    element={
                        <PrivateRoute>
                            <AdminCategoryReport/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Routes.AdminCategoryReportEdit}
                    element={
                        <PrivateRoute>
                            <AdminCategoryReport/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Routes.AdminCategoryReports}
                    element={
                        <PrivateRoute>
                            <AdminCategoryReports/>
                        </PrivateRoute>
                    }
                />

                <Route path="*" element={<Redirect to={Routes.Home} />} />
            </Switch>
        </div>
    );
};

export default RoutesContainer;
