import Chart from "@components/Chart/Chart";
import { iCurrentChart } from "@models/FoodProfile";
import { FC, useCallback, useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import "./FoodProfileChartContainer.styles.scss";

const FoodProfileChartContainer: FC<{ data: iCurrentChart}> = ({ data }) => {
    const chartContainerEl = useRef(null);

    const printValue = useCallback((n) => `${n % 1 === 0 ? n : Number.parseFloat(n).toFixed(1)}%`, []);

    return (
        <div
            className="FoodProfileChartContainer"
            ref={chartContainerEl}
        >
            {data.columns.length > 0 && (
                <AutoSizer>
                    {({ width, height}) => (
                        <Chart
                            charts={data.charts}
                            columns={data.columns}
                            width={width}
                            height={height}
                            printValue={printValue}
                            printLabel={printValue}
                            dashStartPointColumnIndex={data.dashStartPointColumnIndex}
                            dashStartPointSubColumnIndex={data.dashStartPointSubColumnIndex}
                        />
                    )}
                </AutoSizer>
            )}
        </div>
    )
}

export default FoodProfileChartContainer;