import React, { useState, useEffect, useRef, useContext } from "react";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import Transition from "../Transition/Transition";
import { toggleInArray } from "@core/old_helpers";
import ScrollBar from "react-scrollbars-custom";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import "./Select.styles.scss";
import CustomScrollbarsVirtualList from "../CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";
import useOutsideClick from "@hooks/useOutsideClick";
import { IconSearch } from "@icons";
import { GlobalContext } from "@components/App/App.context";
import Icon from "@components/Icon/Icon";
import { Button } from "@datassential/platform-ui-lib";

function Select({
    options = [],
    value,
    title = '',
    isWide = false,
    onChange,
    onAdd = () => null,
    placeholder = "Nothing selected",
    isCheckboxHidden = false,
    isCutLabel = false,
    labelIcon = false,
    hasSearchInput = false,
    searchInputPlaceholder = "",
    isDisabled = false,
    dynamicOption = false,
    dynamicOptionLabel = null,
    wrapContent = false,
    stopPropagation = false,
    prefix = '',
    inheritedStyles = false,
    showModifierIcon = false,
    customScrollbar = false,
    dropdownHeight = 240,
    dropdownAlwaysBottom = false,
    onClear = () => {},
}) {
    const { getRem } = useContext(GlobalContext);
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);
    const [isDropDownShowOverInput, setIsDropDownShowOverInput] = useState(false);
    const isRadioBtn = !Array.isArray(value);
    const dropDownList = useRef(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredOptions, setFilteredOptions] = useState([]);

    useEffect(() => {
        if (dropDownList.current && !dropdownAlwaysBottom) {
            if (
                window.innerHeight - dropDownList.current.getBoundingClientRect().top <
                dropDownList.current.clientHeight
            ) {
                setIsDropDownShowOverInput(true);
            } else {
                setTimeout(() => {
                    setIsDropDownShowOverInput(false);
                }, 500);
            }
        }
    }, [isDropdownOpened]);

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);

    const classList = new Set([
        "Select__input-dropdown",
        isWide && "Select__input-dropdown--wide",
        inheritedStyles ? "Select__input-dropdown--inherited" : "",
        isDropDownShowOverInput ? "Select__input-dropdown--show-up" : "",
        hasSearchInput ? "has-search" : "",
    ]);

    let content = placeholder;
    let modifierIcon = false;

    if (isRadioBtn) {
        if (value) {
            content = options.find(option => value === option.id).name;
        }
    } else {
        const optionsCount = options.length;
        const checkedOptionsCount = value.length;

        if (checkedOptionsCount > 0) {
            if (checkedOptionsCount === 1 && !prefix) {
                content = options.find(({ id }) => id === value[0]).name;
                modifierIcon = true;
            } else if (checkedOptionsCount === optionsCount) {
                content = prefix ? content : "All selected";
                modifierIcon = false;
            } else {
                content = `${checkedOptionsCount} of ${optionsCount}`;
                modifierIcon = true;
            }
        } else {
            modifierIcon = optionsCount > 0 ? true : false;
        }
    }

    if (wrapContent && content) {
        content = `(${content})`;
    }

    content = prefix ? `${prefix} ${content}` : content;

    const handleSelect = (optionId, e) => {
        if (isRadioBtn) {
            onChange(optionId);
            setIsDropdownOpened(false);
        } else {
            if (stopPropagation && e) {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
            }
            onChange(toggleInArray(value, optionId));
        }
    };

    const handleAdd = name => {
        if (isRadioBtn) {
            onAdd(name);
            setIsDropdownOpened(false);
        }
    };

    const handleSelectAll = e => {
        if (stopPropagation && e) {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
        }
        const isAllSelected = options.length === value.length;
        isAllSelected ? onChange([]) : onChange(options.map(item => item.id));
    };

    const handleDropdownOpen = e => {
        if (!isDisabled) {
            setFilteredOptions(options);
            setSearchQuery("");
            setIsDropdownOpened(!isDropdownOpened);
        }
    };

    const handleInputSearch = e => {
        const value = e.target.value;
        let filter = options.filter(item => item.name.toLowerCase().includes(value.toLocaleLowerCase()));
        setFilteredOptions(filter);
        setSearchQuery(value);
    };

    const selectClassList = new Set([
        "Select",
        isWide && "Select--wide",
        inheritedStyles && "Select--inherited",
        isDisabled ? "Select--disabled" : "",
    ]);
    const noOptionsMessage = (
        <div className="Select__row">No matches items {searchQuery.length > 0 && ` for "${searchQuery}"`}</div>
    );

    const scrollbarStyles = { ...(customScrollbar && { height: getRem(dropdownHeight) }) };

    const Row = ({ index, style }) => {
        const option = filteredOptions[index];

        if (option.isButton) {
            return (
                <Button
                    onClick={() => {
                        if (option.onClick) option.onClick();
                        if (option.closeOnClick) setIsDropdownOpened(false);
                    }}
                    modifiers={['naked']}
                    className="Select__button"
                >
                    {option.name}
                </Button>
            );
        }

        return (
            <div className="Select__row" key={index} onClick={e => handleSelect(option.id, e)} style={style}>
                <CheckboxInput
                    isChecked={isRadioBtn ? value === option.id : value.includes(option.id)}
                    labelText={option.name}
                    labelIcon={labelIcon ? option.icon : null}
                    onChange={() => handleSelect(option.id)}
                    isRadioBtn={isRadioBtn}
                    isBlueCheck
                    isCutLabel={isCutLabel}
                    modifiers={[...(inheritedStyles ? ["inherited"] : [])]}
                />
            </div>
        );
    };

    const handleClear = (e) => {
        if ( !value ) return;
        if (e) {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
        }

        onClear();
    };
    const selectRef = useRef(null);
    useOutsideClick({
        ref: selectRef,
        isDisabled: !isDropdownOpened,
        fn: () => setIsDropdownOpened(false)
    });

    return (
        <div className={Array.from(selectClassList).join(" ")} ref={selectRef}>
            {title && <div className="Select__title">{title}:</div>}

            <div
                className={`Select__input ${isDropdownOpened ? "Select__input--opened" : ""}`}
                onClick={e => handleDropdownOpen(e)}>
                {isRadioBtn ? (
                    <CheckboxInput
                        labelText={content}
                        onChange={() => {}}
                        isChecked={!!value}
                        isBlueCheck
                        noIcon={isCheckboxHidden}
                        isCutLabel={isCutLabel}
                        modifiers={[...(inheritedStyles ? ["inherited"] : [])]}
                        onClickIcon={handleClear}
                    />
                ) : (
                    <CheckboxInput
                        labelText={content}
                        onChange={() => {}}
                        isPartialChecked={value.length < options.length && value.length > 0}
                        isChecked={value.length === options.length}
                        isBlueCheck
                        noIcon={isCheckboxHidden}
                        showModifierIcon={showModifierIcon && modifierIcon}
                        modifiers={[...(inheritedStyles ? ["inherited"] : [])]}
                    />
                )}
            </div>
            <Transition shown={isDropdownOpened}>
                {hasSearchInput && (
                    <div className={`Select__search ${isDropDownShowOverInput ? "Select__search--show-up" : ""}`}>
                        <label className="Select__search-label">
                            <Icon className="Select__search-icon">
                                <IconSearch/>
                            </Icon>
                            <input
                                onChange={e => handleInputSearch(e)}
                                className={`Select__search-input ${
                                    isDropDownShowOverInput ? "Select__search-input--show-up" : ""
                                }`}
                                type="text"
                                placeholder={searchInputPlaceholder}
                            />
                        </label>
                    </div>
                )}

                <div ref={dropDownList} className={Array.from(classList).join(" ")}>
                    {!isRadioBtn && (
                        <div onClick={e => handleSelectAll(e)} className="Select__top Select__top--checkbox">
                            <CheckboxInput
                                isChecked={options.length === value.length}
                                labelText="Select All"
                                onChange={handleSelectAll}
                                isPartialChecked={value.length < options.length && value.length > 0}
                                isBlueCheck
                                modifiers={[...(inheritedStyles ? ["inherited"] : [])]}
                            />
                        </div>
                    )}
                    {!filteredOptions.length &&
                        (dynamicOption ? (
                            !searchQuery.length ? (
                                noOptionsMessage
                            ) : (
                                <div className="Select__row" onClick={() => handleAdd(searchQuery)}>
                                    {" "}
                                    {dynamicOptionLabel} {searchQuery}
                                </div>
                            )
                        ) : (
                            noOptionsMessage
                        ))}

                    {filteredOptions.length > 0 && (
                        <ScrollBar
                            native={!customScrollbar}
                            trackYProps={{
                                renderer: props => {
                                    const { style, elementRef, ...restProps } = props;
                                    return (
                                        <div
                                            {...restProps}
                                            ref={elementRef}
                                            style={{ ...style, top: 0, height: "100%" }}
                                        />
                                    );
                                },
                            }}
                            style={scrollbarStyles}>
                            {customScrollbar && (
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <List
                                            className="List"
                                            height={height}
                                            itemCount={filteredOptions.length}
                                            itemSize={getRem(40)}
                                            width={width}
                                            outerElementType={CustomScrollbarsVirtualList}>
                                            {Row}
                                        </List>
                                    )}
                                </AutoSizer>
                            )}

                            {!customScrollbar && filteredOptions.map((option, index) => <Row index={index} key={index} />)}
                        </ScrollBar>
                    )}
                </div>
            </Transition>
        </div>
    );
}

export default Select;
