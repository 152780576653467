import { FC, useMemo } from 'react'
import './horizotalBar.scss'
import { getRoundValue, capitalize } from "@core/old_helpers"

const HorizontalBar: FC<{
    width: string,
    value: string,
    name: string,
    background: string,
}> = ({
    width,
    value,
    name,
    background,
}) => {

    const style = useMemo(() => ({
        width: width,
        background: background
    }), [background, width]);

    return (
        <div className="horizontal-bar">

            <div className='horizontal-bar__title'>
                {capitalize(name)}
            </div>
            <div className="horizontal-bar__element-wrap">
                    <span
                        className='horizontal-bar__element'
                        style={style}
                    />
            </div>

            <div className='horizontal-bar__value'>
                { getRoundValue(+value) }
            </div>
        </div>
    )
};

export default HorizontalBar;