import React from 'react';
import MultipleInput from "@components/MultipleInput/MultipleInput";
import CheckboxInput from "@components/CheckboxInput/CheckboxInput";
import Select from "@components/Select/Select";

function SettingsMain(
    {
        data,
        dataConfig,
        handleChange,
        isMasterOption,
        keywordSettings,
    }
) {

    const {
        include,
        exclude,
        peerInclude,
        isTopTrends,
        isFoodProfile,
        isFlavor,
        isLocal,
        isInsider,
        isTag,
    } = data;

    const handleSubmitKeywords = (fieldName, keywords) => {
        let wordType;
        switch (fieldName) {
            case 'exclude': {
                wordType = 2;
                break;
            }
            case 'peerInclude': {
                wordType = 3;
                break;
            }
            default: {
                wordType = 1;
                break;
            }
        }
        return handleChange(fieldName, [
            ...keywords.map(keyword => ({
                ...keyword,
                wordType,
            })),
            ...data[fieldName],
        ])
    };

    const handleRemoveKeyword = (fieldName, keywordName) => handleChange(
        fieldName,
        data[fieldName].filter(({ name }) => name !== keywordName)
    );

    const macStageField = dataConfig.fields.find(({ field }) => field === 'macStage');

    return (
        <>
            <div className="settings-section">
                <div className="settings-section__inner">
                    {dataConfig.fields.slice(0,3).map(({ name, field }) => {
                        return (
                            <div className="settings-keyword" key={name}>
                                <div className="settings-keyword__title">{name.toUpperCase()}</div>
                                <MultipleInput
                                    submittedItems={data[field]}
                                    words={[
                                        ...include,
                                        ...exclude,
                                        ...peerInclude,
                                    ]}
                                    onSubmit={(words) => handleSubmitKeywords(field, words)}
                                    removeItem={(keywordName) => handleRemoveKeyword(field, keywordName)}
                                    placeholder="Type keyword"
                                    isUpperCase
                                />

                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="settings-section">
                <Select
                    options={keywordSettings[macStageField.field]}
                    value={data[macStageField.field]}
                    title={macStageField.name}
                    onChange={(id) => handleChange(macStageField.field, id)}
                    onClear={() => handleChange(macStageField.field, 0)}
                />
            </div>

            <div className="settings-section">
                <div className="settings-input__title">Included in:</div>
                <div className="Settings__more-tools">
                    <div className="Settings__checkbox-container">
                        <CheckboxInput
                            labelText='Tags'
                            onChange={() => handleChange('isTag', !isTag)}
                            isChecked={isTag}
                            isBlueCheck
                            isMedium
                        />
                    </div>
                    <div className="Settings__checkbox-container">
                        <CheckboxInput
                            labelText='Top Trends'
                            onChange={() => handleChange('isTopTrends', !isTopTrends)}
                            isChecked={isTopTrends}
                            isBlueCheck
                            isMedium
                        />
                    </div>
                    {isMasterOption && (
                        <div className="Settings__checkbox-container">
                            <CheckboxInput
                                labelText='Food Profile'
                                onChange={() => handleChange('isFoodProfile', !isFoodProfile)}
                                isChecked={isFoodProfile}
                                isBlueCheck
                                isMedium
                            />
                        </div>
                    )}
                    {isMasterOption && (
                        <div className="Settings__checkbox-container">
                            <CheckboxInput
                                labelText='Flavor'
                                onChange={() => handleChange('isFlavor', !isFlavor)}
                                isChecked={isFlavor}
                                isBlueCheck
                                isMedium
                            />
                        </div>
                    )}
                    {isMasterOption && (
                        <div className="Settings__checkbox-container">
                            <CheckboxInput
                                labelText='Local'
                                onChange={() => handleChange('isLocal', !isLocal)}
                                isChecked={isLocal}
                                isBlueCheck
                                isMedium
                            />
                        </div>
                    )}
                    {isMasterOption && (
                        <div className="Settings__checkbox-container">
                            <CheckboxInput
                                labelText='Insider'
                                onChange={() => handleChange('isInsider', !isInsider)}
                                isChecked={isInsider}
                                isBlueCheck
                                isMedium
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default SettingsMain;