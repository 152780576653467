import React, { createContext, useEffect } from "react";
import { connect } from "react-redux";
import { resetData, setField } from "@actions/adminReports.actions";
import Page from "@components/Page/Page";
import { deleteAdminReport, getAdminReports } from "@core/api";
import { tableDataSelector } from "@selectors/adminReports.selectors";
import { countriesSelector } from "@selectors/countries.selector";
import AdminReports from "./Reports";
import { addNotification } from "@actions/notifications.actions";

export const SummaryReportsContext = createContext({
    onItemRemove: () => {},
});

const AdminReportsContainer = ({
    countries,
    setReportsData,
    isLoading,
    setIsLoading,
    tableData,
    resetData,
    pushNotification,
}) => {

    const getReports = () => {
        const dataRequest = getAdminReports();
        setIsLoading(true);

        dataRequest
            .then((data) => {
                if (data) {
                    let sortedData = data
                        .map(item => ({ ...item, name: item.name.trim().toLowerCase() }))
                        .sort((a, b) => {
                            const aWord = a.name;
                            const bWord = b.name;

                            return aWord < bWord ? -1 : aWord > bWord ? 1 : 0;
                        });
                    setReportsData(sortedData);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });


        return dataRequest;
    };


    useEffect(() => {
        const dataRequest = getReports();

        return () => {
            resetData();
            dataRequest.abort();
        };
    }, [getAdminReports]);

    const onItemRemove = (id) => {
        setIsLoading(true);
        deleteAdminReport(id)
            .then(() => {
                pushNotification({
                    text: "The report is successfully removed.",
                    duration: 4000,
                });
                getReports();
            })
            .catch(() => {
                pushNotification({
                    text: 'Something went wrong.',
                    error: true,
                    duration: 4000,
                });
            });
    };

    return (
        <Page isWrapped title="Summary Reports">
            <SummaryReportsContext.Provider value={{ onItemRemove }}>
                <AdminReports tableData={tableData} isLoading={isLoading} countries={countries}/>
            </SummaryReportsContext.Provider>
        </Page>
    );
};

const mapStateToProps = state => ({
    countries: countriesSelector(state),
    isLoading: state.adminReports.isLoading,
    tableData: tableDataSelector(state),

});

const mapDispatchToProps = dispatch => ({
    setReportsData: data => dispatch(setField("data", data)),
    setIsLoading: data => dispatch(setField("isLoading", data)),
    resetData: () => dispatch(resetData()),
    pushNotification: notification => dispatch(addNotification(notification)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminReportsContainer);
