import { FC } from "react";
import cn from "classnames";
import Transition from '@components/Transition/Transition';
import "./Loader.scss";

const Loader: FC<{
    isShown: boolean;
    noBg?: boolean;
    isWhite?: boolean;
    className?: string;
 }> = ({
     isShown,
     noBg = false,
     isWhite = false,
     className = '',
 }) => {
    // @ts-ignore
    const isIE = /*@cc_on!@*/false || !!document.documentMode;

    const classNames = cn('SpaceSpinner__overlay', {
        'SpaceSpinner__overlay--transparent': noBg,
        'SpaceSpinner--white': isWhite,
    } ,className);

    return (
        <Transition
            shown={isShown}
            timeout={1000}
            modifiers={["appears"]}
            classNames={classNames}
        >
            {isIE && (
                <div className="SpaceSpinner__spin-wrap">
                    <div className="SpaceSpinner__spin"/>
                </div>
            )}

            {!isIE && (
                <div className="SpaceSpinner__container">
                    <div className="SpaceSpinner__sphere"><span/></div>
                    <div className="SpaceSpinner__orbit SpaceSpinner__orbit1"/>
                    <div className="SpaceSpinner__orbit SpaceSpinner__orbit2"/>
                    <div className="SpaceSpinner__orbit SpaceSpinner__orbit3"/>
                </div>
            )}
        </Transition>
    )
}

export default Loader;