import React from "react";
import "./TooltipTabs.scss";
import PT from "prop-types";

const TooltipTabs = ({
    isShown = false,
    isSmall = false,
    children,
    style = undefined,
    testId,
}) => {

    const classList = new Set([
        "TooltipTabs",
        isShown ? "TooltipTabs--shown" : "",
        isSmall ? "TooltipTabs--small" : "",
    ]);

    return (
        <div
            className={Array.from(classList).join(" ")}
            style={style}
            data-testid={testId}
        >
            {children}
        </div>
    )
};

TooltipTabs.propTypes = {
    testId: PT.string,
};

export default TooltipTabs;