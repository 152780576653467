import { useData } from "@hooks/useData";
import { ID } from "@datassential/platform-ui-lib";
import { fetchGetCategoriesFastestGrowing } from "@core/api";
import { components } from "@apiSchema";
import { CategoryFastestGrowing, ICategoryFastestGrowing } from "@models/CategoryFastestGrowingItems";

type Response = components["schemas"]["MenuTrends.Application.Contracts.Models.TopTrendKeywordsModel"][];

async function getCategoriesFastestGrowing(countryCode: ID): Promise<ICategoryFastestGrowing[]> {
    const data: Response = await fetchGetCategoriesFastestGrowing(countryCode);

    return data.map((item) => new CategoryFastestGrowing({ apiModel: item }));
}

export default function useCategoriesFastestGrowing(countryCode: ID) {
    return useData([], getCategoriesFastestGrowing, countryCode);
}