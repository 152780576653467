import React, { useReducer, useEffect, useRef, useContext, useCallback } from "react";
import { connect } from "react-redux";
import "./ChartContainer.scss";
import { setField } from "@actions/byo.actions";
import {
    barChartDataSelector,
    chartDataSelector, newChartDataSelector,
    tableDataSelector,
} from "@selectors/byo.selectors";
import { currentCountryWithPermissionsSelector, currentCurrencySelector } from "@selectors/countries.selector";
import BarChart from "@components/BarChart/BarChart";
import Loader from "@components/Loader/Loader";
import { GlobalContext } from "@components/App/App.context";
import AutoSizer from "react-virtualized-auto-sizer";
import Chart from "@components/Chart/Chart";
import { getThousandAlias, getFormattedThousandWithDecimal } from "@core/helpers";
import { IconDownload } from "@icons";
import { filtersDataSelector, filtersForBackEndSelector } from "@selectors/filters.selectors";
import http from "@core/http";
import apiConfig from "@apiConfig";
import { exportByoChart } from "@core/api";
import { addNotification } from "@actions/notifications.actions";

const ChartContainer = (
    {
        activeTabId,
        newChartData,
        barChartData,
        isLoading,
        currentCurrency,
        permissions = {},
        byoData,
        rawFiltersData,
        filtersForBackEnd,
        addNotification,
    }
) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const chartContainerEl = useRef(null);
    const { hasChart } = permissions;
    const { getRem } = useContext(GlobalContext);

    const updateDimensions = useCallback(() => {
        const defaultChartWidth = getRem(1350);
        const chartStyles = getComputedStyle(chartContainerEl.current);
        const chartSvgWidth = parseInt(chartStyles.width.replace("px", ""));
        const chartSvgHeight = parseInt(chartStyles.height.replace("px", ""));
        const chartSvgCoefficient = chartSvgWidth / defaultChartWidth;

        if (state.chartSvgWidth !== chartSvgWidth || state.chartSvgHeight !== chartSvgHeight) {
            dispatch({
                chartSvgWidth,
                chartSvgHeight,
                chartSvgCoefficient,
            });
        }
    }, [getRem, state]);

    const downloadChartReport = useCallback(
        () => {
            const exportData = {
                customerFilter: {
                    mainFilter: {
                        filters: filtersForBackEnd,
                        words: byoData.filter(i => i.color).map(i => i.keyword),
                    },
                },
                fullFilter: {
                    ...rawFiltersData,
                    countryCode: filtersForBackEnd.countryCode,
                },
            };
            const id = new Date().getTime();
            addNotification({
                text: "Downloading...",
                id,
            });

            exportByoChart(exportData)
                .then((token) => {
                    http.downloadFileXHRFromUrl(
                        'GET',
                        apiConfig.get('export BYO chart', {key: token})
                    ).then(() => {
                        addNotification({
                            text: "Download was successful",
                            duration: 4000,
                            id,
                        });
                    });
                })
                .catch((error) => addNotification({
                    text: `Something went wrong `,
                    duration: 4000,
                    id,
                    error: true,
                }));
        },
        [byoData, rawFiltersData, addNotification, filtersForBackEnd]
    );

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);

        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, [updateDimensions]);

    const classList = ["chart-container"];

    const printValue = useCallback((n) => {
        return activeTabId === 3 ? `${currentCurrency}${getFormattedThousandWithDecimal(n)}` : `${n % 1 === 0 ? n : n.toFixed(1)}%`;
    }, [activeTabId, currentCurrency]);

    const printLabel = useCallback((n) => {
        return activeTabId === 3 ? `${currentCurrency}${getThousandAlias(n)}` : `${n % 1 === 0 ? n : n.toFixed(1)}%`;
    }, [activeTabId, currentCurrency]);

    return (
        <div ref={chartContainerEl} className={classList.join(" ")}>
            {hasChart && state.chartSvgWidth > 0 && newChartData.columns.length > 0 && (
                <AutoSizer>
                    {({ width, height }) => (
                        <>
                            <Chart
                                charts={newChartData.charts}
                                columns={newChartData.columns}
                                width={width}
                                height={height}
                                printValue={printValue}
                                printLabel={printLabel}
                                dashStartPointColumnIndex={newChartData.dashStartPointColumnIndex}
                                dashStartPointSubColumnIndex={newChartData.dashStartPointSubColumnIndex}
                            />
                            <button
                                className="chart-container__button"
                                onClick={downloadChartReport}
                                title="Download chart"
                            >
                                <IconDownload/>
                            </button>
                        </>
                    )}
                </AutoSizer>
            )}
            {!hasChart && (
                <BarChart
                    data={barChartData}
                    height={state.chartSvgHeight}
                    activeTabId={activeTabId}
                />
            )}
            <Loader isShown={isLoading}/>
        </div>
    );
};

const reducer = (state, action) => ({
    ...state,
    ...action,
});

const initialState = {
    chartSvgWidth: 0,
    chartSvgHeight: 0,
    chartSvgCoefficient: 0,
};

const mapStateToProps = state => ({
    activeTabId: state.byo.activeAnalyticsType,
    chartDataType: state.byo.chartDataType,
    chartData: chartDataSelector(state),
    newChartData: newChartDataSelector(state),
    tableData: tableDataSelector(state),
    permissions: currentCountryWithPermissionsSelector(state),
    isLoading: state.byo.isLoading,
    barChartData: barChartDataSelector(state),
    currentCurrency: currentCurrencySelector(state),
    byoData: state.byo.data,
    rawFiltersData: filtersDataSelector(state),
    filtersForBackEnd: filtersForBackEndSelector(state),
});

const mapDispatchToProps = dispatch => ({
    setAnalyticsType: id => dispatch(setField("activeAnalyticsType", id)),
    setChartDataType: id => dispatch(setField("chartDataType", id)),
    addNotification: notification => dispatch(addNotification(notification)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChartContainer);
