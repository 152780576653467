import React from "react";
import PropTypes from "prop-types";
import "./Search.scss";
import { IconSearch } from "@icons";
import Icon from "@components/Icon/Icon";

const Search = ({
    searchQuery,
    setSearchQuery,
    onEnter = () => {},
    reFetchData = () => {},
    showIcon,
    placeholder = "",
}) => {
    const handleKeyDown = event => {
        if (searchQuery.length > 0 && event.keyCode === 13) {
            onEnter();
        }
    };

    const handleOnChange = (val) => {
        setSearchQuery(val);
        reFetchData()
    };

    const handleClick = () => {
        setSearchQuery("");
        onEnter()
    };

    return (
        <div className="search">
            <label className={`search__label ${showIcon ? "has-icon" : ""}`}>
                <input
                    type="text"
                    className="search__input"
                    placeholder={placeholder}
                    value={searchQuery}
                    onChange={({target}) => handleOnChange(target.value)}
                    onKeyDown={handleKeyDown}
                />

                { (showIcon && !searchQuery.length) && <Icon type="small" className="search__icon" ><IconSearch /></Icon>}
            </label>

            {searchQuery.length > 0 && (
                <button
                    onClick={handleClick}
                    className="search__clear"
                />
            )}
        </div>
    );
};

Search.propTypes = {
    searchQuery: PropTypes.string,
    setSearchQuery: PropTypes.func,
    onEnter: PropTypes.func
};

export default Search;
