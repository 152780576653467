import React from "react";
import "./TilesContainer.styles.scss";

const TilesContainer = ({ children }) => {
    return (
        <div className="tiles-container">
            {children}
        </div>
    )
};

export default TilesContainer;