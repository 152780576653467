import React from "react";
import "./AdminContainer.scss";

const AdminContainer = ({ title, search, actions, content }) => (
    <div className="AdminContainer">
        <div className="AdminContainer__content">
            <div className="AdminContainer__title">{title}</div>
            <div className="AdminContainer__body">
                <div className="AdminContainer__header">
                    <div className="AdminContainer__header-column AdminContainer__header-column--search">
                        {search}
                    </div>
                    <div className="AdminContainer__header-column AdminContainer__header-column--actions">
                        {actions}
                    </div>
                </div>
                <div className="AdminContainer__content">{content}</div>
            </div>
        </div>
    </div>
);

export default AdminContainer;