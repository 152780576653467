import { FC } from "react";

const Title: FC = ({ children }) => {
    return (
        <div className="title-container">
            {children}
        </div>

    )
};
export default Title;