import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Flag from "@components/Flag/Flag";
import { Routes } from "@routes";
import { ReportContext } from "./ReportOptions.reducer";

function ReportHeader(props) {
    const { addReport, currentCountry, handleSaveReport } = props;
    const { optionsData, handleChangeOptionField } = useContext(ReportContext);
    const { main: { name } } = optionsData;

    const [nameInputTouched, setNameInputTouched] = useState(false);

    const handleName = e => {
        setNameInputTouched(true);
        handleChangeOptionField("main", "name", e.target.value.toUpperCase());
    };

    return (
        <>
            <div className="AdminContainer__title">
                {addReport ? "Add new" : "Edit"} summary report
                <div className="AdminContainer__title-info">
                    <span>
                        <Flag compact component={currentCountry.component} />
                    </span>
                    <span>{currentCountry.countryCode}</span>
                </div>
            </div>

            <div className="AdminContainer__top-title is-fixed">
                <div className="AdminContainer__top">
                    <div className="AdminContainer__top-name">
                        <label className="AdminContainer__input-wrap">
                            <span className={`AdminContainer__input-label is-required ${name.length > 0 ? "is-upper" : "is-bottom"}`}>PPT Report name</span>
                            <input
                                type="text"
                                className="AdminContainer__input"
                                value={name.toUpperCase()}
                                onChange={handleName}
                            />
                            {(nameInputTouched && name.length === 0) && (
                                <div className="AdminContainer__error">The PPT report's name can't be blank</div>
                            )}
                        </label>
                    </div>

                    <div className="AdminContainer__btns">
                        <Link to={Routes.AdminSummaryReports} className="btn btn--link">
                            Discard
                        </Link>
                        <button className={`btn btn--filled`} onClick={handleSaveReport}>Save</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportHeader;
