import React from "react";
import PT from "prop-types";
import Modal from "../Modal/Modal";
import cn from "classnames";
import "./CountryMenu.scss";

const CountryMenu = ({ selectCountry, closeModal, shown, x, y, countries = [] }) => {
    const handleSelectCountry = country => {
        selectCountry(country.countryCode);
        closeModal();
    };

    return (
        <Modal closeModal={closeModal} shown={shown}>
            <div
                style={{
                    left: x,
                    top: y
                }}
                className="country-menu"
            >
                <div className="country-menu__title">CHANGE YOUR COUNTRY</div>
                <div className="country-menu__countries">
                    <div className="country-menu__grid">
                        {countries.map((country, idx) => {
                            const classNames = cn("country-menu__country", {
                                "country-menu__country--disabled": !country.hasAccess
                            });
                            const Flag = country.hasAccess ? country.component : country.componentDisabled;

                            return (
                                <div
                                    key={idx}
                                    className="country-menu__country-wrapper"
                                >
                                    <button
                                        key={idx}
                                        onClick={() => handleSelectCountry(country)}
                                        className={classNames}
                                        disabled={!country.hasAccess}
                                    >
                                        <Flag className="country-menu__country-icon"/>
                                        <div className="country-menu__country-name">
                                            {country.name}
                                        </div>
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

CountryMenu.propTypes = {
    x: PT.number,
    y: PT.number,
    closeModal: PT.func,
    selectCountry: PT.func,
    countries: PT.array
};

export default CountryMenu;
