import { GlobalContext } from "@components/App/App.context";
import React, { memo, useCallback, useContext } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Scrollbar from "react-scrollbars-custom";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import CustomScrollbarsVirtualList from "@components/CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";
import SortableTableListItem from "./SortableTableListItem";

const SortableTableList = ({ ID, data, Row, virtualMode, disableDragging }) => {
    const { getRem } = useContext(GlobalContext);

    const listItemRenderer = useCallback(({ data, index }) => {
        const item = data && data[index];
        const dragDisabled = item.isExpanded || disableDragging || false;

        if (!item) {
            return null;
        }

        let dragId = item.id.toString();

        return (
            <Draggable key={item.id} draggableId={dragId} index={index} isDragDisabled={dragDisabled}>
                {(provided, snapshot) => {
                    return (
                        <SortableTableListItem
                            draggableSnapshot={snapshot}
                            draggableProvided={provided}
                            isDragging={snapshot.isDragging}
                            virtualStyle={provided.draggableProps.style}
                            lockDragging={dragDisabled}>
                            <Row isDragging={snapshot.isDragging} data={item} index={index} />
                        </SortableTableListItem>
                    );
                }}
            </Draggable>
        );
    }, [disableDragging]);

    return (
        <Droppable
            droppableId={ID}
            mode={virtualMode ? "virtual" : "standard"}
            renderClone={
                virtualMode
                    ? (provided, snapshot, rubric) => {
                          return (
                              <SortableTableListItem
                                  draggableSnapshot={snapshot}
                                  draggableProvided={provided}
                                  isDragging={snapshot.isDragging}>
                                  <Row isDragging={snapshot.isDragging} data={data[rubric.source.index]} index={rubric.source.index} />
                              </SortableTableListItem>
                          );
                      }
                    : null
            }>
            {(provided, snapshot) => {
                const dataLength = data ? data.length : 0;
                const itemCount = snapshot.isUsingPlaceholder ? dataLength + 1 : dataLength;

                if (virtualMode) {
                    return (
                        <AutoSizer defaultWidth={1} defaultHeight={1}>
                            {({ width, height }) => (
                                <List
                                    width={width}
                                    height={height}
                                    itemSize={getRem(50)}
                                    itemCount={itemCount}
                                    itemData={data}
                                    outerRef={provided.innerRef}
                                    outerElementType={CustomScrollbarsVirtualList}>
                                    {listItemRenderer}
                                </List>
                            )}
                        </AutoSizer>
                    );
                } else {
                    return (
                        <Scrollbar>
                            <div className="SortableTable__list" {...provided.droppableProps} ref={provided.innerRef}>
                                {data.map((item, index) => listItemRenderer({data, index}))}

                                {!virtualMode && provided.placeholder}
                            </div>
                        </Scrollbar>
                    );
                }
            }}
        </Droppable>
    );
};

export default memo(SortableTableList);
