import React from "react";
import "./TextField.styles.scss";

const TextField = ({ value, onChange, placeholder, required, isDisabled }) => {
    return (
        <label className="TextField">
            <span className={`TextField__input-label ${required ? "is-required" : ""} ${value.length > 0 ? "is-upper" : "is-bottom"}`}>
                {placeholder}
            </span>
            <input
                type="text"
                className="TextField__input"
                value={value}
                disabled={isDisabled}
                onChange={e => onChange(e.target.value)}
            />
        </label>
    );
};

export default TextField;
