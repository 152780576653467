import { ActionBlock } from "@core/actionNames";

export const Actions = new ActionBlock('OPTIONS', ['SET_DATA', 'ADD', 'EDIT', 'EDIT_FIELD', 'EDIT_NESTED_FIELD', 'REMOVE' ]);

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case Actions.SET_DATA: {
            return action.data;
        }

        case Actions.ADD: {
            return {
                ...state,
                ...action.data
            }
        }

        case Actions.EDIT: {
            const { index, data } = action;

            return [
                ...state.slice(0, index),
                {
                    ...state[index],
                    ...data,
                },
                ...state.slice(index + 1),
            ];
        }

        case Actions.EDIT_FIELD: {
            const { fieldName, value } = action;
            return {
                ...state,
                [fieldName]: value
            }
        }

        case Actions.EDIT_NESTED_FIELD: {
            const { sectionName, fieldName, value } = action;
            return {
                ...state,
                [sectionName]: {
                    ...state[sectionName],
                    [fieldName]: value,
                }
            }
        }

        case Actions.REMOVE: {
            const { index } = action;
            return [
                {
                    ...state[0],
                    countryCodes: [
                        ...state[0].countryCodes,
                        ...state[index].countryCodes,
                    ],
                },
                ...state.slice(1, index),
                ...state.slice(index + 1),
            ]
        }

        default: {
            return [...state]
        }

    }
}