import React, { useContext, useEffect, useState } from "react";
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import Search from "@components/Search/Search";
import AdminTable from "@pages/Admin/Common/AdminTable";
import { Actions, ReportContext } from "../ReportOptions.reducer";
import "./PopupAddChildReport.styles.scss";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";

const Head = props => {
    const { optionsData, dispatchOptionsAction } = useContext(ReportContext);
    const {
        selectedInstantChartsCategories,
        selectedInstantChartsSubCategories,
        instantChartsCategories,
    } = optionsData;

    const handleChangeCategories = (type, value) => {
        const selectedChilds = data => {
            return data.filter(item => value.includes(item.id));
        };

        const setSelectedItems = (name, data) => {
            dispatchOptionsAction({
                type: Actions.ADD,
                data: {
                    [name]: selectedChilds(data),
                },
            });
        };

        switch (type) {
            case "category":
                setSelectedItems("selectedInstantChartsCategories", instantChartsCategories.categories);
                break;
            case "subcategory":
                setSelectedItems("selectedInstantChartsSubCategories", instantChartsCategories.subCategories);
                break;
            default:
                break;
        }
    };

    const selectedItems = data => {
        return data.map(({ id }) => id);
    };

    return (
        <TableHeader
            {...props}
            handleChangeCategories={handleChangeCategories}
            categoriesToOptions={instantChartsCategories.categories}
            subCategoriesToOptions={instantChartsCategories.subCategories}
            selectedCategories={selectedItems(selectedInstantChartsCategories)}
            selectedSubCategories={selectedItems(selectedInstantChartsSubCategories)}
        />
    );
};

const PopupAddChildReport = props => {
    const { data, closeModal, shown, handleAddChildReport, searchQuery, setSearchQuery } = props;
    const [tableData, setTableData] = useState([]);
    const [countSelectedChildReports, setCountSelectedChildReports] = useState(0);

    useEffect(() => {
        setTableData(data);
        setCountSelectedChildReports(data.filter(item => item.isSelected).map(({ id }) => id).length);
    }, [data]);

    useEffect(() => {
        return () => {
            setSearchQuery("");
        }
    }, [shown]);

    const handleApplyChildReport = () => {
        const ids = tableData.filter(item => item.isSelected).map(({ id }) => id);
        handleAddChildReport(ids);
        closeModal();
    };

    const handleSelectChildReport = (id, value) => {
        const currentIndex = tableData.findIndex(item => item.id === id);

        setCountSelectedChildReports(prevState => {
            return prevState + (value ? 1 : -1);
        });

        setTableData([
            ...tableData.slice(0, currentIndex),
            {
                ...tableData[currentIndex],
                isSelected: value,
            },
            ...tableData.slice(currentIndex + 1),
        ]);
    };

    const unselectAllChildReports = () => {
        setTableData(
            tableData.map(item => ({
                ...item,
                isSelected: false,
            })),
        );

        setCountSelectedChildReports(0);
    };

    const Row = ({ index, style }) => {
        const rowData = tableData[index];

        return (
            <div style={style} className={`AdminTable__row`}>
                <TableRow data={rowData} index={index} handleSelectChildReport={handleSelectChildReport} />
            </div>
        );
    };

    return (
        <Modal closeModal={closeModal} shown={shown} hasOverlay>
            <div className="Popup__wrapper">
                <div className="Popup Popup--reports">
                    <div className="Popup__title Popup__title--small">Add instant chart</div>

                    <div className="PopupAddChildReport">
                        <div className="PopupAddChildReport__header">
                            <div className="PopupAddChildReport__filters-info">
                                <div className="PopupAddChildReport__counter">
                                    {countSelectedChildReports} items selected
                                </div>
                                <div className="PopupAddChildReport__clear" onClick={unselectAllChildReports}>
                                    Clear
                                </div>
                            </div>
                            <div className="PopupAddChildReport__search">
                                <Search
                                    key="search"
                                    searchQuery={searchQuery}
                                    setSearchQuery={setSearchQuery}
                                    placeholder="Search"
                                    showIcon
                                />
                            </div>
                            <div className="PopupAddChildReport__actions">
                                <Button onClick={closeModal} modifiers={["transparent"]}>
                                    Cancel
                                </Button>
                                <Button onClick={handleApplyChildReport} modifiers={["blue", "rounded"]}>
                                    Apply
                                </Button>
                            </div>
                        </div>

                        <div className="PopupAddChildReport__body">
                            <div className="AdminInstantChartsTable">
                                <AdminTable
                                    header={Head()}
                                    row={Row}
                                    length={(tableData && tableData.length) || 0}
                                    disableRowTransition
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default PopupAddChildReport;
