import { FC } from 'react';
import './SelectionList.styles.scss';
import Scrollbar from 'react-scrollbars-custom';
import cn from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';

const SelectionList: FC<{
    ItemRenderer: FC<{ index: number }>;
    itemsCount: number;
    onRemove?: (index: number) => void;
}> = ({
    children,
    ItemRenderer,
    itemsCount,
    onRemove,
}) => {

    const items = Array(itemsCount).fill(null).map((_, index) => (
        <div className="SelectionList__item">
            <ItemRenderer index={index}/>
            {onRemove && (
                <div
                    className="SelectionList__remove-icon"
                    onClick={() => onRemove(index)}
                />
            )}
        </div>
    ));

    return (
        <div
            className="SelectionList"
            onClick={(event) => event.stopPropagation()}
        >
            <div className="SelectionList__thumbnail">
                <b>{children}</b>
            </div>
            <div className={cn(
                "SelectionList__dropdown",
                itemsCount > 6 && 'is-fixed-height'
            )}>
                {itemsCount > 6 ? (
                    <>
                        <div className="SelectionList__sizer">
                            {items}
                        </div>
                        <div className="SelectionList__scroller">
                            <Scrollbar>
                                {items}
                            </Scrollbar>
                        </div>
                    </>
                ) : items}
            </div>
        </div>
    );
};

export default SelectionList;