import { Button, FCX, ID, splitIntoChunks, Toggle } from "@datassential/platform-ui-lib";
import { useEffect, useMemo, useState } from "react";
import { components } from "@apiSchema";
import './StatesSelect.scss';
import CheckboxInput from "@components/CheckboxInput/CheckboxInput";
import { toggleInArray } from "@core/old_helpers";
import { IoIosArrowDown } from "react-icons/io";
import cn from "classnames";
import { useSelector } from "react-redux";
import { currentCountrySelector } from "@selectors/countries.selector";

const StatesSelect: FCX<{
    isChainsEnabled: boolean;
    setIsChainsEnabled: (isChainsEnabled: boolean) => void;
    value: ID[];
    setValue: (value: ID[]) => void;
    options: components['schemas']['MenuTrends.Domain.Entities.State'][];
}> = ({
    value,
    setValue,
    options,
    isChainsEnabled,
    setIsChainsEnabled,
}) => {
    const currentCountry = useSelector(currentCountrySelector);
    const [localValue, setLocalValue] = useState<ID[]>([]);
    const [isOpened, setIsOpened] = useState(false);

    const thumbText = useMemo(
        () => {
            if (localValue.length === options.length) {
                return 'All selected';
            } else if (localValue.length === 0) {
                return 'All selected';
            } else {
                return `${localValue.length} of ${options.length} selected`;
            }
        },
        [localValue, options]
    );

    const chunksCount = useMemo(
        () => {
            if (currentCountry === 'USA') {
                return 5;
            }

            return 3;
        },
        [currentCountry]
    )

    const cols = useMemo(
        () => {
            return splitIntoChunks(options, chunksCount).map((chunk, index) => {
                return {
                    id: index + 1,
                    items: chunk,
                };
            });
        },
        [options, chunksCount]
    );

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    return (
        <>
            <div className="MenuExamples__row spread no-margin">
                <div className="MenuExamples__row-item">
                    <div
                        className="MenuExamples__label"
                        data-testid="textMenuExamplesLabelFilterByState"
                    >
                        Filter by state
                    </div>
                    <div
                        className="StatesSelect__thumb clickable"
                        onClick={() => {
                            setIsOpened(!isOpened);
                            setLocalValue(value);
                        }}
                        data-testid="buttonMenuExamplesSelectStatesToggle"
                    >
                        {thumbText}
                        <IoIosArrowDown
                            className={cn(
                                "StatesSelect__thumb-icon",
                                isOpened && 'is-rotated'
                            )}
                        />
                    </div>
                </div>
                <div className="MenuExamples__row-item">
                    <Toggle
                        isChecked={isChainsEnabled}
                        onChange={() => setIsChainsEnabled(!isChainsEnabled)}
                        data-testid="inputMenuExamplesCheckboxIncludeChains"
                    />
                    <div
                        className="MenuExamples__label"
                        onClick={() => setIsChainsEnabled(!isChainsEnabled)}
                        style={{ cursor: 'pointer' }}
                        data-testid="textMenuExamplesIncludeChains"
                    >
                        &nbsp;&nbsp;Include chains
                    </div>
                </div>
            </div>
            {isOpened && (
                <div className="StatesSelect__dropdown">
                    <div className="StatesSelect__dropdown-header">
                        <div
                            className="StatesSelect__action-button"
                            onClick={() => setLocalValue(options.map(state => state.id))}
                            data-testid="buttonMenuExamplesStatesSelectAll"
                        >
                            Select all
                        </div>
                        &nbsp;/&nbsp;
                        <div
                            className="StatesSelect__action-button"
                            onClick={() => setLocalValue([])}
                            data-testid="buttonMenuExamplesStatesClearAll"
                        >
                            Clear all
                        </div>
                    </div>
                    <div className="StatesSelect__dropdown-body">
                        <div className="StatesSelect__dropdown-section">
                            {cols.map((col, colIndex) => (
                                <div key={col.id} className={`StatesSelect__dropdown-col col-${chunksCount}`}>
                                    {col.items.map((state, index) => (
                                        <div key={state.id} className="StatesSelect__dropdown-item">
                                            <CheckboxInput
                                                isChecked={localValue.includes(state.id)}
                                                onChange={() => setLocalValue(toggleInArray(localValue, state.id))}
                                                labelText={state.name ?? ''}
                                                testId={`inputMenuExamplesStatesSelectOption-${colIndex}-${index}`}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="StatesSelect__dropdown-footer">
                        <Button
                            className="StatesSelect__button"
                            modifiers={['naked']}
                            onClick={() => {
                                setLocalValue(value);
                                setIsOpened(false);
                            }}
                            testId="buttonMenuExamplesStatesSelectCancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            className="StatesSelect__button"
                            onClick={() => {
                                setValue(localValue);
                                setIsOpened(false);
                            }}
                            testId="buttonMenuExamplesStatesSelectApply"
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}

export default StatesSelect;