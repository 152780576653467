import { getAdminKeywordSettingData } from "../core/api";
import { KEYWORD_SETTINGS } from '../core/actionNames';

const { SET_FIELD, SET_DATA } = KEYWORD_SETTINGS;

export function setData (data) {
    return {
        type: SET_DATA,
        data,
    };
}

export function setField (name, value) {
    return {
        type: SET_FIELD,
        value,
        name
    }
}

export function addTagCategory (data) {
    return (dispatch, getState) => {
        dispatch(setField('tagCategories', [
            ...getState().keywordSettings.tagCategories,
            data
        ]));
        dispatch(getData());
    };
}

export function getData() {
    return (dispatch) => {
        getAdminKeywordSettingData().then((data) => {
            dispatch(setData(data));
        })
    };
}

