import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export interface iChartTheme {
    BACKGROUND: {
        DEFAULT: string,
        HAIKU: string,
    },
    CELL: {
        ACTIVE: string,
        DEFAULT: string
    },
    TEXT: {
        ACTIVE: string,
        DEFAULT: string
    },
    BORDER: {
        DEFAULT: string
    },
    GRID: string,
}

export interface ChartTheme {
    DARK: iChartTheme;
    LIGHT: iChartTheme;
}

const useChartTheme = (THEME: ChartTheme = CHART_THEME) => {
    const [theme, setTheme] = useState<iChartTheme>(THEME.DARK);

    const isLightTheme = useSelector((state: any) => state.app.isThemeLightEnable);

    useEffect(() => {
        setTheme(isLightTheme ? THEME.LIGHT : THEME.DARK);
    }, [THEME, isLightTheme]);

    return { THEME: theme };
}

export default useChartTheme;

export const CHART_THEME: ChartTheme = {
    DARK: {
        BACKGROUND: {
            DEFAULT: '#242b39',
            HAIKU: '#1C2531'
        },
        CELL: {
            ACTIVE: '#2E3E56',
            DEFAULT: 'transparent'
        },
        TEXT: {
            ACTIVE: '#ffffff',
            DEFAULT: '#4A6282'
        },
        BORDER: {
            DEFAULT: '#283547'
        },
        GRID: '#2A3647',

    },
    LIGHT: {
        BACKGROUND: {
            DEFAULT: '#fff',
            HAIKU: '#F2F2F2'
        },
        CELL: {
            ACTIVE: '#f2f2f2',
            DEFAULT: 'transparent'
        },
        TEXT: {
            ACTIVE: '#000',
            DEFAULT: '#4A6282'
        },
        BORDER: {
            DEFAULT: '#CCC'
        },
        GRID: '#ccc',
    },
};