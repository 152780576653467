import { ADMIN_INSTANT_CHART } from "../core/actionNames";

const { SET_FIELD, SET_DATA, RESET } = ADMIN_INSTANT_CHART;

const getInitialState = () => ({
    allCharts: null,
    currentInstantChart: {},
    allCategories: [],
    allFilters: null
});

export default function adminInstantChart(state = getInitialState(), action) {
    switch (action.type) {
        case SET_FIELD: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case SET_DATA: {
            return {
                ...state,
                ...action.data,
            };
        }
        case RESET: {
            return getInitialState();
        }
        default: {
            return { ...state };
        }
    }
}
