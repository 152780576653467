import React from "react";
import Cell from "@components/NewAwesomeTable/Cell";
import TableRowKeyword from "./TableRowKeyword";
import TableRowOption from "./TableRowOption";
import AdminTable from "@pages/Admin/Common/AdminTable/AdminTable";

export const RowTypes = {
    Keyword: "keyword",
    Option: "option",
    Default: "default",
};

const RowComponentByType = {
    [RowTypes.Keyword]: TableRowKeyword,
    [RowTypes.Option]: TableRowOption,
    [RowTypes.Default]: null,
};

const AdminKeywordsTable = ({
    data = [],
    isLoading,
    toggleKeywordExpanded,
    setKeywordsData,
    getAllAdminKeywords,
}) => {
    const Row = ({ index, style }) => {
        const rowData = data[index];
        const { rowType = RowTypes.Default, id } = rowData;
        const RowComponent = RowComponentByType[rowType];

        const additionalProps = {
            [RowTypes.Keyword]: {
                onClick: () => toggleKeywordExpanded(id),
            },
            [RowTypes.Option]: {},
            [RowTypes.Default]: {},
        };

        const additionalClassNames = {
            [RowTypes.Keyword]: `
                ${
                    rowData.isExpanded
                        ? "AdminTable__row--expanded"
                        : "AdminTable__row--hoverable"
                }
            `,
            [RowTypes.Option]: "AdminTable__row--sub-expanded",
            [RowTypes.Default]: {},
        };

        return (
            <div
                style={style}
                className={`AdminTable__row ${additionalClassNames[rowType]}`}
                {...additionalProps[rowType]}
            >
                <RowComponent
                    data={rowData}
                    getAllAdminKeywords={getAllAdminKeywords}
                    setKeywordsData={setKeywordsData}
                />
            </div>
        );
    };

    const TableHeader = (
        <>
            <Cell className="AdminTable__col AdminTable__col--small AdminTable__col--no-pr">ID</Cell>
            <Cell className="AdminTable__col AdminTable__col--fluid">
                Keyword
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--regular" options="center no-p">
                Penetration
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--regular AdminTable__col--no-p" options="center no-p">
                Tags
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--regular AdminTable__col--no-p" options="center no-p">
                Top Trends
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--regular AdminTable__col--no-p" options="center no-p">
                Food Profile
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--regular AdminTable__col--no-p" options="center no-p">
                Flavor
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--regular AdminTable__col--no-p" options="center no-p">
                Local
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--regular AdminTable__col--no-p" options="center no-p">
                Insider
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--regular" options={"no-p"} />
        </>
    );

    return (
        <div className="AdminKeywordsTable">
            <AdminTable
                header={TableHeader}
                row={Row}
                length={data.length}
                isLoading={isLoading}
            />
        </div>
    );
};

export default AdminKeywordsTable;