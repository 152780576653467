import Icon from "@components/Icon/Icon";
import { IconCheckSuccess, IconCloseFailure } from "@icons";
import React, { useState } from "react";
import CheckboxInput from "@components/CheckboxInput/CheckboxInput";
import Cell from "@components/NewAwesomeTable/Cell";
import HightlightChars from "@pages/Admin/Common/HightlightChars/HightlightChars";


function TableRow(props) {
    const { data, handleSelectChildReport } = props;
    const { id, renderInstantChartName, instantChartName, category, subCategory, isInstantChartActive, isSelected } = data;
    const [rowSelected, setRowSelected] = useState(isSelected);

    const toggleSelected = (id) => {
        const value = !rowSelected;
        handleSelectChildReport(id, value);
        setRowSelected(value);
    }

    return (
        <>
            <Cell className="AdminTable__col AdminTable__col--small">
                <CheckboxInput isBlueCheck isChecked={rowSelected} onChange={() => toggleSelected(id)}/>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--fluid AdminTable__col--no-p">
                <span className="AdminTable__truncated" title={instantChartName}>
                    <HightlightChars name={renderInstantChartName} />
                </span>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--fluid">
                <span className="AdminTable__truncated" title={category}>
                    {category}
                </span>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--fluid">
                <span className="AdminTable__truncated" title={subCategory}>
                    {subCategory}
                </span>
            </Cell>
            <Cell
                className="AdminTable__col AdminTable__col--medium"
                options="center no-pl"
            >
                {isInstantChartActive ? <Icon><IconCheckSuccess/></Icon> : <Icon><IconCloseFailure/></Icon>}
            </Cell>
        </>
    );
}

export default TableRow;
