import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Cell from '@components/NewAwesomeTable/Cell';
import HightlightChars from '@pages/Admin/Common/HightlightChars';
import Flag from '@components/Flag/Flag';
import Icon from '@components/Icon/Icon';
import {
    IconCheckSuccess,
    IconCloseFailure,
    IconDelete,
    IconEdit,
    IconPDFStroke,
    IconPPTStroke
} from '@icons';
import { Link } from 'react-router-dom';
import { Routes } from '@routes';
import serializeParams from '@core/serializeParams';
import { AdminCategoryReportsItem } from '@apiHooks/useCategoryReports';
import { COUNTRIES_ARRAY } from '@reducers/countries.reducer';
import { equalInLC } from '@core/old_helpers';
import { highlightMatches } from '@core/helpers';
import Popup from '@components/Popup/Popup';
import http from '@core/http';
import apiConfig from '@apiConfig';
import { addNotification } from '@actions/notifications.actions';
import { currentCountrySelector } from '@selectors/countries.selector';
import { ReportFileExtension } from '@models';

const AdminCategoryReportsTableRow: FC<{
    data: AdminCategoryReportsItem;
    searchQuery: string;
    onRemove: () => void;
}> = ({
    data,
    searchQuery,
    onRemove,
}) => {
    const dispatch = useDispatch();
    const currentCountry = useSelector(state => currentCountrySelector(state))
    const pushNotification = (notification: any) => dispatch(addNotification(notification));
    const { isActive, id, name, countryCode, } = data;
    const [ isDeleting, setIsDeleting ] = useState(false);
    const country = COUNTRIES_ARRAY.find(c => equalInLC(c.countryCode, countryCode));


    const downloadReport = (reportType: ReportFileExtension) => {
        const msgId = Date.now();
        pushNotification({
            text: "Downloading...",
            id: msgId,
        });
        http.downloadFileXHRFromUrl(
            'GET',
            apiConfig.get(
                'category report',
                {
                    reportId: id,
                    eTypeOfExport: reportType,
                    countryCode: currentCountry
                }
            ),
        ).then(() => {
            pushNotification({
                text: "Download was successful",
                duration: 4000,
                id: msgId,
            });
        }).catch(() => {
            pushNotification({
                error: true,
                text: "Something went wrong",
                duration: 4000,
                id: msgId,
            });
        });
    }

    return (
        <>
            <Cell className="AdminTable__col AdminTable__col--fluid AdminTable__col--max-half">
                <span className="AdminTable__truncated" title={name}>
                    <HightlightChars name={highlightMatches(name.toUpperCase(), searchQuery)} />
                </span>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--large-extra">
                {!!country && (
                    <>
                        <Flag
                            component={country.component}
                            compact
                        />
                        <span className="AdminTable__truncated">
                            {country.name}
                        </span>
                    </>
                )}
            </Cell>
            <Cell
                className="AdminTable__col AdminTable__col--medium"
                options="center no-pl"
            >
                {isActive ? <Icon><IconCheckSuccess/></Icon> : <Icon><IconCloseFailure/></Icon>}
            </Cell>
            <Cell
                className="AdminTable__col AdminTable__col--medium"
                options="no-p center"
            >
                <Link
                    to={{
                        pathname: Routes.AdminCategoryReportEdit,
                        search: serializeParams({
                            id,
                            country: countryCode
                        })
                    }}
                    className="AdminTable__btn"
                >
                    <Icon type="small">
                        <IconEdit/>
                    </Icon>
                </Link>
                <button
                    className="AdminTable__btn"
                    onClick={() => setIsDeleting(true)}
                >
                    <Icon type="small">
                        <IconDelete/>
                    </Icon>
                </button>
                <button
                    className="AdminTable__btn"
                    onClick={() => downloadReport(ReportFileExtension.Ppt)}
                >
                    <Icon type="small">
                        <IconPPTStroke/>
                    </Icon>
                </button>
                <button
                    className="AdminTable__btn"
                    onClick={() => downloadReport(ReportFileExtension.Pdf)}
                >
                    <Icon type="small">
                        <IconPDFStroke/>
                    </Icon>
                </button>
                <Popup
                    shown={isDeleting}
                    closeModal={() => setIsDeleting(false)}
                    title={`Delete a category report "${data.name.toUpperCase()}"`}
                    modifier="confirm"
                    btnModifier="red"
                    applyBtnTitle="Delete"
                    onConfirm={onRemove}
                >
                    <div className="Popup__text">
                        Are you sure you want to delete <span>{data.name.toUpperCase()}</span>?
                    </div>
                </Popup>
            </Cell>
        </>
    );
};

export default AdminCategoryReportsTableRow;