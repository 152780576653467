import React from 'react';
import SidebarOption from "./SidebarOption";
import './Sidebar.styles.scss';

function Sidebar({data, removeOption, handleChangeOptionName, setActiveOptionIndex}) {

    return (
        <div className="sidebar">
            <div className="sidebar__content">
                {data.map((option, index) => {
                    const optionWithSortedCountryCodes = {
                        ...option,
                        countryCodes: [
                            ...option.countryCodes.filter(code => code === "USA"),
                            ...option.countryCodes.filter(code => code !== "USA").sort()
                        ]
                    };
                    return (
                        <SidebarOption
                            key={option.id}
                            {...optionWithSortedCountryCodes}
                            remove={() => removeOption(index)}
                            changeName={(name) => handleChangeOptionName(index, name)}
                            setActive={() => setActiveOptionIndex(index)}
                        />
                    )
                })}
            </div>
        </div>
    );
}

export default Sidebar;