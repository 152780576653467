import React, { useEffect } from 'react';
import { connect } from "react-redux";
import SettingsMain from "./SettingsMain";
import SettingsTopTrends from "./SettingsTopTrends";
import SettingsFoodProfile from "./SettingsFoodProfile";
import SettingsFlavor from "./SettingsFlavor";
import {Scrollbars} from "react-custom-scrollbars";
import './Settings.styles.scss';
import CheckboxInput from "@components/CheckboxInput/CheckboxInput";
import {optionsDataComparePopupConfig} from '../Keyword.helpers';
import SubTitle from "@components/SubTitle/SubTitle";
import SettingsTags from "@pages/Admin/Keyword/Settings/SettingsTags";


function Settings({data, keywordSettings, handleChangeField, openCopyAttributesPopup, isCopyAttributesButtonShown}) {
    const {
        isExactMatch,
        isTopTrends,
        isFoodProfile,
        isFlavor,
        isTag,
    } = data.main;

    useEffect(() => {
        if(isFoodProfile) {
            const foodProfileFields = optionsDataComparePopupConfig.find(({section}) => section === 'foodProfile').fields;
            const hasNotData = foodProfileFields.reduce((acc, item) => acc && !data.foodProfile[item.field].toString(), true);
            if(hasNotData) {
                foodProfileFields.forEach(item => {
                    handleChangeFoodProfileField(item.field, Array.isArray(keywordSettings[item.field]) ? keywordSettings[item.field].map(item => item.id) : '');
                })
            }
        }

    }, [isFoodProfile])

    const isMasterOption = data.isMaster;

    const handleChangeMainField = (fieldName, value) => handleChangeField('main', fieldName, value);
    const handleChangeFoodProfileField = (fieldName, value) => handleChangeField('foodProfile', fieldName, value);
    const handleChangeTopTrendsField = (fieldName, value) => handleChangeField('topTrends', fieldName, value);
    const handleChangeTagsField = (fieldName, value) => handleChangeField('tags', fieldName, value);
    const handleChangeFlavorField = (fieldName, value) => handleChangeField('flavor', fieldName, value);

    const mainSectionConfig = optionsDataComparePopupConfig.find(({section}) => section === 'main');

    return (
        <div className="Settings__scrollbar-wrapper">
            <Scrollbars>
                <div className="Settings">
                    <div className="settings-section">
                        <div className="settings-section__inner settings-section__inner--justify">
                            <div className="settings-section__top">
                                <SubTitle textColor="yellow">
                                    {mainSectionConfig.name}
                                </SubTitle>
                            </div>
                            <div className='settings-section__inner settings-section__inner-wr'>
                                <CheckboxInput
                                    labelText='Search as exact match'
                                    onChange={() => handleChangeMainField('isExactMatch', !isExactMatch)}
                                    isChecked={isExactMatch}
                                    isBlueCheck
                                    isMedium
                                />
                                <div className="Settings__btns">
                                    {isCopyAttributesButtonShown && (
                                        <button
                                            className="btn btn--bordered"
                                            onClick={openCopyAttributesPopup}
                                        >
                                            Copy attributes
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <SettingsMain
                        dataConfig={mainSectionConfig}
                        data={data.main}
                        isMasterOption={isMasterOption}
                        handleChange={handleChangeMainField}
                        keywordSettings={keywordSettings}
                    />

                    {isTag && (
                        <SettingsTags
                            dataConfig={optionsDataComparePopupConfig.find(({section}) => section === 'tags')}
                            data={data.tags}
                            selectsData={keywordSettings}
                            handleChange={handleChangeTagsField}
                        />
                    )}

                    {isTopTrends && (
                        <SettingsTopTrends
                            dataConfig={optionsDataComparePopupConfig.find(({section}) => section === 'topTrends')}
                            data={data.topTrends}
                            selectsData={keywordSettings}
                            handleChange={handleChangeTopTrendsField}
                        />
                    )}

                    {isFoodProfile && isMasterOption && (
                        <SettingsFoodProfile
                            dataConfig={optionsDataComparePopupConfig.find(({section}) => section === 'foodProfile')}
                            data={data.foodProfile}
                            selectsData={keywordSettings}
                            handleChange={handleChangeFoodProfileField}
                        />
                    )}

                    {isFlavor && isMasterOption && (
                        <SettingsFlavor
                            dataConfig={optionsDataComparePopupConfig.find(({section}) => section === 'flavor')}
                            data={data.flavor}
                            selectsData={keywordSettings}
                            handleChange={handleChangeFlavorField}
                        />
                    )}
                </div>
            </Scrollbars>
        </div>

    );
}

const mapState = state => ({
    keywordSettings: state.keywordSettings,
});

export default connect(mapState)(Settings);