import React, { useContext, useEffect, useState } from "react";
import CheckboxInput from "@components/CheckboxInput/CheckboxInput";
import Select from "@components/Select/Select";
import SubTitle from "@components/SubTitle/SubTitle";
import { Actions, ReportContext } from "./ReportOptions.reducer";

function ReportFilters() {
    const { optionsData, dispatchOptionsAction, handleChangeOptionField } = useContext(ReportContext);
    const [selectedCompanies, setSelectedCompanies] = useState([]);

    // Set selected com
    useEffect(() => {
        const selectedCompanies = optionsData.companies.filter(item => item.hasAccess).map(item => item.id);
        handleChangeOptionField("main", "selectedCompanies", selectedCompanies);
        setSelectedCompanies(selectedCompanies);
    }, [optionsData.companies]);

    const handleMenuItems = (index, value) => {
        const data = {
            isChecked: value,
        };

        dispatchOptionsAction({
            type: Actions.EDIT,
            sectionName: "menuItemRank",
            index,
            data,
        });
    };

    return (
        <>
            <div className="AdminContainer__row">
                <div className="AdminContainer__section">
                    <div className="AdminContainer__column">
                        <div className="AdminContainer__section-title">
                            <SubTitle textColor="yellow">Description</SubTitle>
                        </div>
                        <div>
                            <label className="AdminContainer__input-wrap">
                                <input
                                    type="text"
                                    className="AdminContainer__input"
                                    placeholder={"Description"}
                                    value={optionsData.main.description}
                                    onChange={e => {
                                        handleChangeOptionField("main", "description", e.target.value);
                                    }}
                                />
                            </label>
                        </div>
                    </div>
                    <div className="AdminContainer__column">
                        <div className="AdminContainer__section-title">
                            <SubTitle textColor="yellow">Menupart by item type upfront charts</SubTitle>
                        </div>
                        <div className="AdminContainer__checkboxes-group">
                            {optionsData.menuItemRank && optionsData.menuItemRank.map((item, index) => {
                                const label = item.name.charAt(0).toUpperCase() + item.name.slice(1);

                                return (
                                    <div className="AdminContainer__checkboxes-group-item" key={index}>
                                        <CheckboxInput
                                            isBlueCheck
                                            labelText={label}
                                            isChecked={item.isChecked}
                                            modifiers={["inherited"]}
                                            onChange={() => {
                                                handleMenuItems(index, !item.isChecked);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <div className="AdminContainer__row has-divider"/>
        </>
    );
}

export default ReportFilters;
