import { FC, useContext } from "react";
import { GlobalContext } from "@components/App/App.context";
import MetricChart, { iFlavorNorm } from "@pages/FoodProfile/FoodProfileFlavor/MetricChart";

export interface iExperienceMetricData {
    title: string;
    value: number;
    norm?: iFlavorNorm;
    rankHeaderMsg: string;
    rankValueMsg: string;
    color?: string;
}

const ExperienceMetric: FC<{
    data: iExperienceMetricData;
}> = (
    {
        data,
    }
) => {
    const { getRem } = useContext(GlobalContext);
    const {
        title,
        value,
        norm,
        rankHeaderMsg,
        rankValueMsg,
        color = '#fff',
    } = data;

    return (
        <div className="FoodProfileFlavor__col">
            <div
                className="FoodProfileFlavor__row FoodProfileFlavor__row--col"
                style={{
                    paddingRight: getRem(10),
                    paddingLeft: getRem(10),
                }}
            >
                <MetricChart
                    data={{
                        title,
                        value,
                        norm,
                        color,
                    }}
                />
            </div>
            <div className="FoodProfileFlavor__row FoodProfileFlavor__row--col">
                <div className="FoodProfileFlavor__sub-title">
                    {rankHeaderMsg}
                </div>
                <div className="FoodProfileFlavor__title">
                    {rankValueMsg}
                </div>
            </div>
        </div>
    );
};

export default ExperienceMetric;