import React, { useState } from 'react';
import jsonToFormData from 'json-form-data';
import SubTitle from "@components/SubTitle/SubTitle";
import SettingsInput from "./SettingsInput";
import SettingsTextarea from "./SettingsTextarea";
import SettingsAddImages from "./SettingsAddImages";
import CheckboxInput from "@components/CheckboxInput/CheckboxInput";
import Select from '@components/Select/Select';
import { uploadAdminKeywordImages } from "@core/api";

function SettingsFlavor({ data, dataConfig, handleChange, selectsData }) {
    const [isLoaderActive, setIsLoaderActive] = useState(false);
    const FIELD_MAC_STAGE = "macStage";
    const { fields } = dataConfig;

    const inputs = fields.filter(({ type }) => type === 'select' || type === 'settingsInput');
    const checkboxFields = fields.filter(({ type }) => type === 'checkbox');
    const textAreaFields = fields.filter(({ type }) => type === 'textArea');
    const categoryDisplayName = (selectsData.topTrendsCategory.find(el => el.id === data.topTrendsCategory) || {categoryDisplayNameValue: ''}).categoryDisplayNameValue;

    const onDrop = (images => {
        const formData = jsonToFormData({ images });
        setIsLoaderActive(true)
        uploadAdminKeywordImages(formData).then((imagesIds) => {
            setIsLoaderActive(false)
            handleChange('images', [
                ...data.images,
                ...imagesIds,
            ])
        }).catch(err => setIsLoaderActive(false));
    });

    const handleRemoveImg = (imgId) => {
        handleChange('images', data.images.filter(img => img !== imgId))
    };

    const handleClearSelect = (field) => {
        if ( field !== FIELD_MAC_STAGE ) return;
        handleChange(field, 0);
    }

    return (
        <div className="settings-section settings-section--flavor">
            <div className="settings-section__top">
                <SubTitle textColor="yellow">Flavor settings</SubTitle>
            </div>
            <div className="settings-section__inner">
                {inputs.map(({ name, field, type }) => {

                    if (type === 'select') {
                        return (
                            <Select
                                key={field}
                                options={selectsData[field]}
                                value={data[field]}
                                title={name}
                                onChange={(id) => handleChange(field, id)}
                                onClear={() => handleClearSelect(field)}
                            />
                        )
                    } else {
                        return (
                            <SettingsInput
                                key={field}
                                setInputTextField={(value) => handleChange(field, value)}
                                value={field === 'categoryDisplayNameValue' ? categoryDisplayName : data[field]}
                                title={name}
                            />
                        )
                    }
                })}
            </div>
            <div className="settings-section__inner">
                {checkboxFields.map(({ name, field }) => {
                    return (
                        <div key={field} className="settings-section__checkbox">
                            <CheckboxInput
                                labelText={name}
                                isBlueCheck
                                isChecked={data[field]}
                                onChange={() => handleChange(field, !data[field])}
                                isMedium
                            />
                        </div>
                    )
                })}
            </div>
            <div className="settings-section__inner">
                <div className="settings-section__row">
                    {textAreaFields.map(({ name, field }) => {
                        return (
                            <SettingsTextarea
                                key={field}
                                value={data[field]}
                                setInputTextField={(value) => handleChange(field, value)}
                                title={name}
                            />
                        )
                    })}
                </div>
            </div>
            <div className="settings-section__inner">
                <SettingsAddImages
                    handleRemoveImg={handleRemoveImg}
                    images={data.images}
                    onDrop={onDrop}
                    isLoaderActive={isLoaderActive}
                />
            </div>
        </div>
    );
}
export default SettingsFlavor;