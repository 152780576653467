import { FC, useEffect, useMemo, useReducer } from "react";
import { iPenetration } from "@models/FoodProfile";
import HorizontalBar from "@components/HorizontalBar/HorizontalBar";
import { CSSTransition } from "react-transition-group";
import "./HorizontalBarChart.scss";

const HorizontalBarChart: FC<{
    data: iPenetration[],
    title: string,
    background: string,
}> = ({
    data,
    title,
    background,
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const countBarsForColumn = Math.ceil(data.length / 3);

    const btnText = useMemo(() => !state.showAll ? "See all" : "Hide all", [state.showAll]);

    const handleClick = () => {
        dispatch({
            showAll: !state.showAll,
            isShown: !state.isShown
        })
    };

    useEffect(() => {
        const node = document.querySelector<HTMLElement>('.horizontal-bar__element-wrap');
        if ( !node ) return;
        const width = node.offsetWidth - 20;

        let max = -Infinity;
        let min = Infinity;

        data.forEach(({value}) => {
            max = Math.max(max, +value);
            min = Math.min(min, +value);
        });

        const proportion = width / max;

        dispatch({
            min,
            max,
            width,
            proportion,
        });
    }, [data]);

    return (
        <div className="horizontalBarCharts__box">
             <div className='horizontalBarCharts__title'>
                {title}
            </div>
            <div className="horizontalBarCharts__wrap">
                {data.slice(0, 5).map((elem, idx) => {
                    return (
                        <HorizontalBar
                            background={background}
                            key={idx}
                            {...elem}
                            width={`${state.proportion * +elem.value}px`}
                        />

                    );
                })}
            </div>
            <CSSTransition in={state.isShown} classNames="appears-horizontal-bar" timeout={500} unmountOnExit>
                <div
                    className="horizontalBarCharts__full"
                >
                    <div className='horizontalBarCharts__title'>
                        {title}
                    </div>
                    {state.showAll && (
                        <div className="horizontalBarCharts__wrap">
                            <div className="horizontalBarCharts__col">
                                {data.slice(0, countBarsForColumn).map((elem, idx) => {
                                    return (
                                        <HorizontalBar
                                            background={background}
                                            key={idx}
                                            {...elem}
                                            width={`${state.proportion * +elem.value}px`}
                                        />
                                    );
                                })}
                            </div>
                            <div className="horizontalBarCharts__col">
                                {data.slice(countBarsForColumn, countBarsForColumn * 2).map((elem, idx) => {
                                    return (
                                        <HorizontalBar
                                            background={background}
                                            key={idx}
                                            {...elem}
                                            width={`${state.proportion * +elem.value}px`}
                                        />

                                    );
                                })}
                            </div>
                            <div className="horizontalBarCharts__col">
                                {data.slice(countBarsForColumn * 2).map((elem, idx) => {
                                    return (
                                        <HorizontalBar
                                            background={background}
                                            key={idx}
                                            {...elem}
                                            width={`${state.proportion * +elem.value}px`}
                                        />

                                    );
                                })}
                            </div>
                        </div>
                    )}

                    {state.isShown &&
                        <div
                            onClick={handleClick}
                            className="horizontalBarCharts__btn">
                            {btnText}
                        </div>
                    }

                </div>
            </CSSTransition>
            {data.length > 7 && (
                <div
                    onClick={handleClick}
                    className="horizontalBarCharts__btn">
                    See all
                </div>
            )}
        </div>
    )
}

const initialState: iState = {
    min: -Infinity,
    max: Infinity,
    proportion: 0,
    showAll: false,
    isShown: false,
    width: 0,
};

interface iState {
    min: number;
    max: number;
    proportion: number;
    showAll: boolean;
    isShown: boolean;
    width: number;
}

const reducer = (state: iState, action: Partial<iState>) => ({
    ...state,
    ...action
});

export default HorizontalBarChart;