import React from "react";
import MultipleInput from "@components/MultipleInput/MultipleInput";
import './AdditionalFilters.scss';
import AutoWidthInput from "@components/AutoWidthInput/AutoWidthInput";

export const formatValue = str => {
    const newValue = str
        .replace(/[^0-9.]/g, '')
        .replace(/^0(\d)/, '$1')
        .replace(/^(\d+(\.\d{0,2})?)\d*/, '$1');
    if (newValue === '0' || newValue === '') return null;
    return newValue;
}

const AdditionalFilters = (
    {
        addKeywords,
        removeKeyword,
        keywords,
        words,
        fullLayout = false,
        showCounter = false,
        minPrice,
        maxPrice,
        currentCurrency,
        handleChange,
        style,
    }
) => {
    const inputModifiers = ['filters', ...fullLayout ? ['light'] : []];

    return (
        <div
            className={`additional-filters ${fullLayout ? 'additional-filters--is-full' : ''}`}
            style={style}
        >
            <div
                className="additional-filters__title"
                data-testid="textFiltersPopupOtherFiltersTitle"
            >
                FILTER YOUR ITEMS
            </div>
            <div
                className="additional-filters__subtitle"
                data-testid="textFiltersPopupOtherFiltersSubtitle"
            >
                Use ingredients, flavors, and other keywords to filter your results.
            </div>
            <div className="additional-filters__wrap">
                <div className="additional-filter">
                    <div
                        className="additional-filter__accent"
                        data-testid="textFiltersPopupKeywordsIncludeAllLabel"
                    >
                        only items that <span className="additional-filter__highlight">include all</span> of these terms
                    </div>
                    <div className="additional-filter__input">
                        {showCounter && (
                            <div className="additional-filter__input-counter">
                                {words.filter(word => word.wordType === 4).length} keywords
                            </div>
                        )}
                        <MultipleInput
                            submittedItems={words.filter(word => word.wordType === 4)}
                            onSubmit={(words) => addKeywords(words.map(word => ({ ...word, wordType: 4 })))}
                            removeItem={removeKeyword}
                            keywords={keywords.filter(({ id }) => !words.map(({ id }) => id).includes(id))}
                            modifiers={inputModifiers}
                            placeholder="Type keyword"
                            words={words}
                            isUpperCase
                            testId="selectFiltersPopupKeywordsIncludeAll"
                        />
                    </div>
                    <button
                        className="additional-filter__button"
                        onClick={() => handleChange('words', words.filter(word => word.wordType !== 4))}
                        data-testid="buttonFiltersPopupKeywordsIncludeAllClear"
                    >
                        Clear All
                    </button>
                </div>
                <div className="additional-filter">
                    <div
                        className="additional-filter__accent"
                        data-testid="textFiltersPopupKeywordsIncludeAnyLabel"
                    >
                        only items that <span className="additional-filter__highlight">include any</span> of these terms
                    </div>
                    <div className="additional-filter__input">
                        {showCounter && (
                            <div className="additional-filter__input-counter">
                                {words.filter(word => word.wordType === 3).length} keywords
                            </div>
                        )}
                        <MultipleInput
                            submittedItems={words.filter(word => word.wordType === 3)}
                            onSubmit={(words) => addKeywords(words.map(word => ({ ...word, wordType: 3 })))}
                            removeItem={removeKeyword}
                            keywords={keywords.filter(({ id }) => !words.map(({ id }) => id).includes(id))}
                            modifiers={inputModifiers}
                            placeholder="Type keyword"
                            words={words}
                            isUpperCase
                            testId="selectFiltersPopupKeywordsIncludeAny"
                        />
                    </div>
                    <button
                        className="additional-filter__button"
                        onClick={() => handleChange('words', words.filter(word => word.wordType !== 3))}
                        data-testid="buttonFiltersPopupKeywordsIncludeAnyClear"
                    >
                        Clear All
                    </button>
                </div>
                <div className="additional-filter">
                    <div
                        className="additional-filter__accent"
                        data-testid="textFiltersPopupKeywordsExcludeAllLabel"
                    >
                        <span className="additional-filter__highlight">EXCLUDE ITEMS</span>
                        &nbsp;that contain any of these keyword
                    </div>
                    <div className="additional-filter__input">
                        {showCounter && (
                            <div className="additional-filter__input-counter">
                                {words.filter(word => word.wordType === 2).length} keywords
                            </div>
                        )}

                        <MultipleInput
                            submittedItems={words.filter(word => word.wordType === 2)}
                            onSubmit={(words) => addKeywords(words.map(word => ({ ...word, wordType: 2 })))}
                            removeItem={removeKeyword}
                            keywords={keywords.filter(({ id }) => !words.map(({ id }) => id).includes(id))}
                            modifiers={inputModifiers}
                            placeholder="Type keyword"
                            words={words}
                            isUpperCase
                            testId="selectFiltersPopupKeywordsExcludeAll"
                        />
                    </div>
                    <button
                        className="additional-filter__button"
                        onClick={() => handleChange('words', words.filter(word => word.wordType !== 2))}
                        data-testid="buttonFiltersPopupKeywordsExcludeAllClear"
                    >
                        Clear All
                    </button>
                </div>
            </div>
            <div
                className="additional-filters__footer"
                data-testid="blockFiltersPopupFilterByItemPrice"
            >
                only items priced between&nbsp;
                <AutoWidthInput
                    defaultValue="0"
                    value={minPrice}
                    mask={currentCurrency}
                    onChange={({ target }) => handleChange('minPrice', formatValue(target.value))}
                    maxLength={10}
                    testId="blockFiltersPopupFilterByItemPriceMin"
                />
                &nbsp;and&nbsp;
                <AutoWidthInput
                    defaultValue="-"
                    value={maxPrice}
                    mask={maxPrice === null ? '' : currentCurrency}
                    onChange={({ target }) => handleChange('maxPrice', formatValue(target.value))}
                    maxLength={10}
                    testId="blockFiltersPopupFilterByItemPriceMax"
                />
            </div>
        </div>
    )
};

export default AdditionalFilters;