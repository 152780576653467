import { FC } from "react";
import "./ScrollbarRenders.styles.scss";

const SIZE = 6;

export const TrackHorizontal: FC = ({style, ...props}: any) => {
    return (
        <div {...props} style={{...style, height: SIZE}} className="TrackHorizontal"/>
    )
}

export const ThumbHorizontal: FC = ({ style, ...props }: any) => {
    return (
        <div {...props} style={{...style, height: SIZE }} className="ThumbHorizontal"/>
    )
}
