export class ActionBlock {
    constructor(blockName, actions = []) {
        actions.map(action => this[action] = `${blockName}__${action}` )
    }
}

export const APP = new ActionBlock('FILTERS', ['SET_FIELD', 'SET_THEME', 'RESET_DATA']);
export const FILTERS = new ActionBlock('FILTERS', ['SET_DATA', 'SET_FIELD', 'RESET_DATA']);
export const TAGS = new ActionBlock('FILTERS', ['SET_DATA', 'SET_FIELD', 'RESET_DATA']);
export const COUNTRIES = new ActionBlock('COUNTRIES', ['SET_DATA', 'SET_CURRENT', 'SET_FIELD']);
export const KEYWORDS = new ActionBlock('KEYWORDS', ['SET_DATA']);
export const BYO_SETTINGS = new ActionBlock('BYO_SETTINGS', ['SET_FIELD', 'SET_DATA', 'INCREMENT_TAKE']);
export const BYO = new ActionBlock('BYO', ['SET_FIELD', 'SET_DATA', 'RESET_COLORS', 'RESET_DATA']);
export const NOTIFICATIONS = new ActionBlock('NOTIFICATIONS', ['ADD', 'REMOVE']);
export const TOP_TRENDS = new ActionBlock('TOP_TRENDS', ['SET_FIELD', 'SET_DATA', 'CLEAR']);
export const FOOD_PROFILES = new ActionBlock('FOOD_PROFILES', [ 'SET_DATA']);
export const INSTANT_CHARTS = new ActionBlock('INSTANT_CHARTS', [ 'SET_DATA']);
export const ADMIN_TOOLS = new ActionBlock('ADMIN_TOOLS', [ 'TOGGLE_ADD','SET_FIELD','SET_DATA']);
export const ADMIN_KEYWORDS = new ActionBlock('ADMIN_KEYWORDS', [ 'SET_FIELD','SET_DATA', 'RESET']);
export const KEYWORD_SETTINGS = new ActionBlock('ADMIN_KEYWORDS', [ 'SET_FIELD','SET_DATA', 'RESET']);
export const ADMIN_INSTANT_CHARTS = new ActionBlock('ADMIN_INSTANT_CHARTS', [ 'SET_FIELD','SET_DATA', 'RESET']);
export const ADMIN_INSTANT_CHART = new ActionBlock('ADMIN_INSTANT_CHART', [ 'SET_FIELD','SET_DATA', 'RESET']);
export const ADMIN_REPORTS = new ActionBlock('ADMIN_REPORTS', [ 'SET_FIELD','SET_DATA', 'RESET']);
export const ADMIN_REPORT = new ActionBlock('ADMIN_REPORT', [ 'SET_FIELD','SET_DATA', 'RESET']);
