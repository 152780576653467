import React from "react";
import Cell from "@components/NewAwesomeTable/Cell";
import HightlightChars from "@pages/Admin/Common/HightlightChars/HightlightChars";
import { IconDelete, IconEdit, IconView } from "@icons";
import { getFormattedDate } from "@core/old_helpers";
import Icon from "@components/Icon/Icon";

const Row = (
    {
        data,
        showAll,
        handleShowManagePopup,
        handleShowViewPopup,
        handleRemove,
    }
) => {
    const { id, name, renderName, restaurantsCount, isPublic, createByFullName, createdDate } = data;
    const renderDate = createdDate ? getFormattedDate(new Date(createdDate), 'MM/DD/YYYY') : `—`;
    return (
        <>
            <Cell className="AdminTable__col AdminTable__col--fluid">
                <span className="AdminTable__truncated" title={name}>
                    <HightlightChars name={renderName || name} />
                </span>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--large">
                {showAll && (
                    <button
                        onClick={() => handleShowViewPopup(id)}
                        className="AdminTable__btn"
                        title="Show included restaurants"
                    >
                        <Icon>
                            <IconView />
                        </Icon>
                    </button>
                )}
                &nbsp;
                {restaurantsCount}
            </Cell>

            {!showAll && (
                <>
                    <Cell className="AdminTable__col AdminTable__col--large">{isPublic ? "Public" : "Private"}</Cell>

                    <Cell className="AdminTable__col AdminTable__col--regular AdminTable__col--no-p AdminTable__col--center">
                        <button className="AdminTable__btn" onClick={() => handleShowManagePopup(id)}>
                            <Icon type="small">
                                <IconEdit/>
                            </Icon>
                        </button>
                        <button className="AdminTable__btn" onClick={handleRemove(id)}>
                            <Icon type="small">
                                <IconDelete />
                            </Icon>
                        </button>
                    </Cell>
                </>
            )}
            {showAll && (
                <>
                    <Cell className="AdminTable__col AdminTable__col--large">{createByFullName}</Cell>
                    <Cell className="AdminTable__col AdminTable__col--large">{renderDate}</Cell>
                </>
            )}
        </>
    );
};

export default Row;
