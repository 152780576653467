import React from "react"
import "./Tooltip.scss"
import Modal from "@components/Modal/Modal";
import Icon from "@components/Icon/Icon";

const Tooltip = ({data, state = {}, close, message=''}) => {

    let content;

    if (data.definition || data.examples) {
        content = (
            <>
                <p className="Tooltip__title">{data.definition.toLowerCase()}</p>
                <p className="Tooltip__description">Examples: {data.examples}</p>
             </>
        )
    } else if (data.image) {
        content = (
            <Icon type={"map"}>
                <data.image />
            </Icon>
        )
    } else if (message) {
        content = (
            <p className='Tooltip__title Tooltip__title--message'>{message}</p>
        )
    }

    const classListTooltip = new Set([
        "Tooltip",
        state.last
            ? "Tooltip--last"
            : "",
    ]);

    return (
        <>
            <Modal
                closeModal={close}
                shown={state.isOpened}
            >
                {<div className={Array.from(classListTooltip).join(" ")}
                      style={{
                          left: state.x,
                          top: state.y
                      }}
                >
                    <div className="Tooltip__content">
                        {content}
                    </div>
                </div>}
            </Modal>
        </>)
};

Tooltip.defaultProps = {
    data : {
        title: "Dessert centered around fruit",
        description: "Examples: bananas foster, cherries jubilee, bowl of fruit served with sherbert "
    }
};

export default Tooltip;