import React from 'react';
import dayjs from "dayjs";

const monthValues = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const isIE = /*@cc_on!@*/false || !!document.documentMode;

export const getBarConfig = (
    {
        isThemeLightEnable,
        id: gradientId,
        renderValueText,
        lineWidth,
    }
) => {
    return {
        strokeWidth: lineWidth,
        easing: 'easeOut',
        duration: 1400,
        color: !isIE ? `url(#${gradientId})` : '#4281DA',
        trailColor: !isThemeLightEnable ? "#2C303E" : "#D2D8E2",
        trailWidth: lineWidth,
        svgStyle: null,
        strokeLinecap: "round",
        text: {
            value: 'Text',
            style: {
                opacity: renderValueText ? 1 : 0,
                color: !isThemeLightEnable ? "#FFF" : "#1C2531",
                position: 'absolute',
                left: '50%',
                top: '50%',
                padding: 0,
                margin: 0,
                fontSize: 'inherit',
                fontWeight: '600',
                lineHeight: '20px',
                transform: {
                    prefix: true,
                    value: 'translate(-50%, -50%)'
                }
            }
        },
        step: function (state, circle) {
            const value = `${isSignBeforeNumber(circle.value() * 100)}%`;
            circle.setText(value);

        }
    }
};

export function getMonth(date) {
    const dateObject = new Date(date);
    const month = dateObject.getMonth();
    return monthValues[month];
}

export function isPrimitive(value) {
    return value !== Object(value);
}

export function capitalize(str) {
    try {
        str = str.toLowerCase();
    } catch (err) {
        debugger;
    }
    return str.replace(
        /\w\S*/g,
        txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
}

export function getFormattedThousand(number = "") {
    if (+number < 0) return `-${getFormattedThousand(-number)}`;
    let numberString = number.toString();
    let result = "";
    while (numberString.length > 3) {
        result = `,${numberString.substr(numberString.length - 3)}${result}`;
        numberString = numberString.substr(0, numberString.length - 3);
    }

    result = `${numberString}${result}`;
    return result;
}

export function makeEnding(title, count = 0) {
    switch (title) {
        case 'Healthy': {
            return count === 1 ? 'Healthy' : 'Healthy Terms';
        }
        default: {
        }
    }
    switch (title[title.length - 1]) {
        case 'y': {
            return count === 1 ? title : title.replace(/y$/, 'ies');
        }
        default: {
            return count === 1 ? title : `${title}s`;
        }
    }
}

export function deepCopy(data) {
    return JSON.parse(JSON.stringify(data));
}

export function deepEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function getDDFromNumber(n) {
    if (n === 0) {
        return "00";
    } else if (n < 10) {
        return `0${n}`;
    } else {
        return `${n % 100}`;
    }
}

export function getClassName(classList) {
    return Array.from(classList).join(" ");
}

export function getValueWithSigh(value) {
    return value >= 0 ? `+${value}` : `${value}`;
}

export function camelize(str) {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
            return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
        })
        .replace(/\s+/g, "");
}

export function getMilliseconds(time, from = "sec") {
    switch (from) {
        case "sec": {
            return time * 1000;
        }
        case "min": {
            return time * 1000 * 60;
        }
        case "hour": {
            return time * 1000 * 60 * 60;
        }
        default: {
            return time;
        }
    }
}

export function getDateFormat(date) {
    const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
        mm = +date.getMonth() + 1 < 10 ? `0${+date.getMonth() + 1}` : +date.getMonth() + 1,
        yy = date.getFullYear();
    return `${dd}/${mm}/${yy}`;
}

export function getRoundValue(data) {
    return `${data.toFixed(1)}%`
}

export function isSignBeforeNumber(number) {
    let val;

    if(+number.toFixed(1) === 0) {
        val =  `${number.toFixed(1).replace(/[+-]/, '')}`
    }
    if(+number.toFixed(1) > 0) {
        val = `+${number.toFixed(1)}`
    }
    if(+number.toFixed(1) < 0) {
        val = number.toFixed(1)
    }

    return val;
}

export function getRoundValueWithoutPercent(data) {
    return data.toFixed(1)
}

export const getEmptyKeywordModel = (query, markAsEmpty) => {
    const result = {
        name: query,
        id: 0,
        exactMatch: false,
        wordExtensions: null,
    };

    if (markAsEmpty) {
        result.isEmpty = true;
    }
    return result;
};

export const getDataWithCurrency = (data) => {
    return `$${data}`
};

export function toggleInArray(array, ...values) {
    let result = [...array];
    if (values.length === 0) return [];
    values.forEach((value) => {
        if (array.includes(value)) {
            result = result.filter((item) => item !== value);
        } else {
            result = [...result, value];
        }
    });
    return result;
}

export const getDate = (date) => {
    const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const d = new Date(date);
    return `${monthNames[d.getMonth()]} ${d.getFullYear()} `;
};

export const getChartFoodProfileChartData = (dataChart, name, isLightTheme) => {
    return {
        hasPrediction: dataChart.length > 11,
        chartInfo: [{
            name: name,
            color: isLightTheme ? "#F55353" : "#4794FF",
        }],
        data: dataChart.map(({name, value}, idx) => ({
            isPrediction: idx >= 11,
            label: +name,
            values: [value],
            valuesLabels: [`${value}%`],
        }))

    }
};

export const normalizeFoodProfileChartData = (charsetData) => {
    return charsetData.map((data) => {
        let value = +data.value;
        return {
            ...data,
            value:  value.toFixed(1),
        }
    })

};


export function getRadians(angle) {
    return angle * (Math.PI / 180)
}

export function convertCase(string, from = 'kebab', to = 'camel') {
    return string.split('-').map(((part, index) => index === 0 ? part : capitalize(part))).join('');
}

export function compareInLC(string1, string2) {
    return string1.toLowerCase() === string2.toLowerCase();
}

export function includesInLC(string1, string2) {
    return string1.toLowerCase().includes(string2.toLowerCase());
}

export function equalInLC(string1, string2) {
    return string1.trim().toLowerCase() === string2.trim().toLowerCase();
}

export function startsFromInLC(string1, string2) {
    return string1.toLowerCase().indexOf(string2.toLowerCase()) === 0;
}

export function download(url, id = 'download') {
    const link = document.createElement('a');
    link.id = id;
    link.href = url;
    link.setAttribute('download', 'download');
    link.classList.add('download-link');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function copyToClipboard(text) {
    const textarea = document.createElement('TEXTAREA');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
}

export function getJSXFromHTML (htmlString) {
    const div = document.createElement('DIV');
    div.innerHTML = htmlString;
    return Array.from(div.childNodes).map((node, index) => {
        if (node.nodeType === 3) {
            return node.nodeValue;
        } else {
            const TagName = node.tagName.toLowerCase();
            return (
                <TagName key={index}>
                    {node.innerText}
                </TagName>
            );
        }
    });
}

export function sortByName(data) {
    return data.sort((a,b) => a.name - b.name)
}

export function numericSortByField(data, field) {
    return data.sort((a,b) => a[field] - b[field])
}

export const getPairedFlavorsData = (word) => {
return  word.pairedFlavors
    ? word.pairedFlavors.map(item => ({
        title: capitalize(item.name),
        value: (Number.isNaN(+item.value)) ? item.value : `${(+item.value).toFixed(1)}%`,
        progress: (Number.isNaN(+item.value)) ? 0 : item.value,
    })).slice(0, 5)
    : []
};

export const getKeywordSettingResult = (keywordSetting) => {
    if (keywordSetting) {
        return 'Yes'
    } else {
        return 'No'
    }
};

export const flatten = arr => {
    return arr.reduce(function (flat, toFlatten) {
        return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, []);
};

export const formatValue = str => {
    return str
        .replace(/[^0-9.,]/g, '')
        .replace(/^0(\d)/, '$1')
        .replace(/^(\d+(\.\d{0,2})?)\d*/, '$1');
}

export const StringMatchRanks = {
    Exact: 100,
    Starts: 10,
    Includes: 1,
    NotIncludes: 0,
};

export function getStringMatchRank (string, query) {
    if (compareInLC(string, query)) {
        return StringMatchRanks.Exact;
    }
    else if (startsFromInLC(string, query)) {
        return StringMatchRanks.Starts;
    }
    else if (includesInLC(string, query)) {
        return StringMatchRanks.Includes;
    }
    else {
        return StringMatchRanks.NotIncludes;
    }
}

export function dynamicSort(property, sortingDirection) {
    const sortOrder = sortingDirection === "asc" ? 1 : -1;
    return (a, b) => {
        let itemA = a[property];
        let itemB = b[property];

        if (typeof itemA === 'string') itemA = itemA.toLowerCase();
        if (typeof itemB === 'string') itemB = itemB.toLowerCase();
        return (itemA < itemB ? -1 : itemA > itemB ? 1 : 0) * sortOrder;
    };
}

export function prepareForSorting(value) {
    switch (typeof value) {
        case "string": return value.toLowerCase();
        case "boolean": return value ? 1 : 0;
        default: return value;
    }
}

export function getFormattedDate(currentDate, template = "MMMM YYYY") {
    return dayjs(currentDate).format(template);
}

export function highlightMatch(text, match) {
    if (!text && typeof text !== 'string' || match.trim() === '') return text;
    return text.replace(new RegExp(`(${match.trim().toLowerCase()})`, 'gi'), '<b>$1</b>');
}