import { iPermissionFields, PermissionLevelEnum, PermissionSection } from "@models/PermissionSection";
import { components } from "@apiSchema";
import { FlavorMetric, iFlavorMetric } from "@models/FlavorMetric";

export interface iFoodProfileFlavorData {
    knowIt: iFlavorMetric;
    haveTriedIt: iFlavorMetric;
    hadManyTimes: iFlavorMetric;
    loveIt: iFlavorMetric;
    likeIt: number;
    neutral: number;
    dislike: number;
    hateIt: number;
    noOpinion: number;
}

export interface iFoodProfileFlavor extends iPermissionFields {
    data: iFoodProfileFlavorData;
}

type ApiModel = components['schemas']['MenuTrends.Application.Contracts.Models.DataWithAccessLevel`1[[MenuTrends.Application.Contracts.Models.ExternalApi.Flavor.FoodProfile.FlavorFoodProfileWordModel, MenuTrends.Application.Contracts, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]]'];

interface iData {
    apiModel?: ApiModel;
}

export default class FoodProfileFlavor implements iFoodProfileFlavor {
    permissionLevel = PermissionLevelEnum.None;
    hasData = true;
    data = FoodProfileFlavor.defaultData;


    static defaultData: iFoodProfileFlavorData = {
        knowIt: new FlavorMetric(),
        haveTriedIt: new FlavorMetric(),
        hadManyTimes: new FlavorMetric(),
        loveIt: new FlavorMetric(),
        likeIt: 0,
        neutral: 0,
        dislike: 0,
        hateIt: 0,
        noOpinion: 0,
    };
    static fakeData: iFoodProfileFlavorData = {
        knowIt: {
            ...new FlavorMetric(),
            raw: 50,
        },
        haveTriedIt: {
            ...new FlavorMetric(),
            raw: 30,
        },
        hadManyTimes: {
            ...new FlavorMetric(),
            raw: 60,
        },
        loveIt: {
            ...new FlavorMetric(),
            raw: 30,
        },
        likeIt: 25,
        neutral: 20,
        dislike: 15,
        hateIt: 10,
        noOpinion: 0,
    };

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData(data: iFoodProfileFlavor) {
        ({
            permissionLevel: this.permissionLevel,
            hasData: this.hasData,
            data: this.data,
        } = data);
    }

    private mapFromApi (rawData: ApiModel) {
        const permissionLevel = PermissionSection.getPermissionLevel(rawData.accessLevel);
        let data: iFoodProfileFlavorData = FoodProfileFlavor.defaultData;

        if (rawData.hasData) {
            if (permissionLevel === PermissionLevelEnum.None) {
                data = FoodProfileFlavor.fakeData
            } else if (rawData.data) {
                data = {
                    knowIt: new FlavorMetric({ apiModel: rawData.data.knowIt }),
                    haveTriedIt: new FlavorMetric({ apiModel: rawData.data.haveTriedIt }),
                    hadManyTimes: new FlavorMetric({ apiModel: rawData.data.hadManyTimes }),
                    loveIt: new FlavorMetric({ apiModel: rawData.data.loveIt }),
                    likeIt: rawData.data.likeIt || 0,
                    neutral: rawData.data.neutral || 0,
                    dislike: rawData.data.dislike || 0,
                    hateIt: rawData.data.hateIt || 0,
                    noOpinion: rawData.data.noOpinion || 0,
                };
            }
        }



        this.setData({
            permissionLevel,
            hasData: rawData.hasData || false,
            data,
        });
    }
}