import React from 'react';
import { connect } from 'react-redux';
import { countriesSelector } from "@selectors/countries.selector";
import Loader from "@components/Loader/Loader";
import Keyword from "./Keyword";

function KeywordWrapper ({ countries, ...props }) {
    return (
        <div>
            <Loader isShown={countries.length === 0}/>
            {countries.length > 0 && <Keyword {...props}/>}
        </div>
    );
}

const mapState = state => ({
    countries: countriesSelector(state),
});

export default connect(mapState)(KeywordWrapper);