import { useRef } from 'react';
import { CSSTransition } from "react-transition-group";
import cn from "classnames";

const Transition = ({
    children,
    shown,
    timeout = 250,
    classNames = '',
    modifiers = []
}) => {
    const nodeRef = useRef(null);
    const transitionClassNames = cn(modifiers.map(m => `${m}`));

    return (
        <CSSTransition
            in={shown}
            timeout={timeout}
            unmountOnExit={true}
            nodeRef={nodeRef}
            classNames={transitionClassNames}
        >
            <div
                className={classNames}
                ref={nodeRef}
            >
                {children}
            </div>
        </CSSTransition>
    )
};

export default Transition;