import React, { useRef, useEffect, useState } from 'react'
import PT from "prop-types";
import './bar.style.scss'

const Bar = ({
    title,
    value,
    color,
    height,
    width,
    translateX,
    translateXStart,
    onClick,
    valueBefore,
    valueAfter,
}) => {
    const barElement = useRef(null);
    const [start, setStart] = useState(translateXStart);
    const style = {
        background: color,
        height,
    };

    useEffect(() => {
        start && setStart(false);
    },[height]);

    return (
        <div className='bar' style={{
            width: `${width}%`,
            transform: start ? `translateX(${translateX + 100}%)` : `translateX(${translateX}%)`

        }} >
            <div className='bar__value'>
               <span className="bar__currency">{valueBefore}</span>
                {value}{valueAfter}
            </div>
            <div ref={barElement} className="bar__elem" style={style} onClick={onClick}/>
            <div className='bar__title'>
                {title}
            </div>
        </div>
    )
}

Bar.propTypes = {
    title: PT.string,
    value: PT.number,
    color: PT.string,
    height: PT.oneOfType([
        PT.string,
        PT.number
    ]),
    width: PT.number,
};

export default Bar;