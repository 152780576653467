import React, { FC, useMemo } from 'react';
import { ID, iOption } from '@models';
import CheckboxInput from '@components/CheckboxInput/CheckboxInput';
import Accordion from '@components/Accordion/Accordion';
import { toggleInArray } from '@core/old_helpers';
import { iOptionWithGroup } from '@models/FilterSettings';
import { minusArrayUnique, uniteArraysUnique } from '@core/helpers';

const FilterCheckboxAccordion: FC<{
    title: string;
    options: iOptionWithGroup[];
    value: ID[];
    onChange: (value: ID[]) => void;
}> = ({
    title,
    options,
    value,
    onChange,
}) => {

    const isAllSelected = useMemo(() => options.length === value.length, [options, value]);
    const isSelected = useMemo(() => value.length > 0 && ! isAllSelected, [value, isAllSelected]);


    const handleChange = (id: ID, isGroup: boolean) => {

        if (isGroup) {
            const groupOptionsIds = options.filter(option => option.groupId === id).map(option => option.id);
            const isAllSelected = groupOptionsIds.every(optionId => value.includes(optionId));

            if (isAllSelected) {
                onChange(minusArrayUnique(value, groupOptionsIds));
            } else {
                onChange(uniteArraysUnique(value, groupOptionsIds));
            }
        } else {
            onChange(toggleInArray(value, id));
        }
    };

    const handleToggleAll = () => {
        if (isAllSelected) {
            onChange([]);
        } else {
            onChange(options.map(o => o.id));
        }
    }

    return (
        <div className="AdminFilters__column size-l">
            <CheckboxInput
                isBlueCheck
                onChange={handleToggleAll}
                labelText={title}
                isChecked={isAllSelected}
                isPartialChecked={isSelected}
            />
            <br/>
            <Accordion
                data={options.map(option => ({
                    ...option,
                    name: option.title,
                    isChecked: value.includes(option.id),
                }))}
                handleChange={handleChange}
            />
        </div>
    )
}

export default FilterCheckboxAccordion;