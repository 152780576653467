export function getFiltersValues(filters) {
    const data = {
        venues: {},
        menuItems: {},
        attributes: {},
    };

    Object.keys(filters).forEach(sectionName => {
        const section = filters[sectionName];
        Object.keys(section).forEach(key => {
            const filter = section[key];
            if (key === 'restaurants' || key === 'words' || key === 'tags') {
                data[sectionName][key] = filter;
            }
            else if (Array.isArray(filter)) {
                data[sectionName][key] = filter
                    .filter(el => el.isChecked)
                    .map(el => el.id);
            } else {
                data[sectionName][key] = filter;
            }
        });
    });
    return data;
}

export function getActiveFiltersFromBack(filters) {
    const { restaurantFilters, menuPartFilterItems, otherFilters } = filters;
    const {
        segments,
        dayParts,
        regions,
        cuisines,
        hostedLocations,
    } = restaurantFilters;

    const {
        restaurants,
    } = otherFilters;

    const venues = {
        segments: (segments || []).map(item => item.id),
        hostedLocations: (hostedLocations || []).map(item => item.id),
        regions: (regions || []).map(item => item.id),
        cuisines: (cuisines || []).map(item => item.id),
        restaurants: restaurants || [],
        minUnits: otherFilters.minUnits ? `${otherFilters.minUnits}` : '1',
        maxUnits: otherFilters.maxUnits,
    };

    let menuItems = {
        minPrice: otherFilters.minPrice,
        maxPrice: otherFilters.maxPrice,
        words: otherFilters.customWords || [],
        appetizers: [],
        entrees: [],
        sides: [],
        desserts: [],
        beverages: [],
    };

    if (menuPartFilterItems.length) {
        menuPartFilterItems.forEach((curr, idx) => {
            const key = `${curr.name.toLowerCase()}s`;
            menuItems[key] = menuPartFilterItems[idx].menuItemTypes.map(({ id }) => id);
        }, {});
    }

    const attributes = {
        healthyTerms: otherFilters.healthyDining || false,
        smallPlates: otherFilters.smallPlates || false,
        kidMenus: otherFilters.kidMenus || false,
        glutenFree: otherFilters.glutenFree || false,
        plantBased: otherFilters.plantBased || false,
        ethnicSpeciality: (otherFilters.ethnicSpeciality || []).map(i => i.id),
        catering: otherFilters.catering,
        dayParts: (dayParts || []).map(item => item.id),
        tags: otherFilters.tags || [],
    };

    return {
        venues,
        menuItems,
        attributes,
    };
}
