import React, { useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import { Navigate as Redirect, useNavigate, useLocation } from "react-router-dom";
import Loader from "@components/Loader/Loader";
import Page from "@components/Page/Page";
import { getAdminChildReports, getAdminReport, getAdminReportCompanies } from "@core/api";
import { Routes } from "@routes";
import { countriesSelector } from "@selectors/countries.selector";
import AdminReport from "./Report";
import { getEmptyReportData, normalizeChildRepots, normalizeReportFromBackEnd, getChildCategories } from "./Report.helpers";
import { reportOptions, Actions, ReportContext } from "./ReportOptions.reducer";
import { addNotification } from "@actions/notifications.actions";



const AdminReportContainer = props => {
    const { countries, addNotification } = props;
    let location = useLocation();
    let history = useNavigate();
    const query = new URLSearchParams(location.search);
    const currentCountryCode = query.get("country");
    const currentReportId = query.get("id");
    const selectedCountry = countries.find(item => item.countryCode === currentCountryCode);
    const addReport = location.pathname === Routes.AdminSummaryReportAdd;
    const editReport = location.pathname === Routes.AdminSummaryReportEdit;
    const [dataLoading, setDataLoading] = useState(true);
    const [optionsData, dispatchOptionsAction] = useReducer(reportOptions, {});
    const [isLoading, setIsLoading] = useState(true);

    // Set container data loading
    useEffect(() => {
        setDataLoading(!countries.length || isLoading);
    }, [countries, isLoading]);

    const getChildReports = async () => {
        try {
            const data = await getAdminChildReports(currentCountryCode);

            if (data) {
                const childInstantCharts = normalizeChildRepots(data);
                const categories = getChildCategories(childInstantCharts);
                dispatchOptionsAction({
                    type: Actions.ADD,
                    data: {
                        childInstantCharts,
                        instantChartsCategories: categories,
                        selectedInstantChartsCategories: categories.categories,
                        selectedInstantChartsSubCategories: categories.subCategories,
                    },
                });
            }
        } catch (error) {
            console.error(`getChildReports: `, error);
        }
    };

    // EDIT REPORT
    useEffect(() => {
        if (addReport) return;
        setIsLoading(true);
        const dataRequest = getAdminReport(currentReportId);

        const getReport = async () => {
            try {
                const data = await dataRequest;

                if ( parseInt(data.id) === parseInt(currentReportId) ) {
                    const reportData = normalizeReportFromBackEnd(data);

                    dispatchOptionsAction({
                        type: Actions.SET_DATA,
                        data: reportData,
                    });

                    await getChildReports();

                    setIsLoading(false);
                } else {
                    throw new Error(`Fails xhr request for /admin/RtuReports/${currentReportId}`);
                }
            } catch (error) {
                console.error(error);
                history(Routes.AdminSummaryReports);
            }
        };

        getReport();

        return () => {
            dataRequest.abort();
            setDataLoading(true);
            setIsLoading(true);
        };
    }, []);

    // ADD REPORT
    useEffect(() => {
        if (editReport) return;
        setIsLoading(true);

        const reportData = getEmptyReportData(currentCountryCode);
        const companiesRequest = getAdminReportCompanies();

        dispatchOptionsAction({
            type: Actions.ADD,
            data: {
                ...reportData,
            },
        });

        const getData = async () => {
            await getChildReports();
            const companies = await companiesRequest;
            dispatchOptionsAction({
                type: Actions.ADD,
                data: {
                    companies
                },
            });

            setIsLoading(false);
        }
        getData();

        return () => {
            setDataLoading(true);
            setIsLoading(true);
        }
    }, []);

    const handleChangeOptionField = (sectionName, fieldName, value) => {
        dispatchOptionsAction({
            type: Actions.EDIT_FIELD,
            sectionName,
            fieldName,
            value,
        });
    };

    return (
        <Page isWrapped title={addReport ? "New Summary Report" : "Edit Summary Report"}>
            {!dataLoading ? (
                selectedCountry ? (
                    <ReportContext.Provider value={{ optionsData, dispatchOptionsAction, handleChangeOptionField }}>
                        <AdminReport addReport={addReport} currentCountry={selectedCountry} addNotification={addNotification} />
                    </ReportContext.Provider>
                ) : (
                    <Redirect
                        to={{
                            pathname: Routes.AdminSummaryReports,
                            params: { openSelectCountryPopup: true },
                        }}
                    />
                )
            ) : (
                <Loader isShown={dataLoading} />
            )}
        </Page>
    );
};

const mapStateToProps = state => ({
    countries: countriesSelector(state),
});

const mapDispatchToProps = dispatch => ({
    addNotification: notification => dispatch(addNotification(notification))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminReportContainer);
