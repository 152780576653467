import React, { createContext, FC, useContext, useEffect, useMemo, useState } from "react";
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import Search from "@components/Search/Search";
import AdminTable from "@pages/Admin/Common/AdminTable";
import "./PopupAddChildReport.styles.scss";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import { ID } from '@models';
import { toggleInArray } from '@core/old_helpers';
import { iAdminInstantChartsData } from '@apiHooks/useAdminInstantChartsList';

export const InstantChartContext = createContext<iAdminInstantChartsData>({
    items: [],
    categories: [],
    subCategories: [],
});

const PopupAddChildReport: FC<{
    isOpened: boolean;
    close: () => void;
    isMultiple?: boolean;
    onSelect: (ids: ID[]) => void;
    optionsIdsToExclude?: ID[];
}> = ({
    isOpened,
    close,
    isMultiple= false,
    onSelect,
    optionsIdsToExclude = [],
}) => {
    const { items, categories, subCategories } = useContext(InstantChartContext);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedItemsIds, setSelectedItemsIds] = useState<ID[]>([]);
    const [selectedCategoriesIds, setSelectedCategoriesIds] = useState<ID[]>([]);
    const [selectedSubCategoriesIds, setSelectedSubCategoriesIds] = useState<ID[]>([]);

    const filteredItems = useMemo(() => {
        return items
            .filter(i => !optionsIdsToExclude?.includes(i.id))
            .filter(i => selectedCategoriesIds.includes(i.categoryId))
            .filter(i => selectedSubCategoriesIds.includes(i.subCategoryId))
            .filter(i => (i.title || '').toLowerCase().includes(searchQuery.trim().toLowerCase()));
    }, [searchQuery, items, selectedCategoriesIds, selectedSubCategoriesIds]);

    useEffect(() => {
        return () => {
            setSearchQuery("");
            setSelectedItemsIds([]);
            setSelectedCategoriesIds(categories.map(i => i.id));
            setSelectedSubCategoriesIds(subCategories.map(i => i.id));
        }
    }, [isOpened]);

    const applyChanges = () => {
        onSelect(selectedItemsIds);
        close();
    }

    const toggleItemIsSelected = (id: ID) => () => {
        if (isMultiple) {
            setSelectedItemsIds(toggleInArray(selectedItemsIds, id));
        }
        else {
            setSelectedItemsIds([id]);
        }
    };

    const Row = ({ index, style }: { index: number, style: any }) => {
        const rowData = filteredItems[index];
        return (
            <div style={style} className={`AdminTable__row`}>
                <TableRow
                    data={rowData}
                    toggleIsChecked={toggleItemIsSelected(rowData.id)}
                    searchQuery={searchQuery}
                    isChecked={selectedItemsIds.includes(rowData.id)}
                    isMultipleSelect={isMultiple}
                />
            </div>
        );
    };

    return (
        <Modal closeModal={close} shown={isOpened} hasOverlay>
            <div className="Popup__wrapper">
                <div className="Popup Popup--reports">
                    <div className="Popup__title Popup__title--small">Add instant chart</div>

                    <div className="PopupAddChildReport">
                        <div className="PopupAddChildReport__header">
                            <div className="PopupAddChildReport__filters-info">
                                {selectedItemsIds.length > 0 && (
                                    <>
                                        <div className="PopupAddChildReport__counter">
                                            {isMultiple ? (
                                                <>{selectedItemsIds.length} items selected</>
                                            ) : (
                                                <>
                                                    Selected item:&nbsp;
                                                    <b>{items.find(i => selectedItemsIds.includes(i.id))?.title}</b>
                                                </>
                                            )}
                                        </div>
                                        <div
                                            className="PopupAddChildReport__clear"
                                            onClick={() => setSelectedItemsIds([])}
                                        >
                                            Clear selection
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="PopupAddChildReport__search">
                                <Search
                                    key="search"
                                    searchQuery={searchQuery}
                                    setSearchQuery={setSearchQuery}
                                    placeholder="Search"
                                    showIcon
                                />
                            </div>
                            <div className="PopupAddChildReport__actions">
                                <Button onClick={close} modifiers={["transparent"]}>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={applyChanges}
                                    modifiers={["blue", "rounded"]}
                                    isDisabled={selectedItemsIds.length === 0}
                                >
                                    Apply
                                </Button>
                            </div>
                        </div>

                        <div className="PopupAddChildReport__body">
                            <div className="AdminInstantChartsTable">
                                <AdminTable
                                    header={(
                                        <TableHeader
                                            selectedCategoriesIds={selectedCategoriesIds}
                                            setSelectedCategoriesIds={setSelectedCategoriesIds}
                                            selectedSubCategoriesIds={selectedSubCategoriesIds}
                                            setSelectedSubCategoriesIds={setSelectedSubCategoriesIds}
                                        />
                                    )}
                                    row={Row}
                                    length={filteredItems.length}
                                    disableRowTransition
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default PopupAddChildReport;
