import { BYO_SETTINGS } from '../core/actionNames';

const { SET_FIELD, INCREMENT_TAKE, SET_DATA } = BYO_SETTINGS;

export const TAKE_STEP = 50;

const defaultState = {
    data: [],
    currentByoSetting: null,
    searchQuery: "",
    take: TAKE_STEP,
};

export default function byoSettings(state = defaultState, action) {
    switch (action.type) {
        case SET_FIELD: {
            return {
                ...state,
                [action.name]: action.value,
            };
        }
        case INCREMENT_TAKE: {
            return {
                ...state,
                take: state.take + TAKE_STEP,
            }
        }
        case SET_DATA: {
            return {
                ...state,
                ...action.data,
            }
        }
        default: {
            return state;
        }
    }
}
