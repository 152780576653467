import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Navigate as Redirect, useLocation } from "react-router-dom";
import { setField } from "@actions/adminInstantChart.actions";
import Loader from "@components/Loader/Loader";
import { getAdminInstantCharts, getAdminInstantChartsCategories, getFilters, getRestaurantsList } from "@core/api";
import { Routes } from "@routes";
import { chartsSelector, chartWordsSelector, filtersChartSelector, filtersIsLoadedSelector } from "@selectors/adminInstantChart.selectors";
import { countriesSelector } from "@selectors/countries.selector";
import AdminInstantChart from "./InstantChart";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function InstantChartWrapper({
    instantCharts,
    setInstantCharts,
    countries,
    setInstantChartsCategories,
    filters,
    setCurrentInstantChart,
    filtersLoaded,
    allCategories,
    setInstantChartFilters,
    setInstantChartRestaurants,
    currentRestaurants,
    ...props
}) {
    const query = useQuery();
    const location = useLocation();
    const countryCode = query.get("country");
    const currentCountry = countries.find(item => item.countryCode === countryCode);
    const [correctCountry, setCorrectCountry] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const newChart = location.pathname === Routes.AdminInstantChartAdd;
    const _isMounted = useRef(true);

    useEffect(() => {
        const adminChartsRequest = getAdminInstantCharts();
        const adminChartsCategoriesRequest = getAdminInstantChartsCategories(countryCode);
        const adminChartFiltersRequest = getFilters(countryCode);
        const getRestaurantsListRequest = (countryCode, restaurantListId) => getRestaurantsList(countryCode, restaurantListId);

        Promise.all([adminChartsRequest, adminChartsCategoriesRequest, adminChartFiltersRequest])
            .then(([charts, categories, allFilters]) => {
                if ( _isMounted.current ) {
                    setInstantCharts(charts.instantCharts);
                    setInstantChartsCategories(categories.filter(item => item.name !== null));
                    setInstantChartFilters({
                        tooltipsData: allFilters.tooltips,
                        raw: allFilters.filters,
                        isDataLoaded: true,
                        restaurantLists: allFilters.restaurantLists.map((el) => ({
                            ...el,
                            isActive: el.id === allFilters.defaultRestaurantListId,
                        })),
                        defaultRestaurantListId: allFilters.defaultRestaurantListId,
                        currentWords: allFilters.words
                    });
                }
                return allFilters;
            })
            .then(({defaultRestaurantListId}) => {
                getRestaurantsListRequest(countryCode, defaultRestaurantListId)
                    .then(data => {
                        if ( _isMounted.current )  {
                            setInstantChartRestaurants(data)
                        }
                    })
                    .finally(() => {
                        if ( _isMounted.current )  {
                            setIsLoading(false);
                        }
                    });
            });


        return () => {
            _isMounted.current = false;
            adminChartsRequest.abort();
            adminChartsCategoriesRequest.abort();
        }
    }, []);

    useEffect(() => {
        if (newChart && countries.length > 0 && _isMounted.current) {
            setCorrectCountry(!!currentCountry);
        }
    }, [countries, currentCountry, setCorrectCountry, newChart]);

    return (
        <div>
            {!isLoading && countries.length > 0 && filtersLoaded ? (
                correctCountry ? (
                    <AdminInstantChart
                        instantCharts={instantCharts}
                        allCategories={allCategories}
                        countries={countries}
                        newChart={newChart}
                        currentCountry={currentCountry}
                        filters={filters}
                        {...props}
                    />
                ) : (
                    <Redirect
                        to={{
                            pathname: Routes.AdminInstantCharts,
                            params: { openSelectCountryPopup: true },
                        }}
                    />
                )
            ) : (
                <Loader isShown={countries.length === 0 || isLoading || !filtersLoaded} />
            )}
        </div>
    );
}

const mapStateToProps = state => ({
    countries: countriesSelector(state),
    instantCharts: chartsSelector(state),
    keywords: chartWordsSelector(state),
    filters: filtersChartSelector(state),
    filtersLoaded: filtersIsLoadedSelector(state),
    allCategories: state.adminInstantChart.allCategories
});

const mapDispatchToProps = dispatch => ({
    setInstantCharts: data => dispatch(setField("allCharts", data)),
    setInstantChartsCategories: data => dispatch(setField("allCategories", data)),
    setInstantChartFilters: data => dispatch(setField("allFilters", data)),
    setInstantChartRestaurants: data => dispatch(setField("currentRestaurants", data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstantChartWrapper);
