import { combineReducers } from 'redux';
import app from './app.reducer';
import filters from './filters.reducer';
import countries from './countries.reducer';
import keywords from './keywords.reducer';
import byoSettings from './byoSettings.reducer';
import byo from './byo.reducer';
import notifications from './notifications.reducer';
import topTrends from './topTrends.reducer';
import adminKeywords from './adminKeywords.reducer';
import keywordSettings from './keywordSettings.reducer';
import adminInstantCharts from './adminInstantCharts.reducer';
import adminInstantChart from './adminInstantChart.reducer';
import adminReports from './adminReports.reducer';
import tags from './tags.reducers';

export default combineReducers({
    app,
    filters,
    countries,
    keywords,
    byo,
    byoSettings,
    notifications,
    keywordSettings,
    topTrends,
    adminKeywords,
    adminInstantCharts,
    adminInstantChart,
    adminReports,
    tags,
});
