import { FC, memo, useContext } from "react";
import { iViewBoxProps } from "@components/Chart/Chart.helpers";
import { ChartThemeContext } from "./ChartTheme.context";

export interface iAxisYPoint {
    title: string;
    y: number;
}

const ChartAxisY: FC<{
    data: iAxisYPoint[],
    viewport: iViewBoxProps;
}> = (
    {
        data,
        viewport,
    }
) => {
    const { THEME } = useContext(ChartThemeContext);
    return (
        <g
            name="axis-y"
            className="Chart__axis"
            style={{
                transform: `translateX(-${viewport.padding.left * 0.15}px)`,
            }}
        >
            {data.map((point, index) => (
                <text
                    key={index}
                    x={0}
                    y={point.y}
                    fill={THEME.TEXT.DEFAULT}
                    textAnchor="end"
                    alignmentBaseline="middle"
                    // style={{
                    //     transform: `translateY(100%)`,
                    // }}
                >
                    {point.title}
                </text>
            ))}
        </g>
    );
}

export default memo(ChartAxisY);