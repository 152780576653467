import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "./App.scss";
import BrowserRoutes, { Routes } from '@routes'
import { BrowserRouter as Router, Link } from "react-router-dom";
import { getData, setTheme } from '@actions/app.actions';
import Notification from "@containers/NotificationContainer/Notification";
import { setCurrent, setData } from "@actions/countries.actions";
import Loader from "../Loader/Loader";
import { getDefaultCountryCode, setColorTheme } from "./App.helpers";
import { getCountries } from "@core/api";
import { HelmetProvider } from 'react-helmet-async';
import useWindowSizeDebounced from "@hooks/useWindowSizeDebounced";
import { getResponsiveScale } from "@core/getResponsiveScale";
import { GlobalContext } from "./App.context";
import { AVAILABLE_COUNTRIES } from "@reducers/countries.reducer";
import { AppId, cssX, Env, GtmInit, PlatformContextProvider } from "@datassential/platform-ui-lib";
import cn from "classnames";

function AppContent() {
    const dispatch = useDispatch();
    const hasAdminAccess = useSelector(state => state.app.hasAdminAccess);
    const isLightTheme = useSelector((state) => state.app.isThemeLightEnable);

    const env = useMemo(
        () => {
            switch ((process.env.REACT_APP_PLATFORM_ENV || '').toLowerCase()) {
                case 'qa':
                    return Env.QA;
                case 'staging':
                    return Env.Staging;
                default:
                    return Env.Live;
            }
        },
        []
    );

    const userActions = useMemo(
        () => {
            const result = [
                {
                    id: 'theme',
                    content: (
                        <div
                            className={cn(cssX("Dropdown__item"), 'is-tall')}
                            onClick={() => dispatch(setTheme(!isLightTheme))}
                        >
                            {isLightTheme ? 'Dark Theme' : 'Light Theme'}
                        </div>
                    ),
                }
            ];

            if (hasAdminAccess) {
                result.push({
                    id: 'admin',
                    content: (
                        <Link
                            to={{ pathname: Routes.AdminKeywords }}
                            target="_blank"
                            className={cn(cssX("Dropdown__item"), 'is-tall')}
                        >
                            Tool Admin
                        </Link>
                    ),
                });
            }

            return result;
        },
        [hasAdminAccess, isLightTheme, dispatch]
    );

    return (
        <PlatformContextProvider
            appId={AppId.MenuTrends}
            env={env}
            enableDevTools={env !== Env.Live}
            additionalUserMenu={userActions}
        >
            {process.env.NODE_ENV !== 'development' && <GtmInit/>}
            <Router basename="/">
                <BrowserRoutes/>
            </Router>
        </PlatformContextProvider>
    );
}

function App({
    isAdminAccessLoading,
    getData,
    countries,
    setCurrentCountry,
    setCountries,
    isThemeLight,
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [wWidth] = useWindowSizeDebounced(200);

    const getRem = useCallback((num) => getResponsiveScale(num, wWidth), [wWidth]);

    useEffect(() => {
        const getCurrentCountries = async () => {
            const countries = await getCountries();
            setCountries(countries);
        };

        const isThemeLightEnable = localStorage.getItem('isThemeLightEnable') === "true";

        getCurrentCountries();
        setColorTheme(isThemeLightEnable);
        getData();
    }, []);

    useEffect(() => {
        setColorTheme(isThemeLight);
    }, [isThemeLight]);

    useEffect(() => {
        if (countries.isLoading) return;

        const currentCountryCode = sessionStorage.getItem('currentCountry');
        const currentCountry = countries.data
            .filter(i => AVAILABLE_COUNTRIES.includes(i.countryCode))
            .find(country => country.countryCode === currentCountryCode);

        const setDefaultCountryCode = () => {
            const countryCode = getDefaultCountryCode(countries.data || []);

            if (countryCode) {
                setCurrentCountry(countryCode);
            } else {
                window.location.href = process.env.REACT_APP_SNAP;
            }
        }

        if (currentCountryCode && currentCountry && currentCountry.hasAccess) {
            setCurrentCountry(currentCountryCode);
        } else {
            setDefaultCountryCode();
        }
    }, [countries]);

    useEffect(() => {
        setIsLoading(isAdminAccessLoading || countries.isLoading || !countries.current);
    }, [isAdminAccessLoading, countries.isLoading, countries.current]);

    return (
        <div className="App" id="App">
            <HelmetProvider>
                <GlobalContext.Provider
                    value={{
                        getRem
                    }}
                >
                    <Loader
                        isShown={isLoading}
                        noBg
                    />
                    {!isLoading && (
                        <AppContent/>
                    )}
                    <Notification/>
                </GlobalContext.Provider>
            </HelmetProvider>
        </div>
    );
}

const mapStateToProps = state => ({
    isAdminAccessLoading: state.app.isAdminAccessLoading,
    countries: state.countries,
    isThemeLight: state.app.isThemeLightEnable
});

const mapDispatchToProps = (dispatch) => ({
    getData: () => dispatch(getData()),
    setCurrentCountry: (countryCode) => dispatch(setCurrent(countryCode)),
    setCountries: (countries) => dispatch(setData(countries)),

});

export default connect(mapStateToProps, mapDispatchToProps)(App);
