import React, { useRef, useReducer, useEffect, useContext } from "react";
import PT from "prop-types";
import {
    getXGridCoordinates,
    getXCoordinates,
    getCoordinatesArray,
    getMinMax,
    recalculateHoverColumn,
    drawLines,
    drawGrid,
    drawHaikuText,
    drawYearLabels,
    drawValuesLabels,
    drawMarker,
    drawActiveColumn,
} from "./CanvasChart.helpers";
import ChartTooltip from "./ChartTooltip";
import "./CanvasChart.scss";
import { GlobalContext } from "@components/App/App.context";
import { IconHaiku } from "@icons";

const CanvasChart = (
    {
        width,
        height,
        coefficient,
        charts,
        hasPrediction,
        type,
        isLightTheme,
        activeTabId,
        chartDataType,
        onlyLine = false,
        currentCurrency,

    }
) => {
    const { getRem } = useContext(GlobalContext);
    const chartRef = useRef(null);
    const chartElement = chartRef.current;
    const ctx = chartElement ? chartElement.getContext("2d") : null;
    const colors = charts.chartInfo.map(i => i.color);
    const opacities = charts.chartInfo.map(i => i.opacity);

    useEffect(() => {
        const {min, max, getCoordinate} = getMinMax(charts, height, getRem);
        const xGridCoordinates = getXGridCoordinates(
            charts,
            hasPrediction,
            type,
            width,
            state.leftPadding,
            state.rightPadding
        );

        const xCoordinates = getXCoordinates(xGridCoordinates);
        const coordinatesArray = getCoordinatesArray(
            xCoordinates,
            getCoordinate,
            charts,
        );

        dispatch({
            min,
            max,
            xGridCoordinates,
            xCoordinates,
            coordinatesArray,
        });

    }, [ charts, width, height ]);

    const [ state, dispatch ] = useReducer(reducer, initialState);

    useEffect(() => {
        const verticalPadding = 30;
        const leftPadding = onlyLine ? 0 : 60;
        const rightPadding = onlyLine ? 0 : 10;
        dispatch({
            verticalPadding: getRem(verticalPadding),
            leftPadding: getRem(leftPadding),
            rightPadding: getRem(rightPadding),
        });
        if (ctx) {
            ctx.scale(2, 2);
        }
    }, [ width, height, ctx ]);

    const handlePointerEnter = () => {
        dispatch({
            hasCapture: true,
        });
    };

    const handlePointerLeave = () => {
        dispatch({
            hasCapture: false,
        });
    };

    const {
        xGridCoordinates,
        verticalPadding,
        leftPadding,
        rightPadding,
        hasCapture,
        activeColumnX1,
        activeColumnX2,
        tooltipWidth,
        xCoordinates,
        coordinatesArray,
    } = state;

    const draw = (renderLabels = true) => {
        ctx.clearRect(0, 0, width, height);
        if (!onlyLine) {
            drawGrid(
                ctx,
                coefficient,
                height,
                hasPrediction,
                xGridCoordinates,
                xCoordinates,
                isLightTheme,
            );
            hasCapture &&
            drawActiveColumn(
                ctx,
                height,
                activeColumnX1,
                activeColumnX2,
                isLightTheme,
                charts,
            );
            // hasPrediction && drawHaikuText(
            //     ctx,
            //     width,
            //     height,
            //     xGridCoordinates,
            //     xCoordinates,);

            drawYearLabels(ctx, xGridCoordinates, charts, height, isLightTheme, getRem);

            const suffix = activeTabId > 2 ? '' : '%';
            const prefix = activeTabId > 2 ? currentCurrency : '';

            // to hide them on animation
            if (renderLabels) {
                drawValuesLabels(ctx, charts, height, prefix, suffix, isLightTheme, getRem);
            }
        }

        drawLines(
            ctx,
            coordinatesArray,
            colors,
            opacities,
            height,
            width,
            hasPrediction,
            type,
            verticalPadding,
            leftPadding,
            rightPadding,
            onlyLine,
            isLightTheme,
            getRem,
        );
        hasCapture && drawMarker(ctx, coordinatesArray, colors, isLightTheme, state.dotIndex);
    };

    // const HaikuTooltip = (
    //     <div
    //         className="CanvasChart__haiku-wrap"
    //         style={{
    //             left: `${xGridCoordinates[xGridCoordinates.length - 5]}px`,
    //         }}
    //     >
    //         <HaikuLogo className="CanvasChart__haiku-logo"/>
    //         <div className="CanvasChart__haiku-popup">
    //             <div className="CanvasChart__haiku-popup-title">
    //                 Haiku prediction
    //             </div>
    //             <div className="CanvasChart__haiku-popup-description">
    //                 Haiku™ uses machine learning to accurately predict menu
    //                 penetration up to four years into the future.
    //             </div>
    //         </div>
    //     </div>
    // );

    if (ctx) {
        draw(true);
    }

    const eventHandlers = onlyLine ? {} : {
        onPointerEnter: handlePointerEnter,
        onPointerMove: recalculateHoverColumn(
            chartRef,
            xGridCoordinates,
            activeColumnX1,
            activeColumnX2,
            hasCapture,
            tooltipWidth,
            dispatch,
            charts,
            chartDataType,
            activeTabId
        ),
        onPointerLeave: handlePointerLeave,
    };
    return (
        <>
            <canvas
                ref={chartRef}
                className="CanvasChart"
                width={width * 2}
                height={height * 2}
                style={{
                    width,
                    height,
                }}
                {...eventHandlers}
            />
            {hasPrediction && <IconHaiku className="CanvasChart__haiku-logo" />}
            {hasCapture && !onlyLine && (
                <ChartTooltip
                    activeColumnX1={activeColumnX1}
                    activeColumnX2={activeColumnX2}
                    clientY={state.clientY}
                    tooltipWidth={state.tooltipWidth}
                    chartHeight={height}
                    data={state.tooltipData}
                />
            )}
        </>
    );
};

const initialState = {
    min: -Infinity,
    max: Infinity,
    proportion: 0,
    verticalPadding: 20,
    leftPadding: 50,
    rightPadding: 25,
    xGridCoordinates: [],
    xCoordinates: [],
    coordinatesArray: [],

    hasCapture: false,
    activeColumnX1: 0,
    activeColumnX2: 0,
    tooltipData: {
        title: "",
        values: [],
    },
    dotIndex: null,
    clientY: 0,
    clientX: 0,
    tooltipWidth: 135,
};

const reducer = (state, action) => ({
    ...state,
    ...action,
});

CanvasChart.propTypes = {
    width: PT.number,
    height: PT.number,
    coefficient: PT.number,
    charts: PT.shape({
        chartCount: PT.number,
        chartInfo: PT.arrayOf(
            PT.shape({
                name: PT.string,
                color: PT.string,
            })
        ),
        data: PT.arrayOf(
            PT.shape({
                label: PT.number,
                values: PT.arrayOf(PT.number),
            })
        ),
    }),
    hasPrediction: PT.bool,
    type: PT.string,
    isLightTheme: PT.bool,
    activeTabId: PT.number,
    chartDataType: PT.number,
};

export default CanvasChart;
