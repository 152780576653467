import { APP } from '../core/actionNames';

const { SET_FIELD, SET_THEME } = APP;

const initialState = {
    isThemeLightEnable: localStorage.getItem('isThemeLightEnable') === "true",
    hasAdminAccess: false,
    isAdminAccessLoading: true
};

export default function app(state = initialState, action) {
    switch(action.type) {
        case SET_FIELD: {
            return {
                ...state,
                [action.key]: action.value,
            }
        }
        case SET_THEME: {
            localStorage.setItem('isThemeLightEnable', action.value);
            return {
                ...state,
                isThemeLightEnable: action.value,
            };
        }
        default: {
            return {...state};
        }
    }
}