import { components } from "@apiSchema";

type API__FOOD_PROFILE_LTO = components['schemas']['Application.FoodProfile.Models.MenuItem'];

export interface iFoodProfileLTO {
    date: string;
    description: string;
    name: string;
    restaurant: string;
};

export class FoodProfileLTOData implements iFoodProfileLTO {
    static defaultData: iFoodProfileLTO = {
        date: '',
        description: '',
        name: '',
        restaurant: ''
    }

    date = FoodProfileLTOData.defaultData.date;
    description = FoodProfileLTOData.defaultData.description;
    name = FoodProfileLTOData.defaultData.name;
    restaurant = FoodProfileLTOData.defaultData.restaurant;

    constructor(data?: API__FOOD_PROFILE_LTO) {
        if ( data ) {
            this.mapFromApi(data);
        }
    }

    private setData(model: iFoodProfileLTO) {
        ({
            date: this.date,
            description: this.description,
            name: this.name,
            restaurant: this.restaurant,

        } = model);
    }

    private mapFromApi(data: API__FOOD_PROFILE_LTO) {
        this.setData({
            date: data.date || this.date,
            description: data.description || this.description,
            name: data.name || this.name,
            restaurant: data.restaurant || this.restaurant,
        })
    }
}