import React, { memo } from "react";
import {IconDrag} from "@icons";

function getStyle(style, snapshot) {
    // if (style.transform) {
    //     // const axisLockY = "translate(0px" + style.transform.slice(style.transform.indexOf(","), style.transform.length);
    //     // return {
    //     //     ...style,
    //     //     transform: axisLockY
    //     // };
    // }
    return style;
}
// ...getStyle(draggableProvided.draggableProps.style, draggableSnapshot),

const SortableTableListItem = ({ draggableProvided, draggableSnapshot, virtualStyle, isDragging, lockDragging = false, children }) => {
    return (
        <div
            {...draggableProvided.draggableProps}
            ref={draggableProvided.innerRef}
            className={`SortableTable__item`}
            style={{
                ...draggableProvided.draggableProps.style,
                ...virtualStyle,
            }}>

            {!lockDragging && (
                <span className={`SortableTable__draggable ${isDragging ? "is-dragging" : ""}`} {...draggableProvided.dragHandleProps}>
                    <IconDrag />
                </span>
            )}

            {children}
        </div>
    );
};

export default memo(SortableTableListItem);
