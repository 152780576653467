import React, { FC, memo, useContext } from "react";
import { iViewBoxProps } from "@components/Chart/Chart.helpers";
import { ChartThemeContext } from "./ChartTheme.context";

const ChartAxisX: FC<{
    labels: string[];
    viewport: iViewBoxProps;
    activeColumnIndex: number;
}> = (
    {
        labels,
        viewport,
        activeColumnIndex,
    }
) => {
    const columnWidth = viewport.width / labels.length;
    const { THEME } = useContext(ChartThemeContext);

    return (
        <g
            name="axis-x"
            className="Chart__axis"
            style={{
                transform: `translateY(${viewport.height}px)`,
            }}
        >

            {labels.map((label, index) => {
                const isActive = activeColumnIndex === index;
                return (
                    <React.Fragment key={index}>


                        <text
                            key={index}
                            x={columnWidth * (index + 0.5)}
                            y={viewport.padding.bottom / 2}
                            fill={`${isActive ? THEME.TEXT.ACTIVE : THEME.TEXT.DEFAULT}`}
                            textAnchor="middle"
                            alignmentBaseline="middle"
                        >
                            {label}
                        </text>
                    </React.Fragment>
                )
            })}
        </g>
    );
};

export default memo(ChartAxisX);