import { deepCopy, numericSortByField } from "@core/old_helpers";

function transformFilters(filters) {
    return Object.keys(filters).map(item => {
        const key = item;
        const value = filters[item];

        return {
            name: key,
            isChecked: value,
        };
    });
}

export function emptyReportData(countryCode = null) {
    return {
        id: 0,
        name: "",
        description: "",
        countryCode,
        isActive: false,
        searchQuery: "",
        menuItemRank: {
            appetizer: false,
            entree: false,
            side: false,
            dessert: false,
            beverage: false,
        },
        childReports: [],
        companies: [],
        childInstantCharts: [],
        instantChartsCategories: { categories: [], subCategories: [] },
        selectedInstantChartsCategories: [],
        selectedInstantChartsSubCategories: [],
    };
}

export function normalizeChildRepots(data) {
    return data.map(item => {
        const { id, category, subCategory, instantChartName, isInstantChartActive, name, generatorFilter } = item;
        return {
            instantChartName,
            category,
            subCategory,
            id,
            isInstantChartActive,
            name: name || "",
            isSelected: false,
            isExpanded: false,
            generatorFilter: transformFilters(generatorFilter),
        };
    });
}

export function getChildCategories(data) {
    let categories = [];
    let subCategories = [];

    data.forEach(item => {
        if (!categories.some(el => el.name === item.category)) {
            categories.push({
                name: item.category,
                id: item.category,
            });
        }

        if (!subCategories.some(el => el.name === item.subCategory)) {
            subCategories.push({
                name: item.subCategory,
                id: item.subCategory,
                parentId: item.category,
            });
        }
    });

    return {
        categories,
        subCategories,
    };
}

export function normalizeReport(data) {
    const {
        id,
        name,
        description,
        countryCode,
        isActive,
        childReports,
        companies,
        childInstantCharts,
        menuItemRank,
        ...charts
    } = data;

    const childs = childReports.map(child => {
        const generatorFilter = transformFilters(child.generatorFilter);

        return {
            ...child,
            isExpanded: false,
            generatorFilter,
        };
    });

    const childCategories = getChildCategories(childs);

    return {
        main: {
            id,
            name: name || "",
            description: description || "",
            countryCode,
            isActive,
        },
        menuItemRank: transformFilters(menuItemRank),
        childReports: childs,
        companies,
        childInstantCharts,
        childReportsCategories: childCategories,
        selectedChildReportsCategories: childCategories.categories,
        selectedChildReportsSubCategories: childCategories.subCategories,
        ...charts,
    };
}

export function getEmptyReportData(countryCode) {
    return normalizeReport(emptyReportData(countryCode));
}

export function normalizeReportFromBackEnd({ childReports, ...data }) {
    const empty = emptyReportData();

    return normalizeReport({
        ...empty,
        ...data,
        childReports: numericSortByField(childReports, "order"),
    });
}

export function normalizeReportForBackEnd(_data) {
    const data = deepCopy(_data);
    const {
        main: { id, name, description, countryCode, isActive, selectedCompanies },
        childReports,
        menuItemRank,
        companies,
    } = data;

    const reduceMenuItemRank = arr => {
        return arr.reduce((obj, item) => {
            return Object.assign(obj, { [item["name"]]: item["isChecked"] });
        }, {});
    };

    const updateCompaniesAccess = (data, selectedIds) => {
        return data.map(item => {
            return {
                ...item,
                hasAccess: selectedIds.includes(item.id) ? true : false,
            };
        });
    };

    const updateChildReports = data => {
        return data.map(({ isExpanded, isSelected, generatorFilter, ...item }, idx) => {
            return {
                ...item,
                generatorFilter: generatorFilter.reduce((acc, el) => ({ ...acc, [el.name]: el.isChecked }), {}),
                order: idx,
            };
        });
    };

    return {
        id,
        name: name.trim(),
        description: description.trim(),
        countryCode,
        isActive,
        childReportsCount: childReports.length,
        menuItemRank: reduceMenuItemRank(menuItemRank),
        companies: updateCompaniesAccess(companies, selectedCompanies),
        childReports: updateChildReports(childReports),
    };
}

export function validateReportData({ name, description, childReports }) {
    const emptyFields = [];
    const unselectedFilters = [];
    const unnamedReports = [];

    const getMessage = (arr, msg) => {
        return !!arr.length ? [`${arr.join(", ")} ${msg}.`] : [];
    };

    if (name.length === 0) {
        emptyFields.push("Name");
    }

    if (description.length === 0) {
        emptyFields.push("Description");
    }

    if (childReports.length === 0) {
        emptyFields.push("Child reports");
    } else {
        childReports.forEach(item => {
            const selectedFilters = Object.values(item.generatorFilter).reduce((a, b) => a + b, 0);

            if (item.name.length === 0) {
                unnamedReports.push(item.instantChartName);
            }

            if (!selectedFilters) {
                unselectedFilters.push(item.instantChartName);
            }
        });
    }

    const messages = [
        ...getMessage(emptyFields, "can't be blank"),
        ...getMessage(unselectedFilters, "must be selected at least one filter"),
        ...getMessage(unnamedReports, "PPT report name can't be blank"),
    ];

    return {
        errors: messages,
        valid: !messages.length,
    };
}
