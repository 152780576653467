import { createSelector } from "reselect";

export const currentCountrySelector = state => state.countries.current;
export const countriesSelector = state => state.countries.data;
const activeAnalyticsTypeSelector = state => state.byo.activeAnalyticsType;

export const currentCurrencySelector = createSelector(
    activeAnalyticsTypeSelector,
    currentCountrySelector,
    countriesSelector,
    (activeAnalyticsType, currentCountry, countries) => {
        const currentCountryData = countries.find(({countryCode}) => countryCode === currentCountry);
        if (currentCountryData && activeAnalyticsType ) return currentCountryData.currencyCharComponent;
        return '';
    }
);

export const currentCurrencyStringSelector = createSelector(
    currentCountrySelector,
    countriesSelector,
    (currentCountry, countries) => {
        const currentCountryData = countries.find(({countryCode}) => countryCode === currentCountry);

        if (currentCountryData) return currentCountryData.currency;
        return '';
    }
);

export const currentCountryWithPermissionsSelector = createSelector(
    currentCountrySelector,
    countriesSelector,
    (currentCountry, countries) => {
        const currentCountryData = countries.find(({countryCode}) => countryCode === currentCountry);
        if (currentCountryData) {
            return {
                hasFoodProfile: currentCountryData.hasFoodProfile,
                hasRtuReport: currentCountryData.hasRtuReport,
                hasInsertChart: currentCountryData.hasInsertChart,
                hasFiltersAll: currentCountryData.hasFiltersAll,
                hasLongTermTrend: currentCountryData.hasLongTermTrend,
                hasNewItemsOnly: currentCountryData.hasNewItemsOnly,
                hasReportData: currentCountryData.hasReportData,
                hasChart: currentCountryData.hasChart,
                hasGlobalSearchAllMenu: currentCountryData.hasGlobalSearchAllMenu,
                isTrial: currentCountryData.hasTrialAccess,
            }
        }
    }
);
