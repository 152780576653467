import { createSelector } from "reselect";
import { getDateFormat } from "@core/old_helpers";

const byoSettingsDataSelector = state => state.byoSettings.data;
const byoSettingsSearchQuerySelector = state => state.byoSettings.searchQuery;
const byoSettingsTakeSelector = state => state.byoSettings.take;
const currentCountrySelector = state => state.countries.current;

export const LOAD_BYO_SETTINGS_NO_DATA_TEXT = "No matching records found";


export const getTableDataSelector = createSelector(
    byoSettingsDataSelector,
    byoSettingsSearchQuerySelector,
    byoSettingsTakeSelector,
    (data, query, take) => {
        const cols = ["title", "description", "createdDate", "companyName"];
        const initialTableCols = cols.map(i => ({ cells: [] }));
        data.slice(0, take).forEach((curr, rowIdx) => {
            if (query.length > 0) {
                const hasMatch =
                    (curr.title &&
                        curr.title
                            .toLowerCase()
                            .includes(query.toLowerCase())) ||
                    (curr.description &&
                        curr.description
                            .toLowerCase()
                            .includes(query.toLowerCase()));

                if (!hasMatch) {
                    return;
                }
            }

            cols.forEach((col, colIdx) => {
                let content;

                if (colIdx === 2) {
                    content = getDateFormat(new Date(curr.createdDate));
                } else {
                    content = curr[col];
                }

                initialTableCols[colIdx] = {
                    ...initialTableCols[colIdx],
                    cells: [
                        ...initialTableCols[colIdx].cells,
                        { content, id: curr.id },
                    ],
                };
            });
        });
        if(initialTableCols[0].cells.length === 0) {
            initialTableCols.forEach((col, idx) => col.cells.push({content: idx === 0 ? "No matching records found" : ""}));
        }
        return initialTableCols;
    }
);


export const getItemsDataSelector = createSelector(
    byoSettingsDataSelector,
    byoSettingsSearchQuerySelector,
    currentCountrySelector,
    (data, query, currentCountry) => {
        const result = data
            .filter(item => {
                const title = (item.title || '').trim().toLowerCase();
                const description = (item.description || '').trim().toLowerCase();
                let isSearched = true;
                if (query.length) {
                    isSearched = title.indexOf(query.trim().toLowerCase()) !== -1 || (description && description.indexOf(query.trim().toLowerCase()) !== -1);
                }
                return item.countryCode === currentCountry && isSearched;
            })
            .map(item => {
            const date = new Date(item.createdDate);
            const makeTwoDigits = (number) => {
                return number < 10 ? '0'+number : number
            };
            const dateString = `${makeTwoDigits(date.getMonth() + 1)}/${makeTwoDigits(date.getDate())}/${date.getFullYear()}`;
            return {
                ...item,
                createdDate: dateString
            }
        });
        if(result.length === 0) {
            result[0] = { title: LOAD_BYO_SETTINGS_NO_DATA_TEXT };
        }
        return result;
    }
);
