import React from 'react';

function SettingsInput({ title, value, setInputTextField}) {

    return (
        <div className="settings-input">
            <div className="settings-input__title">{title}:</div>
            <div className="settings-input__input">
                <input
                    type="text"
                    placeholder="Enter keyword name"
                    value={value || ''}
                    onChange={(e) => setInputTextField(e.target.value)}
                />
            </div>
        </div>
    );
}

export default SettingsInput;