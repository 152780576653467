import { ADMIN_REPORTS } from "../core/actionNames";

const { SET_FIELD, SET_DATA, RESET } = ADMIN_REPORTS;

const getInitialState = {
    searchQuery: "",
    sortingDirection: "asc",
    sortingColumnType: "name",
    selectedCountryIds: [],
    data: null,
    isLoading: true
}

export default function adminReports(state = getInitialState, action) {
    switch (action.type) {
        case SET_FIELD: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case SET_DATA: {
            return {
                ...state,
                ...action.data,
            };
        }
        case RESET: {
            return getInitialState;
        }
        default: {
            return { ...state };
        }
    }
}
