import { FCX } from "@datassential/platform-ui-lib";
import cn from "classnames";
import './Tag.scss';

const Tag: FCX<{
    isActive?: boolean;
    onClick?: () => void;
    onRemove?: () => void;
    onMouseEnter?: () => void;
    onMouseMove?: () => void;
    onMouseLeave?: () => void;
    isRemoveBtnHidden?: boolean;
}> = ({
    isActive = false,
    onRemove,
    onClick,
    onMouseEnter,
    onMouseMove,
    onMouseLeave,
    isRemoveBtnHidden = false,
    className,
    testId,
    style,
    children,
}) => {
    return (
        <div
            className={cn(
                "Tag",
                className,
                isActive && "is-active",
                !isRemoveBtnHidden && 'is-p-right'
            )}
            data-testid={testId}
            style={{
                ...style,
                cursor: onClick ? 'pointer' : 'default',
            }}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
        >
            {children}
            {!isRemoveBtnHidden && (
                <div
                    className="Tag__remove-btn"
                    onClick={onRemove}
                    data-testid="buttonTagRemove"
                />
            )}
        </div>
    );
};

export default Tag;