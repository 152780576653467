import React, { useMemo, useRef, useState } from 'react';
import PT from "prop-types";
import AutoComplete from "@components/AutoComplete/AutoComplete";
import useOutsideClick from '@hooks/useOutsideClick';

function SettingsTextarea({
    title,
    value = '',
    setInputTextField,
    autoCompleteData,
}) {
    const inputContainer = useRef(null);
    const [isInputFocused, setIsInputFocused] = useState(false);

    const inputValue = useMemo(() => value || '', [value]);

    const isAutocompleteOpen = useMemo(() => {
        return isInputFocused && !!autoCompleteData.length && inputValue.length > 2
    }, [autoCompleteData.length, isInputFocused, inputValue.length]);

    const onChange = (value) => setInputTextField(value);
    const onToggleFocus = () => setIsInputFocused(!isInputFocused);

    useOutsideClick({
        ref: inputContainer,
        isDisabled: !isInputFocused,
        fn: () => onToggleFocus(),
    });

    return (
        <div className="settings-textarea">
            <div className="settings-textarea__title">{title}:</div>
            <div className="settings-textarea__input" ref={inputContainer}>
                <textarea
                    placeholder="Add description"
                    value={inputValue}
                    onChange={(e) => onChange(e.target.value)}
                    onFocus={onToggleFocus}
                />
                <AutoComplete
                    className="settings-textarea__autocomplete"
                    data={autoCompleteData}
                    shown={isAutocompleteOpen}
                    onClick={({ name }) => {
                        setInputTextField(name);
                        onToggleFocus();
                    }}
                    query={inputValue}
                />
            </div>
        </div>
    );
}

SettingsTextarea.propTypes = {
    title: PT.string,
    value: PT.string,
    setInputTextField: PT.func,
    autoCompleteData: PT.arrayOf(
        PT.shape({
            id: PT.number,
            name: PT.string,
        })
    ),
};

SettingsTextarea.defaultProps = {
    autoCompleteData: []
}

export default SettingsTextarea;