import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { setField } from "@actions/adminInstantCharts.actions";
import Page from "@components/Page/Page";
import { searchQuerySelector } from "@selectors/adminInstantCharts.selectors";
import AdminInstantChartsTable from "./AdminInstantChartsTable";
import AdminContainer from "@pages/Admin/Common/AdminContainer";
import SearchField from "@components/SearchField/SearchField";
import SelectCountryPopup from "@components/SelectCountryPopup/SelectCountryPopup";
import { countriesSelector } from "@selectors/countries.selector";
import { useNavigate, useLocation } from "react-router-dom";
import { Routes } from "@routes";
import serializeParams from "@core/serializeParams";
import { IconExport, IconXlsExport, IconXlsImport } from "@icons";
import useInstantChartsData from "./useInstantChartsData";
import Icon from "@components/Icon/Icon";
import { exportAdminInstantCharts } from "@core/api";
import http from "@core/http";
import apiConfig from "@apiConfig";
import { addNotification } from "@actions/notifications.actions";

const AdminInstantCharts = ({
    setSearchQuery,
    searchError,
    searchQuery,
    countries,
}) => {
    let location = useLocation();
    let history = useNavigate();
    const dispatch = useDispatch();
    const pushNotification = (notification) => dispatch(addNotification(notification));
    const [isAdding, setIsAdding] = useState(false);

    const { data, isLoading, handleRemove } = useInstantChartsData();

    useEffect(() => {
        if ( location.params ) {
            setIsAdding(location.params.openSelectCountryPopup);
        }
    }, [location]);

    const handleConfirmSelectedCountry = code => {
        if (code) {
            setIsAdding(false);

            history({
                pathname: Routes.AdminInstantChartAdd,
                search: serializeParams({
                    country: code,
                }),
            });
        }
    };

    const handleExportTopTrends = () => {
        const id = new Date().getTime();
        pushNotification({
            text: "Downloading...",
            id,
        });
        http.downloadFileXHRFromUrl(
            'GET',
            apiConfig.get('admin instant charts export')
        ).then(() => {
            pushNotification({
                text: "Download was successful",
                duration: 4000,
                success: true,
                id,
            });
        }).catch(err => {
            pushNotification({
                text: "Something went wrong.",
                error: true,
                id,
                duration: 4000,
            });
        });
    };

    const actions = (
        <>
            {/*<button disabled className="AdminContainer__btn AdminContainer__btn--circle">*/}
            {/*    <Icon>*/}
            {/*        <IconXlsImport />*/}
            {/*    </Icon>*/}
            {/*</button>*/}
            <button
                onClick={handleExportTopTrends}
                className="AdminContainer__btn AdminContainer__btn--circle"
            >
                <Icon>
                    <IconXlsExport />
                </Icon>
            </button>
            {/*<button*/}
            {/*    disabled*/}
            {/*    className="AdminContainer__btn AdminContainer__btn--circle AdminContainer__btn--inverse-hover"*/}
            {/*>*/}
            {/*    <Icon>*/}
            {/*        <IconExport />*/}
            {/*    </Icon>*/}
            {/*</button>*/}
            <button
                onClick={() => setIsAdding(true)}
                className="AdminContainer__btn AdminContainer__btn--add AdminContainer__btn--medium"
            >
                + ADD INSTANT CHART
            </button>
            <SelectCountryPopup
                data={countries}
                shown={isAdding}
                title="Please, choose only 1 country for your instant chart"
                handleConfirm={code => handleConfirmSelectedCountry(code)}
                closeModal={() => setIsAdding(false)}
            />
        </>
    );

    const search = (
        <SearchField
            searchQuery={searchQuery}
            searchError={searchError}
            setSearchQuery={setSearchQuery}
        />
    );

    const table = (
        <AdminInstantChartsTable
            isLoading={isLoading}
            tableData={data}
            onRemove={handleRemove}
        />
    );

    return (
        <Page isWrapped title="Instant Charts">
            <AdminContainer
                title="Instant Charts"
                search={search}
                actions={actions}
                content={table}
            />
        </Page>
    );
};

const mapStateToProps = state => {
    return {
        searchQuery: searchQuerySelector(state),
        countries: countriesSelector(state)
    };
};

const mapDispatchToProps = dispatch => ({
    setSearchQuery: query => dispatch(setField("searchQuery", query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminInstantCharts);
