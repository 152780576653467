import { FC } from "react";
import cn from "classnames";
import "./Icon.styles.scss";

type IconModifiers = "regular" | "flag" | "small" | "large" | "map";

interface iProps {
    type?: IconModifiers;
    className?: string;
}

const Icon: FC<iProps> = ({
    type = "regular",
    className,
    children,
    ...rest
}) => {
    return (
        <div
            className={cn("Icon", `Icon--${type}`, className)}
            {...rest}
        >
            {children}
        </div>
    );
};

export default Icon;