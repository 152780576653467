import {NOTIFICATIONS} from "../core/actionNames";

const {ADD, REMOVE} = NOTIFICATIONS;

const setNotification = notification => ({
    type: ADD,
    notification,
});

const setNotificationsArray = notifications => ({
    type: REMOVE,
    notifications,
});

export function addNotification(notification) {
    return (dispatch, getState) => {
        if (!notification.id) {
            notification.id = new Date().getTime();
        }
        const notifications = getState().notifications.data;
        const index = notifications.findIndex(item => item.id === notification.id);
        if (index >= 0) {
            const newData = [
                ...notifications.slice(0, index),
                notification,
                ...notifications.slice(index + 1),
            ];
            dispatch(setNotificationsArray(newData));
        } else {
            dispatch(setNotification(notification));
        }

        if (notification.duration) {
            setTimeout(() => {
                const notifications = getState().notifications.data;
                const newData = notifications.filter(item => item.id !== notification.id);
                dispatch(setNotificationsArray(newData));
            }, notification.duration)
        }
    }
}
