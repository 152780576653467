import { useData } from '@hooks/useData';
import { getAdminInstantCharts } from '@core/api';
import { ID, iOption } from '@models';
import { components } from '@apiSchema';

export interface iAdminInstantChart extends iOption {
    categoryId: ID;
    subCategoryId: ID;
    isActive: boolean;
}

export interface iAdminInstantChartsData {
    items: iAdminInstantChart[];
    categories: iOption[];
    subCategories: iOption[];
}

type Response = components['schemas']['APIs.Controllers.Admin.Models.InstantChartsListModel'];

async function getAdminInstantChartsData(countryCode: ID): Promise<iAdminInstantChartsData> {
    const result: Response = await getAdminInstantCharts();

    const items = (result.instantCharts || []).filter(item => item.countryCode === countryCode);
    const categories = (result?.initialData?.categoriesData || [])
        .filter(item => item.countryCode === countryCode);

    return {
        items: items.map(i => ({
            id: i.id || 0,
            title: i.name || '',
            categoryId: i.categoryId || 0,
            subCategoryId: i.subCategoryId || 0,
            isActive: i.isActive || false,
        })),
        categories: categories.map(i => ({
            id: i.id || 0,
            title: i.name || '',
        })),
        subCategories: categories.flatMap(i => (i.subCategories || []).map(ii => ({
            id: ii.id || 0,
            title: ii.name || '',
        })))
    };
}

export default function useAdminInstantChartsData(countryCode: ID) {
    return useData({
        items: [],
        categories: [],
        subCategories: [],
    }, getAdminInstantChartsData, countryCode);
}