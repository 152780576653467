import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Cell from "@components/NewAwesomeTable/Cell";
import HightlightChars from "@pages/Admin/Common/HightlightChars/HightlightChars";
import Flag from "@components/Flag/Flag";
import serializeParams from "@core/serializeParams";
import { Routes } from "@routes";
import { IconDelete, IconCheckSuccess, IconCloseFailure, IconEdit } from "@icons";
import Popup from "@components/Popup/Popup";
import { GlobalContext } from "@components/App/App.context";
import Icon from "@components/Icon/Icon";
import { capitalize } from "@core/old_helpers";


function AdminInstantChartsTableRow({
    data,
    onRemove
}) {
    const {
        id,
        name,
        countryName,
        isActive,
        flag,
        countryCode,
        renderName,
        renderCategoryName,
        renderSubCategoryName
    } = data;
    const { getRem } = useContext(GlobalContext);
    const [isRemovePopupOpen, setIsRemovePopupOpen] = useState(false);

    return (
        <>
            <Cell className="AdminTable__col AdminTable__col--large-extra">
                <span className="AdminTable__truncated" title={name}>
                    <HightlightChars name={renderName} />
                </span>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--fluid">
                <HightlightChars name={renderCategoryName} />
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--fluid">
                <HightlightChars name={renderSubCategoryName} />

            </Cell>
            <Cell className="AdminTable__col AdminTable__col--large">
                <Flag height={getRem(24)} compact component={flag}/>
                {countryName}
            </Cell>
            <Cell
                className="AdminTable__col AdminTable__col--medium"
                options="center no-pl"
            >
                {isActive ? <Icon><IconCheckSuccess/></Icon> : <Icon><IconCloseFailure/></Icon>}
            </Cell>
            <Cell
                className="AdminTable__col AdminTable__col--medium"
                options="no-p center"
            >
                <Link
                    to={{
                        pathname: Routes.AdminInstantChartEdit,
                        search: serializeParams({
                            id,
                            name,
                            country: countryCode
                        })
                    }}
                    className="AdminTable__btn">
                    <Icon type="small">
                        <IconEdit/>
                    </Icon>
                </Link>
                <button
                    className="AdminTable__btn"
                    onClick={() => setIsRemovePopupOpen(true)}
                >
                    <Icon type="small">
                        <IconDelete/>
                    </Icon>
                </button>
            </Cell>

            <Popup
                shown={isRemovePopupOpen}
                closeModal={() => setIsRemovePopupOpen(false)}
                title={`Delete a instant chart "${name.toUpperCase()}"`}
                btnModifier="red"
                applyBtnTitle="Delete"
                onConfirm={() => onRemove(id)}
            >
                <div className="Popup__text">
                    Are you sure you want to delete <span>{name.toUpperCase()}</span>?
                </div>
            </Popup>
        </>
    );
}

export default AdminInstantChartsTableRow;
