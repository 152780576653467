import React, { useEffect, useReducer, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { setField } from "@actions/adminInstantChart.actions";
import Button from "@components/Button/Button";
import Flag from "@components/Flag/Flag";
import Loader from "@components/Loader/Loader";
import MultipleInput from "@components/MultipleInput/MultipleInput";
import Page from "@components/Page/Page";
import Select from "@components/Select/Select";
import SubTitle from "@components/SubTitle/SubTitle";
import ToggleInput from "@components/ToggleInput/ToggleInput";
import { getAdminInstantChart, addAdminInstantChartsCategory, addAdminInstantChartsSubCategory, addAdminInstantChart, getAdminInstantChartsCategories } from "@core/api";
import { compareInLC, dynamicSort } from "@core/old_helpers";
import { Routes } from "@routes";
import { chartCategoriesToOptions, chartFiltersSelector } from "@selectors/adminInstantChart.selectors";
import { getEmptyOptionData, normalizeOptionFromBackEnd, normalizeOptionForBackEnd, SHOW_KEYWORD_TOOLTIP, normalizeFiltersData } from "./InstantChart.helpers";
import { Actions, reducer } from "./instantChartOptionsReducer";
import AdminFiltersContainer from "@containers/AdminFiltersContainer/AdminFiltersContainer";
import "@pages/Admin/Common/AdminContainer/AdminContainer.scss";
import "./InstantChart.styles.scss";
import { addNotification } from "@actions/notifications.actions";



function AdminInstantChart({
    currentCountry,
    newChart,
    countries,
    instantCharts,
    keywords,
    allCategoriesToOptions,
    setAllCategories,
    filters,
    setCurrentInstantChart,
    allCategories,
    setInstantChartsCategories,
    chartFilters,
    addNotification
}) {
    let location = useLocation();
    const query = new URLSearchParams(location.search);
    let history = useNavigate();
    const [id, setId] = useState(null);
    const [isLoaderShown, setIsLoaderShown] = useState(true);
    const [name, setName] = useState("");
    const [countryCode, setCountryCode] = useState(null);
    const [nameInputTouched, setNameInputTouched] = useState(false);
    const [nameInputValid, setNameInputValid] = useState(true);
    const [optionsData, dispatchOptionsAction] = useReducer(reducer, {});
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [showFiltersAll, setShowFiltersAll] = useState(false);
    const [hasFilterDescriptions, setHasFilterDescriptions] = useState(false);
    const [descriptionInputs, setDescriptionInputs] = useState({filterDescription: '', snapCartTitle: '', snapCartDescription: ''});
    const [sortKeywordsByName, setSortKeywordsByName] = useState(true);
    const [isSendLoaderShown, setIsSendLoaderShown] = useState(false);
    const _isMounted = useRef(true);

    useEffect(() => {
        const countryCode = query.get("country");
        setCountryCode(countryCode);


        if (newChart) {
            const masterOptionData = getEmptyOptionData(countryCode);

            dispatchOptionsAction({
                type: Actions.ADD,
                data: {
                    ...masterOptionData,
                    countryCodes: countries.map(country => country.countryCode),
                    currentSubCategories: [],
                    defaultRestaurantListId: chartFilters.defaultRestaurantListId
                },
            });
            setIsLoaderShown(false);
        } else {
            const instantChartId = parseInt(query.get("id"));
            const instantChartName = query.get("name");

            if (instantChartId && countryCode) {
                setId(instantChartId);
                setName(instantChartName || '');

                getAdminInstantChart(countryCode, instantChartId)
                    .then(data => {
                        const optionsData = normalizeOptionFromBackEnd(data);

                        if (_isMounted.current) {
                            setDescriptionInputs(state => ({
                                ...state,
                                snapCartTitle: optionsData.snapCartTitle || "",
                                snapCartDescription: optionsData.snapCartDescription || "",
                                filterDescription: optionsData.filterDescription || ""
                            }))

                            dispatchOptionsAction({
                                type: Actions.SET_DATA,
                                data: optionsData,
                            });

                            setCurrentInstantChart(data);
                        }

                    })
                    .finally(() => {
                        if (_isMounted.current) {
                            setIsLoaderShown(false);
                        }
                    });
            } else {
                history(Routes.AdminInstantCharts);
            }
        }

        return () => {
            _isMounted.current = false;
        }
    }, []);



    const handleValidName = () => {
        if (!nameInputTouched) return;
        const chartsNames = instantCharts
            .filter(
                item =>
                    item.countryCode === countryCode &&
                    item.categoryId === optionsData.categoryId &&
                    item.subCategoryId === optionsData.subCategoryId,
            )
            .filter(item => item.id !== optionsData.id)
            .map(item => item.name.trim());

        if (name.length > 0 && !chartsNames.some(chart => compareInLC(chart, name.trim()))) {
            setNameInputValid(true);
        } else {
            setNameInputValid(false);
        }
    }

    useEffect(() => {
        handleValidName();
    }, [name, optionsData.categoryId, optionsData.subCategoryId]);

    useEffect(() => {
        if ( optionsData.keywords ) {
            setSortKeywordsByName(false);
        }

        let extensions = [];

        let submitted =
            optionsData.filter &&
            optionsData.filter.words.map(item => {
                const { wordExtensions, ...word } = item;
                extensions = [...extensions, ...(wordExtensions ? wordExtensions : [])];
                return SHOW_KEYWORD_TOOLTIP ? item : { ...word };
            });

        submitted = submitted ? sortKeywordsByName ? submitted.sort(dynamicSort("name", "asc")) : submitted : [];

        handleChangeOptionNestedField("keywords", "extensions", extensions);
        handleChangeOptionNestedField("keywords", "submitted", submitted);
    }, [optionsData.filter, sortKeywordsByName]);

    useEffect(() => {
        const { categoryId } = optionsData;
        if ( categoryId ) {
            const currentCategory = allCategories.find(item => item.id === categoryId);
            let updateSubCategories = [];
            if ( currentCategory )  {
                updateSubCategories = [...currentCategory.subCategories];
                handleChangeOptionField("currentSubCategories", updateSubCategories);
            }
        }
    }, [optionsData.categoryId, allCategories]);

    useEffect(() => {
        setSaveDisabled(
            !name.length || !nameInputValid || !optionsData.categoryId || !optionsData.subCategoryId || !optionsData.filter.words.length || isSendLoaderShown
        );
    }, [name, nameInputValid, optionsData.categoryId, optionsData.subCategoryId, optionsData.filter, isSendLoaderShown]);

    useEffect(() => {
        setShowFiltersAll(currentCountry.hasFiltersAll || false);
        setHasFilterDescriptions(currentCountry.hasFilterDescriptions || false);
    }, [currentCountry])

    useEffect(() => {
        return () => {
            setCurrentInstantChart({});
        }
    }, []);

    const handleChangeOptionField = (fieldName, value) => {
        dispatchOptionsAction({
            type: Actions.EDIT_FIELD,
            fieldName,
            value,
        });
    };

    const handleChangeOptionNestedField = (sectionName, fieldName, value) => {
        dispatchOptionsAction({
            type: Actions.EDIT_NESTED_FIELD,
            sectionName,
            fieldName,
            value,
        });
    };

    const handleNameChange = e => {
        if (!nameInputTouched) {
            setNameInputTouched(true);
        }

        setName(e.target.value.toUpperCase());
    };

    const handleDescriptionFieldsChange = (fieldName, isUpperCase = false) => (
        ({target}) => {
            setDescriptionInputs(state => (
                {
                    ...state,
                    [fieldName]: isUpperCase ? target.value.toUpperCase() : target.value
                }
            ))
        }
    );

    const showNotification = (message, type) => {
        addNotification({
            text: message,
            duration: 5000,
            [type]: true
        });
    };

    const showErrorNotification = (message) => showNotification(message, "error");
    const showSuccessNotification = (message) => showNotification(message, "success");

    const handleSubmitKeywords = words => {
        handleChangeOptionNestedField(
            "filter",
            "words",
            [...optionsData.filter.words, ...words]);
    };

    const handleRemoveKeyword = word => {
        handleChangeOptionNestedField(
            "filter",
            "words",
            optionsData.filter.words.filter(({ name }) => name !== word),
        );
    };

    const handleSelectedCategory = value => {
        handleSelectedSubCategory(null);
        handleChangeOptionField("categoryId", value);

    };

    const handleSelectedSubCategory = value => {
        handleChangeOptionField("subCategoryId", value);
    };

    const handleNewCategory = value => {
        const newCategoryForBackend = {
            id: 0,
            name: value,
            order: 0,
            countryCode: countryCode,
            subCategories: [],
        };

        setIsSendLoaderShown(true);

        getAdminInstantChartsCategories(countryCode).then(categories => {
            const categoryNameAlreadyExist = categories.some(({ name }) => compareInLC(name, value));

            if (categoryNameAlreadyExist) {
                showErrorNotification(`Category "${value}" already exist`);
                setInstantChartsCategories(categories.filter(item => item.name !== null));
                handleSelectedCategory(null);
                setIsSendLoaderShown(false);
            } else {
                addAdminInstantChartsCategory(newCategoryForBackend).then(currentCategoryId => {
                    getAdminInstantChartsCategories(countryCode)
                        .then(categories => {
                            setInstantChartsCategories(categories.filter(item => item.name !== null));
                        })
                        .then(() => {
                            handleSelectedCategory(currentCategoryId);
                            showSuccessNotification(`Category "${value}" successful created`);
                            setIsSendLoaderShown(false);
                        });
                });
            }
        })
        .catch(() => {
            showErrorNotification(`Something went wrong`);
            setIsSendLoaderShown(false);
        });
    };

    const handleNewSubCategory = value => {
        let newSubCategoryForBackend = {
            id: 0,
            parentId: optionsData.categoryId,
            name: value,
            order: 0,
        };

        setIsSendLoaderShown(true);
        getAdminInstantChartsCategories(countryCode).then(categories => {
            let currentCategory = categories.find(({id}) => id === optionsData.categoryId);
            let subCategoryNameAlreadyExist = currentCategory.subCategories.some(({name}) => compareInLC(name, value));

            if ( subCategoryNameAlreadyExist ) {
                showErrorNotification(`Subcategory "${value}" already exist`);
                setInstantChartsCategories(categories.filter(item => item.name !== null));
                handleSelectedSubCategory(null);
                setIsSendLoaderShown(false);
            } else {
                addAdminInstantChartsSubCategory(newSubCategoryForBackend).then(currentSubCategoryId => {
                    getAdminInstantChartsCategories(countryCode)
                        .then(categories => {
                            setInstantChartsCategories(categories.filter(item => item.name !== null));
                        })
                        .then(() => {
                            handleSelectedSubCategory(currentSubCategoryId);
                            showSuccessNotification(`Subcategory "${value}" successful created`);
                            setIsSendLoaderShown(false);
                        });
                });
            }
        })
        .catch(() => {
            showErrorNotification(`Something went wrong`);
            setIsSendLoaderShown(false);
        });
    };

    const sendData = (categoryId, subCategoryId) => {
        setIsSendLoaderShown(true);
        const data = normalizeOptionForBackEnd(categoryId, subCategoryId, optionsData, id, name, descriptionInputs);
        addAdminInstantChart(data)
            .catch(err => console.log(err))
            .finally(() => {
                setIsSendLoaderShown(false);
                showSuccessNotification(`Instant chart successful ${newChart ? "created" : "saved"}`);
                history(Routes.AdminInstantCharts);
            });
    };

    // Handle save
    const handleSave = () => {
        sendData(optionsData.categoryId, optionsData.subCategoryId);
    };

    const getFiltersData = (data) => {
        handleChangeOptionField("settings", normalizeFiltersData(data));
    }

    return (
        <Page isWrapped title={newChart ? "New Instant chart" : "Edit Instant chart"}>
            <div className="AdminContainer AdminContainer--no-pt">
                <div className="AdminContainer__content">
                    <Loader className="is-fixed" isShown={isLoaderShown || isSendLoaderShown} />

                    {!isLoaderShown && (
                        <>
                            {/* Title */}
                            <div className="AdminContainer__title">
                                {newChart ? "Add" : "Edit"} Instant charts
                                <div className="AdminContainer__title-info">
                                    <span>
                                        <Flag compact component={currentCountry.component} />
                                    </span>
                                    <span>{currentCountry.countryCode}</span>
                                </div>
                            </div>

                            {/* Name + actions */}
                            <div className="AdminContainer__top-title is-fixed">
                                <div className="AdminContainer__top">
                                    <div className="AdminContainer__top-name">
                                        <label className="AdminContainer__input-wrap">
                                            <span className={`AdminContainer__input-label is-required ${name.length > 0 ? "is-upper" : "is-bottom"}`}>Instant chart name</span>
                                            <input
                                                type="text"
                                                className="AdminContainer__input"
                                                value={name}
                                                onChange={e => handleNameChange(e)}
                                            />
                                            {!nameInputValid && (
                                                <div className="AdminContainer__error">
                                                    {name.length === 0
                                                        ? "The instant chart's name can't be blank"
                                                        : "The instant chart's name already exist"}
                                                </div>
                                            )}
                                        </label>
                                    </div>

                                    <div className="AdminContainer__btns">
                                        <Link to={Routes.AdminInstantCharts} className="btn btn--link">
                                            Discard
                                        </Link>
                                        <button
                                            className={`btn btn--filled ${saveDisabled ? "AdminContainer__btn--disabled" : ""}`}
                                            disabled={saveDisabled}
                                            onClick={handleSave}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="AdminContainer__main">
                                <div className="InstantChart">
                                    {/* Published + Copy attributes */}
                                    <div className="InstantChart__row InstantChart__row--right">
                                        <div className="InstantChart__top-actions">
                                            <div className="InstantChart__top-actions-item">
                                                <ToggleInput
                                                    onToggle={() => {
                                                        handleChangeOptionField("isActive", !optionsData.isActive);
                                                    }}
                                                    isChecked={optionsData.isActive}
                                                    className="InstantChart__toggle-input"
                                                    labelText="Published"
                                                />
                                            </div>
                                            <div className="InstantChart__top-actions-item">
                                                <div className="InstantChart__button">
                                                    <Button isDisabled modifiers={["white", "rounded", "fluid"]}>Copy attributes</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Keywords + Category + Subcategory */}
                                    <div className="InstantChart__row">
                                        <div className="InstantChart__section">
                                            {/* Keywords */}
                                            <div className="InstantChartSection">
                                                <div className="InstantChartSection__title">
                                                    <SubTitle textColor="yellow">Keywords</SubTitle>
                                                </div>
                                                <div className="InstantChartSection__row">
                                                    <div className="InstantChartSection__column InstantChartSection__column--limit-z-index">
                                                        <MultipleInput
                                                            onSubmit={words => handleSubmitKeywords(words)}
                                                            removeItem={word => handleRemoveKeyword(word)}
                                                            keywords={keywords}
                                                            submittedItems={optionsData.keywords.submitted}
                                                            words={optionsData.keywords.submitted}
                                                            placeholder="Type keyword"
                                                            modifiers={["light", "large"]}
                                                            allowArrowKeys
                                                            showCounter
                                                            isUpperCase
                                                        />
                                                    </div>

                                                    <div className="InstantChartSection__column InstantChartSection__column--fluid">
                                                        <div className="InstantChartSection__row">
                                                            {/* Category */}
                                                            <div className="InstantChartSection__column InstantChartSection__column--fixed InstantChartSection__column--selects">
                                                                <Select
                                                                    options={allCategoriesToOptions}
                                                                    value={optionsData.categoryId}
                                                                    onChange={value => handleSelectedCategory(value)}
                                                                    hasSearchInput
                                                                    searchInputPlaceholder="Search or add"
                                                                    placeholder="Interactive category"
                                                                    title=""
                                                                    isCheckboxHidden
                                                                    onAdd={value => handleNewCategory(value)}
                                                                    dynamicOption
                                                                    dynamicOptionLabel="Add new category: "
                                                                    inheritedStyles
                                                                    customScrollbar
                                                                    dropdownAlwaysBottom
                                                                />
                                                            </div>

                                                            {/* Subcategory */}
                                                            <div className="InstantChartSection__column InstantChartSection__column--fixed InstantChartSection__column--selects">
                                                                <Select
                                                                    options={optionsData.currentSubCategories}
                                                                    value={optionsData.subCategoryId}
                                                                    onChange={value => handleSelectedSubCategory(value)}
                                                                    onAdd={value => handleNewSubCategory(value)}
                                                                    hasSearchInput
                                                                    searchInputPlaceholder="Search or add"
                                                                    placeholder="Interactive subcategory"
                                                                    title=""
                                                                    isCheckboxHidden
                                                                    dynamicOption
                                                                    dynamicOptionLabel="Add new subcategory: "
                                                                    isDisabled={!optionsData.categoryId}
                                                                    inheritedStyles
                                                                    customScrollbar
                                                                    dropdownAlwaysBottom
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* Description */}
                                                        {hasFilterDescriptions && (
                                                            <div className="InstantChartSection__row">
                                                                <div className="InstantChartSection__column InstantChartSection__column--block">
                                                                    <label className="InstantChartSection__input-wrap">
                                                                        <input
                                                                            type="text"
                                                                            className="InstantChartSection__input"
                                                                            placeholder={"Filter description"}
                                                                            name="filterDescription"
                                                                            value={descriptionInputs.filterDescription}
                                                                            onChange={handleDescriptionFieldsChange('filterDescription')}
                                                                        />
                                                                    </label>
                                                                </div>

                                                                <div className="InstantChartSection__column InstantChartSection__column--block">
                                                                    <label className="InstantChartSection__input-wrap">
                                                                        <input
                                                                            type="text"
                                                                            className="InstantChartSection__input"
                                                                            placeholder={"Snap instant chart title"}
                                                                            name="snapCartTitle"
                                                                            value={descriptionInputs.snapCartTitle.toUpperCase()}
                                                                            onChange={handleDescriptionFieldsChange('snapCartTitle', true)}
                                                                        />
                                                                    </label>
                                                                </div>

                                                                <div className="InstantChartSection__column InstantChartSection__column--block">
                                                                    <label className="InstantChartSection__input-wrap">
                                                                        <input
                                                                            type="text"
                                                                            className="InstantChartSection__input"
                                                                            placeholder={"Snap instant chart description"}
                                                                            name="snapCartDescription"
                                                                            value={descriptionInputs.snapCartDescription}
                                                                            onChange={handleDescriptionFieldsChange('snapCartDescription')}
                                                                        />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="InstantChart__row">
                                        <div className="InstantChart__section">
                                            <div className="InstantChartSection">
                                                <AdminFiltersContainer currentCurrency={currentCountry.currencyCharComponent} showFiltersAll={showFiltersAll} getFiltersData={getFiltersData} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Page>
    );
}

const mapStateToProps = state => ({
    allCategoriesToOptions: chartCategoriesToOptions(state),
    chartFilters: chartFiltersSelector(state)
});

const mapDispatchToProps = dispatch => ({
    setAllCategories: data => dispatch(setField("allCategories", data)),
    setCurrentInstantChart: data => dispatch(setField("currentInstantChart", data)),
    setInstantChartsCategories: data => dispatch(setField("allCategories", data)),
    addNotification: notification => dispatch(addNotification(notification))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminInstantChart);
