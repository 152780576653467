import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSpring, animated, easings } from 'react-spring';
import './CircularProgressBar.scss';
import { ID } from "@models";

const GRADIENT_DEFAULT_DARK: iStopPoint[] = [
    { offset: 0, color: '#2C303E', },
    { offset: 70, color: '#4794FF', },
    { offset: 100, color: '#4794FF', },
];

const GRADIENT_DEFAULT_LIGHT: iStopPoint[] = [
    { offset: 0, color: '#246FD8', },
    { offset: 15, color: '#246FD8', },
    { offset: 70, color: '#5DADF6', },
    { offset: 100, color: '#5DADF6', },
];

export interface iStopPoint {
    offset: number;
    color: string;
}

const CircularProgressBar: FC<{
    id: ID;
    value: number;
    renderValueLabel?: boolean;
    width: number;
    lineWidth?: number;
    customGradient?: iStopPoint[];
    style?: CSSProperties;
}> = (
    {
        id,
        value,
        renderValueLabel = false,
        width,
        customGradient,
        children,
        lineWidth = 15,
        style,
    }
) => {
    const [previousValue, setPreviousValue] = useState(0);
    const isThemeLightEnable = useSelector((state: any) => state.app.isThemeLightEnable);

    const defaultGradient = isThemeLightEnable ? GRADIENT_DEFAULT_LIGHT : GRADIENT_DEFAULT_DARK;
    const stopPoints = customGradient || defaultGradient;
    const r = useMemo(
        () => width / 2 - lineWidth / 2,
        [width, lineWidth]
    );

    const p = useMemo(
        () => 2 * Math.PI * r,
        [r],
    );

    const getValueP = useCallback(
        (v) => p * (v / 100),
        [p]
    );

    const dasharraySpring = useSpring({
        from: { strokeDasharray: `${getValueP(previousValue)}, ${p - getValueP(previousValue)}`},
        to: { strokeDasharray: `${getValueP(value)}, ${p - getValueP(value)}`},
        delay: 300,
        config: { duration: 600,  easing: easings.easeInOutCubic },
    });

    const labelSpring = useSpring({
        from: { v: previousValue },
        v: value,
        delay: 300,
        config: { duration: 600,  easing: easings.easeInOutCubic },
    });

    useEffect(() => {
        return () => {
            setPreviousValue(value);
        };
    }, [value]);

    return (
        <div
            className="CircularProgressBar"
            style={{
                ...style,
                width,
                height: width,
            }}
        >
            <svg viewBox={`0 0 ${width} ${width}`} width={width} height={width}>
                <defs>
                    <linearGradient
                        id={`${id}`}
                        x1="0%"
                        y1="0%"
                        x2="50%"
                        y2="100%"
                        gradientUnits="userSpaceOnUse"
                    >
                        {stopPoints.map(point => (
                            <stop
                                offset={point.offset}
                                stopColor={point.color}
                            />
                        ))}
                    </linearGradient>
                </defs>
                <circle
                    cx={width / 2}
                    cy={width / 2}
                    r={r}
                    fill="none"
                    stroke={isThemeLightEnable ? '#D2D8E2' : '#2C303E'}
                    strokeWidth={lineWidth}
                />
                <animated.circle
                    cx={width / 2}
                    cy={width / 2}
                    r={r}
                    fill="none"
                    stroke={`url(#${id})`}
                    strokeWidth={lineWidth}
                    strokeLinecap="round"
                    style={dasharraySpring}
                    strokeDashoffset={p / 4}
                />
            </svg>
            {renderValueLabel && (
                <div className="CircularProgressBar__label">
                    {value > 0 ? '+' : ''}
                    <animated.div>
                        {labelSpring.v.to(v => v.toFixed(1))}
                    </animated.div>
                    %
                </div>
            )}
            {children}
        </div>
    )
};

export default CircularProgressBar;