import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AdminContainer from '@pages/Admin/Common/AdminContainer';
import SearchField from '@components/SearchField/SearchField';
import SelectCountryPopup from '@components/SelectCountryPopup/SelectCountryPopup';
import { countriesSelector } from '@selectors/countries.selector';
import { Routes } from '@routes';
import serializeParams from '@core/serializeParams';
import Page from '@components/Page/Page';
import AdminCategoryReportsTable from '@pages/Admin/CategoryReports/AdminCategoryReportsTable';

const AdminCategoryReports: FC = () => {
    const history = useNavigate();
    const countries = useSelector(state => countriesSelector(state));
    const [searchQuery, setSearchQuery] = useState('');
    const [isAdding, setIsAdding] = useState(false);

    const search = useMemo(() => (
        <SearchField
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
        />
    ), [searchQuery]);

    const handleConfirmSelectedCountry = (code: string) => {
        if (code) {
            setIsAdding(false);

            history({
                pathname: Routes.AdminCategoryReportAdd,
                search: serializeParams({
                    country: code,
                }),
            });
        }
    };

    const actions = (
        <>
            <button
                onClick={() => setIsAdding(true)}
                className="AdminContainer__btn AdminContainer__btn--add AdminContainer__btn--medium">
                + ADD NEW REPORT
            </button>
            <SelectCountryPopup
                data={countries}
                shown={isAdding}
                handleConfirm={handleConfirmSelectedCountry}
                closeModal={() => setIsAdding(false)}
                title="Please, choose only 1 country for your report"
            />
        </>
    );

    const content = <>
        <AdminCategoryReportsTable
            searchQuery={searchQuery}
        />
    </>;

    return (
        <Page isWrapped title="Category Reports">
            <AdminContainer
                title="Category Overview Reports"
                search={search}
                actions={actions}
                content={content}
            />
        </Page>
    );
};

export default AdminCategoryReports;