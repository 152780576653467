import React from "react";
import "./ImageList.styles.scss"
import apiConfig from "@core/apiConfig";

const Image = ({ image, handleRemoveImg }) => {
    return (
        <div className="ImageList__file-item">
            <img
                alt={`pic-${image}`}
                src={`${apiConfig.get('admin keyword image', { id: image })}`}
                className="ImageList__file-img"
            />
            <button
                className="ImageList__file-item-remove"
                onClick={() => handleRemoveImg(image)}
            />
        </div>
    );
};

const ImageList = ({ images, btn, handleRemoveImg }) => {

    const renderImage = (image, index) => {
        return (
            <Image
                handleRemoveImg={handleRemoveImg}
                image={image}
                key={`${image.id}-image`}
            />
        );
    };

    return <section className="ImageList__file-list">
        {images.map(renderImage)}
        {btn}
    </section>;
};

export default ImageList;