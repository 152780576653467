import React, { useState, useEffect, createRef, useContext, useMemo, useCallback, memo } from "react";
import { FixedSizeList } from 'react-window';
import CustomScrollbarsVirtualList from "@components/CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";
import { useDispatch, useSelector } from "react-redux";
import PT from "prop-types";
import Modal from "@components/Modal/Modal";
import AutoSizer from 'react-virtualized-auto-sizer';
import "./LoadByoSettingsContainer.scss";
import * as byoSettingsActions from "@actions/byoSettings.actions";
import { getItemsDataSelector, LOAD_BYO_SETTINGS_NO_DATA_TEXT } from "@selectors/byoSettings.selectors";
import Cell from "@components/NewAwesomeTable/Cell";
import Search from "@components/Search/Search";
import ConfirmationPopup from "@components/ConfirmationPopup/ConfirmationPopup";
import LineEllipsis from "@components/LinesEllipsis/LinesEllipsis";
import { IconDelete, IconSortASC, IconSortDESC } from "@icons";
import { GlobalContext } from "@components/App/App.context";
import Icon from "@components/Icon/Icon";
import { deepCopy, highlightMatch } from "@core/old_helpers";

const ROW_HEIGHT = 50;

const LoadByoSettingsContainer = (
    {
        shown,
        closeModal,
    }
) => {
    const { getRem } = useContext(GlobalContext);
    const dispatch = useDispatch();
    const rawItems = useSelector(getItemsDataSelector);
    const searchQuery = useSelector(state => state.byoSettings.searchQuery);

    const deleteSettingsById = useCallback(
        id => dispatch(byoSettingsActions.deleteSettingsById(id)),
        [dispatch]
    );
    const getById = useCallback(
        id => dispatch(byoSettingsActions.getById(id)),
        [dispatch]
    );
    const setField = useCallback(
        (name, value) => dispatch(byoSettingsActions.setField(name, value)),
        [dispatch]
    );

    useEffect(() => {
        if (!shown) {
            setTimeout(() => setField("searchQuery", ""), 200); // timeout for popup fade-out animation
        }
    }, [shown]);

    const [removeConfirmationShown, setRemoveConfirmationShown] = useState(false);
    const [sortDirection, setSortDirection] = useState('desc');
    const [sortColumn, setSortColumn] = useState('createdDate');
    const [idForDelete, setIdForDelete] = useState(-1);

    const listRef = createRef();

    const items = useMemo(() => {
        const itemsCopy = deepCopy(rawItems);
        itemsCopy.sort((a, b) => {
            const mult = sortDirection === 'asc' ? 1 : -1;
            if (sortColumn === 'createdDate') {
                return (new Date(a[sortColumn])).getTime() > (new Date(b[sortColumn])).getTime() ? mult : -mult;
            }
            return (a[sortColumn] || '').trim().toLowerCase() > (b[sortColumn] || '').trim().toLowerCase() ? mult : -mult;
        });
        return itemsCopy;
    }, [rawItems, sortColumn, sortDirection]);

    const handleHeaderCellClick = (columnName) => {
        if (columnName === sortColumn) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortDirection('asc');
            setSortColumn(columnName);
        }
    };

    const onEditClick = id => () => {
        getById(id);
        closeModal();
    };

    const onRemoveClick = id => () => {
        setRemoveConfirmationShown(true);
        setIdForDelete(id);
    };

    const handleRemoveConfirm = () => {
        deleteSettingsById(idForDelete);
    };

    const renderButtons = cell => (
        <>
            {cell.content}
            <span className="LoadByoSettingsContainer__buttons-wrap">
                <button onClick={onEditClick(cell.id)}
                        className="LoadByoSettingsContainer__button LoadByoSettingsContainer__button--edit">
                    Open
                </button>
                <button onClick={onRemoveClick(cell.id)} className="LoadByoSettingsContainer__button">
                    <Icon>
                        <IconDelete className="LoadByoSettingsContainer__close-icon"/>
                    </Icon>
                </button>
            </span>
        </>
    );

    return (
        <>
            <Modal shown={shown} hasOverlay closeModal={closeModal} closeOnOutsideClick>
                <div
                    style={{ 'height': items[0].title === LOAD_BYO_SETTINGS_NO_DATA_TEXT && '30vh' }}
                    className="LoadByoSettingsContainer">
                    <div className="LoadByoSettingsContainer__search-wr">
                        <Search
                            searchQuery={searchQuery}
                            setSearchQuery={(val) => setField("searchQuery", val)}
                            placeholder="Find a report"
                            showIcon
                        />
                    </div>

                    <div className="MenuExamplesPopup__header">
                        <div className="MenuExamplesPopup__row">
                            <Cell
                                className="LoadByoSettingsContainer__column"
                                options="head white hv"
                                onClick={() => handleHeaderCellClick('title')}
                            >
                                {sortColumn === 'title' && sortDirection === 'asc' && (
                                    <Icon type="small" className="MenuExamplesPopup__icon">
                                        <IconSortASC/>
                                    </Icon>
                                )}
                                {sortColumn === 'title' && sortDirection === 'desc' && (
                                    <Icon type="small" className="MenuExamplesPopup__icon">
                                        <IconSortDESC/>
                                    </Icon>
                                )}
                                Name
                            </Cell>
                            <Cell
                                className="LoadByoSettingsContainer__column"
                                options="head white"
                            >
                                Description
                            </Cell>
                            <Cell
                                className="LoadByoSettingsContainer__column"
                                options="head white hv"
                                onClick={() => handleHeaderCellClick('createdDate')}
                            >
                                {sortColumn === 'createdDate' && sortDirection === 'asc' && (
                                    <Icon type="small" className="MenuExamplesPopup__icon">
                                        <IconSortASC/>
                                    </Icon>
                                )}
                                {sortColumn === 'createdDate' && sortDirection === 'desc' && (
                                    <Icon type="small" className="MenuExamplesPopup__icon">
                                        <IconSortDESC/>
                                    </Icon>
                                )}
                                Date
                            </Cell>
                            <Cell
                                className="LoadByoSettingsContainer__column"
                                options="head white hv"
                                onClick={() => handleHeaderCellClick('companyName')}
                            >
                                {sortColumn === 'companyName' && sortDirection === 'asc' && (
                                    <Icon type="small" className="MenuExamplesPopup__icon">
                                        <IconSortASC/>
                                    </Icon>
                                )}
                                {sortColumn === 'companyName' && sortDirection === 'desc' && (
                                    <Icon type="small" className="MenuExamplesPopup__icon">
                                        <IconSortDESC/>
                                    </Icon>
                                )}
                                Saved by
                            </Cell>
                        </div>
                    </div>
                    <div className="MenuExamplesPopup__table">
                        {items[0].title === LOAD_BYO_SETTINGS_NO_DATA_TEXT &&
                            <div className="MenuExamplesPopup__not-found">
                                {items[0].title}
                            </div>
                        }

                        <AutoSizer>
                            {({ height, width }) => {
                                return (
                                    <FixedSizeList
                                        ref={listRef}
                                        height={height}
                                        width={width}
                                        itemSize={getRem(ROW_HEIGHT)}
                                        itemCount={items.length}
                                        outerElementType={CustomScrollbarsVirtualList}
                                    >
                                        {({ index, style }) => (
                                            <div className="LoadByoSettingsContainer__row" style={style}>
                                                <Cell
                                                    className="LoadByoSettingsContainer__column"
                                                    options="gray bold first gray"
                                                >
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: items[index].title !== LOAD_BYO_SETTINGS_NO_DATA_TEXT && highlightMatch(items[index].title, searchQuery)
                                                        }}
                                                    />
                                                </Cell>
                                                <Cell
                                                    className="LoadByoSettingsContainer__column"
                                                    options="lighter gray"
                                                >
                                                    <LineEllipsis lines={2} rowHeight={getRem(48)}>
                                                        {items[index] ? highlightMatch(items[index].description, searchQuery): ''}
                                                    </LineEllipsis>
                                                </Cell>
                                                <Cell
                                                    className="LoadByoSettingsContainer__column"
                                                    options="gray"
                                                >
                                                    {
                                                        items[index].createdDate
                                                    }
                                                </Cell>
                                                <Cell
                                                    className="LoadByoSettingsContainer__column"
                                                    options="last justify gray"
                                                >
                                                    {items[index].companyName}
                                                    {items[index].title !== LOAD_BYO_SETTINGS_NO_DATA_TEXT && renderButtons(items[index])}
                                                </Cell>
                                            </div>
                                        )}
                                    </FixedSizeList>
                                );
                            }}
                        </AutoSizer>
                    </div>
                </div>
            </Modal>
            <ConfirmationPopup
                shown={removeConfirmationShown}
                closeModal={() => setRemoveConfirmationShown(false)}
                title="Are you sure you want to delete this report?"
                onConfirm={handleRemoveConfirm}
                submitButtonText="Delete"
            />
        </>
    );
}


LoadByoSettingsContainer.propTypes = {
    shown: PT.bool,
    closeModal: PT.func,
};

export default memo(LoadByoSettingsContainer);