import React, {useState} from 'react';
import {connect} from 'react-redux';
import {countriesSelector} from "@selectors/countries.selector";
import Popup from "@components/Popup/Popup";
import Flag from "@components/Flag/Flag";
import {getClassName} from "@core/old_helpers";
import './SidebarOption.styles.scss';
import TooltipTabs from "@components/TooltipTabs/TooltipTabs";
import { IconDelete, IconArrowBottom } from '@icons';
import Icon from '@components/Icon/Icon';

function SidebarOption(
    {
        isMaster,
        name,
        isActive,
        changeName,
        remove,
        countryCodes,
        countries,
        setActive,
    }
) {
    const [isOpened, setIsOpened] = useState(true);
    const [isEditing, setIsEditing] = useState(!isMaster);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isShownTooltipOptionName, setIsShownTooltipOptionName] = useState(false);

    const handleOnBlur = () => {
        if(name === '') {
            changeName('New group')
        }
    };

    const handleDeleteButtonClick = (event) => {
        event.stopPropagation();
        setIsDeleting(true);
    };

    const handleToggleIsOpened = (event) => {
        event.stopPropagation();
        setIsOpened(!isOpened);
    };

    const props = {};

    if (!isActive) {
        props.onClick = setActive;
    }

    const topClassList = new Set(['sidebar-option__top']);
    const innerClassList = new Set(['sidebar-option__inner']);

    if (!isOpened) {
        innerClassList.add('sidebar-option__inner--collapsed');
    }
    if (isActive) {
        topClassList.add('sidebar-option__top--active');
    }

    return (
        <div className="sidebar-option" {...props}>
            <div className={getClassName(topClassList)}
                 onMouseEnter={() => setIsShownTooltipOptionName(true)}
                 onMouseLeave={() => setIsShownTooltipOptionName(false)}
            >
                <div
                    className="sidebar-option__title">
                        <input
                            className={`sidebar-option__title-input ${isMaster ? 'sidebar-option__title-input--is-master' : ''}`}
                            placeholder="New group"
                            readOnly={isMaster}
                            type="text"
                            value={isMaster ? 'Master group' : name}
                            onChange={(event) => changeName(event.target.value)}
                            onBlur={handleOnBlur}
                            autoFocus
                        />
                </div>
                <TooltipTabs isShown={name.length && isShownTooltipOptionName}><span>{name}</span></TooltipTabs>
                <div className="sidebar-option__btns">
                    {!isMaster &&
                    (
                        <>
                            <button
                                className="sidebar-option__btn"
                                onClick={handleDeleteButtonClick}
                            >
                                <Icon type='small'>
                                    <IconDelete/>
                                </Icon>
                            </button>
                        </>
                    )}
                    <button
                        onClick={handleToggleIsOpened}
                        className={`sidebar-option__btn sidebar-option__arrow ${isOpened ? 'sidebar-option__arrow--opened' : ''}`}
                    >
                        <Icon>
                            <IconArrowBottom/>
                        </Icon>
                    </button>
                </div>
            </div>
            <div className={getClassName(innerClassList)}>
                {countryCodes.map((countryCode) => {
                    const country = countries.find(country => country.countryCode === countryCode);
                    return <Flag
                        key={countryCode}
                        {...country}/>;
                })}
                {countryCodes.length === 0 && (
                    <div className="sidebar-option__no-data-msg">
                        No countries
                    </div>
                )}
            </div>
            <Popup
                shown={isDeleting}
                closeModal={() => setIsDeleting(false)}
                title={`Delete country group "${name}"`}
                btnModifier="red"
                applyBtnTitle="Delete"
                onConfirm={remove}
            >
                <div className="Popup__text">
                    Are you sure you want to delete <span>{name}</span> and all related keyword settings?
                </div>
            </Popup>
        </div>
    );
}

const mapState = state => ({
    countries: countriesSelector(state),
});

export default connect(mapState)(SidebarOption);