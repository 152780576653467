import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { connect } from "react-redux";
import './FoodProfiles.scss';
import ProfileItemList from "@components/FoodProfileItemList/FoodProfileItemList";
import { getFoodProfiles } from "@core/api";
import { TOP_TRENDS_CATEGORIES } from "@reducers/topTrends.reducer"
import Filters from "@components/Filters/Filters";
import Page from "@components/Page/Page"
import { IconSearch } from '@icons';
import Icon from '@components/Icon/Icon';

const FoodProfiles = ({ countryCode = "USA" }) => {
    const [data, setData] = useState({});
    const [activeSelectorBtn, setActiveSelectorBtn] = useState(1);
    const [checked, setChecked] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isShown, setIsShown] = useState(false);
    const [categories, setCategories] = useState(TOP_TRENDS_CATEGORIES);
    const [searchValue, setSearchValue] = useState('');


    const handleOnChange = (val) => {
        setSearchValue(val);
    };

    const toggleCategory = (index) => {
        if (checked.includes(index)) {
            setChecked(checked.filter(id => id !== index))
        } else {
            setChecked([
                ...checked,
                index
            ])
        }

        setCategories(categories.map((el) => {
            if (el.id === index) {
                return ({
                    ...el,
                    isSelected: !el.isSelected,
                })
            } else {
                return ({
                    ...el,
                })
            }
        }))
    };

    useEffect(() => {
        setChecked(categories.map(({ id }) => id));
        setCategories(categories.map(el => ({
            ...el,
            isSelected: true
        })));
        setIsShown(true);
    }, []);

    const handleClickSelectAll = () => {
        setActiveSelectorBtn(1);
        setChecked(categories.map(({ id }) => id));
        setCategories(categories.map(el => ({
            ...el,
            isSelected: true
        })))
    };

    const handleClickClearAll = () => {
        setActiveSelectorBtn(2);
        setChecked([]);
        setCategories(categories.map(el => ({
            ...el,
            isSelected: false
        })))
    };

    useEffect(() => {
        getFoodProfiles(countryCode).then(res => {
            setIsLoading(false);
            res.keyWords.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
            setData(res);
        })
    }, []);

    const searchQuery = (
        <div className="search">
            <input
                spellCheck={false}
                value={searchValue}
                onChange={({ target }) => handleOnChange(target.value)}
                className="search__input "
                type="text"
                placeholder="Find a term"
            />
            <span className="foodProfileList__searc-icon">
                <Icon type='small'>
                    <IconSearch/>
                </Icon>
            </span>
        </div>
    );

    return (
        <Page title="Food Profiles">
            <div className='foodProfileList'>
                <CSSTransition in={isShown} classNames="appears1" timeout={1200} unmountOnExit>
                    <div className="foodProfileList__filters">
                        <div className="TopTrends__sidebar TopTrendsSidebar">
                            <div className="TopTrends__select-settings-container">
                                <div className="TopTrends__select-settings">
                                    <span
                                        className={`TopTrends__select-btn ${
                                            activeSelectorBtn === 2 ? "TopTrends__select-btn--active" : ""}`}
                                        onClick={handleClickClearAll}
                                    >
                                        clear
                                    </span>
                                    /
                                    <span
                                        className={`TopTrends__select-btn ${
                                            activeSelectorBtn === 1 ? "TopTrends__select-btn--active" : ""}`}
                                        onClick={handleClickSelectAll}
                                    >
                                        select
                                    </span>
                                </div>
                            </div>
                            <Filters
                                setActiveSelectorBtn={setActiveSelectorBtn}
                                key="filters"
                                filtersArray={categories}
                                toggleFilter={toggleCategory}
                            />
                            <div className="foodProfileList__search-panel">
                                {searchQuery}
                            </div>
                        </div>
                    </div>
                </CSSTransition>
                <CSSTransition in={isShown} classNames="appears3" timeout={1200} unmountOnExit>
                    <ProfileItemList
                        checked={checked}
                        data={data.keyWords}
                        isLoading={isLoading}
                        searchValue={searchValue}
                    />
                </CSSTransition>
            </div>
        </Page>
    )
};

export default connect(state => ({
    countryCode: 'USA',
}),)(FoodProfiles);