import { getRoundValue } from "@core/old_helpers";
import { iPenetration } from "@models/FoodProfile";
import { FC } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import "./RegionalPenetration.scss";

const RegionalPenetration: FC<{ data: iPenetration[]}> = ({
    data = [],
}) => {
    const isLightTheme = useSelector((state: any) => state.app.isThemeLightEnable);

    return (
        <div className='RegionalPenetration'>
            <div className="RegionalPenetration__wrap">
                <div className='RegionalPenetration__map'>
                    <img
                        src={`/Map-${isLightTheme ? 'light' : 'dark'}.svg`}
                        alt="usa-map"
                    />
                </div>
                <div className='RegionalPenetration__info'>
                    {data.map((region, index) => {
                        const classNames = cn("RegionalPenetration__info-row", {
                            "RegionalPenetration__info-row--west": region.name === "West",
                            "RegionalPenetration__info-row--midwest": region.name === "Midwest",
                            "RegionalPenetration__info-row--south": region.name === "South",
                            "RegionalPenetration__info-row--east": region.name === "Northeast",
                        });

                        return (
                            <div
                                className={classNames}
                                key={index}
                            >
                                <span className='RegionalPenetration__circle'/>
                                <span className='text'>
                                    {region.name}
                                    {" "}
                                    {!Number.isNaN(+region.value) ? getRoundValue(+region.value) : region.value}
                                </span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default RegionalPenetration;