import React from 'react';
import Subtitle from "./Subtitle";
import './SidebarHeader.styles.scss';
import { IconEdit } from '@icons';
import Icon from '@components/Icon/Icon';

function SidebarHeader({ addNewOption, openComparePopup, openManageCountriesPopup, optionsLength }) {
    return (
        <div className="SidebarHeader">
            <Subtitle>
                Countries
            </Subtitle>
            <div className="sidebar__btns">
                {optionsLength > 1 && (
                    <button
                        // className={`btn btn--bordered ${keywordOptions.length > 0 ? 'btn--disable' : ''}`}
                        onClick={openComparePopup}
                    >
                        Compare
                    </button>
                )}
                <button
                    className="btn"
                    onClick={openManageCountriesPopup}
                >
                    <Icon type="small">
                        <IconEdit/>
                    </Icon>
                </button>
                <button
                    className={`btn btn--filled btn--round `}
                    onClick={addNewOption}
                >
                    +
                </button>
            </div>
        </div>
    );
}

export default SidebarHeader;