import { components } from "@apiSchema";

export interface iFlavorMetric {
    raw: number;
    norm: number;
    normHeaderText: string;
    normDescriptionText: string;
    rank: number;
    rankHeaderText: string;
    rankCountText: string;
}

type ApiModel = components['schemas']['MenuTrends.Application.Contracts.Models.ExternalApi.Flavor.FoodProfile.FlavorFoodProfileMetricModel'];

interface iData {
    apiModel?: ApiModel;
}

export class FlavorMetric implements iFlavorMetric {
    raw = FlavorMetric.defaultData.raw;
    norm = FlavorMetric.defaultData.norm;
    normHeaderText = FlavorMetric.defaultData.normHeaderText;
    normDescriptionText = FlavorMetric.defaultData.normDescriptionText;
    rank = FlavorMetric.defaultData.rank;
    rankHeaderText = FlavorMetric.defaultData.rankHeaderText;
    rankCountText = FlavorMetric.defaultData.rankCountText;

    static defaultData: iFlavorMetric = {
        raw: 0,
        norm: 0,
        normHeaderText: '',
        normDescriptionText: '',
        rank: 0,
        rankHeaderText: '',
        rankCountText: '',
    }

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData(data: iFlavorMetric) {
        ({
            raw: this.raw,
            norm: this.norm,
            normHeaderText: this.normHeaderText,
            normDescriptionText: this.normDescriptionText,
            rank: this.rank,
            rankHeaderText: this.rankHeaderText,
            rankCountText: this.rankCountText,
        } = data);
    }

    private mapFromApi (rawData: ApiModel) {
        this.setData({
            raw: rawData.raw || 0,
            norm: rawData.norm || 0,
            normHeaderText: rawData.normHeaderText || '',
            normDescriptionText: rawData.normDescriptionText || '',
            rank: rawData.rank || 0,
            rankHeaderText: rawData.rankHeaderText || '',
            rankCountText: rawData.rankCountText || '',
        });
    }
}