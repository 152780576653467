import React, { useCallback } from "react";
import { Scrollbars } from "react-custom-scrollbars";

const initialColor = 'rgba(0,0,0,.3)';

const CustomScrollbars = ({ onScroll, forwardedRef, style, children, thumbColor = initialColor }) => {

    const refSetter = useCallback(scrollbarsRef => {
        if (scrollbarsRef) {
            forwardedRef(scrollbarsRef.view);
        } else {
            forwardedRef(null);
        }
    }, []);

    const renderThumb = ({ style, ...props }) => {
        const thumbStyle = {
            backgroundColor: thumbColor,
            borderRadius: "20px"
        };
        return (
            <div
                style={{ ...style, ...thumbStyle }}
                {...props}/>
        );
    };

    return (
        <Scrollbars
            renderThumbVertical={renderThumb}
            ref={refSetter}
            style={{
                ...style,
                overflow: "hidden",
            }}
            onScroll={onScroll}
        >
            {children}
        </Scrollbars>
    );
};

export default React.forwardRef((props, ref) => (
    <CustomScrollbars {...props} forwardedRef={ref} />
));