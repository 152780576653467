import React, { FC, useState } from 'react';
import Cell from '@components/NewAwesomeTable/Cell';
import Icon from '@components/Icon/Icon';
import { IconSortASC, IconSortDESC } from '@icons';
import TooltipTabs from '@components/TooltipTabs/TooltipTabs';
import Select from '@components/Select/Select';
import { SortingDirection } from '@models';
import { AdminCategoryReportsItem } from '@apiHooks/useCategoryReports';

const AdminCategoryReportsTableHead: FC<{
    isFilteredByQuery: boolean;
    sortingColumnType: keyof AdminCategoryReportsItem | null;
    sortingDirection: SortingDirection;
    handleSorting: (sortingColumnType: keyof AdminCategoryReportsItem) => void;
    selectedCountriesIds: string[];
    setSelectedCountriesIds: (ids: string[]) => void;
    countriesOptions: any[];
}> = ({
    isFilteredByQuery,
    handleSorting,
    sortingColumnType,
    sortingDirection,
    selectedCountriesIds,
    setSelectedCountriesIds,
    countriesOptions,
}) => {
    const [isNameTooltipShown, setIsNameTooltipShown] = useState(false);
    return (
        <>
            <Cell
                className="AdminTable__col AdminTable__col--fluid AdminTable__col--max-half"
                onMouseOver={() => setIsNameTooltipShown(true)}
                onMouseOut={() => setIsNameTooltipShown(false)}
                onClick={() => handleSorting('name')}
                options={!isFilteredByQuery ? "hv" : ""}
            >
                <span className={`AdminTable__header-label ${sortingColumnType === "name" ? "has-icon" : ""}`}>
                    Report name
                    {(sortingColumnType === "name" && !isFilteredByQuery) && (
                        <div className="AdminTable__header-icon">
                            {sortingDirection === "asc" ? <Icon type="small">
                                <IconSortASC />
                            </Icon> : <Icon type="small">
                                <IconSortDESC />
                            </Icon>}
                        </div>
                    )}
                </span>
                <TooltipTabs isShown={isNameTooltipShown && sortingColumnType === "name" && !isFilteredByQuery}>
                    <span>{sortingDirection === "asc" ? "A - Z" : "Z - A"}</span>
                </TooltipTabs>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--large-extra" options="hv">
                <div className="AdminTable__header-dropdown">
                    <Select
                        placeholder=""
                        options={countriesOptions}
                        value={selectedCountriesIds}
                        onChange={setSelectedCountriesIds}
                        isCheckboxHidden
                        labelIcon
                        stopPropagation
                        prefix="All countries"
                        inheritedStyles
                        showModifierIcon
                        customScrollbar
                        wrapContent
                    />
                </div>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--medium" options="center no-pl">
                Status
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--medium" />
        </>
    );
};

export default AdminCategoryReportsTableHead;