import { TAGS } from '../core/actionNames';
const { SET_DATA, SET_FIELD, RESET_DATA } = TAGS;

export function setData (data) {
    return {
        type: SET_DATA,
        data,
    };
}

export function setField (name, value) {
    return {
        type: SET_FIELD,
        name,
        value,
    };
}

export function resetData () {
    return {
        type: RESET_DATA,
    };
}