import { ID } from '@models';
import { useData } from '@hooks/useData';
import { getCategoryReports, getRtuReports } from '@core/api';
import { components } from '@apiSchema';

export interface iReport {
    id: ID;
    name: string;
    description: string;
}

interface iSlidesToGo {
    summaryReports: iReport[];
    categoryReports: iReport[];
}

async function getSlidesToGo (countryCode: ID): Promise<iSlidesToGo> {
    const response: [
        components['schemas']['APIs.Controllers.RtuReports.Models.RtuReportModel'][],
        components['schemas']['Application.CategoryReports.DTO.CategoryReportDTO'][],
    ] = await Promise.all([
        getRtuReports(countryCode),
        getCategoryReports(countryCode),
    ]);

    return {
        summaryReports: response[0].map(i => ({
            id: i.id || 0,
            name: i.name || '',
            description: i.description || '',
        })),
        categoryReports: response[1].map(i => ({
            id: i.id || 0,
            name: i.name || '',
            description: i.description || '',
        })),
    };
}

export default function useSlidesToGo (countryCode: ID) {
    return useData({
        summaryReports: [],
        categoryReports: [],
    }, getSlidesToGo, countryCode);
}