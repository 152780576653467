import React, { useContext } from "react";
import PT from "prop-types";
import { capitalize } from "@core/old_helpers";
import Scrollbars from "react-scrollbars-custom";
import { CSSTransition } from "react-transition-group";
import { FixedSizeList as List } from "react-window";
import AutoSizer from 'react-virtualized-auto-sizer';
import "./AutoComplete.scss";
import CustomScrollbarsVirtualList from "../CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";
import { GlobalContext } from "@components/App/App.context";

const AutoComplete = (
    {
        data = [],
        onClick,
        onMouseMove,
        className = "",
        activeOptionIndex,
        query = '',
        modifiers = [],
        shown = true,
        isUpperCase = false,
        testId,
    }
) => {
    const { getRem } = useContext(GlobalContext);

    const classList = new Set([ "auto-complete", className,
        ...modifiers.map(i => `${i}-auto-complete`)
        ,
    ]);

    const Row = ({index, style}) => {
        const keyword = data[index];
        const preparedName = isUpperCase ? (keyword.name || '').toUpperCase() : capitalize(keyword.name || '');
        const indexOfQuery = keyword.name.toLowerCase().indexOf(query.toLowerCase());
        const content = `${preparedName.substring(0, indexOfQuery)
        }<span class="auto-complete__word--white">${
            preparedName.substring(indexOfQuery, indexOfQuery + query.length)
        }</span>${preparedName.substring(indexOfQuery + query.length,)}`;

        return (
            <button
                style={style}
                key={`${keyword.id}-${index}-${preparedName}`}
                onClick={() => {
                    onClick(keyword);
                }}
                onMouseMove={() => {
                    onMouseMove(index);
                }}
                className={`auto-complete__word ${
                    index === activeOptionIndex
                        ? "auto-complete__word--active"
                        : ""
                }`}
                dangerouslySetInnerHTML={{__html: content}}
                data-testid={`buttonAutoCompleteItem-${index}`}
            />
        )
    };

    return (
        <CSSTransition
            classNames="auto-complete"
            timeout={150}
            in={data.length > 0 && shown}
            unmountOnExit
            data-testid={testId}
        >
            <div className={Array.from(classList).join(" ")}>
                <Scrollbars
                    trackYProps={{
                        style: {
                            width: getRem(10),
                        },
                    }}
                >
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                className="List"
                                height={height}
                                itemCount={data.length}
                                itemSize={getRem(30)}
                                width={width}
                                outerElementType={CustomScrollbarsVirtualList}
                            >
                                {Row}
                            </List>
                        )}
                    </AutoSizer>
                </Scrollbars>
            </div>
        </CSSTransition>
    );
};

AutoComplete.defaultProps = {
    onMouseMove: () => {
    },
};

AutoComplete.propTypes = {
    data: PT.arrayOf(
        PT.shape({
            id: PT.number,
            name: PT.string,
        })
    ),
    onClick: PT.func,
    activeOptionIndex: PT.number,
    onMouseMove: PT.func,
    query: PT.string,
    modifiers: PT.arrayOf(PT.string),
    testId: PT.string,
};

export default AutoComplete;
