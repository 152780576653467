import React, { useState } from "react"
import { connect } from "react-redux"
import { clear } from '@actions/topTrends.actions';
import ConfirmationPopup from "@components/ConfirmationPopup/ConfirmationPopup";
import { filtersDataSelector } from "@selectors/filters.selectors";
import { requestDataSelector } from "@selectors/topTrends.selectors";
import apiConfig from "@core/apiConfig";
import { addNotification } from "@actions/notifications.actions";
import { exportTopTrends } from "@core/api";
import { currentCountryWithPermissionsSelector } from "@selectors/countries.selector";
import ExportButton from "@components/ExportButton/ExportButtonContainer";
import http from "@core/http";


const FooterRightContainer = ({clear, isAbleCleanBtn, requestData, addNotification, rawFiltersData, permissions = {}}) => {

    const [isClearPopupOpened, setIsClearPopupOpened] = useState(false);
    const { isTrial } = permissions;

    const handleExportTopTrends = () => {
        const id = new Date().getTime();
        addNotification({
            text: "Downloading...",
            id,
        });
        exportTopTrends({
            customerFilter: requestData,
            fullFilter: rawFiltersData,
        }).then((token) => {
            http.downloadFileXHRFromUrl(
                'GET',
                apiConfig.get('export top trends', {key: token})
            ).then(() => {
                addNotification({
                    text: "Download was successful",
                    duration: 4000,
                    id,
                });
            }).catch(err => {
                addNotification({
                    text: "Something went wrong.",
                    error: true,
                    id,
                    duration: 4000,
                });
            });
        }).catch((error) => addNotification({
            text: `Something went wrong `,
            duration: 4000,
            id,
            error: true,
        }));
    };

    return (
        <div className="page-footer__btn-container">
            {isAbleCleanBtn && (
                <button
                    onClick={() => setIsClearPopupOpened(true)}
                    className="page-footer__list-item"
                >
                    CLEAR
                </button>
            )}

            <ExportButton
                isTrial={isTrial}
                handleExportMenuClick={handleExportTopTrends} />

            <ConfirmationPopup
                closeModal={() => setIsClearPopupOpened(false)}
                shown={isClearPopupOpened}
                title="Clear keywords and filters"
                text="Are you sure you want to clear all keywords and filters?"
                onConfirm={clear}
            />
        </div>
    )
};

const mapStateToProps = state => ({
    byoSettingsData: state.byoSettings.data,
    isUsa: state.countries.current === 'USA',
    isFiltersPopupShown: state.filters.isFiltersPopupShown,
    rawFiltersData: filtersDataSelector(state),
    requestData: requestDataSelector(state),
    permissions: currentCountryWithPermissionsSelector(state),
});

const mapDispatchToProps = dispatch => ({
    clear: () => dispatch(clear()),
    addNotification: notification => dispatch(addNotification(notification)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FooterRightContainer);