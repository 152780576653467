import React, { useState, useEffect } from "react";
import { getCustomRestaurantListsAvailableRestaurants } from "@core/api";
import { useSearch, useSelectOptions, useSort } from "@core/customHooks";
import { dynamicSort } from "@core/old_helpers";
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import Search from "@components/Search/Search";
import Table from "../Table/Table";

const FILTER_FIELDS = ["segment", "cuisine", "regions"];

const RestaurantsModal = ({ shown, closeModal, handleConfirm, currentCountry, includedRestaurantsIds }) => {
    const [rawData, setRawData] = useState([]);
    const [restaurants, setRestaurants] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [searchQuery, setSearchQuery, filteredSearchData] = useSearch(restaurants);
    const [countSelectedRestaurants, setCountSelectedRestaurants] = useState(0);
    const sort = useSort();
    const [dataFilters, handleChangeFilters] = useSelectOptions(rawData, FILTER_FIELDS);
    const [selectedRestaurants, setSelectedRestaurants] = useState([]);

    useEffect(() => {
        const restaurantsRequest = getCustomRestaurantListsAvailableRestaurants(currentCountry);
        setIsLoading(true);
        restaurantsRequest
            .then(data => {
                const restaurants = data || [];
                setRawData(restaurants);
                const transformData = restaurants.map(item => ({
                    ...item,
                    isSelected: false,
                }));
                setRestaurants(transformData);
            })
            .finally(() => {
                setIsLoading(false);
            });

        return () => {
            restaurantsRequest.abort();
        };
    }, []);

    useEffect(() => {
        const { segmentSelectedIds, cuisineSelectedIds, regionsSelectedIds } = dataFilters;
        let restaurants = filteredSearchData.filter(item => !includedRestaurantsIds.includes(item.id));

        const filterItems = data => fnFilter => fnFilter(data);
        const segments = data => (restaurants = data.filter(item => segmentSelectedIds.includes(item.segment)));
        const cuisines = data => (restaurants = data.filter(item => cuisineSelectedIds.includes(item.cuisine)));
        const regions = data => {
            restaurants = data.filter(item => {
                const matched = item.regions.find(el => regionsSelectedIds.includes(el.id));
                return !!matched;
            });
        };

        filterItems(restaurants)(segments);
        filterItems(restaurants)(cuisines);
        filterItems(restaurants)(regions);

        setTableData(restaurants.sort(dynamicSort(sort.column, sort.direction)));
    }, [sort.column, sort.direction, dataFilters, filteredSearchData, includedRestaurantsIds]);

    const handleSelectRestaurant = (id, value) => {
        const currentIndex = restaurants.findIndex(item => item.id === id);
        setRestaurants(prevState => [
            ...prevState.slice(0, currentIndex),
            {
                ...prevState[currentIndex],
                isSelected: value,
            },
            ...prevState.slice(currentIndex + 1),
        ]);

        setCountSelectedRestaurants(prevState => {
            return prevState + (value ? 1 : -1);
        });

        setSelectedRestaurants(prevState => {
            if (value) {
                return [...prevState, restaurants[currentIndex]];
            } else  {
                const removedIndex = prevState.findIndex(item => item.id === id);

                return [
                    ...prevState.slice(0, removedIndex),
                    ...prevState.slice(removedIndex + 1),
                ]
            }
        });
    };

    const handleUnselectAllRestaurants = () => {
        setCountSelectedRestaurants(0);
        setRestaurants(prevState => {
            return prevState.map(item => ({
                ...item,
                isSelected: false,
            }));
        });
    };

    const reset = () => {
        setSelectedRestaurants([]);
        setCountSelectedRestaurants(0);
        setIsLoading(true);
        setSearchQuery("");
        handleUnselectAllRestaurants();
    }

    const handleApplyRestaurants = () => {
        handleConfirm(selectedRestaurants);
        closeModal();
        reset();
    };

    const handleClose = () => {
        closeModal();
        reset();
    }

    return (
        <Modal closeModal={closeModal} shown={shown}>
            <div className="Popup__wrapper">
                <div className="Popup Popup--large">
                    <div className="RestaurantsMenuManage">
                        <div className="RestaurantsMenuManage__header RestaurantsMenuManage__header--small-space">
                            <div className="RestaurantsMenuManage__title">Choose restaurants</div>
                            <div className="RestaurantsMenuManage__actions">
                                <Button onClick={handleClose} modifiers={["transparent"]}>
                                    Cancel
                                </Button>
                                <Button isDisabled={isLoading} onClick={handleApplyRestaurants} modifiers={["blue", "rounded"]}>
                                    Apply
                                </Button>
                            </div>
                        </div>

                        <div className="RestaurantsMenuManage__content">
                            <div className="RestaurantsMenuManage__row">
                                <div className="RestaurantsMenuManage__filters-info">
                                    <div className="RestaurantsMenuManage__counter">
                                        {countSelectedRestaurants} items selected
                                    </div>
                                    <div
                                        className="RestaurantsMenuManage__clear"
                                        onClick={handleUnselectAllRestaurants}
                                    >
                                        Clear
                                    </div>
                                </div>
                            </div>
                            <div className="RestaurantsMenuManage__row"></div>
                            <div className="RestaurantsMenuManage__row">
                                <div className="RestaurantsMenuManage__search">
                                    <Search
                                        key="search"
                                        searchQuery={searchQuery}
                                        setSearchQuery={setSearchQuery}
                                        placeholder="Find a restaurant"
                                        showIcon
                                    />
                                </div>
                            </div>

                            <div className="RestaurantsMenuManage__row">
                                <div className="RestaurantsMenuManage__table RestaurantsMenuManage__table--large">
                                    <Table
                                        data={tableData}
                                        isSortDisabled={!!searchQuery}
                                        sort={sort}
                                        handleChangeFilters={handleChangeFilters}
                                        dataFilters={dataFilters}
                                        isLoading={isLoading}
                                        allowSelectRows
                                        handleSelectRestaurant={handleSelectRestaurant}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default RestaurantsModal;
