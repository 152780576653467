import { deepCopy } from "@core/old_helpers";

export const FRONT_ID_PREFIX = "front-";
export const SHOW_KEYWORD_TOOLTIP = true;

export function getEmptyOptionData(countryCode = null) {
    return {
        id: `${FRONT_ID_PREFIX}${new Date().getTime()}`,
        name: null,
        categoryId: null,
        subCategoryId: null,
        countryCode,
        isActive: false,
        currentSubCategories: [],
        filterDescription: "",
        snapCartTitle: "",
        snapCartDescription: "",
        order: 0,
        parentReportId: null,
        filter: {
            words: [],
            restaurantListId: 0,
            segments: [],
            regions: [],
            cuisines: [],
            dayParts: [],
            menuItemTypes: [],
            generatorFilter: [],
            healthyDining: false,
            smallPlates: false,
            kidMenus: false,
            glutenFree: false,
            plantBased: false,
            customWords: [],
            minPrice: null,
            maxPrice: null,
            minUnits: null,
            maxUnits: null,
            catering: null
        },
    };
}


export function normalizeOptionFromBackEnd(option) {
    const emptyOption = getEmptyOptionData();
    const { chart } = option;
    const { filter, ...optionData} = chart;

    return {
        ...emptyOption,
        ...optionData,
        filter: {
            ...emptyOption.filter,
            ...filter
        }
    };
}

export function normalizeOptionForBackEnd(categoryId, subCategoryId, _optionsData, id, name, descriptionInputs) {
    const optionsData = deepCopy(_optionsData);
    const { countryCodes, currentSubCategories, keywords, settings, defaultRestaurantListId, currentWords, ...data } = optionsData;
    const {ethnicSpeciality, maxUnits, minUnits, minPrice, maxPrice, ...filters } = settings;
    const currentId = id || 0;

    return {
        ...data,
        id: currentId,
        name: name,
        categoryId,
        subCategoryId,
        filterDescription: descriptionInputs.filterDescription,
        snapCartTitle: descriptionInputs.snapCartTitle,
        snapCartDescription: descriptionInputs.snapCartDescription,
        filter: {
            ...data.filter,
            ...filters,
            maxUnits: parseInt(maxUnits) || null,
            minUnits: parseInt(minUnits) || null,
            minPrice: parseInt(minPrice) || null,
            maxPrice: parseInt(maxPrice) || null,
            words: [...keywords.submitted],
            restaurantListId: data.filter.restaurantListId ? data.filter.restaurantListId : defaultRestaurantListId
        },
    };
}

export function normalizeFiltersData(data) {
    const { venues, menuItems, attributes } = data;

    let settings = {};
    let menuItemTypes = [];

    const addField = (field, value) => {
        settings = {
            ...settings,
            [field]: value
        }
    }

    Object.keys(venues).forEach(filterKey => {
        if ( Array.isArray(venues[filterKey]) && filterKey !== "restaurants" ) {
            let ids = venues[filterKey].filter(item => item.isChecked).map(item => item.id);
            addField(filterKey, ids);
        } else {
            addField(filterKey, venues[filterKey]);
        }
    });

    Object.keys(attributes).forEach(filterKey => {
        if ( filterKey === "tags" ) {
            addField(filterKey, attributes[filterKey]);
        } else if ( Array.isArray(attributes[filterKey]) && filterKey !== "ethnicSpeciality" ) {
            let ids = attributes[filterKey].filter(item => item.isChecked).map(item => item.id);
            addField(filterKey, ids);
        } else {
            const key = (filterKey === "healthyTerms") ? "healthyDining" : filterKey;
            addField(key, attributes[filterKey]);
        }
    });

    Object.keys(menuItems).forEach(filterKey => {
        if ( Array.isArray(menuItems[filterKey]) ) {
            const key = (filterKey === "words") ? "customWords" : filterKey;

            if ( filterKey === "words" ) {
                addField(key, menuItems[filterKey]);
            } else {
                let ids = menuItems[filterKey].filter(item => item.isChecked).map(item => item.id);
                menuItemTypes = [...menuItemTypes, ...ids];
            }
        } else {
            addField(filterKey, menuItems[filterKey]);
        }
    });

    addField("menuItemTypes", menuItemTypes);

    return settings;
}