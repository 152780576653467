import React, { useEffect, useState } from 'react';
import SubTitle from "@components/SubTitle/SubTitle";
import SettingsDropdown from "@components/Select/Select";
import SettingsTextarea from "./SettingsTextarea";
import {getAdminKeywordSearchFilter} from "@core/api";

function SettingsFoodProfile({ handleChange, data, dataConfig, selectsData }) {
    const { fields } = dataConfig;
    const { filterDescription = '' } = data;
    const MINIMAIL_CHARS_TO_SEARCH = 3;
    const [filterDescriptionAutocomplete, setFilterDescriptionAutocomplete] = useState([]);

    useEffect(() => {
        if ( filterDescription.length < MINIMAIL_CHARS_TO_SEARCH ) {
            setFilterDescriptionAutocomplete([]);
            return;
        }

        const searchRequest = getAdminKeywordSearchFilter(filterDescription);

        const getWords = async () => {
            const response = await searchRequest;

            const words = response
                .filter(
                    (item) =>
                    item.toLowerCase() !== filterDescription.toLowerCase()
                )
                .map((item, index) => ({
                    id: Date.now() + index,
                    name: item
                }));

            setFilterDescriptionAutocomplete(words);
        }

        getWords();

        return () => searchRequest.abort();
    }, [filterDescription]);

    return (
        <div className="settings-section settings-section--food-profile">
            <div className="settings-section__top">
                <SubTitle textColor="yellow">Food Profile settings</SubTitle>
                <span className='settings-section__hint'>*Please select at least one category and a daypart</span>
            </div>
            <div className="settings-section__inner">
                {fields.map(({ name, field }) => {
                    if (field === 'filterDescription') {
                        return (
                            <SettingsTextarea
                                key={field}
                                value={data[field]}
                                setInputTextField={(value) => handleChange(field, value)}
                                title={name}
                                autoCompleteData={filterDescriptionAutocomplete}
                            />
                        );
                    }
                    return (
                        <SettingsDropdown
                            key={field}
                            options={selectsData[field]}
                            value={data[field]}
                            title={name}
                            onChange={(value) => handleChange(field, value)}
                        />
                    )

                })}
            </div>
        </div>
    );
}

export default SettingsFoodProfile;