import Icon from "@components/Icon/Icon";
import { IconSortASC, IconSortDESC } from "@icons";
import React from "react";
import Cell from "@components/NewAwesomeTable/Cell";
import Select from "@components/Select/Select";

const Head = (
    {
        isSortDisabled,
        sort,
        handleChangeFilters,
        dataFilters,
        allowSelectRows,
        isViewMode,
    }
) => {
    const handleSorting = type => {
        if (isSortDisabled) return;
        sort.setDirection(sort.column === type ? (sort.direction === "dec" ? "asc" : "dec") : "asc");
        sort.setColumn(type);
    };

    const hoverableCellOptions = !isSortDisabled ? "hv" : "";
    const classesHeaderLabel = type => `AdminTable__header-label ${sort.column === type ? "has-icon" : ""}`;

    const ColumnSortIcons = ({ type }) => {
        if (sort.column === type && !isSortDisabled) {
            return (
                <div className="AdminTable__header-icon">
                    {sort.direction === "asc" ? <Icon type="small"><IconSortASC/></Icon> :  <Icon type="small"><IconSortDESC/></Icon>}
                </div>
            );
        }

        return null;
    };

    return (
        <>
            {allowSelectRows && <Cell className="AdminTable__col AdminTable__col--small"></Cell>}
            <Cell
                className="AdminTable__col AdminTable__col--fluid"
                options={hoverableCellOptions}
                onClick={() => handleSorting("name")}
            >
                <span className={classesHeaderLabel("name")}>
                    Restaurant name
                    <ColumnSortIcons type="name"/>
                </span>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--large" options="hv">
                <div className="AdminTable__header-dropdown">
                    <Select
                        placeholder=""
                        options={dataFilters.segment}
                        value={dataFilters.segmentSelectedIds}
                        onChange={value => handleChangeFilters("segmentSelectedIds", value)}
                        isCheckboxHidden
                        labelIcon
                        stopPropagation
                        prefix="Segment"
                        inheritedStyles
                        showModifierIcon
                        customScrollbar
                        wrapContent
                        dropdownHeight={120}
                    />
                </div>
            </Cell>
            <Cell
                className="AdminTable__col AdminTable__col--regular AdminTable__col--no-pr"
                options={hoverableCellOptions}
                onClick={() => handleSorting("unitsCount")}
            >
                <span className={classesHeaderLabel("unitsCount")}>
                    Units
                    <ColumnSortIcons type="unitsCount"/>
                </span>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--large" options="hv">
                <div className="AdminTable__header-dropdown">
                    <Select
                        placeholder=""
                        options={dataFilters.cuisine}
                        value={dataFilters.cuisineSelectedIds}
                        onChange={value => handleChangeFilters("cuisineSelectedIds", value)}
                        isCheckboxHidden
                        labelIcon
                        stopPropagation
                        prefix="Cuisine"
                        inheritedStyles
                        showModifierIcon
                        customScrollbar
                        wrapContent
                        dropdownHeight={120}
                    />
                </div>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--large" options="hv">
                <div className="AdminTable__header-dropdown">
                    <Select
                        placeholder=""
                        options={dataFilters.regions}
                        value={dataFilters.regionsSelectedIds}
                        onChange={value => handleChangeFilters("regionsSelectedIds", value)}
                        isCheckboxHidden
                        labelIcon
                        stopPropagation
                        prefix="Regions"
                        inheritedStyles
                        showModifierIcon
                        customScrollbar
                        wrapContent
                        dropdownHeight={120}
                    />
                </div>
            </Cell>
            {!allowSelectRows && !isViewMode && (
                <Cell className="AdminTable__col AdminTable__col--small AdminTable__col--no-p"/>
            )}
        </>
    );
};

export default Head;
