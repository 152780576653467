import Icon from '@components/Icon/Icon';
import { IconAdd } from '@icons';
import React, {useEffect} from 'react';
import { useDropzone } from "react-dropzone";
import './DropZone.styles.scss'
import ImageList from "./DropZoneImageList";

const DropZone = ({ accept, images = [], onDrop, handleRemoveImg, setIsRejectedImage }) => {

    const maxSize = 5242880;

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        accept,
        minSize: 0,
        maxSize,
    });
    const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].size > maxSize;

    useEffect(() => {
        setIsRejectedImage(isFileTooLarge)
    }, [onDrop]);

    const btn = (
        <div {...getRootProps({ className: images.length > 0 ? "settings-add-images__add-btn" : 'DropZone__btn-add' })}>
            {images.length > 0
                ? <Icon><IconAdd/></Icon>
                : (
                    <>
                        <p className="settings-add-images__title settings-add-images__title-in-box">Click here to add photos or drag and drop</p>
                        <p className="settings-add-images__subtitle settings-add-images__subtitle-in-box">Available formats: JPEG, PNG. Maximum file size - 5
                            mb</p>
                    </>
                )}
            <input className="DropZone__input" {...getInputProps()} />
        </div>
    );

    return (
        <div className='DropZone__container'>
            <ImageList
                btn={btn}
                images={images}
                handleRemoveImg={handleRemoveImg}
            />

        </div>
    );
};

export default DropZone;