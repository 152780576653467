import { BYO } from '@core/actionNames';
import { getByoData, getInstantChart } from '@core/api';
import { compareInLC, deepCopy, equalInLC, getEmptyKeywordModel } from '@core/old_helpers';
import { getKeywordData } from '@core/models/ByoTableData';
import { setData as setFiltersData } from './filters.actions';
import { filtersForBackEndSelector } from '@selectors/filters.selectors';
import { getActiveFiltersFromBack } from "@core/filters.helpers";
import { COLORS } from "@reducers/byo.reducer";
import { tableDataSelector } from "@selectors/byo.selectors";
import { parseWordsFromString } from "@core/helpers";

const {SET_FIELD, SET_DATA, RESET_DATA, RESET_COLORS} = BYO;

export function setField(name, value) {

    return {
        type: SET_FIELD,
        name,
        value,
    };
}

function resetColors() {
    return {
        type: RESET_COLORS,
    };
}

export function clearData() {
    return {type: RESET_DATA};
}

export function resetData(keepWords = false, keepGoing = false) {
    return (dispatch, getState) => {
        dispatch(setData({
            data: keepWords ? getState().byo.data.map(({ slices, keyword, ...rest }) => ({
                ...rest,
                keyword: {...keyword, isEmpty: true},
            })) : [],
            searchQuery: "",
            searchError: false,
            activeAnalyticsType: 1,
            chartDataType: 1,
            activeValuesType: 1,
            activeSliceIndex: 0,
            sortingColumn: null,
            sortingDirection: "desc",
            noData: !keepWords && !keepGoing,
        }));
        if (!keepWords) {
            dispatch(resetColors());
        }
    };
}

export function setData(data) {
    return {
        type: SET_DATA,
        data
    }
}

let requestsSend = 0;

function fetchData(words, cb) {
    return (dispatch, getState) => {
        if (!getState().byo.isInstantChartLoading) {
            const filters = filtersForBackEndSelector(getState());
            const instantChartId = getState().byo.instantChartId;
            const requestData = {
                words,
                filters,
            };

            if (instantChartId) {
                requestData.instantChartId = instantChartId;
                dispatch(setField('instantChartId', null));
            }
            requestsSend += 1;
            dispatch(setField('isLoading', true));
            getByoData(requestData)
                .then((data) => {
                    cb(data);
                }).finally(() => {
                requestsSend -= 1;
                if (requestsSend === 0) {
                    dispatch(setField('isLoading', false));
                }
            });
        }
    };
}

function applyData(data) {
    return (dispatch, getState) => {
        const {
            data: currentData,
        } = getState().byo;

        const newData = currentData.map((item) => {
            const newItemData = data.find(word => compareInLC(word.keyword.name, item.keyword.name));
            if (newItemData) {
                return {
                    ...item,
                    ...getKeywordData(newItemData),
                };
            }
            return item;
        });

        if (currentData.length === data.length) {
            const state = getState();
            const sortedData = tableDataSelector({
                ...state,
                byo: {
                    ...state.byo,
                    data: newData
                },
            });
            newData.forEach((i) => {
                const wordIndexInSortedData = sortedData.keywords.findIndex(word => compareInLC(word.name, i.keyword.name));
                i.color = COLORS[wordIndexInSortedData] || undefined;
            });
            dispatch(setField('availableColors', [...COLORS].slice(newData.length)));
        }

        dispatch(setField('data', newData));
    };
}

export function reFetchData() {
    return (dispatch, getState) => {
        dispatch(resetColors());
        dispatch(fetchData(getState().byo.data.map(item => item.keyword), (data) => {
            dispatch(applyData(data));
        }));
    }
}

export function addWords(words, update = true) {
    return (dispatch, getState) => {
        const {
            availableColors: colors,
            data: byoData,
            isInstantChartLoading,
        } = getState().byo;

        dispatch(setField('data', [
            ...byoData,
            ...words.map((data, index) => ( {
                keyword: data,
                color: isInstantChartLoading ? undefined : colors[index],
            })),
        ]));
        if (!isInstantChartLoading) {
            dispatch(setField('availableColors', colors.slice(words.length)));
        }
        if (getState().filters.raw.length && update) {
            dispatch(fetchData(words, (data) => {
                dispatch(applyData(data));
            }));
        }
    };
}

export function addWord(data) {
    return (dispatch) => dispatch(addWords([ data ]));
}

export function reactOnFiltersLoaded() {
    return (dispatch, getState) => {
        let byoData = getState().byo.data;
        const keywords = getState().keywords.data;

        if (keywords.length > 0) {
            dispatch(setField('data', [
                ...byoData.map(item => {
                    if (item.keyword.isEmpty) {
                        return {
                            ...item,
                            keyword: getWordModel(keywords, item.keyword.name),
                        };
                    }

                    return item;
                })
            ]));
            byoData = getState().byo.data;
            if (byoData.filter(({name}) => name !== '').length > 0) {
                dispatch(setField('data', [
                    ...byoData.map(({ slices, ...item }) => ({
                        ...item
                    }))
                ]));
                dispatch(reFetchData());
            }
        }
    }
}

function getWordModel(keywords, searchQuery, addedItems = [], markAsEmpty = false) {
    const keyword = keywords.find((word) => {
        if (equalInLC(word.name, searchQuery)) return true;
        if (word.isTag) return false;
        for (let i = 0; i < word.wordExtensions.length; i++) {
            const extension = word.wordExtensions[i];
            if (equalInLC(extension.name, searchQuery) && extension.wordType === 3) {
                const asAdded = addedItems.find(i => equalInLC(i.name, word.name));
                if (!!asAdded) {
                    return asAdded.wordExtensions.some(e => equalInLC(e.name, searchQuery) && e.wordType === 3);
                }
                return true;
            }
        }
        return false;
    });

    return deepCopy(
        keyword
            ? { ...keyword, wordExtensions: keyword.isTag ? [] : keyword.wordExtensions }
            : getEmptyKeywordModel(searchQuery, markAsEmpty)
    );
}

export function submitSearch() {
    return (dispatch, getState) => {
        const filters = getState().filters.raw;
        if (!filters.length) return;
        const searchQuery = getState().byo.searchQuery.toLowerCase().trim();
        const byoData = getState().byo.data;
        const keywords = getState().keywords.data;
        const words = parseWordsFromString(searchQuery)
            .map((word) => getWordModel(keywords, word, byoData.map(i => i.keyword)));

        if (words.length > 0) {
            if (words.every(
                word => byoData.find((item) => equalInLC(item.keyword.name, word.name))
            )) {
                dispatch(setField('searchError', true));
                setTimeout(() => {
                    dispatch(setField('searchError', false));
                }, 3000);
            } else {
                dispatch(addWords(
                    words.filter(
                        word => !byoData.some((item) => equalInLC(item.keyword.name, word.name))
                    )
                ));
            }
            dispatch(setField('searchQuery', ''));
        }
    };
}

export function deleteKeyword(name) {
    return (dispatch, getState) => {
        const byoData = getState().byo.data;
        const keywordIndex = byoData.findIndex((item => item.keyword.name === name));
        const availableColors = [ ...getState().byo.availableColors ];
        if (byoData[keywordIndex].color) {
            availableColors.push(byoData[keywordIndex].color);
        }
        if (byoData.length === 1) {
            dispatch(setField('noData', true));
        }
        const newActivePairedWithWordIndex = getState().byo.activePairedWithWordIndex >= byoData.length - 1
            ? 0
            : getState().byo.activePairedWithWordIndex;
        dispatch(setData({
            availableColors,
            noData: byoData.length === 1,
            activePairedWithWordIndex: newActivePairedWithWordIndex,
            data: [
                ...byoData.slice(0, keywordIndex),
                ...byoData.slice(keywordIndex + 1),
            ]
        }));
    }
}

export function paintKeyword(name) {
    return (dispatch, getState) => {
        const byoData = getState().byo.data;
        const keywordIndex = byoData.findIndex((item => item.keyword.name === name));
        const availableColors = [ ...getState().byo.availableColors ];
        let newColor = null;
        if (byoData[keywordIndex].color) {
            availableColors.unshift(byoData[keywordIndex].color);
        } else {
            newColor = availableColors.shift();
        }
        dispatch(setData({
            availableColors,
            data: [
                ...byoData.slice(0, keywordIndex),
                {
                    ...byoData[keywordIndex],
                    color: newColor,
                },
                ...byoData.slice(keywordIndex + 1),
            ]
        }));
    }
}

export function setKeywordSettings(id, data, name) {
    return (dispatch, getState) => {
        const byoData = getState().byo.data;
        let keywordIndex;

        if (id > 0) {
            keywordIndex = byoData.findIndex(item => item.keyword.id === id);
        } else if (!!name) {
            keywordIndex = byoData.findIndex(item => compareInLC(item.keyword.name, name));
        }

        if (keywordIndex >= 0) {
            dispatch(setField('data', [
                ...byoData.slice(0, keywordIndex),
                {
                    ...byoData[keywordIndex],
                    keyword: {
                        ...byoData[keywordIndex].keyword,
                        wordExtensions: data,
                    }
                },
                ...byoData.slice(keywordIndex + 1),
            ]));
            dispatch(reFetchData());
        }
    };
}

export function onLoad() {
    return (dispatch, getState) => {
        const keywords = getState().keywords.data;
        const urlSearchParams = new URLSearchParams(window.location.search);
        const potentialWordsString = urlSearchParams.get('words');
        const instantChartId = urlSearchParams.get('instantChartId');
        const wordsCount = urlSearchParams.get('wordsCount');
        if (potentialWordsString) {
            const wordsNames = Array.from(new Set(decodeURIComponent(potentialWordsString).split(',')));
            dispatch(addWords(wordsNames.map((word) => {
                return getWordModel(keywords, word, [], true);
            })));
        } else if (instantChartId) {
            dispatch(setField('isInstantChartLoading', true));
            const emptyWords = (new Array(+wordsCount)).fill(getEmptyKeywordModel('', true));
            dispatch(setField('instantChartId', instantChartId));
            dispatch(addWords(emptyWords, false));
            getInstantChart(getState().countries.current, instantChartId).then((data) => {
                dispatch(setField('isInstantChartLoading', false));
                dispatch(setFiltersData({
                    activeRestaurantListId: data.filters.restaurantFilters.restaurantListId,
                    active: null,
                }));
                if (getState().filters.raw.length) {
                    dispatch(setFiltersData({
                        active: getActiveFiltersFromBack(data.filters),
                    }));
                } else {
                    dispatch(setFiltersData({
                        activeToApply: getActiveFiltersFromBack(data.filters),
                    }));
                }
                dispatch(setData({
                    data: [],
                }));
                dispatch(resetColors());
                dispatch(addWords(data.words));
            })
        } else {
            dispatch(setField('noData', true));
        }
        dispatch(setField('isLoaded', true));
    }
}

export function onUnload() {
    return (dispatch) => {
        dispatch(setField('isLoaded', false));
        dispatch(clearData());
    }
}