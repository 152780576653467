import React from "react";
import "./HightlightChars.scss";

const HightlightChars = ({ name }) => (
    <span
        dangerouslySetInnerHTML={{
            __html: name,
        }}
    />
);

export default HightlightChars;
