import React, { FC, useContext, useMemo } from "react";
import Cell from "@components/NewAwesomeTable/Cell";
import Select from "@components/Select/Select";
import { InstantChartContext } from './SelectInstantCharts';
import { ID } from '@models';

const TableHeader: FC<{
    selectedCategoriesIds: ID[];
    selectedSubCategoriesIds: ID[];
    setSelectedCategoriesIds: (ids: ID[]) => void;
    setSelectedSubCategoriesIds: (ids: ID[]) => void;
}> = ({
    selectedCategoriesIds,
    selectedSubCategoriesIds,
    setSelectedCategoriesIds,
    setSelectedSubCategoriesIds,
}) => {
    const { categories, subCategories } = useContext(InstantChartContext);

    const categoriesToOptions = useMemo(() => {
        return categories.map(i => ({
            id: i.id,
            name: i.title
        }))
    }, [categories]);
    const subCategoriesToOptions = useMemo(() => {
        return subCategories.map(i => ({
            id: i.id,
            name: i.title
        }))
    }, [subCategories]);

    return (
        <>
            <Cell className="AdminTable__col AdminTable__col--small"/>
            <Cell className="AdminTable__col AdminTable__col--fluid AdminTable__col--no-p">Instant chart name</Cell>
            <Cell className="AdminTable__col AdminTable__col--fluid" options="hv">
                <div className="AdminTable__header-dropdown">
                    <Select
                        options={categoriesToOptions}
                        value={selectedCategoriesIds}
                        onChange={(ids: ID[]) => setSelectedCategoriesIds(ids)}
                        isCheckboxHidden
                        wrapContent
                        stopPropagation
                        dropdownAlwaysBottom
                        prefix="Interactive category"
                        placeholder=""
                        inheritedStyles
                        showModifierIcon
                        customScrollbar
                    />
                </div>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--fluid" options="hv">
                <div className="AdminTable__header-dropdown">
                    <Select
                        options={subCategoriesToOptions}
                        value={selectedSubCategoriesIds}
                        onChange={(ids: ID[]) => setSelectedSubCategoriesIds(ids)}
                        isCheckboxHidden
                        wrapContent
                        stopPropagation
                        dropdownAlwaysBottom
                        prefix="Interactive subcategory"
                        placeholder=""
                        inheritedStyles
                        showModifierIcon
                        customScrollbar
                    />
                </div>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--medium" options="center no-pl">
                Status
            </Cell>
        </>
    );
};

export default TableHeader;
