import { ADMIN_KEYWORDS } from '../core/actionNames';

const { SET_FIELD, SET_DATA, RESET } = ADMIN_KEYWORDS;

const getInitialState = () => ({
    data: [],
    expandedKeywordsIds: [],
    searchQuery: '',
});

export default function adminKeywords(state = getInitialState(), action) {
    switch (action.type) {
        case SET_FIELD: {
            return {
                ...state,
                [action.key]: action.value
            };
        }
        case SET_DATA: {
            return {
                ...state,
                ...action.data
            };
        }
        case RESET: {
            return getInitialState();
        }
        default: {
            return { ...state };
        }
    }
}