import React, { Component, createRef } from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";
import PT from "prop-types";
import Overlay from "../Overlay/Overlay";
import "./Modal.scss";

export default class Modal extends Component {
    constructor(props) {
        super(props);
        this.transitionContainer = createRef();
        this.close = this.close.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { shown, } = this.props;
        if (shown && !prevProps.shown) {
            document.addEventListener("click", this.close, true);
            document.addEventListener("keydown", this.closeOnEsc, true);
            // }
        } else if (!shown && prevProps.shown) {
            document.removeEventListener("click", this.close, true);
            document.removeEventListener("keydown", this.closeOnEsc);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.close, true);
        document.removeEventListener("keydown", this.closeOnEsc);
    }

    closeOnEsc = (e = false) => {
        if (this.props.shown) {
            if (e.code === "Escape") {
                e.stopPropagation();
                this.props.closeModal();
            }
        }
    };

    close(e) {
        const { shown, closeOnOutsideClick = true, closeModal, } = this.props;
        if (shown) {
            if (
                !e
                || (closeOnOutsideClick && !this.transitionContainer.current.contains(e.target) && !e.target.closest('.modal')) // click outside
                || e.target.classList.contains("overlay") // click overlay
            ) {
                closeModal();
            }
        }
    };

    render() {
        const { hasOverlay = false, shown, isOverFooter = true, } = this.props;

        return createPortal(
            <CSSTransition
                in={shown}
                timeout={250}
                classNames={hasOverlay ? "transition-overlayed" : "transition"}
                unmountOnExit={true}
            >
                <div
                    className={`modal ${isOverFooter ? 'modal--over-footer' : ''}`}
                    ref={this.transitionContainer}
                >
                    {hasOverlay && <Overlay />}
                    {this.props.children}
                </div>
            </CSSTransition>,
            document.querySelector("body")
        );
    }
}

Modal.propTypes = {
    hasOverlay: PT.bool,
    closeOnOutsideClick: PT.bool,
    shown: PT.bool,
    closeModal: PT.func,
};
