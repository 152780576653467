import React from 'react';
import SubTitle from "@components/SubTitle/SubTitle";
import Select from "@components/Select/Select";
import CreateTagCategoryPopup from "@pages/Admin/Keyword/Settings/CreateTagCategoryPopup";
import { useDispatch } from "react-redux";
import { addTagCategory } from "@actions/keywordSettings.actions";

function SettingsTags({ handleChange, data, dataConfig, selectsData }) {
    const { fields } = dataConfig;
    const dispatch = useDispatch();
    const dispatchAddTagCategory = (data) => dispatch(addTagCategory(data));

    const [isCreateCategoryPopupOpen, setIsCreateCategoryPopupOpen] = React.useState(false);

    return (
        <div className="settings-section settings-section--tags">
            <div className="settings-section__top">
                <SubTitle textColor="yellow">{dataConfig.name}</SubTitle>
                <span className='settings-section__hint'>*Please select one category</span>
            </div>
            <div className="settings-section__inner">
                {fields.map(({name, field}) => {
                    return (
                        <Select
                            key={field}
                            options={[
                                {
                                    id: 0,
                                    name: '+ Create new category',
                                    isButton: true,
                                    onClick: () => {
                                        setIsCreateCategoryPopupOpen(true);
                                    },
                                    closeOnClick: true,
                                },
                                ...selectsData.tagCategories,
                            ]}
                            value={data[field]}
                            title={name}
                            onChange={(value) => handleChange(field, value)}
                        />
                    )
                })}
            </div>
            <CreateTagCategoryPopup
                isShown={isCreateCategoryPopupOpen}
                close={() => setIsCreateCategoryPopupOpen(false)}
                setCategory={(data) => {
                    dispatchAddTagCategory(data);
                    handleChange('categoryId', data.id);
                }}
            />
        </div>
    );
}
export default SettingsTags;