import React, { useEffect, useState } from "react";
import Cell from "@components/NewAwesomeTable/Cell";
import AdminTable from "@pages/Admin/Common/AdminTable";
import AdminInstantChartsTableRow from "./AdminInstantChartsTableRow";
import TooltipTabs from "@components/TooltipTabs/TooltipTabs";
import Select from "@components/Select/Select";
import {
    categoriesSelector,
    selectedSubCategoryIdsSelector,
    getSubcategoryNames,
    getCountriesToOptions,
    selectedCountryIdsSelector,
    searchQuerySelector,
    sortingDirectionSelector,
    sortingColumnTypeSelector,
    selectedCategoryIdsSelector,
} from "@selectors/adminInstantCharts.selectors";
import { connect } from "react-redux";
import { setData, setField } from "@actions/adminInstantCharts.actions";
import { IconSortASC, IconSortDESC } from "@icons";
import Icon from "@components/Icon/Icon";

const AdminInstantChartsTable = ({
    tableData = [],
    isLoading,
    sortingDirection,
    sortingColumnType,
    categoriesToOptions,
    selectedCategoryIds,
    selectedSubCategoryIds,
    setSelectedCategoryIds,
    setSelectedSubCategoryIds,
    subCategoriesToOptions,
    countriesToOptions,
    selectedCountryIds,
    setSelectedContryIds,
    searchQuery,
    setSortingData,
    onRemove
}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [query, setQuery] = useState(false);

    useEffect(() => {
        setQuery(searchQuery.trim().length > 0)
    }, [searchQuery]);

    const handleSorting = (type) => {
        if (query) return;

        setSortingData({
            sortingColumnType: type,
            sortingDirection: sortingColumnType === type ? sortingDirection === "dec" ? "asc" : "dec" : "asc"
        });
    }

    const Row = ({ index, style }) => {
        const rowData = tableData[index];

        return (
            <div style={style} className={`AdminTable__row`}>
                <AdminInstantChartsTableRow
                    data={rowData}
                    index={index}
                    onRemove={onRemove}
                />
            </div>
        );
    };

    const toggleTooltipShow = () => {
        setShowTooltip(!showTooltip);
    };

    const handleChangeFilters = (type, ids) => {
        switch (type) {
            case "category":
                setSelectedCategoryIds(ids);
                break;
            case "subcategory":
                setSelectedSubCategoryIds(ids);
                break;
            case "country":
                setSelectedContryIds(ids);
                break;
            default:
                break;
        }
    };

    const TableHeader = (
        <>
            <Cell
                className="AdminTable__col AdminTable__col--large-extra"
                onMouseOver={toggleTooltipShow}
                onMouseOut={toggleTooltipShow}
                onClick={() => handleSorting("name")}
                options={!query ? "hv" : ""}>

                <span className={`AdminTable__header-label ${sortingColumnType === "name" ? "has-icon" : ""}`}>
                    Instant chart name
                    {(sortingColumnType === "name" && !query) && (
                        <div className="AdminTable__header-icon">
                            {sortingDirection === "asc" ? <Icon type="small">
                                <IconSortASC />
                            </Icon> : <Icon type="small">
                                <IconSortDESC />
                            </Icon>}
                        </div>
                    )}
                </span>
                <TooltipTabs isShown={showTooltip && sortingColumnType === "name" && !query}>
                    <span>{sortingDirection === "asc" ? "A - Z" : "Z - A"}</span>
                </TooltipTabs>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--fluid" options="hv">
                <div className="AdminTable__header-dropdown">
                    <Select
                        options={categoriesToOptions}
                        value={selectedCategoryIds}
                        onChange={value => handleChangeFilters("category", value)}
                        isCheckboxHidden
                        wrapContent
                        stopPropagation
                        prefix="Interactive category"
                        placeholder=""
                        inheritedStyles
                        showModifierIcon
                        customScrollbar
                    />
                </div>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--fluid" options="hv">
                <div className="AdminTable__header-dropdown">
                    <Select
                        options={subCategoriesToOptions}
                        value={selectedSubCategoryIds}
                        onChange={value => handleChangeFilters("subcategory", value)}
                        isCheckboxHidden
                        wrapContent
                        isDisabled={!subCategoriesToOptions.length}
                        stopPropagation
                        prefix="Interactive subcategory"
                        placeholder=""
                        inheritedStyles
                        showModifierIcon
                        customScrollbar
                    />
                </div>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--large" options="hv">
                <div className="AdminTable__header-dropdown">
                    <Select
                        placeholder=""
                        options={countriesToOptions}
                        value={selectedCountryIds}
                        onChange={value => handleChangeFilters("country", value)}
                        isAdmin
                        isCheckboxHidden
                        labelIcon
                        stopPropagation
                        prefix="All countries"
                        inheritedStyles
                        showModifierIcon
                        customScrollbar
                        wrapContent
                    />
                </div>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--medium" options="center no-pl">
                Status
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--medium" />
        </>
    );

    return (
        <div className="AdminInstantChartsTable">
            <AdminTable
                header={TableHeader}
                row={Row}
                length={tableData.length}
                isLoading={isLoading}
                disableRowTransition
            />
        </div>
    );
};
const mapStateToProps = state => {
    return {
        categoriesToOptions: categoriesSelector(state),
        subCategoriesToOptions: getSubcategoryNames(state),
        countriesToOptions: getCountriesToOptions(state),
        selectedSubCategoryIds: selectedSubCategoryIdsSelector(state),
        selectedCountryIds: selectedCountryIdsSelector(state),
        searchQuery: searchQuerySelector(state),
        sortingDirection: sortingDirectionSelector(state),
        sortingColumnType: sortingColumnTypeSelector(state),
        selectedCategoryIds: selectedCategoryIdsSelector(state),
    };
};

const mapDispatchToProps = dispatch => ({
    setSelectedSubCategoryIds: ids => dispatch(setField("selectedSubCategoryIds", ids)),
    setSelectedContryIds: ids => dispatch(setField("selectedCountryIds", ids)),
    setSortingData: (data) => dispatch(setData(data)),
    setSelectedCategoryIds: ids => dispatch(setField("selectedCategoryIds", ids)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminInstantChartsTable);
