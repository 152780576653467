import { components } from "@apiSchema";
import { iChart, iChartBase, iColumn } from "@components/Chart/Chart.helpers";
import { ID } from "@models/index";
import FoodProfileFlavor, { iFoodProfileFlavor } from "@models/FoodProfileFlavor";

type API__PENETRATION = components['schemas']['Application.FoodProfile.Models.BaseItemInternal'];
type API__FOOD_PROFILE = components['schemas']['Application.FoodProfile.Models.FoodProfileOutput'];
type API__SLICE_TYPE = components['schemas']['MenuTrends.Domain.Enums.SliceType'];

export interface iPenetration {
    name: string;
    value: string;
}

export interface iVenueValues {
    sliceType: VenueTypesEnum;
    id: number;
    name: string;
    fourYearsGrowth: string;
    values: iPenetration[];
}

export enum VenueTypesEnum {
    None = 0,
    RestaurantSegments = 1000,
    RestaurantTypes = 1001,
    OnSite = 1018,
}

export interface iFoodProfile {
    id: ID;
    name: string;
    filterDescription: string;
    penetration: string;
    oneYearGrowth: string;
    fourYearsGrowth: string;
    fourYearsPredictedGrowth: string;
    totalGrowth: string;
    macStage: number;
    yearsPenetrations: iPenetration[];
    regionsPenetrations: iPenetration[];
    venueTypesValues: iVenueValues[];
    howItUsed: iPenetration[];
    onDifferentMenuItems: iPenetration[];
    pairedFlavors: iPenetration[];
    chart: iChartBase;
    years: string[];
    flavorData: iFoodProfileFlavor;
}

export interface iCurrentChart {
    dashStartPointColumnIndex?: number;
    dashStartPointSubColumnIndex?: number;
    columns: iColumn[];
    charts: iChart[];
}

export class FoodProfileData implements iFoodProfile {
    static defaultData: iFoodProfile = {
        id: 0,
        name: '',
        filterDescription: '',
        penetration: '',
        oneYearGrowth: 'N/A',
        fourYearsGrowth: 'N/A',
        fourYearsPredictedGrowth: 'N/A',
        totalGrowth: 'N/A',
        macStage: 0,
        yearsPenetrations: [],
        regionsPenetrations: [],
        venueTypesValues: [],
        howItUsed: [],
        onDifferentMenuItems: [],
        pairedFlavors: [],
        chart: {
            actualData: [],
            actualDataQuarters: [],
            predictedData: [],
            predictedDataQuarters: [],
            concatData: [],
        },
        years: [],
        flavorData: new FoodProfileFlavor(),
    }

    id = FoodProfileData.defaultData.id;
    name = FoodProfileData.defaultData.name;
    filterDescription = FoodProfileData.defaultData.filterDescription;
    penetration = FoodProfileData.defaultData.penetration;
    oneYearGrowth = FoodProfileData.defaultData.oneYearGrowth;
    fourYearsGrowth = FoodProfileData.defaultData.fourYearsGrowth;
    fourYearsPredictedGrowth = FoodProfileData.defaultData.fourYearsPredictedGrowth;
    totalGrowth = FoodProfileData.defaultData.totalGrowth;
    macStage = FoodProfileData.defaultData.macStage;
    yearsPenetrations = FoodProfileData.defaultData.yearsPenetrations;
    regionsPenetrations = FoodProfileData.defaultData.regionsPenetrations;
    venueTypesValues = FoodProfileData.defaultData.venueTypesValues;
    howItUsed = FoodProfileData.defaultData.howItUsed;
    onDifferentMenuItems = FoodProfileData.defaultData.onDifferentMenuItems;
    pairedFlavors = FoodProfileData.defaultData.pairedFlavors;
    chart = FoodProfileData.defaultData.chart;
    years = FoodProfileData.defaultData.years;
    flavorData = FoodProfileData.defaultData.flavorData;

    constructor(data?: API__FOOD_PROFILE) {
        if ( data ) {
            this.mapFromApi(data);
        }
    }

    private setData(model: iFoodProfile) {
        ({
            id: this.id,
            name: this.name,
            filterDescription: this.filterDescription,
            penetration: this.penetration,
            oneYearGrowth: this.oneYearGrowth,
            fourYearsGrowth: this.fourYearsGrowth,
            fourYearsPredictedGrowth: this.fourYearsPredictedGrowth,
            totalGrowth: this.totalGrowth,
            macStage: this.macStage,
            yearsPenetrations: this.yearsPenetrations,
            regionsPenetrations: this.regionsPenetrations,
            venueTypesValues: this.venueTypesValues,
            howItUsed: this.howItUsed,
            onDifferentMenuItems: this.onDifferentMenuItems,
            pairedFlavors: this.pairedFlavors,
            chart: this.chart,
            years: this.years,
            flavorData: this.flavorData,
        } = model);
    };

    private mapFromApi(data : API__FOOD_PROFILE) {
        const mapPenetrationFromApi = (data: API__PENETRATION): iPenetration => {
            return {
                name: data.name || '',
                value: data.value || '',
            }
        };

        const getSliceType = (value: API__SLICE_TYPE): VenueTypesEnum => {
            switch (value) {
                case 1000:
                    return VenueTypesEnum.RestaurantSegments;
                case 1001:
                    return VenueTypesEnum.RestaurantTypes;
                case 1018:
                    return VenueTypesEnum.OnSite;
                default:
                    return VenueTypesEnum.None;
            }
        }

        const venueValues = data.venueTypesValues.map(v => ({
            ...v,
            sliceType: getSliceType(v.sliceType),
            name: v.name || '',
            fourYearsGrowth: v.fourYearsGrowth || '',
            values: v.values.map(mapPenetrationFromApi),
        }));

        const venueTypesValues = venueValues
            .sort((a, b) => {
                const ai = TABLE_COLUMNS_ORDER.indexOf(a.name);
                const bi = TABLE_COLUMNS_ORDER.indexOf(b.name);

                return ai < bi ? -1 : 1;
            })
            .slice(0, TABLE_COLUMNS_ORDER.length);

        const sortByYear = (a: iPenetration, b: iPenetration) => b.name > a.name ? -1 : 1;

        const getWordData = (data: iPenetration[]) => {
            return data.map((y, index) => ({
                ...y,
                id: index,
                valueNumeric: parseFloat(y.value) || 0,
                baseSize: '0'
            }))
        };

        const yearsPenetrations = data.yearsPenetrations.map(mapPenetrationFromApi);
        const yearsPredictedPenetrations = data.yearsPredictedPenetrations.map(mapPenetrationFromApi);
        const quartersPenetrations = data.quartersPenetrations.map(mapPenetrationFromApi);
        const quartersPredictedPenetrations = data.quartersPredictedPenetrations.map(mapPenetrationFromApi);
        const regionsPenetrations = data.regionsPenetrations.map(mapPenetrationFromApi);
        const howItUsed = data.howItUsed.map(mapPenetrationFromApi);
        const onDifferentMenuItems = data.onDifferentMenuItems.map(mapPenetrationFromApi);
        const pairedFlavors = data.pairedFlavors.map(mapPenetrationFromApi);

        const actualData = getWordData(yearsPenetrations.sort(sortByYear));
        const actualDataQuarters = getWordData(quartersPenetrations.sort(sortByYear));
        const predictedData = getWordData(yearsPredictedPenetrations.sort(sortByYear));
        const predictedDataQuarters = getWordData(quartersPredictedPenetrations.sort(sortByYear));

        const years = venueTypesValues[0].values
            .map(({ name }) => name)
            .filter(year => [...yearsPenetrations, ...quartersPenetrations].find(({ name }) => name === year));
        this.setData({
            id: data.id,
            name: data.name || '',
            filterDescription: data.filterDescription || this.filterDescription,
            penetration: data.penetration,
            oneYearGrowth: data.oneYearGrowth ?? FoodProfileData.defaultData.oneYearGrowth,
            fourYearsGrowth: data.fourYearsGrowth ?? FoodProfileData.defaultData.fourYearsGrowth,
            fourYearsPredictedGrowth: data.fourYearsPredictedGrowth ?? FoodProfileData.defaultData.fourYearsPredictedGrowth,
            totalGrowth: data.totalGrowth ?? FoodProfileData.defaultData.totalGrowth,
            macStage: data.macStage,
            yearsPenetrations,
            regionsPenetrations,
            venueTypesValues,
            howItUsed,
            onDifferentMenuItems,
            pairedFlavors,
            years,
            chart: {
                actualData,
                actualDataQuarters,
                predictedData,
                predictedDataQuarters,
                concatData: [
                    ...actualData,
                    ...actualDataQuarters,
                    ...predictedDataQuarters,
                    ...predictedData,
                ],
            },
            flavorData: new FoodProfileFlavor({ apiModel: data.flavorData }),
        });
    }
}

const TABLE_COLUMNS_ORDER = [
    'QSR',
    'Fast Casual',
    'Midscale',
    'Casual Dining',
    'Fine Dining',
    'Independent',
    'Regional',
    'Chain',
    'Hospital',
    'C&U',
    'Schools',
    'Long Term Care',
    'Restaurants',
];