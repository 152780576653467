import React from 'react'
import { getRoundValue } from "@core/old_helpers"
import './ProfileItem.scss'
import { usePlatformFeatureFlag } from "@datassential/platform-ui-lib";
import cn from "classnames";
import { highlightMatches } from "@core/helpers";

const FoodProfileItem = ({
    title = '',
    value = '',
    isTag = false,
    searchQuery = '',
}) => {
    const isTagsEnabled = usePlatformFeatureFlag("MT filter by tags");
    return (
        <div className='profile-item'>
            <span
                className={cn(
                    'profile-item__title', 'profile-item__val',
                    isTagsEnabled && !isTag && 'is-light',
                    isTagsEnabled && isTag && 'tag-label',
                )}
                dangerouslySetInnerHTML={{ __html: highlightMatches(title, searchQuery) }}
            />
            <span className='profile-item__value profile-item__val'>{getRoundValue(value)}</span>
        </div>
    )
};

export default FoodProfileItem;