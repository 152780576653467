import React, { ReactNode } from 'react';
import './Cell.styles.scss';
import { getClassName } from "@core/old_helpers";

const Cell = React.forwardRef<HTMLDivElement, {
    className?: string;
    options?: string;
    children?: ReactNode;
    onMouseOver?: () => void;
    onMouseOut?: () => void;
    onClick?: () => void;
    style?: any;
}>(({
    options = '',
    className = '',
    children,
    ...rest
}, ref) => {
    const classList = new Set([
        'Cell',
        ...options.split(' ').map(option => `Cell--${option}`),
        ...className.split(' '),
    ]);
    return (
        <div ref={ref} {...rest} className={getClassName(classList)}>
            {children}
        </div>
    );
});

export default Cell;