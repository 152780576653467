import React, { useReducer, useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux'
import CountryMenu from "../CountryMenu/CountryMenu";
import cn from "classnames";
import { setTheme } from '@actions/app.actions';
import HeaderCenterMenu from '@components/HeaderCenterMenu/HeaderCenterMenu'
import Button from '@components/Button/Button'
import ColorModeRadioBtn from "../ColorModeRadioBtn/ColorModeRadioBtn";
import "./PageHeader.scss";
import { Routes } from "@routes";
import { IconLogoDark, IconLogoLight } from "@icons";

const PageHeader = (
    {
        countries = [],
        currentCountry,
        setCurrent,
        isThemeLightEnable,
        hasAdminAccess,
        setTheme,
    }
) => {
    const location = useLocation();
    const [isActiveCentreMenu, setIsActiveCentreMenu ] = useState(false)
    const [state, dispatch] = useReducer(reducer, initialState);
    const [isVisible, setIsVisible] = useState(false);
    const [isHeaderActive, setIsHeaderActive] = useState(false);
    const [isCountryDropdownShown, setIsCountryDropdownShown] = useState(true);
    const [isCenterMenuShown, setIsCenterMenuShown] = useState(true);
    const [isHomePage, setIsHomePage] = useState(false);
    const isAdminPage = location.pathname.startsWith("/admin/");
    const isFoodProfilePage = location.pathname.startsWith("/food-profile");
    const url = isAdminPage ? Routes.Home : '/admin/keywords';
    useEffect(() => {
        setIsActiveCentreMenu(true);
        setIsHeaderActive(true);
    }, []);

    useEffect(() => {
        switch (location.pathname) {
            case Routes.Home: {
                setIsCountryDropdownShown(true);
                setIsVisible(true);
                setIsCenterMenuShown(false);
                break;
            }
            case Routes.foodProfiles:
            case Routes.foodProfile:
            case Routes.reports:
            case Routes.instantCharts: {
                setIsCenterMenuShown(true);
                setIsCountryDropdownShown(false);
                setIsVisible(true);
                break;
            }
            default: {
                setIsCenterMenuShown(true);
                setIsCountryDropdownShown(true);
                setIsVisible(true);
            }
        }
    }, [location]);

    const handleFlagClick = event => {
        const boundingRect = event.currentTarget.getBoundingClientRect();
        dispatch({
            type: "showCountryMenu",
            payload: {
                countryMenuX: boundingRect.left,
                countryMenuY: boundingRect.bottom + 10,
                isCountryMenuShown: true
            }
        });
    };

    const hideCountryMenu = () => {
        dispatch({ type: "hideCountryMenu" });
    };

    const selectedCountry = countries.find(c => c.countryCode === currentCountry);
    const CurrentCountryFlag = selectedCountry && selectedCountry.component;

    // const settingsIcon = !isThemeLightEnable
    //     ? <GearsIcon className="header-gears-btn__icon"/>
    //     : <GearsIconDark className="header-gears-btn__icon"/>;

    const Logo = !isThemeLightEnable ? IconLogoLight : (isHomePage ? IconLogoLight : IconLogoDark);
    const isThemeSwitcherVisible = location.pathname !== Routes.Home;

    const classList = cn("page-header", {
        'page-header--admin-background': isAdminPage,
        'page-header--background': isFoodProfilePage,
        'page-header--active': isHeaderActive,
        'page-header--hoverable': location.pathname !== Routes.Home
    });

    useEffect(() => {
        setIsHomePage(location.pathname === Routes.Home);
    }, [location]);

    if (!isVisible) return null;

    return (
        <div
            onMouseEnter={()=> setIsActiveCentreMenu(true)}
            onMouseLeave={()=> setIsActiveCentreMenu(false)}
            className={classList}
        >
            {isCenterMenuShown && <div className={cn("page-header__hint", { "is-visible": !isActiveCentreMenu })}><div className="page-header__hint-dots"/></div>}
            {isCenterMenuShown &&
                <HeaderCenterMenu
                    isAdminPage={isAdminPage}
                    setIsActiveCentreMenu={setIsActiveCentreMenu}
                    isActive={isActiveCentreMenu}
                    isThemeLightEnable={isThemeLightEnable}
                    setTheme={setTheme}
                    hasAdminAccess={hasAdminAccess}
                />
            }
            <div className="page-header__left">
                <Link to="/" style={{ fontSize: 0, lineHeight: 0 }}>
                    <Logo className="page-header__infinite-icon"/>
                </Link>
                {!isAdminPage && <button
                    className={`header-flag-btn ${isCountryDropdownShown ? 'header-flag-btn--shown' : ''}`}
                    onClick={isCountryDropdownShown ? handleFlagClick : () => {}}
                    title={selectedCountry ? selectedCountry.name : ''}
                >
                    {selectedCountry && <CurrentCountryFlag className="header-flag-btn__icon"/>}
                </button>}
            </div>
            <div className={`page-header__right ${hasAdminAccess ? "is-compact" : ""}`}>
                {isThemeSwitcherVisible && (
                    <ColorModeRadioBtn
                        isThemeLightEnable={isThemeLightEnable}
                        setTheme={setTheme}
                    />
                )}
            </div>
            {isCountryDropdownShown && (
                <CountryMenu
                    x={state.countryMenuX}
                    y={state.countryMenuY}
                    closeModal={hideCountryMenu}
                    shown={state.isCountryMenuShown}
                    selectCountry={(code) => setCurrent(code)}
                    countries={countries}
                />
            )}
            {hasAdminAccess && <Button
                className="Button__admin"
                shouldOpenNewTab={!isAdminPage}
                url={url}
            >
                {isAdminPage ? 'Application' : 'Admin'}
            </Button>}
        </div>
    );
};

const initialState = {
    isCountryMenuShown: false,
    countryMenuX: null,
    countryMenuY: null
};

const reducer = (state, action) => {
    switch (action.type) {
        case "showCountryMenu": {
            return {
                ...state,
                ...action.payload
            };
        }
        case "hideCountryMenu": {
            return {
                ...state,
                isCountryMenuShown: false
            };
        }
        default:
            return state;
    }
};

export default connect((state) => ({
    isThemeLightEnable: state.app.isThemeLightEnable,
    hasAdminAccess: state.app.hasAdminAccess,
    isVisible: !!state.countries.current,
}), { setTheme })(PageHeader);
