import React, { useContext, useState } from "react";
import Button from "@components/Button/Button";
import Loader from "@components/Loader/Loader";
import ToggleInput from "@components/ToggleInput/ToggleInput";
import PopupAddChildReport from "./PopupAddChildReport/PopupAddChildReport";
import ReportFilters from "./ReportFilters";
import ReportHeader from "./ReportHeader";
import { Actions, ReportContext } from "./ReportOptions.reducer";
import ReportTable from "./ReportTable";
import { normalizeReportForBackEnd, validateReportData } from "./Report.helpers";
import { addAdminReport, editAdminReport } from "@core/api";
import { useNavigate } from "react-router-dom";
import { Routes } from "@routes";
import { getStringMatchRank, StringMatchRanks } from "@core/old_helpers";

function AdminReport(props) {
    let history = useNavigate();
    const { addReport, currentCountry, addNotification } = props;
    const { optionsData, dispatchOptionsAction, handleChangeOptionField } = useContext(ReportContext);
    const { main } = optionsData;
    const [ shownAddPopup, setShownAddPopup ] = useState(false);
    const [isSendLoaderShown, setIsSendLoaderShown] = useState(false);

    const handleAddChildReport = (ids) => {
        dispatchOptionsAction({
            type: Actions.ADD_CHILD_REPORTS,
            data: ids
        })
    }

    const filterInstantCharts = data => {
        const searchQuery = optionsData.searchQuery.trim();

        let filteredData = data.filter(item => {
            return (
                optionsData.selectedInstantChartsCategories.some(i => i.name === item.category) &&
                optionsData.selectedInstantChartsSubCategories.some(i => i.name === item.subCategory)
            );
        });

        if ( searchQuery !== "") {
            filteredData = filteredData.filter(keyword => {
                return getStringMatchRank(keyword.instantChartName, searchQuery) >= StringMatchRanks.Includes;

            }).sort((a, b) => {
                const aWord = a.instantChartName;
                const bWord = b.instantChartName;

                return (aWord < bWord ? -1 : aWord > bWord ? 1 : 0);
            });

            filteredData.sort((a, b) => {
                const aRank = getStringMatchRank(a.instantChartName, searchQuery);
                const bRank = getStringMatchRank(b.instantChartName, searchQuery);

                return aRank < bRank ? 1 : aRank > bRank ? -1 : 0;
            });
        }

        return filteredData.map(item => {
            const searchRegExp = new RegExp(`(${searchQuery})`, "gi");
            const renderInstantChartName = searchQuery === "" ? item.instantChartName : item.instantChartName.replace(searchRegExp, "<b>$1</b>");

            return {
                ...item,
                renderInstantChartName
            }
        });
    }

    const showNotification = (message, type) => {
        addNotification({
            text: message,
            duration: 4000,
            [type]: true
        });
    };

    const showErrorNotification = (message) => showNotification(message, "error");
    const showSuccessNotification = (message) => showNotification(message, "success");

    const handleSaveReport = async () => {
        if (isSendLoaderShown) return;


        setIsSendLoaderShown(true);
        const sendData = normalizeReportForBackEnd(optionsData);
        const { errors, valid } = validateReportData(sendData);

        if ( valid ) {
            try {
                if ( addReport ) {
                    await addAdminReport(sendData);
                } else {
                    await editAdminReport(sendData);
                }
                showSuccessNotification(`Your report was successfully ${addReport ? "created" : "saved"}`);
                history(Routes.AdminSummaryReports);
            } catch (e) {
                showErrorNotification(`${e.status} ${e.statusText}`);
                setIsSendLoaderShown(false);
            }

        } else {

            showErrorNotification(errors.map((el, idx) => <div key={idx}>{el}</div>));
            setIsSendLoaderShown(false);
        }
    }

    const handleSearchQuery = (value) => {
        dispatchOptionsAction({
            type: Actions.ADD,
            data: {
                searchQuery: value
            }
        });
    };

    return (
        <div className="AdminContainer AdminContainer--no-pt">
            <Loader className="is-fixed" isShown={isSendLoaderShown} />

            <div className="AdminContainer__content">
                <ReportHeader
                    reportLabel="summary report"
                    currentCountry={currentCountry}
                    addReport={addReport}
                    handleSaveReport={handleSaveReport}
                    ReportContext={ReportContext}
                    backRoute={Routes.AdminSummaryReports}
                />

                <div className="AdminContainer__main">
                    <div className="AdminContainer__body">
                        <div className="AdminContainer__row AdminContainer__row--right">
                            <div className="AdminContainer__top-actions">
                                <div className="AdminContainer__top-actions-item">
                                    <ToggleInput
                                        labelText="Published"
                                        onToggle={() => {
                                            handleChangeOptionField("main", "isActive", !main.isActive);
                                        }}
                                        isChecked={main.isActive}
                                    />
                                </div>
                                <div className="AdminContainer__top-actions-item">
                                    <div className="AdminContainer__button">
                                        <Button isDisabled modifiers={["white", "rounded", "fluid"]}>
                                            Copy attributes
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ReportFilters />

                        <div className="AdminContainer__row AdminContainer__row--right">
                            <div className="AdminContainer__btns">
                                <button onClick={() => setShownAddPopup(true)} className="btn btn--filled">Add Instant chart</button>
                                <PopupAddChildReport data={filterInstantCharts(optionsData.childInstantCharts)}
                                                     closeModal={() => setShownAddPopup(false)}
                                                     handleAddChildReport={handleAddChildReport}
                                                     shown={shownAddPopup}
                                                     searchQuery={optionsData.searchQuery}
                                                     setSearchQuery={(value) => handleSearchQuery(value)} />
                            </div>
                        </div>

                        <div className="AdminContainer__row AdminContainer__row--pb">
                            <ReportTable />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminReport;
