import { resetData, setField } from '@actions/adminInstantCharts.actions';
import { addNotification } from '@actions/notifications.actions';
import { deleteAdminInstantChart, getAdminInstantCharts } from '@core/api';
import { dataSelector, selectedCategoryIdsSelector, tableDataSelector } from '@selectors/adminInstantCharts.selectors';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useInstantChartsData = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const request = useRef(null);
    const data = useSelector(tableDataSelector);
    const selectedCategoryIds = useSelector(selectedCategoryIdsSelector);
    const rawData = useSelector(dataSelector);
    const addNotify = useCallback(notification => dispatch(addNotification(notification)), [dispatch]);

    const dataRequest = useCallback(() => getAdminInstantCharts(), []);

    const getInstantCharts = useCallback(async () => {
        setIsLoading(true);
        if (request.current && request.current.abort) request.current.abort();
        request.current = dataRequest();

        const response = await request.current;
        const { initialData, instantCharts } = response;

        const setData = data => dispatch(setField("data",data));
        const setSelectedCategoryIds = ids => dispatch(setField("selectedCategoryIds", ids));
        const setSelectedCountryIds = data => dispatch(setField("selectedCountryIds", data));

        let trimmedChartsName = instantCharts
          .map(item => ({
            ...item,
            name: item.name,
          }))
          .reverse();
        const categoriesIds = initialData.categoriesData.filter(item => item.name).map(({ id }) => id);
        const countryIds = initialData.countries.map(({ countryCode }) => countryCode);

        setData({
            ...response,
            instantCharts: trimmedChartsName
        });
        setSelectedCategoryIds(categoriesIds);
        setSelectedCountryIds(countryIds);

        setIsLoading(false);
    }, [dataRequest, dispatch]);

    useEffect(() => {
        getInstantCharts();

        return () => {
            dispatch(resetData());
        };
    }, [dataRequest, dispatch, getInstantCharts]);

    useEffect(() => {
        const setSelectedSubCategoryIds = ids => dispatch(setField("selectedSubCategoryIds", ids));
        const setVisibleSubCategoryIds = ids => dispatch(setField("visibleSubCategoryIds", ids));

        const getSubCategoriesIds = () => {
            let subCategoriesIds = [];
            selectedCategoryIds.forEach(selectedId => {
                let filtered = rawData.filter(item => item.categoryId === selectedId) || [];
                let ids = [];
                filtered.forEach(item => {
                    ids.push(item.subCategoryId);
                });
                subCategoriesIds.push(...new Set(ids));
            });
            return subCategoriesIds;
        }

        const subCategoryIds = getSubCategoriesIds();

        setSelectedSubCategoryIds(subCategoryIds);
        setVisibleSubCategoryIds(subCategoryIds);
    }, [selectedCategoryIds, rawData, dispatch]);

    const handleRemove = useCallback(async (id) => {
        setIsLoading(true);
        try {
            await deleteAdminInstantChart(id);
            addNotify({
                text: "The instant chart is removed. The instant chart list will be updated in a few seconds",
                duration: 4000,
            });
            getInstantCharts();
        }
        catch (err) {
            addNotify({
                text: "The instant chart can't removed.",
                error: true,
                duration: 4000,
            });
            setIsLoading(false);
        }
    }, [getInstantCharts, addNotify]);

    return {
        isLoading,
        data,
        handleRemove
    }
}

export default useInstantChartsData;