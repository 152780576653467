import {
    getByoSettings,
    getByoSetting,
    postByoSettings,
    putByoSettings,
    deleteByoSettings,
} from "../core/api";
import { BYO_SETTINGS } from "../core/actionNames";
import { addNotification } from "../actions/notifications.actions";
import {
    setData as setFiltersData,
    reset as resetFilters,
    setActiveRestaurantList,
} from "../actions/filters.actions";
import {
    resetData as resetByoData,
    addWords,
    setData as setByoData,
} from "../actions/byo.actions";
import {
    filtersForBackEndSelector,
} from "../selectors/filters.selectors";
import { getActiveFiltersFromBack } from "../core/filters.helpers";
import { TAKE_STEP } from "../reducers/byoSettings.reducer";

const { SET_FIELD, INCREMENT_TAKE, SET_DATA } = BYO_SETTINGS;

export function setField(name, value) {
    return {
        type: SET_FIELD,
        name,
        value,
    };
}
export function setData(data) {
    return {
        type: SET_DATA,
        data,
    };
}

export function resetData() {
    return setData({
        currentByoSetting: null,
        searchQuery: "",
        take: TAKE_STEP,
    });
}

export function get() {
    return dispatch => {
        getByoSettings().then(data => {
            dispatch(setField("data", data));
        });
    };
}

export function incrementTake() {
    return { type: INCREMENT_TAKE, }
}

export function clearByoSetting() {
    return (dispatch) => {
        dispatch(resetByoData());
        dispatch(setField("currentByoSetting", null));
        dispatch(resetFilters())
    };
}

export function getById(id) {
    return (dispatch, getState) => {
        dispatch(setByoData({
            isInstantChartLoading: true,
            activeKeywordId: null,
        }));
        getByoSetting(id).then(res => {
            dispatch(setField("currentByoSetting", res));

            // update keywords
            dispatch(resetByoData(false, true));

            // update filters
            dispatch(setActiveRestaurantList(res.filters.restaurantFilters.restaurantListId));
            dispatch(
                setFiltersData({ active: getActiveFiltersFromBack(res.filters) })
            );
            dispatch(setByoData({
                isInstantChartLoading: false,
            }));
            dispatch(addWords(res.words));
        });
    };
}

function postSettings(data, cb) {
    return () => {
        postByoSettings(data).then(cb);
    };
}

function putSettings(data, cb) {
    return () => {
        putByoSettings(data).then(cb);
    };
}

export function deleteSettingsById(id, cb) {
    return (dispatch, getState) => {
        const currentData = getState().byoSettings.data;
        deleteByoSettings(id).then(cb);
        dispatch(setField("data", currentData.filter(i => i.id !== id)));
    };
}

export function save(reportModel) {
    return (dispatch, getState) => {
        const currentData = getState().byo.data;
        const currentByoSetting = getState().byoSettings.currentByoSetting;
        const words = currentData.map(i => i.keyword);
        const filters = filtersForBackEndSelector(getState());
        const { isNewReport, ...model } = reportModel;
        const apiEndpoint = isNewReport ? postSettings : putSettings;
        const data = {
            ...model,
            words,
            filters,
        };

        dispatch(
            apiEndpoint(data, () => {
                dispatch(
                    addNotification({
                        text: "Your report was successfully saved",
                        duration: 4000,
                    })
                );
                dispatch(get());
                if (currentByoSetting) {
                    dispatch(
                        setField("currentByoSetting", {
                            ...currentByoSetting,
                            ...data,
                        })
                    );
                }
            })
        );
    };
}
