import React, { FC, useMemo, useRef, useState, MouseEvent, memo } from "react";
import {
    BASE_CHART_HEIGHT,
    getChartPoints,
    getDashedPartStartX,
    getSubColumns,
    getViewportGrid,
    iChart,
    iColumn,
    iViewBoxProps
} from "@components/Chart/Chart.helpers";
import ChartAxisY from "@components/Chart/ChartAxisY";
import ChartAxisX from "@components/Chart/ChartAxisX";
import ChartArea from "@components/Chart/ChartArea";
import ChartCurve, { LineStyle } from "@components/Chart/ChartCurve";
import ChartColumns from "@components/Chart/ChartColumns";
import './Chart.styles.scss';
import ChartTooltip from "./ChartTooltip/ChartTooltip";
import { ReactComponent as Menucast } from "@icons/menucast.svg";
import ChartGrid from "./ChartGrid";
import { iTooltipColumn, iTooltipColumnsGroup, iTooltipRow } from "@components/Chart/ChartTooltip/ChartTooltip.helpers";
import { deepCopy } from "@core/old_helpers";
import useChartTheme, { ChartTheme } from "./useChartTheme";
import { ChartThemeContext } from "./ChartTheme.context";

const Chart: FC<{
    charts: iChart[];
    columns: iColumn[];
    width: number;
    height: number;
    printValue: (value: number) => string;
    printLabel: (value: number) => string;
    dashStartPointColumnIndex?: number;
    dashStartPointSubColumnIndex?: number;
    theme?: ChartTheme;
    baseChartHeight?: number;
}> = (
    {
        columns,
        charts,
        width,
        height,
        printValue,
        printLabel,
        dashStartPointColumnIndex = -1,
        dashStartPointSubColumnIndex = -1,
        theme,
        baseChartHeight,
    }
) => {
    const chartRef = useRef<HTMLDivElement>(null);
    const [clientY, setClientY] = useState(0);
    const [activeColumnIndex, setActiveColumnIndex] = useState(-1);
    const { THEME } = useChartTheme(theme);
    const ID = useRef(Math.random());

    const baseHeight = useMemo(
        () => baseChartHeight ?? BASE_CHART_HEIGHT,
        [baseChartHeight]
    );

    const viewport = useMemo<iViewBoxProps>(() => {
        const padding = {
            top: 15,
            bottom: 50,
            left: 56,
            right: 5,
        };
        const proportion = baseHeight / Math.max(baseHeight, height);

        const scaledWidth = width * proportion;

        return {
            width: scaledWidth - padding.left - padding.right,
            height: baseHeight - padding.top - padding.bottom,
            canvasWidth: scaledWidth,
            canvasHeight: baseHeight,
            padding,
            proportion,
        }
    }, [width, height]);

    const y = useMemo(() => {
        if (chartRef.current) {
            return clientY - chartRef.current.getBoundingClientRect().top;
        }
        return 0;
    }, [clientY]);

    const x = useMemo(() => {
        const columnWidth = viewport.width / columns.length;
        if (activeColumnIndex === -1) return 0;
        return (viewport.padding.left + columnWidth * (activeColumnIndex + 0.5)) / viewport.proportion;
    }, [activeColumnIndex, viewport, columns]);

    const { yAxisValues, getY } = useMemo(() => {
        return getViewportGrid({
            height: viewport.height,
            chartValues: charts
                .flatMap(d => d.data)
                .flatMap(d => d),
        });
    }, [charts, viewport]);

    const yAxisData = useMemo(() => {
        return yAxisValues.map(value => ({
            title: printLabel(value),
            y: getY(value),
        }))
    }, [yAxisValues, getY, printLabel]);

    const dashedPartStartX = useMemo(() => {
        return getDashedPartStartX({
            viewport,
            columns,
            dashStartPointColumnIndex,
            dashStartPointSubColumnIndex,
        });
    }, [viewport, columns, dashStartPointColumnIndex, dashStartPointSubColumnIndex]);

    const isSomeChartActive = useMemo(() => {
        return charts.some(c => c.isActive);
    }, [charts]);

    const tooltipData: {
        rows: iTooltipRow[];
        data: iTooltipColumnsGroup[];
    } = useMemo(() => {
        const result: {
            rows: iTooltipRow[];
            data: iTooltipColumnsGroup[];
        } = {
            rows: [],
            data: [],
        };

        if (activeColumnIndex > -1) {
            const column = columns[activeColumnIndex];
            const subColumns: iTooltipColumn[] = getSubColumns(column).map((column, index) => ({
                title: column,
                index,
            }));
            let sortingColumnIndex = 0;

            if (dashStartPointColumnIndex === activeColumnIndex && dashStartPointSubColumnIndex >= 0) {
                const actual = subColumns.slice(0, dashStartPointSubColumnIndex);
                const predicted = subColumns.slice(dashStartPointSubColumnIndex);

                if (actual.length) {
                    result.data.push({
                        title: 'Actual',
                        columns: actual,
                    });
                    sortingColumnIndex = actual.length - 1
                }

                if (predicted.length) {
                    result.data.push({
                        title: 'Predicted',
                        columns: predicted,
                    });
                }
            }
            else {
                result.data.push({
                    title: '',
                    columns: subColumns,
                });
                sortingColumnIndex = subColumns.length - 1;
            }

            const chartsCopy: iChart[] = deepCopy(charts);
            chartsCopy.sort((a, b) => {
                const aValue = a.data[activeColumnIndex][sortingColumnIndex];
                const bValue = b.data[activeColumnIndex][sortingColumnIndex];
                return aValue >= bValue ? -1 : 1;
            });

            result.rows = chartsCopy.map((chart) => ({
                title: chart.title,
                color: chart.color,
                values: chart.data[activeColumnIndex].map(printValue),
            }));
        }

        return result;

    }, [
        activeColumnIndex,
        columns,
        charts,
        dashStartPointColumnIndex,
        dashStartPointSubColumnIndex,
        printValue,
    ]);

    const handleMouseMove = (event: MouseEvent) => {
        setClientY(event.clientY);
    };

    const getDashedPartWidth = () => {
        const width = viewport.width - dashedPartStartX;
        const padding = width ? viewport.padding.right : 0;
        return width + padding;
    }

    const allPoints = useMemo(() => (
        charts.map(chart => (
            getChartPoints({
                columns,
                chart,
                getY,
                viewport,
            })
        ))
    ), [charts, columns, getY, viewport]);

    const labels = useMemo(() => columns.map(i => i.title), [columns]);

    const currentTheme = useMemo(() => ({ THEME }), [THEME]);
    const RADIUS = 8;

    const sizes = useMemo(() => {
        return {
            height: viewport.height + viewport.padding.top + viewport.padding.bottom,
            width: viewport.width + viewport.padding.left + viewport.padding.right,
        }
    }, [viewport]);

    return (
        <ChartThemeContext.Provider value={currentTheme}>
        <div
            className="Chart"
            style={{ width, height }}
            onMouseMove={handleMouseMove}
            ref={chartRef}
        >

            {activeColumnIndex >= 0 && (
                <ChartTooltip
                    title={(
                        <>
                            {columns[activeColumnIndex].title}
                            {!!columns[activeColumnIndex].tooltipSubTitle && (
                                <span
                                    className="ChartTooltip__sub-title"
                                    dangerouslySetInnerHTML={{
                                        __html: `&nbsp;${columns[activeColumnIndex].tooltipSubTitle!}`,
                                    }}
                                />
                            )}
                        </>
                    )}
                    rows={tooltipData.rows}
                    data={tooltipData.data}
                    y={y}
                    x={x}
                    position={x < width / 2 ? 'right' : 'left'}
                />
            )}

            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={sizes.width}
                height={sizes.height}
                viewBox={`0 0 ${sizes.width} ${sizes.height}`}
                style={{
                    font: 'bold 12px Nunito'
                }}
            >
                <g name="bg" fill={THEME.BACKGROUND.DEFAULT}>
                    <rect
                        x1={0}
                        y1={0}
                        width={sizes.width}
                        height={sizes.height}
                        rx={RADIUS}
                        ry={RADIUS}
                    />
                    <rect
                        x={dashedPartStartX + viewport.padding.left}
                        y={0}
                        width={getDashedPartWidth()}
                        height={sizes.height}
                        fill={THEME.BACKGROUND.HAIKU}
                        ry={RADIUS}
                        rx={RADIUS}
                    />
                    <rect
                        x={dashedPartStartX + viewport.padding.left}
                        y={0}
                        width={RADIUS}
                        height={sizes.height}
                        fill={THEME.BACKGROUND.HAIKU}
                    />
                    <g
                        name="logo"
                        style={{
                            transform: `translate(${viewport.padding.left + dashedPartStartX + 15}px, ${viewport.height + viewport.padding.top - 65}px)`
                        }}
                    >
                        <Menucast
                            className="Chart__haiku"
                            width={`${(3/15) * 100}%`}
                            height={60}
                        />
                    </g>
                </g>

                <g
                    name="viewport"
                    viewBox={`0 0 ${viewport.width} ${viewport.height}`}
                    style={{
                        width: viewport.width,
                        height: viewport.height,
                        transform: `translate(${viewport.padding.left}px, ${viewport.padding.top}px)`,
                        position: 'relative',
                    }}
                >
                    <clipPath id={`${ID.current}-solid`}>
                        <rect
                            x={0}
                            y={0}
                            height={viewport.height + viewport.padding.top + viewport.padding.bottom}
                            width={dashedPartStartX}
                        />
                    </clipPath>
                    <clipPath id={`${ID.current}-dashed`}>
                        <rect
                            x={dashedPartStartX}
                            y={-viewport.padding.top}
                            height={viewport.height + viewport.padding.top + viewport.padding.bottom}
                            width={viewport.width - dashedPartStartX}
                        />
                    </clipPath>

                    <ChartGrid
                        viewport={viewport}
                        yAxisData={yAxisData}
                    />


                    {charts.map((chart, index) => (
                        <ChartArea
                            key={`area-${chart.color}`}
                            points={allPoints[index]}
                            color={chart.color}
                            viewport={viewport}
                            isActive={!isSomeChartActive || chart.isActive}
                        />
                    ))}
                    {charts.map((chart, index) => {
                        return (
                            <React.Fragment key={`curve-${chart.color}`}>
                                <g clipPath={`url(#${ID.current}-solid)`}>
                                    <ChartCurve
                                        isActive={!isSomeChartActive || chart.isActive}
                                        points={allPoints[index]}
                                        color={chart.color}
                                        lineStyle={LineStyle.Solid}
                                    />
                                </g>
                                <g clipPath={`url(#${ID.current}-dashed)`}>
                                    <ChartCurve
                                        isActive={!isSomeChartActive || chart.isActive}
                                        points={allPoints[index]}
                                        color={chart.color}
                                        lineStyle={LineStyle.Dashed}
                                    />
                                </g>
                            </React.Fragment>
                        );
                    })}
                    <ChartColumns
                        viewport={viewport}
                        getY={getY}
                        charts={charts}
                        columns={columns}
                        setActiveColumnIndex={setActiveColumnIndex}
                        dashStartPointColumnIndex={dashStartPointColumnIndex}
                        dashStartPointSubColumnIndex={dashStartPointSubColumnIndex}
                    />
                    <ChartAxisY
                        data={yAxisData}
                        viewport={viewport}
                    />
                    <ChartAxisX
                        labels={labels}
                        viewport={viewport}
                        activeColumnIndex={activeColumnIndex}
                    />
                </g>
            </svg>
        </div>
        </ChartThemeContext.Provider>
    );
};

export default memo(Chart);