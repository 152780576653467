import React from "react";
import PT from "prop-types";
import "./RadioInput.scss";

const RadioInput = ({ isChecked, onChange, label, description = "", modifiers = [], hasCustomIcons = null }) => {
    const classList = new Set([
        "radio-input",
        ...modifiers.map(i => `radio-input--${i}`),
    ]);

    return (
        <div className={Array.from(classList).join(" ")}>
            <div className="radio-input__container">
                <label className="radio-input__radio">
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={onChange}
                    />
                    <span className={hasCustomIcons ? "has-custom-icons" : null}>
                        {hasCustomIcons && hasCustomIcons}
                        {`${label} `}
                        {description.length > 0 && (
                            <span className="radio-input__description">
                                ({description})
                            </span>
                        )}
                    </span>
                </label>
            </div>
        </div>
    );
};

RadioInput.propTypes = {
    isChecked: PT.bool,
    onChange: PT.func,
    label: PT.string,
    description: PT.string,
};

export default RadioInput;
