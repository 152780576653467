import { components } from '@apiSchema';
import { getFoodProfile } from '@core/api';
import { useData } from '@hooks/useData';
import { FoodProfileData } from '@models/FoodProfile';

interface iArgs {
    id: string,
    countryCode: string,
}
type Response = components['schemas']['Application.FoodProfile.Models.FoodProfileOutput'];

async function getFoodProfileData({ id, countryCode }: iArgs): Promise<FoodProfileData> {
    const response: Response = await getFoodProfile(countryCode, id);
    return new FoodProfileData(response);
}

const useFoodProfileData = (params: iArgs) => {
    return useData(new FoodProfileData(), getFoodProfileData, params);
}

export default useFoodProfileData;