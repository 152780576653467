import React, { FC, CSSProperties, useMemo, useState, useEffect, ReactNode, useCallback } from 'react';
import AdminTable from '@pages/Admin/Common/AdminTable';
import AdminCategoryReportsTableHead from '@pages/Admin/CategoryReports/AdminCategoryReportsTableHead';
import useTableSort from '@hooks/useTableSort';
import useCategoryReports, { AdminCategoryReportsItem } from '@apiHooks/useCategoryReports';
import AdminCategoryReportsTableRow from '@pages/Admin/CategoryReports/AdminCategoryReportsTableRow';
import search from '@components/Search/Search';
import { useSelector } from 'react-redux';
import { countriesSelector } from '@selectors/countries.selector';
import { COUNTRIES_ARRAY } from '@reducers/countries.reducer';
import { equalInLC } from '@core/old_helpers';
import { ID } from '@models';

const rowFactory: (
    sortedData: AdminCategoryReportsItem[],
    searchQuery: string,
    onRemove: (id: ID) => void,
) => FC<{
    index: number;
    style: CSSProperties;
}> = ((
    sortedData,
    searchQuery,
    onRemove,
) => {
    return ({ index, style }) => {
        const rowData = sortedData[index];

        return (
            <div style={style} className="AdminTable__row">
                <AdminCategoryReportsTableRow
                    data={rowData}
                    searchQuery={searchQuery}
                    onRemove={() => onRemove(rowData.id)}
                />
            </div>
        );
    }
});

const AdminCategoryReportsTable: FC<{
    searchQuery: string;
}> = (
    {
        searchQuery,
    }
) => {
    const {
        isLoading,
        data,
        deleteReport,
    } = useCategoryReports();
    const [selectedCountriesIds, setSelectedCountriesIds] = useState<string[]>([]);

    const matchedCountries: Array<{
        id: string;
        name: string;
        icon: FC;
    }> = useMemo(() => {
        return COUNTRIES_ARRAY
            .filter(
                country => data.some(
                    item => equalInLC(item.countryCode, country.countryCode)
                )
            )
            .map(country => ({
                id: country.countryCode,
                name: country.countryCode,
                icon: country.component,
            }));
    }, [data]);

    useEffect(() => {
        setSelectedCountriesIds(matchedCountries.map(option => option.id));
    }, [matchedCountries]);

    const filteredData = useMemo(() => {
        return data.filter(item => selectedCountriesIds.some(countryCode => equalInLC(item.countryCode, countryCode)));
    }, [selectedCountriesIds, data]);

    const {
        sortingDirection,
        sortingField,
        sortedData,
        setSortingDirection,
        handleSort,
    } = useTableSort(filteredData, 'name', { query: searchQuery, fields: ['name'] });

    const Row = useMemo(() => {
        return rowFactory(sortedData, searchQuery, deleteReport);
    }, [sortedData, searchQuery, deleteReport]);

    const handleSorting = useCallback((key: keyof AdminCategoryReportsItem) => {
        if (key === sortingField) {
            setSortingDirection(sortingDirection === 'asc' ? 'desc' : 'asc');
        } else {
            handleSort(key);
        }
    }, [sortingField, sortingDirection, handleSort, setSortingDirection]);

    return (
        <AdminTable
            isLoading={isLoading}
            length={sortedData.length}
            header={(
                <AdminCategoryReportsTableHead
                    isFilteredByQuery={searchQuery.trim() !== ''}
                    sortingColumnType={sortingField}
                    sortingDirection={sortingDirection}
                    handleSorting={handleSorting}
                    selectedCountriesIds={selectedCountriesIds}
                    setSelectedCountriesIds={setSelectedCountriesIds}
                    countriesOptions={matchedCountries}
                />
            )}
            row={Row}
        />
    );
};

export default AdminCategoryReportsTable;