import RestaurantActivity from '@components/RestaurantActivity/RestaurantActivity';
import { iFoodProfileLTO } from '@models/FoodProfileLTO';
import { FC } from 'react';
import './RestaurantActivityList.scss';

const RestaurantActivityList: FC<{
    data: iFoodProfileLTO[],
}> = ({
    data = [],
}) => {
    return (
        <div className='restaurantActivityList__container'>
            {data.length > 0 &&
                data.map((value, idx) => (
                    <div key={idx} className='restaurantActivityList__item'>
                        <RestaurantActivity data={value} />
                    </div>
                )
            )}
        </div>
    )
};

export default RestaurantActivityList