import React, { FC, useEffect, useMemo, useState } from 'react';
import CheckboxInput from '@components/CheckboxInput/CheckboxInput';
import { CSSTransition } from 'react-transition-group';
import Dropdown from '@components/MenuItems/Dropdown/Dropdown';
import { ID } from '@models';
import { iOptionWithGroup } from '@models/FilterSettings';
import { toggleInArray } from '@core/old_helpers';
import { minusArrayUnique, uniteArraysUnique } from '@core/helpers';

const FilterCheckboxGroupDropdown: FC<{
    indexInRow: number;
    title: string;
    options: iOptionWithGroup[];
    value: ID[];
    onChange: (value: ID[]) => void;
}> = ({
    indexInRow,
    title,
    onChange,
    value: allValue,
    options,
}) => {
    const [isOpened, setIsOpened] = useState(false);
    const value = useMemo(
        () => allValue.filter(id => options.some(i => i.id === id)),
        [options, allValue]
    );
    const isAllSelected = useMemo(() => options.length === value.length, [options, value]);
    const isSelected = useMemo(() => value.length > 0 && !isAllSelected, [value, isAllSelected]);

    const handleToggleAll = () => {
        const allIds = options.map(i => i.id);
        if (isAllSelected) {
            onChange(minusArrayUnique(allValue, allIds));
        } else {
            onChange(uniteArraysUnique(allValue, allIds));
        }
    }

    const handleToggle = (id: ID, isGroup: boolean) => {
        if (id === 'all') {
            handleToggleAll();
        }
        else if (isGroup) {
            const groupOptionsIds = options.filter(option => option.groupId === id).map(option => option.id);
            const isAllSelected = groupOptionsIds.every(optionId => value.includes(optionId));

            if (isAllSelected) {
                onChange(minusArrayUnique(allValue, groupOptionsIds));
            } else {
                onChange(uniteArraysUnique(allValue, groupOptionsIds))
            }
        }
        else {
            onChange(toggleInArray(allValue, id));
        }
    }

    const toggleIsOpened = () => {
        console.log('click');
        setIsOpened(!isOpened);
    };

    const withGroups = useMemo(() => options.some(i => i.groupId || i.groupName), [options]);

    useEffect(() => {
        if (isOpened) {
            const closeDropdown = ({ target }: MouseEvent) => {
                const dropdownElement = document.querySelector(".AdminFilters__dropdown-enter-done");
                if (dropdownElement && !dropdownElement.contains(target as Node)) {
                    setIsOpened(false);
                }
            };

            document.addEventListener("click", closeDropdown, true);
            return () => {
                document.removeEventListener("click", closeDropdown, true);
            }
        }
    }, [ isOpened ]);

    return (
        <div className="AdminFilters__column p20">
            <div style={{ display: 'flex' }}>
                <CheckboxInput
                    isBlueCheck
                    onChange={handleToggleAll}
                    labelText={title}
                    isChecked={isAllSelected}
                    isPartialChecked={isSelected}
                    hasArrowIcon
                    isArrowDown={isOpened}
                    onClick={toggleIsOpened}
                />
            </div>
            {value.length > 0 && (
                <div>
                    {isAllSelected
                        ? 'All selected'
                        : `${value.length} of ${options.length} selected`
                    }
                </div>
            )}
            <CSSTransition
                in={isOpened}
                timeout={400}
                unmountOnExit
                classNames="AdminFilters__dropdown"
            >
                <Dropdown
                    options={options.map(option => ({
                        ...option,
                        name: option.title,
                        isChecked: value.includes(option.id),
                    }))}
                    handleChange={handleToggle}
                    withGroups={withGroups}
                    arrowLeft={`${1 + 20 * indexInRow}%`}
                />
            </CSSTransition>
        </div>
    );
};

export default FilterCheckboxGroupDropdown;