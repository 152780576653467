import React, { memo } from "react";
import Cell from "@components/NewAwesomeTable/Cell";
import Select from "@components/Select/Select";

const AdminReportTableHead = ({
    categoriesToOptions,
    selectedCategories,
    handleChangeCategories,
    subCategoriesToOptions,
    selectedSubCategories,
}) => {
    return (
        <>
            <Cell className="AdminTable__col AdminTable__col--fluid">
                <span className="AdminTable__truncated" title="Instant chart name">
                    Instant chart name
                </span>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--large-extra">
                <span className="AdminTable__truncated" title="IC Title">
                    IC Title
                </span>
                <span className="AdminTable__require-symbol">*</span>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--large-extra" options="hv">
                <div className="AdminTable__header-dropdown">
                    <Select
                        options={categoriesToOptions}
                        value={selectedCategories}
                        onChange={value => handleChangeCategories("category", value)}
                        isCheckboxHidden
                        wrapContent
                        stopPropagation
                        dropdownAlwaysBottom
                        prefix="IC Category"
                        placeholder=""
                        inheritedStyles
                        showModifierIcon
                        customScrollbar
                    />
                </div>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--large-extra" options="hv">
                <div className="AdminTable__header-dropdown">
                    <Select
                        options={subCategoriesToOptions}
                        value={selectedSubCategories}
                        onChange={value => handleChangeCategories("subcategory", value)}
                        isCheckboxHidden
                        wrapContent
                        stopPropagation
                        dropdownAlwaysBottom
                        prefix="IC Sub Category"
                        placeholder=""
                        inheritedStyles
                        showModifierIcon
                        customScrollbar
                    />
                </div>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--regular" options="center">
                Status
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--medium AdminTable__col--no-p">
                Data Cuts<span className="AdminTable__require-symbol">*</span>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--regular" options="center no-p"></Cell>
        </>
    );
};

export default memo(AdminReportTableHead);
