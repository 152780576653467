import { components } from '@apiSchema';
import { ID } from '@models/index';
import { iFilterSettings } from '@models/FilterSettings';

export interface iFilterValue {
    segments: ID[];
    hostedLocations: ID[];
    regions: ID[];
    cuisines: ID[];
    maxUnits: number | null;
    minUnits: number | null;
    menuItemTypes: ID[];
    minPrice: number | null;
    maxPrice: number | null;
    restaurants: components['schemas']['Application.Common.Models.MainFilterInput.FilterItem'][];
    customWords: components['schemas']['MenuTrends.Application.Contracts.Models.FilterWord'][];
    dayParts: ID[];
    catering: boolean | null;
    plantBased: boolean;
    smallPlates: boolean;
    glutenFree: boolean;
    healthyDining: boolean;
    kidMenus: boolean;
    tags: ID[];
}

type ApiModel = components['schemas']['Application.InstantCharts.InstantChartFilterDTO'];

interface iData {
    apiModel?: ApiModel;
    model?: iFilterValue;
}

export class FilterValue implements iFilterValue {
    static defaultData: iFilterValue = {
        segments: [],
        hostedLocations: [],
        regions: [],
        cuisines: [],
        maxUnits: null,
        minUnits: null,
        menuItemTypes: [],
        minPrice: null,
        maxPrice: null,
        restaurants: [],
        customWords: [],
        dayParts: [],
        catering: null,
        plantBased: false,
        smallPlates: false,
        glutenFree: false,
        healthyDining: false,
        kidMenus: false,
        tags: [],
    };

    segments = FilterValue.defaultData.segments;
    hostedLocations = FilterValue.defaultData.hostedLocations;
    regions = FilterValue.defaultData.regions;
    cuisines = FilterValue.defaultData.cuisines;
    maxUnits = FilterValue.defaultData.maxUnits;
    minUnits = FilterValue.defaultData.minUnits;
    menuItemTypes = FilterValue.defaultData.menuItemTypes;
    minPrice = FilterValue.defaultData.minPrice;
    maxPrice = FilterValue.defaultData.maxPrice;
    customWords = FilterValue.defaultData.customWords;
    dayParts = FilterValue.defaultData.dayParts;
    catering = FilterValue.defaultData.catering;
    plantBased = FilterValue.defaultData.plantBased;
    restaurants = FilterValue.defaultData.restaurants;
    smallPlates = FilterValue.defaultData.smallPlates;
    glutenFree = FilterValue.defaultData.glutenFree;
    healthyDining = FilterValue.defaultData.healthyDining;
    kidMenus = FilterValue.defaultData.kidMenus;
    tags = FilterValue.defaultData.tags;

    constructor(data?: iData) {
        if (data) {
            if (data.apiModel) {
                this.mapFromApi(data.apiModel);
            } else if (data.model) {
                this.setData(data.model);
            }
        }
    }

    private setData (data: iFilterValue) {
        Object.assign(this, data);
    }

    mapFromApi(data: ApiModel) {
        const {
            /* venues settings */
            segments,
            hostedLocations,
            regions,
            cuisines,
            maxUnits,
            minUnits,

            /*menu items settings*/
            menuItemTypes,
            minPrice,
            maxPrice,
            customWords,

            /* attributes settings */
            dayParts,
            catering,

            plantBased,
            restaurants,
            smallPlates,
            glutenFree,
            healthyDining,
            kidMenus,
            tags,
        } = data;

        this.setData({
            segments: segments || FilterValue.defaultData.segments,
            hostedLocations: hostedLocations || FilterValue.defaultData.hostedLocations,
            regions: regions || FilterValue.defaultData.regions,
            cuisines: cuisines || FilterValue.defaultData.cuisines,
            maxUnits: maxUnits || FilterValue.defaultData.maxUnits,
            minUnits: minUnits || FilterValue.defaultData.minUnits,
            menuItemTypes: menuItemTypes || FilterValue.defaultData.menuItemTypes,
            minPrice: minPrice || FilterValue.defaultData.minPrice,
            maxPrice: maxPrice || FilterValue.defaultData.maxPrice,
            customWords: customWords || FilterValue.defaultData.customWords,
            dayParts: dayParts || FilterValue.defaultData.dayParts,
            catering: catering ?? FilterValue.defaultData.catering,
            plantBased: plantBased || FilterValue.defaultData.plantBased,
            restaurants: restaurants || FilterValue.defaultData.restaurants,
            smallPlates: smallPlates || FilterValue.defaultData.smallPlates,
            glutenFree: glutenFree || FilterValue.defaultData.glutenFree,
            healthyDining: healthyDining || FilterValue.defaultData.healthyDining,
            kidMenus: kidMenus || FilterValue.defaultData.kidMenus,
            tags: tags || FilterValue.defaultData.tags,
        });
    }

    public mapForApi (): ApiModel {
        return {
            generatorFilter: [],
            restaurantListId: 0,
            words: [],

            segments: this.segments as number[],
            hostedLocations: this.hostedLocations as number[],
            regions: this.regions as number[],
            cuisines: this.cuisines as number[],
            restaurants: this.restaurants,
            minUnits: this.minUnits,
            maxUnits: this.maxUnits,

            menuItemTypes: this.menuItemTypes as number[],
            minPrice: this.minPrice,
            maxPrice: this.maxPrice,
            customWords: this.customWords,

            dayParts: this.dayParts as number[],
            catering: this.catering,
            smallPlates: this.smallPlates,
            glutenFree: this.glutenFree,
            healthyDining: this.healthyDining,
            kidMenus: this.kidMenus,
            plantBased: this.plantBased,
            tags: this.tags as number[],
        };
    }

    static getDefaultFilterValue (data: iFilterSettings): iFilterValue {
        return {
            segments: data.segments.map(i => i.id),
            hostedLocations: data.hostedLocations.map(i => i.id),
            regions: data.regions.map(i => i.id),
            cuisines: data.cuisines.map(i => i.id),
            maxUnits: FilterValue.defaultData.maxUnits,
            minUnits: FilterValue.defaultData.minUnits,
            menuItemTypes: [
                ...data.appetizers.map(i => i.id),
                ...data.entries.map(i => i.id),
                ...data.sides.map(i => i.id),
                ...data.desserts.map(i => i.id),
                ...data.beverages.map(i => i.id),
            ],
            minPrice: FilterValue.defaultData.minPrice,
            maxPrice: FilterValue.defaultData.maxPrice,
            customWords: FilterValue.defaultData.customWords,
            dayParts: data.dayParts.map(i => i.id),
            catering: FilterValue.defaultData.catering,
            plantBased: FilterValue.defaultData.plantBased,
            restaurants: FilterValue.defaultData.restaurants,
            smallPlates: FilterValue.defaultData.smallPlates,
            glutenFree: FilterValue.defaultData.glutenFree,
            healthyDining: FilterValue.defaultData.healthyDining,
            kidMenus: FilterValue.defaultData.kidMenus,
            tags: FilterValue.defaultData.tags,
        };
    }
}
// @ts-ignore
window.FilterValue = FilterValue;