import { components } from '@apiSchema';
import AbstractModel from '@models/AbstractModel';
import { ID, iOption } from '@models/index';
import { SLICES } from '@core/models/ByoTableData';

export interface iOptionWithGroup extends iOption {
    groupId: ID;
    groupName: string;
}

export interface iFilterSettings {
    restaurantListId: ID;
    segments: iOption[];
    regions: iOption[];
    dayParts: iOption[];
    hostedLocations: iOption[];
    cuisines: iOptionWithGroup[];
    appetizers: iOption[];
    entries: iOption[];
    sides: iOption[];
    desserts: iOption[];
    beverages: iOptionWithGroup[];
    restaurants: components['schemas']['MenuTrends.Application.Contracts.Models.BaseModel'][];
    words: components['schemas']['MenuTrends.Application.Contracts.Models.FilterWord'][];
    tagsCategories: iOption[];
    tags: Array<iOption & {
        categoryId: ID,
    }>;
}

type ApiModel = components["schemas"]["Application.Common.Models.MainFilterOutput.Filter"] & {
    restaurants?: components['schemas']['MenuTrends.Application.Contracts.Models.BaseModel'][];
    words?: components['schemas']['MenuTrends.Application.Contracts.Models.FilterWord'][];
    tagCategories?: components["schemas"]["Application.Common.Models.MainFilterOutput.FilterItemWithOrder"][];
    restaurantListId: ID;
};

const ITEM_TYPES_CONFIG = SLICES.find(item => item.id === 1006)?.columnsOrder || [];

export class FilterSettings extends AbstractModel<iFilterSettings, ApiModel> implements iFilterSettings {
    static defaultData: iFilterSettings = {
        restaurantListId: 0,
        segments: [],
        regions: [],
        dayParts: [],
        hostedLocations: [],
        cuisines: [],
        appetizers: [],
        entries: [],
        sides: [],
        desserts: [],
        beverages: [],
        restaurants: [],
        words: [],
        tags: [],
        tagsCategories: [],
    };

    restaurantListId = FilterSettings.defaultData.restaurantListId;
    segments = FilterSettings.defaultData.segments;
    regions = FilterSettings.defaultData.regions;
    dayParts = FilterSettings.defaultData.dayParts;
    hostedLocations = FilterSettings.defaultData.hostedLocations;
    cuisines = FilterSettings.defaultData.cuisines;
    appetizers = FilterSettings.defaultData.appetizers;
    entries = FilterSettings.defaultData.entries;
    sides = FilterSettings.defaultData.sides;
    desserts = FilterSettings.defaultData.desserts;
    beverages = FilterSettings.defaultData.beverages;
    restaurants = FilterSettings.defaultData.restaurants;
    words = FilterSettings.defaultData.words;
    tags = FilterSettings.defaultData.tags;
    tagsCategories = FilterSettings.defaultData.tagsCategories;

    mapFromApi(apiModel: ApiModel) {
        const result: iFilterSettings = FilterSettings.defaultData;

        const {
            restaurantFilters,
            menuPartFilterItems,
            restaurants,
            words,
            restaurantListId,
            tagCategories,
        } = apiModel;

        result.restaurantListId = restaurantListId;

        if (restaurantFilters) {
            const {
                segments = FilterSettings.defaultData.segments,
                regions = FilterSettings.defaultData.regions,
                dayParts = FilterSettings.defaultData.dayParts,
                hostedLocations = FilterSettings.defaultData.hostedLocations,
                cuisines = FilterSettings.defaultData.cuisines,
            } = restaurantFilters;

            if (segments) result.segments = FilterSettings.mapToOptions(segments);
            if (regions) result.regions = FilterSettings.mapToOptions(regions);
            if (dayParts) result.dayParts = FilterSettings.mapToOptions(dayParts);
            if (hostedLocations) result.hostedLocations = FilterSettings.mapToOptions(hostedLocations);
            if (cuisines) result.cuisines = FilterSettings.mapToGroupOptions(cuisines);
        }

        if (menuPartFilterItems) {
            const appetizers = menuPartFilterItems.find(i => i.id === 1);
            const entries = menuPartFilterItems.find(i => i.id === 2);
            const sides = menuPartFilterItems.find(i => i.id === 3);
            const desserts = menuPartFilterItems.find(i => i.id === 4);
            const beverages = menuPartFilterItems.find(i => i.id === 5);
            if (appetizers) result.appetizers = FilterSettings.mapToOptions(appetizers.menuItemTypes || FilterSettings.defaultData.appetizers);
            if (entries) result.entries = FilterSettings.mapToOptions(entries.menuItemTypes || FilterSettings.defaultData.entries);
            if (sides) result.sides = FilterSettings.mapToOptions(sides.menuItemTypes || FilterSettings.defaultData.sides);
            if (desserts) result.desserts = FilterSettings.mapToOptions(desserts.menuItemTypes || FilterSettings.defaultData.desserts);
            if (beverages) result.beverages = FilterSettings.mapToGroupOptions(beverages.menuItemTypes || FilterSettings.defaultData.beverages);

            result.appetizers.sort(FilterSettings.sortOptions);
            result.entries.sort(FilterSettings.sortOptions);
            result.sides.sort(FilterSettings.sortOptions);
            result.desserts.sort(FilterSettings.sortOptions);
            result.beverages.sort(FilterSettings.sortOptions);
        }

        result.restaurants = restaurants || FilterSettings.defaultData.restaurants;
        result.words = words || FilterSettings.defaultData.words;
        result.tags = result.words
            .filter(word => word.isTag)
            .map(i => ({
                id: i.id ?? 0,
                title: i.name ?? '',
                categoryId: i.tagCategoryId ?? 0,
            }));

        result.tagsCategories = tagCategories
            ?.map(i => ({
                id: i.id ?? 0,
                title: i.name || '',
            }))
            .filter(i => result.tags.some(tag => tag.categoryId === i.id))
            ?? FilterSettings.defaultData.tagsCategories;


        this.setData(result);
    }

    static sortOptions(optionA: iOption, optionB: iOption): -1 | 1 {
        const aValue = ITEM_TYPES_CONFIG.indexOf(optionA.id);
        const bValue = ITEM_TYPES_CONFIG.indexOf(optionB.id);
        return aValue <= bValue ? -1 : 1;
    }

    static mapToOptions(data?: any[]): iOption[] {
        if (!data) return [];
        return data.map(item => ({
            id: item.id || 0,
            title: item.name || item.title || '',
        }))
    }

    static mapToGroupOptions(data?: any[]): iOptionWithGroup[] {
        if (!data) return [];
        return data.map(item => ({
            id: item.id || 0,
            title: item.name || item.title || '',
            groupId: item.groupId || 0,
            groupName: item.groupName || '',
        }));
    }
}