import React, { useState, useReducer, useEffect, useContext } from "react";
import PT from "prop-types";
import Scrollbars from "react-scrollbars-custom";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import "./Accordion.scss";
import { GlobalContext } from "@components/App/App.context";

const Accordion = ({
    data,
    handleChange,
    groupsIdsOrder = [],
}) => {
    const { getRem } = useContext(GlobalContext);
    const [activeGroupIndex, setActiveGroupIndex] = useState(-1);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        if (data.length) {
            const newGroupsIds = Array.from(new Set(data.map(option => option.groupId)));
            const newGroups = newGroupsIds.map(groupId => ({
                id: groupId,
                name: data.find(option => option.groupId === groupId).groupName,
            }));

            newGroups.sort((a, b) => {
                const aIndex = groupsIdsOrder.indexOf(a.id);
                const bIndex = groupsIdsOrder.indexOf(b.id);
                return aIndex - bIndex;
            });
            setGroups(newGroups);
        }
    }, [data, groupsIdsOrder]);

    const handleToggleActiveGroup = (index) => {
        if (activeGroupIndex === index) {
            setActiveGroupIndex(-1);
        } else {
            setActiveGroupIndex(index);
        }
    };

    return (
        <>
            {groups.map((group, groupIndex) => {
                const isOpened = groupIndex === activeGroupIndex;
                const classList = new Set([
                    "Accordion",
                    isOpened ? "Accordion--opened" : "",
                ]);

                const options = data.filter(option => option.groupId === group.id);

                return (
                    <div
                        key={group.id}
                        onClick={() => handleToggleActiveGroup(groupIndex)}
                        className={Array.from(classList).join(" ")}
                        data-testid={`selectAccordionGroup-${groupIndex}`}
                    >
                        <CheckboxInput
                            labelText={group.name}
                            onChange={() => handleChange(group.id, true)}
                            onClick={() => handleToggleActiveGroup(groupIndex)}
                            isChecked={options.every(o => o.isChecked)}
                            isPartialChecked={options.some(o => o.isChecked) && !options.every(o => o.isChecked)}
                            hasArrowIcon
                            stopPropagation
                            isWhiteMark
                            isArrowDown={isOpened}
                            testId={`inputAccordionGroupCheckbox-${groupIndex}`}
                        />
                        <div className="Accordion__scrollbar-wrap">
                            <Scrollbars
                                trackYProps={{
                                    style: {
                                        width: getRem(10),
                                    },
                                }}
                            >
                                {data.filter(option => option.groupId === group.id).map((option, optionIndex) => (
                                    <div
                                        key={option.id}
                                        className="Accordion__item"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                        }}
                                    >
                                        <CheckboxInput
                                            isSmall
                                            onChange={() => handleChange(option.id)}
                                            labelText={option.name}
                                            isChecked={option.isChecked}
                                            stopPropagation
                                            testId={`inputAccordionItemCheckbox-${groupIndex}-${optionIndex}`}
                                        />
                                    </div>
                                ))}
                            </Scrollbars>
                        </div>
                    </div>
                );
            })}
            </>
    );
};

Accordion.propTypes = {
    data: PT.array,
    handleChange: PT.func,
    groupsIdsOrder: PT.array,
};

export default Accordion;
