import { components } from '@apiSchema';
import { ID, iOption } from '@models/index';
import { FilterValue } from '@models/FilterValue';

export interface iCategoryReport {
    id: number;
    name: string;
    description: string;
    note: string;
    isActive: boolean;
    countryCode: string;
    restaurantListId: ID;
    universe: FilterValue;
    keywords: iOption[];
    instantCharts: iOption[];
    mostPopularInstantCharts: iOption[];
    topTrendCategories: ID[];
    differentVarietiesMAC: ID[];
    ingredientsMAC: ID[];
    insiderCategories: ID[];
    flavorCategories: ID[];
    topTrendCategoriesUniverse: ID[];
}

type ApiModel = components['schemas']['Application.CategoryReports.DTO.CategoryAnalysisExportInputDTO'];

interface iData {
    apiModel?: ApiModel;
    model?: iCategoryReport;
}

export class CategoryReport implements iCategoryReport {
    static defaultData: iCategoryReport = {
        id: 0,
        name: '',
        description: '',
        note: '',
        isActive: false,
        countryCode: '',
        restaurantListId: 0,
        universe: new FilterValue(),
        keywords: [],
        instantCharts: [],
        mostPopularInstantCharts: [],
        topTrendCategories: [],
        differentVarietiesMAC: [],
        ingredientsMAC: [],
        insiderCategories: [],
        flavorCategories: [],
        topTrendCategoriesUniverse: [],
    }

    id = CategoryReport.defaultData.id;
    name = CategoryReport.defaultData.name;
    description = CategoryReport.defaultData.description;
    note = CategoryReport.defaultData.note;
    isActive = CategoryReport.defaultData.isActive;
    countryCode = CategoryReport.defaultData.countryCode;
    restaurantListId = CategoryReport.defaultData.restaurantListId;
    universe = CategoryReport.defaultData.universe;
    keywords = CategoryReport.defaultData.keywords;
    instantCharts = CategoryReport.defaultData.instantCharts;
    mostPopularInstantCharts = CategoryReport.defaultData.mostPopularInstantCharts;
    topTrendCategories = CategoryReport.defaultData.topTrendCategories;
    differentVarietiesMAC = CategoryReport.defaultData.differentVarietiesMAC;
    ingredientsMAC = CategoryReport.defaultData.ingredientsMAC;
    insiderCategories = CategoryReport.defaultData.insiderCategories;
    flavorCategories = CategoryReport.defaultData.flavorCategories;
    topTrendCategoriesUniverse = CategoryReport.defaultData.topTrendCategoriesUniverse;

    constructor(data?: iData) {
        if (data) {
            if (data.apiModel) {
                this.mapFromApi(data.apiModel);
            } else if (data.model) {
                this.setData(data.model);
            }
        }
    }

    setData (data: iCategoryReport) {
        Object.assign(this, data);
    }

    private mapFromApi (apiModel: ApiModel) {
        const universe: iCategoryReport['universe'] = new FilterValue({ apiModel: apiModel.universe});
        let mostPopularInstantCharts = CategoryReport.defaultData.mostPopularInstantCharts
        let instantCharts = CategoryReport.defaultData.instantCharts
        let keywords = CategoryReport.defaultData.keywords

        if (!!apiModel.mostPopularInstantCharts) {
            mostPopularInstantCharts = Object.keys(apiModel.mostPopularInstantCharts).map((id) => {
                return {
                    id: +id,
                    title: (apiModel.mostPopularInstantCharts || {})[id] || '',
                };
            });
        }

        if (!!apiModel.instantCharts) {
            instantCharts = Object.keys(apiModel.instantCharts).map((id) => {
                return {
                    id: +id,
                    title: (apiModel.instantCharts || {})[id] || '',
                };
            });
        }

        if (!!apiModel.keywords) {
            keywords = Object.keys(apiModel.keywords).map((id) => {
                return {
                    id: +id,
                    title: (apiModel.keywords || {})[id] || '',
                };
            });
        }


        this.setData({
            id: apiModel.id || CategoryReport.defaultData.id,
            name: apiModel.name || CategoryReport.defaultData.name,
            description: apiModel.description || CategoryReport.defaultData.description,
            note: apiModel.note || CategoryReport.defaultData.note,
            isActive: apiModel.isActive || CategoryReport.defaultData.isActive,
            countryCode: apiModel.countryCode || CategoryReport.defaultData.countryCode,
            restaurantListId: apiModel.restaurantListId || CategoryReport.defaultData.restaurantListId,
            universe,
            instantCharts,
            keywords,
            mostPopularInstantCharts,
            topTrendCategories: apiModel.topTrendCategories || CategoryReport.defaultData.topTrendCategories,
            differentVarietiesMAC: apiModel.externalSettings?.differentVarietiesMAC || CategoryReport.defaultData.differentVarietiesMAC,
            ingredientsMAC: apiModel.externalSettings?.ingredientsMAC || CategoryReport.defaultData.ingredientsMAC,
            insiderCategories: apiModel.externalSettings?.insiderCategories || CategoryReport.defaultData.insiderCategories,
            flavorCategories: apiModel.externalSettings?.flavorCategories || CategoryReport.defaultData.flavorCategories,
            topTrendCategoriesUniverse: apiModel.topTrendCategoriesUniverse || CategoryReport.defaultData.topTrendCategoriesUniverse,
        })
    }

    public mapForApi (): ApiModel {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            note: this.note,
            isActive: this.isActive,
            countryCode: this.countryCode,
            restaurantListId: this.restaurantListId as number,
            universe: this.universe.mapForApi(),
            keywords: this.keywords.reduce((acc, item) => ({
                ...acc,
                [item.id]: item.title,
            }), {}),
            instantCharts: this.instantCharts.reduce((acc, item) => ({
                ...acc,
                [item.id]: item.title,
            }), {}),
            mostPopularInstantCharts: this.mostPopularInstantCharts.reduce((acc, item) => ({
                ...acc,
                [item.id]: item.title,
            }), {}),
            topTrendCategories: this.topTrendCategories as number[],
            externalSettings: {
                flavorCategories: this.flavorCategories as number[],
                insiderCategories: this.insiderCategories as number[],
                ingredientsMAC: this.ingredientsMAC as number[],
                differentVarietiesMAC: this.differentVarietiesMAC as number[],
            },
            topTrendCategoriesUniverse: this.topTrendCategoriesUniverse as number[],
        };
    }
}