import Icon from "@components/Icon/Icon";
import { IconSortASC, IconSortDESC } from "@icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setData, setField } from "@actions/adminReports.actions";
import Cell from "@components/NewAwesomeTable/Cell";
import Select from "@components/Select/Select";
import TooltipTabs from "@components/TooltipTabs/TooltipTabs";
import {
    getCountriesToOptions,
    selectedCountryIdsSelector,
    searchQuerySelector
} from "@selectors/adminReports.selectors";

function AdminReportsTableHead({
    selectedCountryIds,
    setSelectedContryIds,
    countriesToOptions,
    searchQuery,
    setSortingData,
    sortingDirection,
    sortingColumnType
}) {
    const [showTooltip, setShowTooltip] = useState(false);
    const [query, setQuery] = useState(false);


    useEffect(() => {
        setQuery(searchQuery.trim().length > 0)
    }, [searchQuery]);

    const toggleTooltipShow = () => {
        setShowTooltip(!showTooltip);
    };

    const handleSorting = (type) => {
        if (query) return;

        setSortingData({
            sortingColumnType: type,
            sortingDirection: sortingColumnType === type ? sortingDirection === "dec" ? "asc" : "dec" : "asc"
        });
    }

    const handleChangeFilters = (type, ids) => {
        switch (type) {
            case "country":
                setSelectedContryIds(ids);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Cell
                className="AdminTable__col AdminTable__col--fluid AdminTable__col--max-half"
                onMouseOver={toggleTooltipShow}
                onMouseOut={toggleTooltipShow}
                onClick={() => handleSorting("name")}
                options={!query ? "hv" : ""}>

                <span className={`AdminTable__header-label ${sortingColumnType === "name" ? "has-icon" : ""}`}>
                    Report name
                    {(sortingColumnType === "name" && !query) && (
                        <div className="AdminTable__header-icon">
                            {sortingDirection === "asc" ? <Icon type="small">
                                <IconSortASC />
                            </Icon> : <Icon type="small">
                                <IconSortDESC />
                            </Icon>}
                        </div>
                    )}
                </span>
                <TooltipTabs isShown={showTooltip && sortingColumnType === "name" && !query}>
                    <span>{sortingDirection === "asc" ? "A - Z" : "Z - A"}</span>
                </TooltipTabs>
            </Cell>
            <Cell
                className="AdminTable__col AdminTable__col--large"
                options={!query ? "hv" : ""}
                onClick={() => handleSorting("childReportsCount")}>
                <span className={`AdminTable__header-label ${sortingColumnType === "childReportsCount" ? "has-icon" : ""}`}>
                    # of child reports
                    {(sortingColumnType === "childReportsCount" && !query) && (
                        <div className="AdminTable__header-icon">
                            {sortingDirection === "asc" ? <Icon type="small">
                                <IconSortASC />
                            </Icon> : <Icon type="small">
                                <IconSortDESC />
                            </Icon>}
                        </div>
                    )}
                </span>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--large" options="hv">
                <div className="AdminTable__header-dropdown">
                    <Select
                        placeholder=""
                        options={countriesToOptions}
                        value={selectedCountryIds}
                        onChange={value => handleChangeFilters("country", value)}
                        isCheckboxHidden
                        labelIcon
                        stopPropagation
                        prefix="All countries"
                        inheritedStyles
                        showModifierIcon
                        customScrollbar
                        wrapContent
                    />
                </div>
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--medium">
                Status
            </Cell>
            <Cell className="AdminTable__col AdminTable__col--small" />
        </>
    );
}

const mapStateToProps = state => {
    return {
        selectedCountryIds: selectedCountryIdsSelector(state),
        countriesToOptions: getCountriesToOptions(state),
        searchQuery: searchQuerySelector(state),
        sortingDirection: state.adminReports.sortingDirection,
        sortingColumnType: state.adminReports.sortingColumnType
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setSelectedContryIds: ids => dispatch(setField("selectedCountryIds", ids)),
        setSortingData: (data) => dispatch(setData(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminReportsTableHead);
