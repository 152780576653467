import React from 'react';
import { createSelector } from 'reselect';
import { CUSTOM_GROWTH_SLICES, ORIGINAL_GROWTH_SLICES, SLICES, SlicesEnum } from '@core/models/ByoTableData';
import {
    capitalize,
    compareInLC,
    deepCopy,
    getFormattedThousand,
    getMonth,
    getPairedFlavorsData, equalInLC
} from '@core/old_helpers';
import { currentCurrencySelector, currentCountrySelector, currentCurrencyStringSelector } from "./countries.selector"
import { ReactComponent as West } from "@assets/images/West.svg";
import { ReactComponent as Midwest } from "@assets/images/Midwest.svg";
import { ReactComponent as NorthEast } from "@assets/images/NorthEast.svg";
import { ReactComponent as South } from "@assets/images/South.svg";
import { getChartData } from '@components/Chart/Chart.helpers';
import { getFormattedPrice } from "@core/helpers";

function getMacStageNumeric (macStage) {
    switch (macStage.toLowerCase()) {
        case 'inception': return 1;
        case 'adoption': return 2;
        case 'proliferation': return 3;
        case 'ubiquity': return 4;
        default: return null;
    }
}

const imagesMapList = [
    {
        image: West,
        id: 1,
    },
    {
        image: Midwest,
        id: 2,
    },
    {
        image: South,
        id: 3,
    },
    {
        image: NorthEast,
        id: 4,
    },

];
const byoSelector = state => state.byo;
const activeSliceIndexSelector = state => state.byo.activeSliceIndex;
const activeAnalyticsTypeSelector = state => state.byo.activeAnalyticsType;
const sortingColumnSelector = state => state.byo.sortingColumn;
const sortingDirectionSelector = state => state.byo.sortingDirection;
const chartDataTypeSelector = state => state.byo.chartDataType;
const activeValuesTypeSelector = state => state.byo.activeValuesType;
const keywordsSelector = state => state.keywords.data;
const byoDataSelector = state => state.byo.data;
const activeKeywordIdSelector = state => state.byo.activeKeywordId;
const activePastGrowthSliceIdSelector = state => state.byo.pastGrowthSliceId;
const tooltipsDataSelector = state => state.filters.tooltipsData;


export const searchAutocompleteSelector = createSelector(
    byoSelector,
    keywordsSelector,
    ({ searchQuery }, keywords) => {
        if (searchQuery.length < 3) return [];
        return keywords
            .filter(
                ({ name }) =>
                    name.toLowerCase().indexOf(searchQuery.toLowerCase()) === 0
            )
            .map(({ name, id }) => ({ name, id }))
            .slice(0, 10);
    }
);


export const tableSlices = SLICES.filter((slice) => slice.isTableSlice);

export const tableAvailableSlicesSelector = createSelector(
    activeSliceIndexSelector,
    activeAnalyticsTypeSelector,
    activeValuesTypeSelector,
    byoDataSelector,
    currentCountrySelector,
    activePastGrowthSliceIdSelector,
    (activeSliceIndex, activeAnalyticsType, activeValuesType, byoData, currentCountry, activePastGrowthSliceId) => {
        if (byoData.length === 0) return [];
        const { keyword, slices } = byoData[0];
        if (!slices) return [];

        return tableSlices
            .filter(tableSlice => {
                const activeSlice = SLICES.find(slice => tableSlice.id === slice.id);
                const countries = activeSlice.visibleForCountries;

                if (
                    activeSlice.id === SlicesEnum.Consumers
                    && countries?.every(c => !equalInLC(c, currentCountry))
                ) return false;

                const cells = getKeywordCells
                    .bind({
                        activeAnalyticsType,
                        activeValuesType,
                        activeSlice,
                        activePastGrowthSliceId,
                    })(keyword, slices);

                return cells.filter(({ sliceId }) => sliceId === activeSlice.id).length > 0;
            })
            .map(({ name, id, tooltip }, index) => ({
                name,
                id,
                isActive: activeSliceIndex === index,
                tooltip,
            }))
    }
);

export function getMacStageName(macStage) {
    switch (macStage) {
        case 0:
            return '-';
        case 1:
            return 'Inception';
        case 2:
            return 'Adoption';
        case 3:
            return 'Proliferation';
        case 4:
            return 'Ubiquity';
        default:
            return '-';
    }
}

function getKeywordCells(keyword, data) {
    const { activeSlice, activeAnalyticsType, activeValuesType, activePastGrowthSliceId } = this;
    const result = activeSlice.columns.flatMap((column) => {
        let cells = [];
        let sliceId = column;

        if (column === SlicesEnum.PastGrowth) {
            cells = data[`${activeAnalyticsType}:${activeValuesType}:${activePastGrowthSliceId}`]
            sliceId = activePastGrowthSliceId;
        }
        else if (column === SlicesEnum.PredictedGrowth) {
            cells = data[`${activeAnalyticsType}:${activeValuesType}:${SlicesEnum.PredictedFourYearTrend}`]
            sliceId = SlicesEnum.PredictedFourYearTrend;
        }
        else {
            cells = data[`${activeAnalyticsType}:${activeValuesType}:${column}`]
        }

        return cells.map((data) => ({
            ...data,
            sliceId,
            originalSliceId: column,
        }));
    });

    if (activeSlice.columnsOrder) {
        result.sort((a, b) => {
            if (a.sliceId === SlicesEnum.Total) return -1;
            if (b.sliceId === SlicesEnum.Total) return 1;
            let orderA = activeSlice.columnsOrder.indexOf(a.id);
            let orderB = activeSlice.columnsOrder.indexOf(b.id);

            if (orderA === -1) orderA = activeSlice.columnsOrder.length;
            if (orderB === -1) orderB = activeSlice.columnsOrder.length;

            return orderA < orderB ? -1 : 1;
        });
    }

    result.unshift({
        name: 'MAC',
        sliceId: null,
        value: getMacStageName(keyword.macStage),
    });

    return result;
}

const DARK_COLUMNS = [
    SlicesEnum.Total,
    SlicesEnum.OneYearTrend,
    SlicesEnum.FourYearTrend,
    SlicesEnum.PredictedFourYearTrend,
];

export const tableDataSelector = createSelector(
    activeAnalyticsTypeSelector,
    activeValuesTypeSelector,
    tableAvailableSlicesSelector,
    sortingColumnSelector,
    sortingDirectionSelector,
    byoDataSelector,
    currentCountrySelector,
    currentCurrencySelector,
    currentCurrencyStringSelector,
    tooltipsDataSelector,
    activePastGrowthSliceIdSelector,
    (
        activeAnalyticsType,
        activeValuesType,
        tableAvailableSlices,
        sortingColumnFromStore,
        sortingDirection,
        byoData,
        currentCountry,
        currentCurrency,
        currentCurrencyString,
        tooltipsData,
        activePastGrowthSliceId,
        manipulateGrowthColumns,
    ) => {
        const result = {
            keywords: [],
            columns: [
                { content: '', isHeader: true, id: 'fake 1' },
                { content: '', isHeader: true, id: 'fake 2' },
                { content: '', isHeader: true, id: 'fake 3' },
                { content: '', isHeader: true, id: 'fake 4' },
                { content: '', isHeader: true, id: 'fake 5' },
                { content: '', isHeader: true, id: 'fake 6' },
                { content: '', isHeader: true, id: 'fake 7' },
                { content: '', isHeader: true, id: 'fake 8' },
                { content: '', isHeader: true, id: 'fake 9' },
            ],
            baseSizes: [
                { content: '', isFooter: true },
                { content: '', isFooter: true },
                { content: '', isFooter: true },
                { content: '', isFooter: true },
                { content: '', isFooter: true },
                { content: '', isFooter: true },
                { content: '', isFooter: true },
                { content: '', isFooter: true },
                { content: '', isFooter: true },
            ],
            values: [],
        };
        const data = deepCopy(byoData);
        result.keywords = data.map((item) => {
            const { keyword, color, slices = {}, pairedFlavors } = item;
            const { exactMatch, id, name, wordExtensions } = keyword;

            const chartData = slices[`${activeAnalyticsType}:1:${SlicesEnum.Period}`] || [
                { name: 2019, value: 0 },
                { name: 2018, value: 0 },
            ];

            chartData.sort((a, b) => {
                return a.name < b.name ? -1 : 1;
            });

            const totalCell = slices[`${activeAnalyticsType}:1:${SlicesEnum.Total}`];

            const result = {
                id,
                name,
                color,
                wordExtensions,
                isFoodProfile: keyword.isFoodProfile,
                content: exactMatch ? `"${name.toUpperCase()}"` : name.toUpperCase(),
                keyword,
                chartData,
                pairedFlavors,
            };

            if (totalCell && totalCell[0]) {
                result.menuExamplesData = {
                    id: totalCell ? totalCell[0].id : 29,
                    sliceId: SlicesEnum.Total,
                };
            }

            return result;
        });

        /* generate empty values */
        result.values = result.keywords.map(() => [
            { content: '', isPlaceholder: true },
            { content: '', isPlaceholder: true },
            { content: '', isPlaceholder: true },
            { content: '', isPlaceholder: true },
            { content: '', isPlaceholder: true },
            { content: '', isPlaceholder: true },
        ]);

        if (tableAvailableSlices.length === 0) return result;
        const activeSlice = SLICES.find(
            slice => {
                const tableSlice = tableAvailableSlices.find(({ id }) => id === slice.id);
                return !!tableSlice && tableSlice.isActive;
            }
        );

        if (!data.length || !activeSlice) return result;

        const getCells = getKeywordCells.bind({
            activeAnalyticsType,
            activeValuesType,
            activeSlice,
            activePastGrowthSliceId,
        });

        result.values = data
            .map(({ keyword, slices }) => slices ? getCells(keyword, slices) : [])
            .map(row => row
                .map((cell) => {
                    let content = cell.value;
                    let copyTableValue = cell.value;

                    const valueNum = +cell.value;

                    if (activeAnalyticsType === 3) {
                        if (ORIGINAL_GROWTH_SLICES.includes(cell.sliceId)) {
                            if (!isNaN(valueNum)) {
                                content = `${valueNum >= 0 ? '+' : ''}${Math.round(valueNum)}%`;
                                copyTableValue = `${Math.round(valueNum)}%`;
                            } else {
                                content = 'N/A';
                            }
                        } else if (cell.sliceId === SlicesEnum.Consumers) {
                            if (!isNaN(valueNum)) {
                                content = `${Math.round(valueNum)}%`;
                                copyTableValue = `${Math.round(valueNum)}%`;
                            }
                        } else {
                            content = (
                                <>
                                    <span className="table__cell-currency-icon">
                                        {(cell.value !== '-' && cell.name !== 'MAC') && currentCurrency}
                                    </span>
                                    <span>
                                        {cell.value}
                                    </span>
                                </>
                            );
                        }
                    } else if (!isNaN(valueNum)) {
                        if (ORIGINAL_GROWTH_SLICES.includes(cell.sliceId)) {
                            content = `${valueNum >= 0 ? '+' : ''}${Math.round(valueNum)}%`;
                            copyTableValue = `${Math.round(valueNum)}%`;
                        } else if (cell.sliceId === SlicesEnum.Consumers) {
                            content = `${Math.round(valueNum)}%`;
                            copyTableValue = `${Math.round(valueNum)}%`;
                        } else {
                            content = activeValuesType === 1
                                ? `${valueNum.toFixed(1)}%`
                                : `${getFormattedThousand(valueNum)}`;
                            copyTableValue = content;
                        }
                    }

                    if (cell.name === 'MAC' && currentCountry !== 'USA') {
                        content = "coming soon"
                        copyTableValue = '-';
                    }

                    return {
                        ...cell,
                        content,
                        copyTableValue,
                    };
                })
            );

        result.columns = result.values[0].map(({ name, id, sliceId, originalSliceId }, index) => {

                let tooltipData;

                if (sliceId === SlicesEnum.Regions) {
                    tooltipData = imagesMapList.find(map => map.id === id)
                } else {
                    tooltipData = tooltipsData.filter(({ slice }) => sliceId === slice).find((el) => el.id === id)
                }
                return ({
                    tooltipData,
                    content: name,
                    id: id || sliceId || `static ${index}`,
                    isHeader: true,
                    sliceId,
                    originalSliceId,
                    isTableOnly: CUSTOM_GROWTH_SLICES.includes(originalSliceId),
                    isCopyTableOnly: ORIGINAL_GROWTH_SLICES.includes(originalSliceId),
                    isDark: DARK_COLUMNS.includes(sliceId),
                    isMedium: originalSliceId === SlicesEnum.PredictedGrowth,
                })
            }
        );

        result.baseSizes = result.values[0].map(({ baseSize }) => {
            let content = +baseSize;
            const suffix = currentCountry === 'USA' ? '' : `(${currentCurrencyString})`;
            const prefix = currentCountry === 'USA' ? currentCurrencyString : '';
            if (isNaN(content)) {
                content = baseSize;
            } else {
                content = activeAnalyticsType === 3
                    ? `${prefix}${getFormattedPrice(content)} ${suffix}`
                    : getFormattedThousand(content);
            }
            return {
                content,
                isFooter: true,
            };
        });

        const totalColumnIndex = result.columns.findIndex(
            ({ sliceId }) => sliceId === SlicesEnum.Total
        );

        const sortingColumn = sortingColumnFromStore === null
            ? (totalColumnIndex >= 0 ? totalColumnIndex : 0)
            : sortingColumnFromStore;
        const direction = sortingDirection === 'asc' ? -1 : 1;
        result.columns = result.columns.map((column, index) => {
            const newColumn = { ...column };
            if (index === sortingColumn) {
                newColumn.sortIconType = sortingDirection;
            }

            if (column.originalSliceId === SlicesEnum.PastGrowth) {
                newColumn.content = 'Past Growth:';
                newColumn.options = [
                    { id: SlicesEnum.OneYearTrend, title: '12 months', isActive: activePastGrowthSliceId === SlicesEnum.OneYearTrend, },
                    { id: SlicesEnum.FourYearTrend, title: '4 years', isActive: activePastGrowthSliceId === SlicesEnum.FourYearTrend, },
                ];
            }

            if (column.originalSliceId === SlicesEnum.PredictedGrowth) {
                newColumn.content = 'Predicted Growth:';
                newColumn.options = [
                    { id: SlicesEnum.PredictedFourYearTrend, title: '4 years', isActive: true, },
                ];
            }

            return newColumn;
        });
        const keywordsAndValues = result.keywords.map((keyword, index) => ({
            keyword,
            values: result.values[index],
        }));
        keywordsAndValues.sort((a, b) => {
            if (a.values.length === 0) return 1;
            if (b.values.length === 0) return -1;
            if (a.values[sortingColumn].name.toLowerCase() === 'mac') {
                const aValue = getMacStageNumeric(a.values[sortingColumn].value);
                const bValue = getMacStageNumeric(b.values[sortingColumn].value);
                if (!aValue) return 1;
                if (!bValue) return -1;
                return aValue < bValue ? direction : -direction;
            }
            const aValue = +a.values[sortingColumn].value;
            const bValue = +b.values[sortingColumn].value;
            if (Number.isNaN(aValue)) return 1;
            if (Number.isNaN(bValue)) return -1;
            return aValue < bValue ? direction : -direction;
        });

        return {
            ...result,
            keywords: keywordsAndValues.map(({ keyword }) => keyword),
            values: keywordsAndValues.map(({ values }) => values),
            hasFlavorPermission: data.some(word => word.flavorData && word.flavorData.permissionLevel > 0),
        };
    }
);

const getAvarage = function (arr) {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
        sum += parseFloat(arr[i].value, 10);
    }

    return sum / arr.length;
};

export const chartDataSelector = createSelector(
    byoDataSelector,
    activeAnalyticsTypeSelector,
    chartDataTypeSelector,
    activeKeywordIdSelector,
    currentCurrencySelector,
    (byoData, activeAnalyticsType, chartDataType, activeKeywordId, currentCurrency) => {
        const data = deepCopy(byoData).filter(item => item.slices && item.color);
        const yearsData = data
            .map(
                item => {
                    return {
                        ...item,
                        normalizedYears: [
                            ...item.slices[`${activeAnalyticsType}:1:${SlicesEnum.Period}`],
                            ...item.slices[`${activeAnalyticsType}:1:${SlicesEnum.PredictPeriod}`],
                        ]
                            .sort((a, b) => a.name < b.name ? -1 : 1)
                    }
                }
            );
        if (yearsData.length === 0) {
            return {
                chartsCount: 0,
                chartInfo: [],
                data: [],
            };
        }

        yearsData.sort((a, b) => {
            const avarageA = getAvarage(a.normalizedYears);
            const avarageB = getAvarage(b.normalizedYears);
            return ((avarageA === avarageB) ? 0 : ((avarageA < avarageB) ? 1 : -1));
        });

        const result = {
            hasPrediction: yearsData[0].normalizedYears.length === 15,
            chartsCount: yearsData.length,
            chartInfo: yearsData.map(item => ({
                name: item.keyword.name,
                opacity: activeKeywordId && activeKeywordId !== item.keyword.name.toLowerCase() ? 0.1 : 1,
                color: item.color || 'white',
            })),
            data: yearsData[0].normalizedYears.map(({ name }, index) => {
                return {
                    isPrediction: index >= 11,
                    label: name.indexOf(' Half') > 0 ? +name.substr(0, 4) + 0.5 : +name,
                    values: yearsData.map(({ normalizedYears: keywordData }) => {
                        let value = +keywordData[index].value;
                        if (!isNaN(value)) {
                            if (chartDataType === 2 && value !== 0) {
                                value = (keywordData[index].value / keywordData[0].value) * 100;
                            }
                            return +value.toFixed(activeAnalyticsType === 3 ? 2 : 1);
                        }
                        return keywordData[index].valueNumeric
                    }),
                    valuesLabels: yearsData.map(({ normalizedYears: keywordData }) => {
                        let value = +keywordData[index].value;
                        if (!isNaN(value) && activeAnalyticsType !== 3) {
                            return `${value.toFixed(1)}%`;
                        }
                        return `${currentCurrency}${keywordData[index].value}`;
                    }),
                };
            }),
        };
        return result;
    }
);

export const newChartDataSelector = createSelector(
    byoDataSelector,
    currentCountrySelector,
    activeAnalyticsTypeSelector,
    activeKeywordIdSelector,
    (byoData, currentCountry, activeAnalyticsType, activeKeywordId) => {
        const data = deepCopy(byoData).filter(item => item.slices && item.color);

        if (data.length === 0) {
            return {
                columns: [],
                charts: [],
            };
        }

        const words = data.map(word => {
            const actualData = word.slices[`${activeAnalyticsType}:1:${SlicesEnum.Period}`] || [];
            const predictedData = word.slices[`${activeAnalyticsType}:1:${SlicesEnum.PredictPeriod}`] || [];
            const actualDataQuarters = word.slices[`${activeAnalyticsType}:1:${SlicesEnum.PeriodQuarter}`] || [];
            const predictedDataQuarters = word.slices[`${activeAnalyticsType}:1:${SlicesEnum.PredictPeriodQuarter}`] || [];

            actualData.sort((a, b) => a.name <= b.name ? -1 : 1);
            predictedData.sort((a, b) => a.name <= b.name ? -1 : 1);
            actualDataQuarters.sort((a, b) => a.name <= b.name ? -1 : 1);
            predictedDataQuarters.sort((a, b) => a.name <= b.name ? -1 : 1);

            return {
                title: word.keyword.name,
                isActive: word.keyword.id === activeKeywordId,
                color: word.color,
                actualData,
                predictedData,
                actualDataQuarters,
                predictedDataQuarters,
                concatData: [
                    ...actualData,
                    ...actualDataQuarters,
                    ...predictedDataQuarters,
                    ...predictedData,
                ],
            }
        });

        const mappedData = getChartData(words);

        if (currentCountry === 'CAN') {
            mappedData.columns.forEach(column => {
                if (column.title === '2020') {
                    column.tooltipSubTitle = `
                        <span class="Canada-2020-interpolated-label">
                            <span class="Canada-2020-interpolated-label__inner">
                                extrapolated due to covid
                            </span>
                        </span>
                    `;
                }
            });
        }

        return mappedData;
    },
);

export const barChartDataSelector = createSelector(
    chartDataSelector,
    (chartData) => {
        if (!chartData.data.length) return [];
        const years = chartData.data.map(item => item.label);

        years.sort((a, b) => a > b ? -1 : 1);
        const currentYear = chartData.data.find(year => year.label === years[0]);


        const result = currentYear.values.map((value, index) => ({
            value,
            title: chartData.chartInfo[index].name,
            color: chartData.chartInfo[index].color,
            id: index,
        }));

        return result;
    }
);


export const pairedFlavorsDataSelector = createSelector(
    tableDataSelector,
    activeKeywordIdSelector,
    (data, activeKeywordId) => {
        const nonEmptyWords = data.keywords.filter(word => !!word.pairedFlavors);
        if (activeKeywordId) {
            const word = data.keywords.find(word => compareInLC(word.keyword.name, activeKeywordId));
            return [{
                title: capitalize(activeKeywordId),
                items: getPairedFlavorsData(word),
            }]
        } else if (nonEmptyWords.length) {
            return [
                ...nonEmptyWords.map(word => {
                    return ({
                        title: capitalize(word.keyword.name),
                        items: getPairedFlavorsData(word),
                    })
                })
            ]
        } else {
            return [];
        }
    }
);

export const copyTableDataSelector = createSelector(
    tableDataSelector,
    activeAnalyticsTypeSelector,
    ({ columns, keywords, values, baseSizes }, activeAnalyticsType) => {

        let label;
        switch (activeAnalyticsType) {
            case 1:
                label = 'Number of restaurants';
                break;
            case 2:
                label = 'Number of items';
                break;
            case 3:
                label = 'Median price';
                break;
            default:
                return label;
        }

        const d = new Date();
        const date = `Extracted ${getMonth(d)} ${d.getDate()}, ${d.getFullYear()}`;

        const unionColumns = columns
            .map((column, index) => ({
                column,
                value: values.map(v => v[index]),
                baseSize: baseSizes[index],
            }))
            .filter((i) => !i.column.isTableOnly);

        const rows = [
            ['', ...unionColumns.map(({ column: { content } }) => `${content}`)],
            ...keywords.map(({ content }, index) => [
                content,
                ...unionColumns.map(({ value }) => value[index]?.copyTableValue),
            ]),
            [label, ...unionColumns.map(({ baseSize: { content }}) => `${content || ''}`)]
        ];

        return [
            [date],
            ['Questions? Call us at (312) 655-0622 or email support@datassential.com'],
            ...rows,
        ].map(row => row.join('\t')).join('\n');
    }
);
