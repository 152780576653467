import React from "react";
import PT from "prop-types";
import Modal from "../Modal/Modal";
import "./KeywordMenu.scss";
import { Link } from 'react-router-dom'
import cn from "classnames";

const KeywordMenu = (
    {
        options = [],
        x,
        y,
        closeModal,
        shown,
        className,
        closeOnOptionClick = false,
    }
) => {

    return (
        <Modal closeModal={closeModal} shown={shown}>
            <div
                style={{
                    left: x, top: y
                }}
                className={cn('keyword-menu', className)}
            >
                {options.map(({ title, onClick, url }, index) => {
                    let Option = 'button';
                    const optionProps = {};

                    if (!!url) {
                        Option = Link;
                        optionProps.to = url;
                    } else {
                        optionProps.onClick = () => {
                            onClick();
                            if (closeOnOptionClick) {
                                closeModal();
                            }
                        };
                    }
                    return (
                        <Option
                            key={index}
                            className="keyword-menu__item"
                            {...optionProps}
                        >
                            {title}
                        </Option>
                    );
                })}
            </div>
        </Modal>
    );
};

KeywordMenu.propTypes = {
    x: PT.number,
    y: PT.number,
    closeModal: PT.func,
};

export default KeywordMenu;
