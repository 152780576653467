import { TOP_TRENDS } from '../core/actionNames';
import { toggleInArray } from "@core/old_helpers";
import { getTopTrendsData, getTopTrendsCopyTable } from "../core/api";
import { reset as resetFilters } from "./filters.actions";
import { requestDataSelector } from "../selectors/topTrends.selectors";
import { PermissionSection } from "@models/PermissionSection";

const { SET_FIELD, SET_DATA, CLEAR } = TOP_TRENDS;

export function reset() {
    return { type: CLEAR };
}

export function clear() {
    return (dispatch, getState) => {
        if (getState().topTrends.isLoaded) {
            dispatch(reset());
            dispatch(resetFilters());
            dispatch(reFetchData());
        }
    };
}

export function toggleCategory(id) {
    return (dispatch, getState) => {
        const { selectedCategories } = getState().topTrends;

        dispatch({
            type: SET_DATA,
            data: {
                selectedCategories: toggleInArray(selectedCategories, id),
            }
        });
    };
}

let request = {
    abort: () => {
    },
    then: () => {
    },
};

let requestCopyTable = {
    abort: () => {
    },
    then: () => {
    },
};

export function reFetchData(saveProgress = false) {
    return (dispatch, getState) => {
        if (!saveProgress) {
            dispatch(setData({
                data: [],
                copyTableData: null,
                isLoading: true,
                hasMoreData: true,
            }));
        }

        const requestData = requestDataSelector(getState());


        if (!saveProgress) {
            requestCopyTable.abort();
            requestCopyTable = getTopTrendsCopyTable(requestData);
            requestCopyTable.then(response => {
                dispatch(setData({
                    copyTableData: response
                }))
            }).catch(err => {
                dispatch(setData({
                    copyTableData: null
                }))
            })
        }

        request.abort();
        request = getTopTrendsData(requestData);
        request.then((response) => {
            const data = response.data.map((keyword, index) => ({
                ...keyword,
                word: {
                    ...keyword.word,
                    wordExtensions: keyword.word.wordExtensions || []
                },
                flavorData: response.flavorData.data?.[index],
            }));
            dispatch(setData({
                data: [...getState().topTrends.data, ...data],
                flavorPermissionLevel: PermissionSection.getPermissionLevel(response.flavorData.accessLevel),
                totalRecords: response.totalRecords,
                isLoading: false,
                hasMoreData: response.data.length === requestData.tableParameters.take,
            }))
        });
    }
}

export function setField(key, value) {
    return {
        type: SET_FIELD,
        key,
        value,
    }
}

export function setData(data) {
    return {
        type: SET_DATA,
        data,
    }
}

export function setDataWithReFetch(data) {
    return (dispatch) => {
        dispatch({
            type: SET_DATA,
            data
        });
        dispatch(reFetchData());
    };
}

export function toggleField(key) {
    return (dispatch, getState) => {
        const value = getState().topTrends[key];
        dispatch({
            type: SET_FIELD,
            key,
            value: !value,
        });
        dispatch(reFetchData());
    }
}

export function drill(wordData) {
    return (dispatch, getState) => {
        const drillWords = getState().topTrends.drillWords;

        dispatch(setField('drillWords', [...drillWords, wordData]));
        dispatch(setField('searchQuery', ''));
        dispatch(reFetchData());
    }
}

export function deleteDrillWord(wordId) {
    return (dispatch, getState) => {
        const drillWords = getState().topTrends.drillWords;

        dispatch(setField('drillWords', drillWords.filter(({ id }) => id !== wordId)));
        dispatch(reFetchData());
    }

}

export function setSortingColumn(columnId) {
    return (dispatch, getState) => {
        const { sortingColumn, sortingDirection } = getState().topTrends;
        if (sortingColumn === columnId) {
            dispatch(setField('sortingDirection', sortingDirection === 'asc' ? 'desc' : 'asc'));
        } else {
            dispatch(setData({
                sortingColumn: columnId,
                sortingDirection: 'desc',
            }));
        }
        dispatch(reFetchData());
    }
}

export function setGrowthPeriod(period) {
    return (dispatch) => {
        dispatch(setField('growthPeriod', period));
        dispatch(reFetchData());
    }
}


export function selectAllCategories() {
    return (dispatch, getState) => {
        dispatch(
            setField(
                'selectedCategories',
                getState().topTrends.categories.map(({ id }) => id)
            )
        );

    }
}

export function selectCategories(ids) {
    return (dispatch, getState) => {
        dispatch(
            setField(
                'selectedCategories',
                ids
            )
        );
        dispatch(reFetchData());
    }
}

export function onLoad() {
    return (dispatch, getState) => {
        dispatch(setField('isLoaded', true));
        if (getState().filters.isDataLoaded) {
            dispatch(reFetchData());
        }
    }
}

export function onUnload() {
    return (dispatch) => {
        dispatch(setField('isLoaded', false));
    }
}