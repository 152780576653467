import React from "react";
import AdminTable from "@pages/Admin/Common/AdminTable";
import Head from "./Head";
import Row from "./Row";

const Table = ({
    data,
    showAll,
    handleSelectedRow,
    selectedRow,
    isSortDisabled,
    sort,
    handleChangeFilters,
    dataFilters,
    handleShowManagePopup,
    handleShowViewPopup,
    isLoadingCustomRestaurantLists,
    handleRemoveItem,
}) => {
    const TableHeader = (
        <Head
            showAll={showAll}
            isSortDisabled={isSortDisabled}
            sort={sort}
            handleChangeFilters={handleChangeFilters}
            dataFilters={dataFilters}
        />
    );

    const TableRow = ({ index, style }) => {
        const rowData = data[index];

        return (
            <div
                style={style}
                className={`AdminTable__row AdminTable__row--hoverable ${
                    selectedRow === rowData.id ? "is-selected" : ""
                }`}
                onClick={() => {
                    handleSelectedRow(rowData.id);
                }}
            >
                <Row
                    data={rowData}
                    showAll={showAll}
                    handleShowManagePopup={handleShowManagePopup}
                    handleShowViewPopup={handleShowViewPopup}
                    handleRemove={handleRemoveItem}
                />
            </div>
        );
    };

    return (
        <AdminTable
            isLoading={isLoadingCustomRestaurantLists}
            header={TableHeader}
            length={data.length}
            row={TableRow}
            disableRowTransition
            modifiers={["fluid"]}
        />
    );
};

export default Table;
