export interface iData<A> {
    apiModel?: A;
}

export default abstract class AbstractModel<I, A> {
    constructor(data?: iData<A>) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }
    setData (data: I) {
        Object.assign(this, data);
    }
    abstract mapFromApi(data: A): void;
}