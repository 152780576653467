import React, { useReducer, useEffect } from 'react'
import { connect } from "react-redux"
import { currentCurrencySelector } from "@selectors/countries.selector"
import Bar from '../Bar/Bar'
import PT from "prop-types";
import './BarChart.style.scss'

const BarChart = ({ height, data, activeTabId, currentCurrency }) => {
    const [ state, dispatch ] = useReducer(reducer, initialState);

    useEffect(() => {

        let max = -Infinity;
        let min = Infinity;

        data.forEach(({value}) => {
            max = Math.max(max, value);
            min = Math.min(min, value);
        });

        const proportion = (height - 80) / max;


        dispatch({
            min,
            max,
            proportion,
        });
    }, [ height, data ]);

    let width;
    if (data.length <= 3) {
        width = 22
    } else if (data.length === 4) {
        width = 17
    } else {
        width = 15
    }

    const amount = data.length;
    const margin = (100 - width * amount) / (amount + 1);

    return (
        <div className='BarChart'>
            {state.proportion > 0 && data.map((el, idx) => {

                return (

                    <Bar
                        key={el.id}
                        {...el}
                        height={
                            parseInt(state.proportion * el.value) > parseInt(height - 80)
                                ? 0
                                : parseInt(state.proportion * el.value)
                        }
                        width={width}
                        translateX={(idx * 100) + ((margin / width * 100) * (idx + 1))}
                        translateXStart={true}
                        valueBefore={activeTabId === 3 ? currentCurrency : ''}
                        valueAfter={activeTabId === 3 ? '' : '%'}
                    />

                );
            })}
        </div>
    )
}

const initialState = {
    min: -Infinity,
    max: Infinity,
    proportion: 0,
};

const reducer = (state, action) => ({
    ...state,
    ...action
});

BarChart.propTypes = {
    height: PT.number,
    data: PT.array,
};

export default connect(state => ({
    currentCurrency: currentCurrencySelector(state),
}))(BarChart)