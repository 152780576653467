import { getAverage } from "@core/helpers";
import { iCurrentChart } from "@models/FoodProfile";

const STEPS = [1, 2, 5, 10, 20, 25, 50, 100, 200, 250, 500, 1000, 2000, 2500, 5000, 10000];
export const BASE_CHART_HEIGHT = 320;
const APPROXIMATE_LABELS_COUNT = 5;
export const DISABLED_OPACITY = 0.1;

export interface iViewBoxProps {
    canvasWidth: number;
    canvasHeight: number;
    width: number;
    height: number;
    padding: {
        top: number;
        bottom: number;
        left: number;
        right: number;
    };
    proportion: number;
}

export enum PointType {
    Small,
    Solid,
    Empty,
}

export interface iChart {
    color: string;
    isActive: boolean;
    title: string;
    data: number[][];
}

export interface iColumn {
    title: string;
    tooltipSubTitle?: string;
    subColumnsCount?: number;
    subColumns?: string[];
}

export function getViewportGrid(
    { chartValues, height }: {
        chartValues: number[];
        height: number
    }
): {
    getY: (y: number) => number;
    yAxisValues: number[];
} {
    let maxValue = -Infinity;
    let minValue = Infinity;

    chartValues.forEach(value => {
        maxValue = Math.max(maxValue, value);
        minValue = Math.min(minValue, value);
    });

    let min = minValue;
    let max = maxValue;

    if (maxValue >= 100) {
        max = maxValue + 10;
    } else if (maxValue >= 40) {
        max = Math.min(100, maxValue + 8);
        min = Math.max(-0.1, minValue - 8);
    } else if (maxValue >= 10) {
        max = maxValue + 4;
        min = Math.max(-0.1, minValue - 4);
    } else if (maxValue >= 3) {
        max = maxValue + 2;
        min = Math.max(-0.1, minValue - 2);
    } else if (maxValue >= 1) {
        max = maxValue + 1;
        min = Math.max(-0.1, minValue - 1);
    } else {
        max = 1;
        min = Math.max(-0.1, min - 1);
    }

    let diff = max - min;
    const letStep = diff / (APPROXIMATE_LABELS_COUNT - 1);
    const step: number = STEPS.filter(step => step >= letStep)[0];

    // avoid javascript bit issue
    min = min - min % step;
    if (max % step > 0) {
        max = max - max % step + step;
    } else {
        max = max - max % step;
    }

    const proportion = height / (max - min);

    function getY(value: number) {
        return (max - value) * proportion;
    }

    const labelsCount = Math.round((max - min) / step + 1);

    return {
        getY,
        yAxisValues: Array(labelsCount).fill(null).map((_, index) => min + step * index),
    };
}

export interface iPoint {
    x: number;
    y: number;
}

export function getChartPoints(
    {
        columns,
        chart,
        viewport,
        getY,
    }: {
        columns: iColumn[];
        chart: iChart;
        viewport: iViewBoxProps;
        getY: (value: number) => number;
    }
): iPoint[] {
    const columnWidth = viewport.width / columns.length;
    const chartPoints: iPoint[] = [];
    const xCoordsShift = 0;
    const totalChartWidth = columns.length * columnWidth;

    columns.forEach((column, columnIndex) => {
        const subColumnWidth = columnWidth / getSubColumnsCount(column);

        (column.subColumns || ['']) /* we assume, that there is only one sub-column */
            .forEach((_, subColumnIndex) => {
                const value = getChartValueByIndex({
                    chart,
                    columnIndex,
                    subColumnIndex,
                });

                if (value !== undefined) {
                    chartPoints.push({
                        x: xCoordsShift + columnWidth * columnIndex + subColumnWidth * (subColumnIndex + 0.5),
                        y: getY(value),
                    });
                }
            });
    });

    chartPoints.unshift({
        x: xCoordsShift,
        y: chartPoints[0].y,
    });

    if (getSubColumnsCount(columns[columns.length - 1]) === 1) {
        chartPoints.push({
            x: xCoordsShift + totalChartWidth,
            y: chartPoints[chartPoints.length - 1].y,
        });
    }
    return chartPoints;
}

export function getPath(points: iPoint[]): string {
    let result = '';

    points.forEach((point, index) => {
        result += ` ${index === 0 ? 'M' : 'L'} ${point.x} ${point.y}`;
    });

    return result;
}

export function getSubColumnsCount(column: iColumn): number {
    if (column.subColumnsCount) {
        return column.subColumnsCount;
    } else if (column.subColumns) {
        return column.subColumns.length;
    } else {
        return 1;
    }
}

export function getChartValueByIndex(
    { chart, columnIndex, subColumnIndex = 0 }: {
        chart: iChart;
        columnIndex: number;
        subColumnIndex?: number;
    }
): number | undefined {
    if (chart.data[columnIndex]) {
        return chart.data[columnIndex][subColumnIndex];
    }

    return undefined;
}

export function getSubColumns (column: iColumn): string[] {
    return column.subColumns || [''];
}

export function getPointType (
    {
        subColumnsCount,
        columnIndex,
        subColumnIndex,
        dashStartPointColumnIndex,
        dashStartPointSubColumnIndex,
    }: {
        subColumnsCount: number;
        columnIndex: number;
        subColumnIndex: number;
        dashStartPointColumnIndex: number;
        dashStartPointSubColumnIndex: number;
    }
): PointType {
    let type = PointType.Small;
    if (subColumnsCount > 1) {
        if (dashStartPointSubColumnIndex > -1) { /* if dashStartPointSubColumnIndex is not specified, we use Solid type */
            if (columnIndex === dashStartPointColumnIndex) {
                if (subColumnIndex >= dashStartPointSubColumnIndex) {
                    type = PointType.Empty;
                } else {
                    type = PointType.Solid;
                }
            }
            else if (columnIndex >= dashStartPointColumnIndex) {
                type = PointType.Empty;
            } else {
                type = PointType.Solid;
            }
        }
        else {
            type = PointType.Solid;
        }
    }

    return type;
}

export function getDashedPartStartX (
    {
        viewport,
        columns,
        dashStartPointColumnIndex,
        dashStartPointSubColumnIndex,
    }: {
        viewport: iViewBoxProps;
        columns: iColumn[];
        dashStartPointColumnIndex: number;
        dashStartPointSubColumnIndex: number;
    }
): number {
    const columnWidth = viewport.width / columns.length;
    let result: number = viewport.width;

    if (dashStartPointColumnIndex >= 0 && dashStartPointColumnIndex) {
        result = columnWidth * dashStartPointColumnIndex;
        const subColumnsCount = getSubColumnsCount(columns[dashStartPointColumnIndex]);

        if (dashStartPointSubColumnIndex >= 0) {
            const subColumnWidth = columnWidth / subColumnsCount;
            result += subColumnWidth * (Math.min(subColumnsCount, dashStartPointSubColumnIndex));
        }
    }
    return result;
}

interface iChartDataItem {
    id: number;
    name: string;
    value: string;
    valueNumeric: number;
    baseSize: string;
}

export interface iChartBase {
    actualData: iChartDataItem[];
    actualDataQuarters: iChartDataItem[];
    concatData: iChartDataItem[];
    predictedData: iChartDataItem[];
    predictedDataQuarters: iChartDataItem[];
}

export interface iChartData extends iChartBase {
    color: string;
    isActive: boolean;
    title: string;
}

export const getChartData = (data: iChartData[]): iCurrentChart => {
    if ( data.length === 0 ) {
        return {
            columns: [],
            charts: [],
        }
    }

    data.sort((a, b) => {
        const averageA = getAverage(a.concatData.map(i => i.valueNumeric));
        const averageB = getAverage(b.concatData.map(i => i.valueNumeric));
        return ((averageA === averageB) ? 0 : ((averageA < averageB) ? 1 : -1));
    });

    const {
        actualData,
        actualDataQuarters,
        predictedData,
        predictedDataQuarters,
    } = data[0];

    const columns = [];

    actualData.forEach((item) => {
        columns.push({
            title: item.name,
        });
    });

    if (actualDataQuarters.length || predictedDataQuarters.length) {
        const values = [
            ...actualDataQuarters,
            ...predictedDataQuarters,
        ];

        columns.push({
            title: values[0].name.substr(0, 4),
            subColumnsCount: 4,
            subColumns: values.map(v => v.name.substr(5).toUpperCase()),
        });
    }

    predictedData.forEach((item) => {
        columns.push({
            title: item.name,
        });
    });

    let dashStartPointColumnIndex;
    let dashStartPointSubColumnIndex;

    if (predictedData.length) {
        dashStartPointColumnIndex = actualData.length;
        dashStartPointSubColumnIndex = actualDataQuarters.length;
    }

    return {
        dashStartPointColumnIndex,
        dashStartPointSubColumnIndex,
        columns,
        charts: data.map(word => {
            let data = [...word.actualData.map(i => [i.valueNumeric])];

            if (word.actualDataQuarters.length + word.predictedDataQuarters.length > 0) {
                data = [
                    ...data,
                    [
                        ...word.actualDataQuarters.map(i => i.valueNumeric),
                        ...word.predictedDataQuarters.map(i => i.valueNumeric)
                    ]
                ];
            }

            data = [
                ...data,
                ...word.predictedData.map(i => [i.valueNumeric]),
            ];

            return {
                title: word.title,
                isActive: word.isActive,
                color: word.color,
                data,
            }
        })
    }
}