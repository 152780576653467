import { FCX, ID } from "@datassential/platform-ui-lib";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IconArrowLeft, IconArrowRight } from "@icons";
import cn from "classnames";
import HomeWidget from "@pages/Home/HomeWidget";
import AutoSizer from "react-virtualized-auto-sizer";
import Chart from "@components/Chart/Chart";
import CellNew from "@components/CellNew/CellNew";
import { ICategoryFastestGrowing } from "@models/CategoryFastestGrowingItems";
import { getChartData } from "@components/Chart/Chart.helpers";
import { COLORS } from "@reducers/byo.reducer";
import Loader from "@components/Loader/Loader";
import CanvasChart from "@components/CanvasChart/CanvasChart";
import { GlobalContext } from "@components/App/App.context";

const SlideContent: FCX<{
    category: {
        id: ID,
        image: string;
        name: string;
    } & ICategoryFastestGrowing,
    next: () => void;
    prev: () => void;
}> = ({
    category,
    prev,
    next,
}) => {
    const { getRem } = useContext(GlobalContext);
    const [isHovered, setIsHovered] = useState(false);

    const isLoading = useMemo(
        () => category.keywords.length === 0,
        [category.keywords]
    );

    useEffect(() => {
        if (!isHovered) {
            const timer = setTimeout(next, 10000);
            return () => clearTimeout(timer);
        }
    }, [isHovered, next]);

    const printValue = useCallback((n) => {
        return `${n % 1 === 0 ? n : n.toFixed(1)}%`;
    }, []);

    const printLabel = useCallback((n) => {
        return `${n % 1 === 0 ? n : n.toFixed(1)}%`;
    }, []);

    const chartData = useMemo(
        () => {
            return getChartData(category.keywords.map((item, index) => ({
                id: item.id,
                title: item.name,
                color: COLORS[index],
                isActive: false,
                ...item.chartData,
            })));
        },
        [category.keywords]
    );

    const tableData = useMemo(
        () => {
            if (isLoading) return Array(5).fill(null).map((_, index) => ({
                id: index,
                title: '',
                penetration: '',
                miniChartData: [],
                predictedPenetration: '',
                fourYearTrend: '',
                color: COLORS[index],
            }));

            return category.keywords.map((item, index) => ({
                id: item.id,
                title: item.name,
                miniChartData: item.chartData.actualData.map((i, index) => ({
                    label: index,
                    values: [i.valueNumeric] as number[],
                })),
                penetration: item.penetration === null
                    ? 'N/A'
                    : `${item.penetration!.toFixed(1)}%`,
                fourYearTrend: item.fourYearTrend === null
                    ? 'N/A'
                    : `${item.fourYearTrend > 0 ? '+' : ''}${item.fourYearTrend.toFixed(1)}%`,
                predictedPenetration: item.predictedFourYearGrowth === null
                    ? 'N/A'
                    : `${item.predictedFourYearGrowth > 0 ? '+' : ''}${item.predictedFourYearGrowth.toFixed(1)}%`,
                color: COLORS[index],
            }))
        },
        [category.keywords, isLoading]
    );

    return (
        <div
            className={"HomeSlider__slide"}
        >
            <img
                src={category.image}
                className="HomeSlider__slide-bg"
                alt={category.name}
            />
            <div className="HomeSlider__slide-content">
                <div
                    className="HomeSlider__nav is-left clickable"
                    onClick={prev}
                    data-testid="buttonHomeSliderNavLeft"
                >
                    <IconArrowLeft className="HomeSlider__nav-icon"/>
                </div>
                <div
                    className="HomeSlider__nav is-right clickable"
                    data-testid="buttonHomeSliderNavRight"
                    onClick={next}
                >
                    {!isHovered && (
                        <svg width="58" height="58" viewBox="0 0 58 58" className="HomeSlider__nav-progress">
                            <circle/>
                        </svg>
                    )}
                    <IconArrowRight className="HomeSlider__nav-icon"/>
                </div>
                <div
                    className="HomeSlider__slide-content-inner"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <HomeWidget
                        className="is-chart"
                        header={(
                            <div
                                className="Home__slider-title"
                                data-testid="textHomeSliderTitle"
                            >
                                {category.name.toUpperCase()}: Fastest Growing
                            </div>
                        )}
                    >
                        <Loader isShown={isLoading}/>
                        {!isLoading && (
                            <AutoSizer>
                                {({ width, height }) => (
                                    <>
                                        <Chart
                                            charts={chartData.charts}
                                            columns={chartData.columns}
                                            width={width}
                                            height={height}
                                            printValue={printValue}
                                            printLabel={printLabel}
                                            dashStartPointColumnIndex={chartData.dashStartPointColumnIndex}
                                            dashStartPointSubColumnIndex={chartData.dashStartPointSubColumnIndex}
                                            baseChartHeight={height}
                                        />
                                    </>
                                )}
                            </AutoSizer>
                        )}
                    </HomeWidget>
                    <HomeWidget
                        className="is-table"
                        header={(
                            <>
                                <CellNew
                                    modifiers={['bg-head']}
                                    className={cn("Home__table-cell bdrs-1")}
                                    testId="textHomeTableHead-0"
                                />
                                <CellNew
                                    modifiers={['bg-head', 'bg-dark', 'center', 'no-p']}
                                    className={cn("Home__table-cell")}
                                    testId="textHomeTableHead-1"
                                >
                                    Menu<br/> Penetration
                                </CellNew>
                                <CellNew
                                    modifiers={['bg-head', 'bg-dark', 'center', 'no-p']}
                                    className={cn("Home__table-cell is-accent")}
                                    testId="textHomeTableHead-2"
                                >
                                    Past<br/>4-Yr Growth
                                </CellNew>
                                <CellNew
                                    modifiers={['bg-head', 'bg-dark', 'center', 'no-p']}
                                    className={cn("Home__table-cell bdrs-2")}
                                    testId="textHomeTableHead-2"
                                >
                                    Predicted<br/>4-Yr Growth
                                </CellNew>
                            </>
                        )}
                    >
                        <div className="Home__table-body">
                            {tableData.map((item, index) => (
                                <div key={item.id} className="Home__table-row">
                                    <CellNew
                                        modifiers={[]}
                                        className={cn("Home__table-cell bdrs-3 Cell", isLoading && 'Cell--load')}
                                        testId={`textHomeTableCell-${index}-0`}
                                    >
                                        <div className="Home__table-chart">
                                            <CanvasChart
                                                activeTabId={1}
                                                chartDataType={1}
                                                width={getRem(100)}
                                                height={getRem(80)}
                                                coefficient={getRem(80) / getRem(1350)}
                                                charts={{
                                                    chartCount: 1,
                                                    chartInfo: [{name: '', color: item.color}],
                                                    data: item.miniChartData,
                                                }}
                                                hasPrediction={false}
                                                isLightTheme={false}
                                                onlyLine
                                                currentCurrency={''}
                                            />
                                        </div>
                                        {item.title}
                                    </CellNew>
                                    <CellNew
                                        modifiers={['bg-dark', 'center', 'no-p']}
                                        className={cn("Home__table-cell Cell", isLoading && 'Cell--load')}
                                        testId={`textHomeTableCell-${index}-1`}
                                    >
                                        {item.penetration}
                                    </CellNew>
                                    <CellNew
                                        modifiers={['bg-dark', 'center', 'no-p']}
                                        className={cn("Home__table-cell Cell", isLoading && 'Cell--load', 'is-accent')}
                                        testId={`textHomeTableCell-${index}-2`}
                                    >
                                        {item.fourYearTrend}
                                    </CellNew>
                                    <CellNew
                                        modifiers={['bg-dark', 'center', 'no-p']}
                                        className={cn("Home__table-cell bdrs-4 Cell", isLoading && 'Cell--load')}
                                        testId={`textHomeTableCell-${index}-2`}
                                    >
                                        {item.predictedPenetration}
                                    </CellNew>
                                </div>
                            ))}
                        </div>
                    </HomeWidget>
                </div>
            </div>
        </div>
    );
}

export default SlideContent;