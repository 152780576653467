import { ID } from '@models';
import { getRestaurantsList as fetchRestaurantsList } from '@core/api';
import { useData } from '@hooks/useData';
import { components } from '@apiSchema';

type Response = components['schemas']['MenuTrends.Application.Contracts.Models.BaseModel'];

async function getRestaurantsList(countryCode: ID, restaurantListId: number): Promise<Response[]> {
    if (!countryCode || !restaurantListId) return [];
    return await fetchRestaurantsList(countryCode, restaurantListId) as Response[];
}

export default function useRestaurantsList(countryCode: ID, restaurantListId: number) {
    return useData([], getRestaurantsList, countryCode, restaurantListId);
}