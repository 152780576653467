import React, { FC, useMemo, useState } from 'react';
import AutoWidthInput from '@components/AutoWidthInput/AutoWidthInput';
import { iFilterValue } from '@models/FilterValue';

function formatValue(str: string): number | null {
    const num = str
        .replace(/[^0-9]/g, '')
        .replace(/^0(\d?)/, '$1');

    return +num || null;
}

const FilterRangeUnits: FC<{
    minUnits: iFilterValue['minUnits'];
    maxUnits: iFilterValue['maxUnits'];
    onChange: (field: 'minUnits' | 'maxUnits') => (value: number | null) => void;
}> = ({
    minUnits,
    maxUnits,
    onChange,
}) => {
    const [isUnitsCountOpen, setIsUnitsCountOpen] = useState(false);

    const isUnitsCountApplied = useMemo(() => {
        return minUnits !== null || maxUnits !== null;
    }, [minUnits, maxUnits]);
    return (
        <>
            {!isUnitsCountOpen && (
                <button
                    onClick={() => {
                        setIsUnitsCountOpen(true);
                    }}
                    style={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    }}
                >
                    <div>Filter by unit count</div>
                    {isUnitsCountApplied && (
                        <div>
                            You have <span>{minUnits}-{maxUnits && 'any'}</span> range applied
                        </div>
                    )}
                </button>
            )}
            {isUnitsCountOpen && (
                <div>
                    only places between&nbsp;
                    <AutoWidthInput
                        defaultValue="1"
                        value={minUnits || 0}
                        onChange={(event) => onChange('minUnits')(formatValue(event.target.value))}
                    />
                    &nbsp;and&nbsp;
                    <AutoWidthInput
                        defaultValue="any"
                        value={maxUnits || 0}
                        onChange={(event) => onChange('maxUnits')(formatValue(event.target.value))}
                    />
                    &nbsp;units
                </div>
            )}
        </>
    )
};

export default FilterRangeUnits;