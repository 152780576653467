import React, { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import SubTitle from '@components/SubTitle/SubTitle';
import ToggleInput from '@components/ToggleInput/ToggleInput';
import { IconArrowBottom, IconArrowRight, IconArrowTop } from '@icons';
import cn from 'classnames';
import { SectionsControlContext } from '@pages/Admin/CategoryReport/CategoryReport';

const Section: FC<{
    title: string;
    headerContent?: ReactNode;
    hasEnableToggle?: boolean;
    isEnabled?: boolean;
    toggleIsEnabled?: () => void;
    isCollapsedByDefault?: boolean;
    hasError?: boolean;
}> = ({
    title,
    headerContent,
    hasEnableToggle = false,
    isEnabled= false,
    children,
    toggleIsEnabled,
    isCollapsedByDefault= true,
    hasError = false,
}) => {
    const { savesCount } = useContext(SectionsControlContext);
    const [isCollapsed, setIsCollapsed] = useState(isCollapsedByDefault);

    useEffect(() => {
        if (savesCount > 0) {
            setIsCollapsed(true);
        }
    }, [savesCount])

    const isContentShown = useMemo(() => {
        if (isCollapsed) return false;
        if (hasEnableToggle) return isEnabled;
        return true;
    }, [hasEnableToggle, isEnabled, isCollapsed]);

    const isHeaderContentShown = useMemo(() => {
        if (hasEnableToggle) return isEnabled;
        return true;
    }, [hasEnableToggle, isEnabled]);

    const handleToggle = () => {
        setIsCollapsed(isEnabled);
        if (toggleIsEnabled) {
            toggleIsEnabled();
        }
    };

    return (
        <div className="AdminContainer__row has-divider">
            <div
                className="AdminContainer__row-header"
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                <div className="AdminContainer__row-header-part">
                    <SubTitle textColor="white">
                        {hasEnableToggle && (
                            <>
                                <div
                                    onClick={(event) => event.stopPropagation()}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <ToggleInput
                                        onToggle={handleToggle}
                                        isChecked={isEnabled}
                                    />
                                </div>
                                &nbsp;&nbsp;&nbsp;
                            </>
                        )}
                        {title}
                    </SubTitle>
                </div>
                {isHeaderContentShown && (
                    <div className="AdminContainer__row-header-part">
                        <div className={cn(
                            'AdminContainer__row-header-content',
                            hasError && 'is-error',
                        )}>
                            {headerContent}
                        </div>
                        <div className="AdminContainer__row-header-button">
                            {isCollapsed ? <IconArrowBottom/> : <IconArrowTop />}
                        </div>
                    </div>
                )}
            </div>
            {isContentShown && (
                <>
                    <br/>
                    <div style={{ padding: '0 2%' }}>
                        {children}
                    </div>
                    <br/>
                    <br/>
                </>
            )}
        </div>
    )
};

export default Section;