import React, { useState, useEffect } from 'react';
import Modal from "@components/Modal/Modal";
import Subtitle from "@components/SubTitle/SubTitle";
import SettingsDropdown from "@components/Select/Select";
import { normalizeForDropDown, optionsDataComparePopupConfig } from '../Keyword.helpers'
import "./ComparePopup.styles.scss";
import Flag from "@components/Flag/Flag";

function Popup({ closeModal, shown, title, optionsData, countries, name }) {
    const [firstOptionId, setFirstOptionId] = useState(null);
    const [secondOptionId, setSecondOptionId] = useState(null);
    const firstOption = optionsData.find(option => option.id === firstOptionId);
    const secondOption = optionsData.find(option => option.id === secondOptionId);
    const allOptionActive = firstOption && secondOption ? true : false;
    useEffect(() => {
        if(optionsData.length > 1) {
            setFirstOptionId(optionsData[0].id);
            setSecondOptionId(optionsData[1].id);
        }
    },[optionsData]);
    
    return (
        <Modal closeModal={closeModal} shown={shown} hasOverlay>
            <div className='ComparePopup-wr'>
                <div className='ComparePopup'>

                    <div className="ComparePopup__top">
                        <div className="ComparePopup__title">{title}</div>
                        <div className="ComparePopup__close">
                            <button
                                className="btn btn--bordered"
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                    <div className="ComparePopup__content">
                        <div className="ComparePopup__heading">
                            <Subtitle textColor="yellow">Keyword name</Subtitle>
                            <div className="ComparePopup__name">{name}</div>
                        </div>
                        <div className="compare-table">
                            <div className="compare-table__col compare-table__col--1">
                            </div>
                            <div className="compare-table__col compare-table__col--2">
                                <div className="compare-table__top">
                                    <SettingsDropdown
                                        title="Choose group"
                                        isWide
                                        options={normalizeForDropDown(optionsData)}
                                        value={firstOptionId}
                                        onChange={setFirstOptionId}
                                        isCheckboxHidden
                                    />
                                    {firstOptionId && (
                                        <div className="compare-table__countries">
                                            {
                                                firstOption.countryCodes.map((countryCode) => {
                                                    const country = countries.find(country => country.countryCode === countryCode)

                                                    return <Flag
                                                        key={countryCode}
                                                        {...country}/>

                                                })
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="compare-table__col compare-table__col--2">
                                <div className="compare-table__top">
                                    <SettingsDropdown
                                        title="Choose group"
                                        isWide
                                        options={normalizeForDropDown(optionsData)}
                                        value={secondOptionId}
                                        onChange={setSecondOptionId}
                                        isCheckboxHidden
                                    />
                                    {secondOptionId && (
                                        <div className="compare-table__countries">
                                            {
                                                secondOption && secondOption.countryCodes.map((countryCode) => {
                                                    const country = countries.find(country => country.countryCode === countryCode)

                                                    return <Flag
                                                        key={countryCode}
                                                        {...country}/>

                                                })
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {optionsDataComparePopupConfig.map(({ name, section, fields }) => {
                                return (
                                    <div className="compare-table"  key={section}>
                                        <div className="compare-table__section">
                                            <Subtitle textColor="white">{name}</Subtitle>
                                            {fields.map(({ name, field }) => {
                                                const classList = new Set([
                                                    'compare-table__col',
                                                    'compare-table__col--2',
                                                    allOptionActive && firstOption[section][field] !== secondOption[section][field]
                                                        ? 'compare-table__col--isDifferent'
                                                        : ''
                                                ]);
                                                return (
                                                    <div
                                                        key={field}
                                                        className="compare-table__row"
                                                    >
                                                        <div className={
                                                            `compare-table__col
                                                             compare-table__col--1 
                                                            ${allOptionActive && firstOption[section][field] !== secondOption[section][field] 
                                                            ? 'compare-table__col--isDifferent' 
                                                            : ''}`}>
                                                            {name}:
                                                        </div>
                                                        {firstOption ?
                                                            <div className={Array.from(classList).join(' ')}>
                                                                {firstOption[section][field]}
                                                            </div> : null}
                                                        {secondOption ?
                                                            <div className={Array.from(classList).join(' ')}>
                                                                {secondOption[section][field]}
                                                            </div> : null}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )

                        })}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default Popup;


// <div className="compare-table__section">
//     <Subtitle textColor="white">Keyword settings</Subtitle>
//     <div className="compare-table__row">Peer include:</div>
//     <div className="compare-table__row">Include:</div>
//     <div className="compare-table__row hasDifference">Exclude:</div>
// </div>
// <div className="compare-table__section">
//     <Subtitle textColor="white">Top Trends settings</Subtitle>
// <div className="compare-table__row hasDifference">Descriptors:</div>
// <div className="compare-table__row">Flavor:</div>
// <div className="compare-table__row hasDifference">Ingredients:</div>
// <div className="compare-table__row hasDifference">Menu items:</div>
// </div>
// <div className="compare-table__section">
// <Subtitle textColor="white">Food Profile settings</Subtitle>
// <div className="compare-table__row">Appetizers:</div>
// <div className="compare-table__row hasDifference">Entrees:</div>
// <div className="compare-table__row">Sides:</div>
// <div className="compare-table__row hasDifference">Desserts:</div>
// <div className="compare-table__row">Beverages:</div>
// <div className="compare-table__row">Day Parts:</div>
// <div className="compare-table__row">Filter description:</div>
// </div>
// <div className="compare-table__section hasDifference">
// <Subtitle textColor="white">Flavor</Subtitle>
// <div className="compare-table__row">Category:</div>
// <div className="compare-table__row">Category Display Name:</div>
// <div className="compare-table__row">Keyword Display Name:</div>
// <div className="compare-table__row">MAC stage:</div>
// <div className="compare-table__row">Date:</div>
// <div className="compare-table__row">Do Not Display Penetration</div>
// <div className="compare-table__row">Display Image Slider</div>
// <div className="compare-table__row">Bullet description 1</div>
// <div className="compare-table__row">Bullet description 2</div>
// <div className="compare-table__row">Bullet description 3</div>
// <div className="compare-table__row">Bullet description 4</div>
// </div>