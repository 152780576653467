import { FC, memo, useMemo } from "react";
import { DISABLED_OPACITY, getPath, iPoint } from "@components/Chart/Chart.helpers";

export enum LineStyle {
    Solid,
    Dashed,
}

const ChartCurve: FC<{
    points: iPoint[];
    color: string;
    lineStyle: LineStyle;
    isActive: boolean;
}> = (
    {
        points,
        color,
        lineStyle,
        isActive,
    }
) => {
    const d: string = useMemo(() => {
        if (points.length === 0) return '';

        return getPath(points);
    }, [points]);

    return (
        <g className="Chart__curve">
            <path
                d={d}
                stroke={color}
                strokeOpacity={isActive ? 1 : DISABLED_OPACITY}
                strokeWidth={lineStyle === LineStyle.Dashed ? 1 : 3}
                strokeDasharray={lineStyle === LineStyle.Dashed ? '3 6' : ''}
                fill="none"
                strokeLinecap="round"
            />
        </g>
    );
};

export default memo(ChartCurve);