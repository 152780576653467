import React from "react";
import PropTypes from "prop-types";
import "./Filters.scss";

const Filters = (
    {
        filtersArray = [],
        applyFilters = null,
        toggleFilter,
        isApplyButtonHighlighted = false,
    }
) => {
    return (
        <div className="filters">
            <div className="filters__list">
                {filtersArray.map((filter, idx) => {
                    const classList = new Set([
                        "filters__list-item",
                        filter.isSelected ? "filters__list-item--selected" : ""
                    ]);
                    if (idx <= filtersArray.length - 1 && idx >= filtersArray.length - 3) {
                        classList.add('filters__list-item--no-mb');
                    }
                    return (
                        <button
                            key={idx}
                            onClick={() => toggleFilter(filter.id) }
                            className={Array.from(classList).join(" ")}
                        >
                            {filter.name}
                        </button>
                    );
                })}
            </div>
            {
                applyFilters && (
                    <button
                        onClick={applyFilters}
                        className={`filters__apply-btn ${isApplyButtonHighlighted ? 'filters__apply-btn--highlighted' : ''}`}
                    >
                        Apply Categories
                    </button>
                )
            }
        </div>
    );
};

Filters.propTypes = {
    filtersArray: PropTypes.array,
    toggleFilter: PropTypes.func,
    applyFilters: PropTypes.func
};

export default Filters;
