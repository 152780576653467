import React, { FC, memo, useCallback, useContext } from "react";
import {
    getChartValueByIndex,
    getPointType,
    getSubColumns,
    getSubColumnsCount,
    iChart,
    iColumn,
    iViewBoxProps,
} from "@components/Chart/Chart.helpers";
import ChartDot from "@components/Chart/ChartDot";
import { ChartThemeContext } from "./ChartTheme.context";

const ChartColumns: FC<{
    viewport: iViewBoxProps;
    getY: (value: number) => number;
    charts: iChart[];
    columns: iColumn[];
    setActiveColumnIndex: (index: number) => void;
    dashStartPointColumnIndex: number;
    dashStartPointSubColumnIndex: number;
}> = (
    {
        viewport,
        charts,
        columns,
        getY,
        setActiveColumnIndex,
        dashStartPointColumnIndex,
        dashStartPointSubColumnIndex,
    }
) => {
    const { THEME } = useContext(ChartThemeContext);
    const columnWidth = viewport.width / columns.length;

    const handleColumnMouseEnter = useCallback((index: number) => {
        setActiveColumnIndex(index);
    }, [setActiveColumnIndex]);

    const handleColumnMouseLeave = useCallback(() => {
        setActiveColumnIndex(-1);
    }, [setActiveColumnIndex]);

    return (
        <g name="columns" className="Chart__columns">
            {columns.map((column, columnIndex) => {
                return (
                    <g
                        key={columnIndex}
                        name="column"
                        className="Chart__column"
                        onMouseEnter={() => handleColumnMouseEnter(columnIndex)}
                        onMouseLeave={handleColumnMouseLeave}
                    >
                        <rect
                            width={columnWidth}
                            height={viewport.height + viewport.padding.top + viewport.padding.bottom}
                            x={columnWidth * columnIndex}
                            y={-viewport.padding.top}
                            fill="transparent"
                            stroke='transparent'
                        />
                        <line
                            x1={columnWidth * columnIndex}
                            y1={-viewport.padding.top}
                            x2={columnWidth * columnIndex}
                            y2={viewport.height + viewport.padding.bottom}
                            stroke={THEME.BORDER.DEFAULT}
                        />
                        <line
                            x1={columnWidth * (columnIndex + 1)}
                            y1={-viewport.padding.top}
                            x2={columnWidth * (columnIndex + 1)}
                            y2={viewport.height + viewport.padding.bottom}
                            stroke={THEME.BORDER.DEFAULT}
                        />

                        <rect
                            x={columnWidth * columnIndex}
                            y={viewport.height}
                            height={viewport.height}
                            width={columnWidth}
                            fill={`${THEME.CELL.ACTIVE}`}
                        />
                        <line
                            x1={columnWidth * columnIndex}
                            y1={viewport.height + viewport.padding.bottom}
                            x2={columnWidth * (columnIndex + 1)}
                            y2={viewport.height + viewport.padding.bottom}
                            stroke="#F55353"
                            strokeWidth={6}
                        />
                        {charts.map((chart, chartIndex) => {
                            const subColumnsCount = getSubColumnsCount(column);
                            const subColumnWidth = columnWidth / subColumnsCount;
                            return (
                                <React.Fragment key={chartIndex}>
                                    {getSubColumns(column).map((_, subColumnIndex) => {
                                        const cx = columnWidth * columnIndex + subColumnWidth * (subColumnIndex + 0.5);
                                        const value = getChartValueByIndex({
                                            chart,
                                            columnIndex,
                                            subColumnIndex,
                                        });
                                        if (value === undefined) return null;

                                        return (
                                            <ChartDot
                                                key={subColumnIndex}
                                                cx={cx}
                                                cy={getY(value)}
                                                color={charts[chartIndex].color}
                                                type={getPointType({
                                                    subColumnsCount,
                                                    dashStartPointColumnIndex,
                                                    dashStartPointSubColumnIndex,
                                                    columnIndex,
                                                    subColumnIndex,
                                                })}
                                            />
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </g>
                );
            })}
        </g>
    );
};

export default memo(ChartColumns);