import { GlobalContext } from "@components/App/App.context";
import CircularProgressBar from "@components/CircularProgressBar/CircularProgressBar";
import { isSignBeforeNumber } from "@core/old_helpers";
import { getMacStageName } from "@selectors/byo.selectors";
import { FC, useContext, useMemo } from "react";
import "./CircularProgressbarWidthContent.scss";

function getGrowthValue (v: number): string {
    return `${v >= 0 ? '+' : ''}${v}`;
}

export interface iCircularProgressbarWidthContentData {
    penetration: string,
    oneYearGrowth: string,
    fourYearsGrowth: string,
    fourYearsPredictedGrowth: string,
    totalGrowth: string,
    filterDescription: string,
    macStage: number,
}

function getGrowthContentValue (v: string): string {
    return Number.isNaN(+v) ? v : `${getGrowthValue(Math.round(+v))}%`;
}

const CircularProgressbarWidthContent: FC<{
    data: iCircularProgressbarWidthContentData;
}> = ({
    data,
}) => {
    const {
        penetration,
        oneYearGrowth,
        fourYearsGrowth,
        fourYearsPredictedGrowth,
        totalGrowth,
        filterDescription,
    } = data;
    const { getRem } = useContext(GlobalContext);

    const oneYearGrowthContent = getGrowthContentValue(oneYearGrowth);
    const fourYearGrowthContent = getGrowthContentValue(fourYearsGrowth);
    const fourYearPredictedGrowthContent = getGrowthContentValue(fourYearsPredictedGrowth);
    const totalYearGrowthContent = getGrowthContentValue(totalGrowth);

    return (
        <div className="CircularProgressbarWidthContent">

            <div className="CircularProgressbarWidthContent__wrap">
                <div className="CircularProgressbarWidthContent__circle">
                    <CircularProgressBar
                        id="CircleBar-fp-penetration"
                        value={+penetration || 0}
                        renderValueLabel
                        width={getRem(118)}
                        lineWidth={getRem(18)}
                    />
                </div>
                <div className="CircularProgressbarWidthContent__info-block">
                    <div className="CircularProgressbarWidthContent__info">
                        <span className='CircularProgressbarWidthContent__info-title'>Past 12-Month Growth</span>
                        <span className='CircularProgressbarWidthContent__info-value'>{oneYearGrowthContent}</span>
                    </div>
                    <div className="CircularProgressbarWidthContent__info">
                        <span className='CircularProgressbarWidthContent__info-title'>Past 4-Year Growth</span>
                        <span className='CircularProgressbarWidthContent__info-value'>{fourYearGrowthContent}</span>
                    </div>
                    <div className="CircularProgressbarWidthContent__info">
                        <span className='CircularProgressbarWidthContent__info-title'>Predicted 4-Year Growth</span>
                        <span className='CircularProgressbarWidthContent__info-value'>{fourYearPredictedGrowthContent}</span>
                    </div>
                    <div className="CircularProgressbarWidthContent__info">
                        <span className={'CircularProgressbarWidthContent__info-title'}>Past 10-Year Growth</span>
                        <span className='CircularProgressbarWidthContent__info-value'>{totalYearGrowthContent}</span>
                    </div>
                </div>
            </div>

            {filterDescription && (
                <div className="CircularProgressbarWidthContent__filter-description">
                    {filterDescription}
                </div>
            )}
        </div>
    )
}

export default CircularProgressbarWidthContent;