import React from "react";
import PT from "prop-types";
import "./DropdownMenu.scss";
import Transition from "@components/Transition/Transition";

const DropdownMenu = ({
    closeModal,
    shown,
    data,
    handleItemClick,
    isWide = false,
    onTop = false,
}) => {
    const classList = new Set([
        "dropdown-menu",
        isWide ? "dropdown-menu--wide" : "",
        onTop ? "dropdown-menu--top" : "",
    ]);
    return (
        <Transition
            shown={shown}
            timeout={250}
            classNames="page-footer__dropdown-restaurants"
        >
            <div className={Array.from(classList).join(" ")}>
                {data.map((restaurant, index) => (
                    <button
                        key={restaurant.id}
                        className="dropdown-menu__item"
                        onClick={() => {
                            handleItemClick(restaurant.id);
                            closeModal();
                        }}
                    >
                        {restaurant.name}
                    </button>
                ))}
            </div>
        </Transition>
    );
};

DropdownMenu.propTypes = {
    x: PT.number,
    y: PT.number,
    closeModal: PT.func,
    shown: PT.bool,
    data: PT.array,
    isWide: PT.bool,
    onTop: PT.bool,
    handleItemClick: PT.func,
};

export default DropdownMenu;
