import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Page from "@components/Page/Page";
import SearchField from "@components/SearchField/SearchField";
import AdminKeywordsTable from "./AdminKeywordsTable";
import { getAllAdminKeywords } from '@core/api';
import { sortByName } from '@core/old_helpers'
import { searchQuerySelector, tableDataSelector } from "@selectors/adminKeywords.selectors";
import { toggleKeywordExpanded, setField } from "@actions/adminKeywords.actions";
import apiConfig from '@core/apiConfig';
import './Keywords.styles.scss';
import { Routes } from "@routes";
import AdminContainer from "@pages/Admin/Common/AdminContainer";
import { IconXlsExport } from "@icons";
import Icon from "@components/Icon/Icon";


const Keywords = (
    {
        tableData,
        searchQuery,
        setSearchQuery,
        searchError,
        setKeywordsData,
        toggleKeywordExpanded,
    }
) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const dataRequest = getAllAdminKeywords();
        dataRequest
            .then((data) => {
                const words = data
                    // .map(word => ({ ...word, name: word.name.trim().toLowerCase() }))
                    .sort((a, b) => {
                        const aWord = a.name.trim().toLowerCase();
                        const bWord = b.name.trim().toLowerCase();

                        return aWord < bWord ? -1 : aWord > bWord ? 1 : 0;
                    });

                setKeywordsData(words);
            })
            .finally(() => setIsLoading(false));
        return () => {
            dataRequest.abort();
        };
    }, []);

    const actions = (
        <>
            {/* <button className="AdminContainer__btn">
                <ImportXLS/>
            </button> */}
            <a href={apiConfig.get("admin keyword export")} className="AdminContainer__btn AdminContainer__btn--circle">
                <Icon>
                    <IconXlsExport />
                </Icon>
            </a>
            {/* <button className="AdminContainer__btn">
                <ExportArrow/>
            </button> */}
            <Link to={Routes.AdminKeywordAdd} className="AdminContainer__btn AdminContainer__btn--add">
                + ADD KEYWORD
            </Link>
        </>
    );

    const search = (
        <SearchField
            searchQuery={searchQuery}
            searchError={searchError}
            setSearchQuery={setSearchQuery}
        />
    );

    const table = (
        <AdminKeywordsTable
            isLoading={isLoading}
            getAllAdminKeywords={getAllAdminKeywords}
            setKeywordsData={setKeywordsData}
            data={sortByName(tableData)}
            toggleKeywordExpanded={toggleKeywordExpanded}
        />
    );

    return (
        <Page isWrapped title="Keywords">
            <AdminContainer title="Keyword management" actions={actions} search={search} content={table} />
        </Page>
    );
};

const mapStateToProps = state => ({
    searchQuery: searchQuerySelector(state),
    tableData: tableDataSelector(state),
});

const mapDispatchToProps = dispatch => ({
    setKeywordsData: (data) => dispatch(setField('data', data)),
    setSearchQuery: (query) => dispatch(setField('searchQuery', query)),
    toggleKeywordExpanded: id => dispatch(toggleKeywordExpanded(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Keywords);