
import { useRef, useEffect, useState } from "react";
import { capitalize, deepCopy, getStringMatchRank, StringMatchRanks } from "./old_helpers";

export function usePrevious(value) {
    const ref = useRef(null);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function useTraceUpdate(props, place = "") {
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {});

        if (Object.keys(changedProps).length > 0) {
            console.log(`Changed props ${place}:`, changedProps);
        }
        prev.current = props;
    });
}

export const useSearch = (data, field = "name", initialSearchQuery = "") => {
    const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
    const [filteredSearchData, setFilteredSearchData] = useState(data);

    useEffect(() => {
        const trimmedSearchQuery = searchQuery.trim();
        const searchRegExp = new RegExp(`(${trimmedSearchQuery})`, "gi");
        const isFilter = trimmedSearchQuery.length > 0 && data.length > 0;

        let filtered = isFilter ? [] : data;

        if (isFilter) {
            filtered = data
                .filter(item => {
                    return item[field] && getStringMatchRank(item[field], trimmedSearchQuery) >= StringMatchRanks.Includes;
                })
                .map(item => {
                    const renderName = !trimmedSearchQuery ? item[field] : item[field].replace(searchRegExp, "<b>$1</b>");
                    const renderField = `render${capitalize(field)}`;

                    return {
                        ...item,
                        [renderField]: renderName,
                    };
                });
        }

        setFilteredSearchData(filtered);
    }, [data, searchQuery]);

    return [searchQuery, setSearchQuery, filteredSearchData];
};


export const useSelectOptions = (data, fields) => {
    const getInitialState = (fields) => {
        let initial = {};
        fields.forEach(field => {
            initial = {
                ...initial,
                [field]: [],
                [`${field}SelectedIds`]: []
            }

        });
        return initial;
    };

    const initialState = getInitialState(fields);

    const [dataFilters, setDataFilters] = useState(initialState);

    const handleChangeFilters = (type, ids) => {
        setDataFilters(prevState => ({
            ...prevState,
            [type]: ids,
        }));
    };

    useEffect(() => {
        if ( data.length === 0 || fields.length === 0 ) return;

        fields.forEach(field => {
            const fieldIsArray = Array.isArray(data[0][field]);

            if (fieldIsArray) {
                let uniqueItems = [];
                data.forEach(item => {
                    uniqueItems = [...uniqueItems, ...item[field]];
                });
                uniqueItems = uniqueItems.filter(
                    (item, index, self) => index === self.findIndex(({ name }) => name === item.name),
                );
                handleChangeFilters(field, uniqueItems);
                handleChangeFilters(
                    `${field}SelectedIds`,
                    uniqueItems.map(({ id }) => id),
                );
            } else {
                const uniqueItems = [...new Set(data.map(item => item[field]))];

                handleChangeFilters(
                    field,
                    uniqueItems.map(item => ({ id: item, name: item })),
                );
                handleChangeFilters(`${field}SelectedIds`, uniqueItems);
            }
        });
    }, [data, fields]);

    return [dataFilters, handleChangeFilters]
}

export const useSort = (initialColumn = "name", initialDirection = "asc") => {
    const [direction, setDirection] = useState(initialDirection);
    const [column, setColumn] = useState(initialColumn);

    return { direction, setDirection, column, setColumn }
}


// ?????
export const useDataFilter = (data = [], selectedIds, field) => {
    const [filtered, setFiltered] = useState(deepCopy(data));

    useEffect(() => {
        setFiltered(data.filter(item => selectedIds.includes(item[field])))
    }, [data, selectedIds, field]);

    return [ filtered ]
}


export function useDebouncedCallback(
    callback,
    wait
) {
    const argsRef = useRef();
    const timeout = useRef();

    function cleanup() {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    }

    useEffect(() => cleanup, []);

    return function debouncedCallback(...args) {
        argsRef.current = args;

        cleanup();

        timeout.current = setTimeout(() => {
            if (argsRef.current) {
                callback(...argsRef.current);
            }
        }, wait);
    };
}
