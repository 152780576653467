import ExportButton from "@components/ExportButton/ExportButtonContainer";
import { currentCountryWithPermissionsSelector } from "@selectors/countries.selector";
import { iCurrentCountryWithPermissionsSelector } from "@selectors/selectors.models";
import { FC } from "react";
import { useSelector } from "react-redux";

const FoodProfileFooter: FC<{
    onClick: () => void;
}> = ({
    onClick,
}) => {
    const { isTrial } = useSelector(currentCountryWithPermissionsSelector) as iCurrentCountryWithPermissionsSelector;
    return (
        <div className="page-footer__btn-container">
            <ExportButton
                isTrial={isTrial}
                handleExportMenuClick={onClick} />
        </div>
    )
}

export default FoodProfileFooter;