import { ADMIN_INSTANT_CHART } from "../core/actionNames";

const { SET_FIELD, SET_DATA } = ADMIN_INSTANT_CHART;

export function setData(data) {
  return {
      type: SET_DATA,
      data,
  };
}
export function setField(key, value) {
  return {
      type: SET_FIELD,
      key,
      value,
  };
}