import React from "react";
import Cell from "@components/NewAwesomeTable/Cell";
import Select from "@components/Select/Select";
import { IconSortASC, IconSortDESC } from "@icons";
import Icon from "@components/Icon/Icon";

const Head = ({ showAll, isSortDisabled, sort, handleChangeFilters, dataFilters }) => {
    const handleSorting = type => {
        if (isSortDisabled) return;

        sort.setDirection(sort.column === type ? (sort.direction === "dec" ? "asc" : "dec") : "asc");
        sort.setColumn(type);
    };

    const hoverableCellOptions = !isSortDisabled ? "hv" : "";
    const classesHeaderLabel = type => `AdminTable__header-label ${sort.column === type ? "has-icon" : ""}`;

    const ColumnSortIcons = ({ type }) => {
        if (sort.column === type && !isSortDisabled) {
            return (
                <div className="AdminTable__header-icon">
                    {sort.direction === "asc" ? <Icon type="small"><IconSortASC/></Icon> : <Icon type="small"><IconSortDESC/></Icon>}
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <Cell
                className="AdminTable__col AdminTable__col--fluid"
                options={hoverableCellOptions}
                onClick={() => handleSorting("name")}
            >
                <span className={classesHeaderLabel("name")}>
                    Restaurant list name
                    <ColumnSortIcons type="name" />
                </span>
            </Cell>
            <Cell
                className="AdminTable__col AdminTable__col--large"
                options={hoverableCellOptions}
                onClick={() => handleSorting("restaurantsCount")}
            >
                <span className={classesHeaderLabel("restaurantsCount")}>
                    Restaurants
                    <ColumnSortIcons type="restaurantsCount" />
                </span>
            </Cell>

            {!showAll && (
                <>
                    <Cell className="AdminTable__col AdminTable__col--large" options="hv">
                        <div className="AdminTable__header-dropdown">
                            <Select
                                placeholder=""
                                options={dataFilters.access}
                                value={dataFilters.selectedAccessIds}
                                onChange={value => handleChangeFilters("selectedAccessIds", value)}
                                isCheckboxHidden
                                labelIcon
                                stopPropagation
                                prefix="Access"
                                inheritedStyles
                                showModifierIcon
                                customScrollbar
                                wrapContent
                                dropdownHeight={80}
                            />
                        </div>
                    </Cell>

                    <Cell className="AdminTable__col AdminTable__col--regular AdminTable__col--no-p" />
                </>
            )}

            {showAll && (
                <>
                    <Cell className="AdminTable__col AdminTable__col--large" options="hv">
                        <div className="AdminTable__header-dropdown">
                            <Select
                                placeholder=""
                                options={dataFilters.createdBy}
                                value={dataFilters.selectedCreatedByIds}
                                onChange={value => handleChangeFilters("selectedCreatedByIds", value)}
                                isCheckboxHidden
                                labelIcon
                                stopPropagation
                                prefix="Created by"
                                inheritedStyles
                                showModifierIcon
                                customScrollbar
                                wrapContent
                                dropdownHeight={120}
                            />
                        </div>
                    </Cell>
                    <Cell
                        className="AdminTable__col AdminTable__col--large"
                        options={hoverableCellOptions}
                        onClick={() => handleSorting("createdDate")}
                    >
                        <span className={classesHeaderLabel("createdDate")}>
                            Created date
                            <ColumnSortIcons type="createdDate" />
                        </span>
                    </Cell>
                </>
            )}
        </>
    );
};

export default Head;
