import React from 'react';
import './Subtitle.styles.scss';

function Subtitle({ children, textColor }) {
    return (
        <div className={`Subtitle Subtitle--${textColor}`}>
            {children}
        </div>
    );
}

export default Subtitle;