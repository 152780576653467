import { useData } from '@hooks/useData';
import { fetchAdminDictionaries } from '@core/api';
import {
    components,
} from '@apiSchema';
import { TOP_TRENDS_CATEGORIES } from '@reducers/topTrends.reducer';

export interface iAdminDictionaries {
    topTrendsCategories: components['schemas']['MenuTrends.Common.Models.IdNameItem'][];
    flavorCategories: components['schemas']['MenuTrends.Application.Contracts.Models.ExternalApi.Flavor.FlavorCategory'][];
    insiderCategories: components['schemas']['MenuTrends.Common.Models.IdNameItem'][];
    macs: components['schemas']['MenuTrends.Application.Contracts.Models.ExternalApi.Snap.MacModel'][];
}

type Response = components['schemas']['MenuTrends.Application.Contracts.Models.AdminDictionaries'];

async function getAdminDictionaries(): Promise<iAdminDictionaries> {
    const result: Response = await fetchAdminDictionaries();

    (result.topTrendsCategories || []).sort((a, b) => {
        const aIndex = TOP_TRENDS_CATEGORIES.findIndex(i => i.id === a.id);
        const bIndex = TOP_TRENDS_CATEGORIES.findIndex(i => i.id === b.id);

        return aIndex <= bIndex ? -1 : 1;
    });

    return {
        topTrendsCategories: result.topTrendsCategories || [],
        flavorCategories: result.flavorCategories || [],
        insiderCategories: result.insiderCategories || [],
        macs: result.macs || [],
    };
}

export default function useAdminDictionaries() {
    return useData({
        flavorCategories: [],
        insiderCategories: [],
        macs: [],
        topTrendsCategories: [],
    }, getAdminDictionaries);
}