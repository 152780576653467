import React, { useContext } from "react";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import CustomScrollbarsVirtualList from "@components/CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";
import Loader from "@components/Loader/Loader";
import "./AdminTable.scss";
import NoMatches from "@components/NoMatches/NoMatches";
import { GlobalContext } from "@components/App/App.context";
import cn from "classnames";

const AdminTable = ({
    header,
    row,
    length,
    isLoading = false,
    disableRowTransition = false,
    modifiers = [],
    hasInnerScroll = true,
}) => {
    const { getRem } = useContext(GlobalContext);

    const classList = new Set([
        "AdminTable",
        disableRowTransition ? "AdminTable--no-animation" : "",
        ...modifiers.map(i => `AdminTable--${i}`),
    ]);

    return (
        <div className={Array.from(classList).join(" ")}>
            <div className="AdminTable__header">
                <div className="AdminTable__row AdminTable__row--header">
                    {header}
                </div>
            </div>

            <div className={cn(
                "AdminTable__body",
                !hasInnerScroll && "AdminTable__body--is-fluid"
            )}>
                {!isLoading && length === 0 && <NoMatches/>}
                {hasInnerScroll ? (
                    <>
                        {length > 0 && <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    className="List"
                                    height={height}
                                    itemCount={length}
                                    itemSize={getRem(50)}
                                    width={width}
                                    outerElementType={CustomScrollbarsVirtualList}
                                >
                                    {row}
                                </List>
                            )}
                        </AutoSizer>}
                    </>
                ) : Array(length).fill(null).map((_, index) => row({
                    style: { height: getRem(50)},
                    index,
                }))}


            </div>

            <Loader isShown={isLoading} />
        </div>
    )
};

export default AdminTable;
