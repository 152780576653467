import { ADMIN_INSTANT_CHARTS } from "../core/actionNames";

const { SET_FIELD, SET_DATA, RESET } = ADMIN_INSTANT_CHARTS;

const getInitialState = () => ({
    data: {
        initialData: {},
        instantCharts: []
    },
    searchQuery: "",
    sortingDirection: null,
    sortingColumnType: null,
    selectedCountryIds: [],
    selectedCategoryIds: [],
    selectedSubCategoryIds: [],
    visibleSubCategoryIds: [],

});

export default function adminInstantCharts(state = getInitialState(), action) {
    switch (action.type) {
        case SET_FIELD: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case SET_DATA: {
            return {
                ...state,
                ...action.data,
            };
        }
        case RESET: {
            return getInitialState();
        }
        default: {
            return { ...state };
        }
    }
}
