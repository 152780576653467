import React, { useEffect, useState, useReducer, useContext, useMemo } from 'react'
import { connect } from 'react-redux';
import {
    reFetchData,
    setField,
    setDataWithReFetch,
    toggleField,
    drill,
    deleteDrillWord,
    selectAllCategories,
    setSortingColumn,
    onLoad,
    onUnload, setGrowthPeriod, selectCategories,
} from '@actions/topTrends.actions';
import Search from "@components/Search/Search";
import ToggleInput from "@components/ToggleInput/ToggleInput";
import Filters from "@components/Filters/Filters";
import {
    categoriesForRenderSelector,
    requestDataSelector,
    tableDataSelector
} from "@selectors/topTrends.selectors";
import './TopTrends.styles.scss'
import TopTrendsTable from "./TopTrendsTable";
import TableInfo from "@components/TableInfo/TableInfo";
import FooterContainer from "@containers/FooterContainer/FooterContainer";
import FooterRightContent from "./FooterRightContent";
import RestaurantsMenu from "@components/RestaurantsMenu/RestaurantsMenu";
import LeftFooterContent from "@components/LeftFooterContent/LeftFooterContent";
import MenuExamplesPopup from "@containers/MenuExamplesPopup/MenuExamplesPopup";
import { exportTopTrendsMenuExamples, getTopTrendsMenuExamples } from "@core/api";
import { CSSTransition } from "react-transition-group";
import { addNotification } from "@actions/notifications.actions";
import Page from "@components/Page/Page"
import { filtersDataSelector } from "@selectors/filters.selectors";
import { currentCountryWithPermissionsSelector } from "@selectors/countries.selector";
import LowPenetrationDropdown from "@components/LowPenetrationDropdown/LowPenetrationDropdown";
import { Link } from "react-router-dom";
import KeywordsList from "@components/KeywordsList/KeywordsList";
import { GlobalContext } from "@components/App/App.context";
import cn from "classnames";
import { equalInLC, makeEnding } from "@core/old_helpers";
import { IconFiltersFunnel } from "@icons";


const TopTrends = (
    {
        data,
        searchQuery,
        isLoading,
        includeLowPenetrationItems,
        includeLessTenMenusCount,
        showOnlyNewItems,
        categories,
        totalRecords,
        reFetchData,
        setSearchQuery,
        setIncludeOptions,
        toggleShowOnlyNewItems,
        loadMoreItems,
        drillWords,
        drill,
        deleteDrillWord,
        sortingColumn,
        sortingDirection,
        setSortingColumn,
        onLoad,
        onUnload,
        requestData,
        currentRestaurantList,
        growthPeriod,
        setGrowthPeriod,
        hasFlavorData,
        selectCategories,
        addNotification,
        hasMoreData,
        rawFiltersData,
        permissions = {},
    }
) => {
    const { getRem } = useContext(GlobalContext);
    const [selectedWords, setSelectedWords] = useReducer(selectedWordsReducer, []);
    const [isSelectable, setIsSelectable] = useState(false);
    const [isCategoriesPopupOpened, setIsCategoriesPopupOpened] = useState(false);
    const [isMenuExamplesPopupOpened, setIsMenuExamplesPopupOpened] = useState(false);
    const [menuExamplesWordData, setMenuExamplesWordData] = useReducer((state, action) => ({ ...action }), {});
    const [localCategories, setLocalCategories] = useReducer((state, action) => [...action], []);
    const [isShown, setIsShown] = useState(false);
    const [tooltipShownInx, setTooltipShownInx] = useState(null);
    const { hasNewItemsOnly } = permissions;
    const isApplyButtonHighlighted = useMemo(() => {
        return localCategories.length && categories.some(
            category => localCategories.find(({ id }) => id === category.id).isSelected !== category.isSelected
        ) && localCategories.some(
            category => localCategories.find(({ id }) => id === category.id).isSelected
        );
    }, [localCategories, categories]);

    const isCategoriesApplied = useMemo(
        () => categories.some(c => c.isSelected),
        [categories]
    );

    const appliedCategoriescount = useMemo(
        () => categories.filter(c => c.isSelected).length,
        [categories]
    );

    useEffect(() => {
        if (currentRestaurantList && growthPeriod !== 4) {
            setGrowthPeriod(4);
        }
    }, [currentRestaurantList]);

    useEffect(() => {
        setLocalCategories(categories);
    }, [JSON.stringify(categories)]);

    const removeKeywordFromSelectedList = (keywordName) => {
        setSelectedWords(selectedWords.filter(el => el !== keywordName))
    };

    const handleClickClearAll = () => {
        selectCategories([]);
        setIsCategoriesPopupOpened(false);
    };

    const toggleCategory = (id) => {
        setLocalCategories(localCategories.map((category) => ({
            ...category,
            isSelected: id === category.id ? !category.isSelected : category.isSelected,
        })));

    };

    const handleApplyCategories = () => {
        selectCategories(localCategories.filter(({ isSelected }) => isSelected).map(({ id }) => id));
        setIsCategoriesPopupOpened(false);
    };

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const categoryIdsString = urlSearchParams.get('categoryId');

        if (categoryIdsString) {
            const categoryIds = categoryIdsString.split(',').map(id => +id);
            selectCategories(categoryIds);
        } else {
            selectAllCategories();
        }
        onLoad();
        setIsShown(true);
        return () => {
            onUnload();
        }
    }, []);

    const openMenuExamples = (word) => {
        setIsMenuExamplesPopupOpened(true);
        setMenuExamplesWordData(word);
    };

    const fetchMenuExamples = getTopTrendsMenuExamples.bind(null, {
        ...requestData,
        mainFilter: {
            ...requestData.mainFilter,
            word: menuExamplesWordData,
        },
    });

    const fetchMenuExamplesExport = exportTopTrendsMenuExamples.bind(null, {
        customerFilter: {
            ...requestData.mainFilter,
            word: menuExamplesWordData,
        },
        fullFilter: { ...rawFiltersData },
    });

    let linkToByo = '';

    if (selectedWords.length) {
        linkToByo = encodeURIComponent(selectedWords.join(','));
    }

    const showSelectedWordsUi = useMemo(
        () => isSelectable && selectedWords.length > 0,
        [isSelectable, selectedWords]
    );

    return (
        <Page title="Top Trends">
            <div className="TopTrends">
                <CSSTransition in={isShown} classNames="appears1" timeout={3000} unmountOnExit>
                    <div className="TopTrends__row TopTrends__row--mh">
                        <div className="TopTrends__col">
                            <TableInfo
                                isLoading={isLoading}
                                className="TopTrends__table-info"
                                drillWords={drillWords}
                                totalRecords={totalRecords}
                                handleDeleteItem={deleteDrillWord}
                            />
                        </div>
                        <div className="TopTrends__col TopTrends__col--right">
                            <div className="TopTrends__search">
                                <Search
                                    key="search"
                                    searchQuery={searchQuery}
                                    setSearchQuery={setSearchQuery}
                                    reFetchData={reFetchData}
                                    onEnter={reFetchData}
                                    placeholder="Search for a keyword"
                                    showIcon
                                />
                            </div>
                        </div>
                    </div>
                </CSSTransition>
                <CSSTransition in={isShown} classNames="appears1" timeout={3000} unmountOnExit>
                    <div className="TopTrends__row">
                        <div className="TopTrends__col TopTrends__col--mh">
                            <ToggleInput
                                className="TopTrendsSidebar__radio"
                                labelText="Build keyword list"
                                isChecked={isSelectable}
                                onToggle={() => setIsSelectable(!isSelectable)}
                            />
                            <ToggleInput
                                isVisible={!!hasNewItemsOnly}
                                className="TopTrendsSidebar__radio"
                                labelText="New items only:"
                                isChecked={showOnlyNewItems}
                                onToggle={toggleShowOnlyNewItems}
                                tooltipLabel="Only include items that are new to menus"
                                tooltipShownInx={tooltipShownInx}
                                tooltipInx={1}
                                setTooltipShownInx={setTooltipShownInx}
                            />
                            <LowPenetrationDropdown
                                includeLowPenetrationItems={includeLowPenetrationItems}
                                includeLessTenMenusCount={includeLessTenMenusCount}
                                setIncludeOptions={setIncludeOptions}
                            />
                        </div>
                        <div className="TopTrends__col TopTrends__col--mh TopTrends__col--right">
                            {showSelectedWordsUi &&
                                <>
                                    <button
                                        className="TopTrendsTable__selected-clear-btn"
                                        onClick={() => setIsSelectable(false)}
                                    >
                                        CLEAR SELECTIONS
                                    </button>
                                    &nbsp;
                                    <div className={'TopTrendsTable__selected-info'}>
                                        <div>
                                            {selectedWords.length} keywords selected
                                        </div>
                                        <KeywordsList
                                            height={selectedWords.length >= 8 ? `${getRem(255)}` : getRem(32 * selectedWords.length)}
                                            withoutScrollBar={selectedWords.length < 8}
                                            removeKeyword={removeKeywordFromSelectedList}
                                            className='TopTrendsTable__selected-keywords-list'
                                            keywords={selectedWords}
                                        />
                                    </div>
                                    &nbsp;
                                    <Link
                                        to={`/byo?words=${linkToByo}`}
                                        className={cn(
                                            "TopTrends__button",
                                            selectedWords.length === 0 && 'TopTrends__button--disabled',
                                        )}
                                    >
                                        Go
                                    </Link>
                                </>
                            }
                            {!showSelectedWordsUi && (
                                <>
                                    {isCategoriesApplied && (
                                        <button
                                            className="TopTrendsTable__selected-clear-btn"
                                            onClick={handleClickClearAll}
                                        >
                                            CLEAR CATEGORIES
                                        </button>
                                    )}
                                    <div
                                        className="TopTrends__category-button"
                                        onClick={() => setIsCategoriesPopupOpened(!isCategoriesPopupOpened)}
                                    >
                                        {isCategoriesApplied
                                            ? `${appliedCategoriescount === categories.length ? 'All' : appliedCategoriescount} ${makeEnding('Category', appliedCategoriescount)} Applied`
                                            : 'Narrow by category'}
                                        <IconFiltersFunnel/>
                                    </div>
                                    <CSSTransition
                                        in={isCategoriesPopupOpened}
                                        classNames="appears2"
                                        timeout={100}
                                        unmountOnExit
                                    >
                                        <div className="TopTrends__categories-popup">
                                            <Filters
                                                key="filters"
                                                filtersArray={localCategories}
                                                toggleFilter={toggleCategory}
                                                applyFilters={handleApplyCategories}
                                                isApplyButtonHighlighted={isApplyButtonHighlighted}
                                            />
                                        </div>
                                    </CSSTransition>
                                </>
                            )}
                        </div>
                    </div>
                </CSSTransition>
                <CSSTransition in={isShown} classNames="appears2" timeout={3000} unmountOnExit>
                    <TopTrendsTable
                        data={data}
                        sortingColumn={sortingColumn}
                        sortingDirection={sortingDirection}
                        setSortingColumn={setSortingColumn}
                        drill={drill}
                        isLoading={isLoading}
                        openMenuExamples={openMenuExamples}
                        growsMaxYears={currentRestaurantList ? currentRestaurantList.yearsCount : 11}
                        growthPeriod={growthPeriod}
                        setGrowthPeriod={setGrowthPeriod}
                        hasFlavorData={hasFlavorData}
                        loadMore={loadMoreItems}
                        addNotification={addNotification}
                        hasMoreData={hasMoreData}
                        tooltipShownInx={tooltipShownInx}
                        setTooltipShownInx={setTooltipShownInx}
                        setSelectedWords={setSelectedWords}
                        selectedWords={selectedWords}
                        isSelectable={isSelectable}
                        setIsSelectable={setIsSelectable}

                    />
                </CSSTransition>
                <FooterContainer footerRightContent={<FooterRightContent isAbleCleanBtn={true}/>}>
                    <LeftFooterContent/>
                    <RestaurantsMenu/>
                </FooterContainer>
                <MenuExamplesPopup
                    fetch={fetchMenuExamples}
                    fetchExport={fetchMenuExamplesExport}
                    isShown={isMenuExamplesPopupOpened}
                    close={() => setIsMenuExamplesPopupOpened(false)}
                    downloadUrl={'export top trends menu examples'}
                />
            </div>
        </Page>
    )
};
const selectedWordsReducer = (state, action) => {
    return [...action];
};

const mapState = state => {
    const currentRestaurantList = state.filters.restaurantLists
        .find(({ id }) => id === state.filters.activeRestaurantListId);
    return {
        ...state.topTrends,
        categories: categoriesForRenderSelector(state),
        isFiltersLoaded: !!state.filters.active,
        data: tableDataSelector(state),
        requestData: requestDataSelector(state),
        currentRestaurantList,
        rawFiltersData: filtersDataSelector(state),
        hasFlavorData: equalInLC(state.countries.current, 'USA'),
        currentCountry: state.countries.current,
        permissions: currentCountryWithPermissionsSelector(state),
    };
};

const mapDispatch = dispatch => ({
    drill: (wordData) => dispatch(drill(wordData)),
    reFetchData: () => dispatch(reFetchData()),
    loadMoreItems: () => dispatch(reFetchData(true)),
    setSearchQuery: value => dispatch(setField('searchQuery', value)),
    selectCategories: (ids) => dispatch(selectCategories(ids)),
    setIncludeOptions: data => dispatch(setDataWithReFetch(data)),
    toggleIncludeLowPenetrationItems: () => dispatch(toggleField('includeLowPenetrationItems')),
    toggleShowOnlyNewItems: () => dispatch(toggleField('showOnlyNewItems')),
    deleteDrillWord: (id) => dispatch(deleteDrillWord(id)),
    setSortingColumn: (val) => dispatch(setSortingColumn(val)),
    onLoad: () => dispatch(onLoad()),
    onUnload: () => dispatch(onUnload()),
    setGrowthPeriod: (period) => dispatch(setGrowthPeriod(period)),
    addNotification: notification => dispatch(addNotification(notification)),

});

export default connect(mapState, mapDispatch)(TopTrends)