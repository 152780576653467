import React from 'react';
import './ExportButton.scss';

const ExportButton = ({handleExportMenuClick, isTrial}) => {
   return (
       <button
           onClick={handleExportMenuClick}
           disabled={isTrial}
           className={`ExportButton  ${isTrial ? 'ExportButton--disabled' : ''}`}
       >
           Export
           <div className='ExportButton__tooltip'>
               Export is not available for Trial mode
           </div>
       </button>
   )
};

export default ExportButton;