import React, { useState, useEffect, } from "react";
import PT from "prop-types";
import Modal from "../Modal/Modal";
import RadioInput from "../RadioInput/RadioInput";
import Button from "../Button/Button";
import "./SaveByoSettingsPopup.scss";
import { IconCheck } from "@icons";
import Icon from "@components/Icon/Icon";

const radioArray = [
    {
        id: 0,
        label: "Private",
        description: "only you'll have access to this report",
    },
    {
        id: 1,
        label: "Public",
        description: "accessible by others in your company",
    },
];

const SaveByoSettingsPopup = ({
    shown,
    closeModal,
    onSave,
    currentByoSetting,
}) => {
    const [reportName, setReportName] = useState("");
    const [description, setDescription] = useState("");
    const [checkedRadioId, setCheckedRadioId] = useState(-1);
    const initialIsNewReport = !currentByoSetting;
    const [isNewReport, setIsNewReport] = useState(initialIsNewReport);

    useEffect(() => {
        if (shown && currentByoSetting) {
            setReportName(currentByoSetting.title || "");
            setDescription(currentByoSetting.description || "");
            currentByoSetting.isPublic
                ? setCheckedRadioId(radioArray.find(i => i.label === "Public").id)
                : setCheckedRadioId(radioArray.find(i => i.label === "Private").id);
        }
        setIsNewReport(!currentByoSetting);
    }, [shown]);

    const resetState = () => {
        setReportName("");
        setDescription("");
        setCheckedRadioId(-1);
        setIsNewReport(false);
    };

    const handleClose = () => {
        closeModal();
        resetState();
    };

    const isSaveEnabled =
        reportName.length > 0 &&
        description.length > 0 &&
        checkedRadioId !== -1;

    const handleSaveClick = () => {
        const reportModel = {
            id: currentByoSetting ? currentByoSetting.id : 0,
            title: reportName,
            description,
            isPublic:
                radioArray.find(i => i.label === "Public").id ===
                checkedRadioId,
            isNewReport,
        };
        onSave(reportModel);
        resetState();
        closeModal();
    };

    return (
        <Modal closeModal={handleClose} shown={shown} hasOverlay>
            <div className="saveByoSettingsPopup">
                <div className="saveByoSettingsPopup__inner">
                    <div className="saveByoSettingsPopup__report_name">
                        <input
                            type="text"
                            // placeholder="Name your project"
                            className={`saveByoSettingsPopup__name-input saveByoSettingsPopup__name-input--${reportName.length > 0 ? 'filled' : 'empty'}`}
                            value={reportName}
                            onChange={event =>
                                setReportName(event.target.value)
                            }
                        />
                        <span
                            className="saveByoSettingsPopup__name-label"
                        >
                            Name your report
                        </span>
                    </div>
                    <div className="saveByoSettingsPopup__report-description">
                        <textarea
                            value={description}
                            onChange={event =>
                                setDescription(event.target.value)
                            }
                            // placeholder="Give it a description"
                            className={`saveByoSettingsPopup__descr-input saveByoSettingsPopup__descr-input--${description.length > 0 ? 'filled' : 'empty'}`}
                        />
                        <span
                            className="saveByoSettingsPopup__description-label"
                        >
                            Give it a description
                        </span>
                    </div>
                    <div className="saveByoSettingsPopup__radio-wr">
                        <div className="saveByoSettingsPopup__subtitle">Save as:</div>
                        <div className="saveByoSettingsPopup__btns">
                            {radioArray.map(radio => (
                                <div
                                    key={radio.id}
                                    className="radio-input-wrapper"
                                >
                                    <RadioInput
                                        name="status"
                                        isChecked={radio.id === checkedRadioId}
                                        description={radio.description}
                                        label={radio.label}
                                        onChange={() =>
                                            setCheckedRadioId(radio.id)
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    {currentByoSetting && (
                        <div className="saveByoSettingsPopup__radio-wr saveByoSettingsPopup__radio-wr--center">
                            <div className="saveByoSettingsPopup__subtitle">Save as a new report</div>
                            <div className="saveByoSettingsPopup__btns">
                                <div className="saveByoSettingsPopup__checkbox-container">
                                    <RadioInput
                                        name="newReporter"
                                        onChange={() => setIsNewReport(!isNewReport)}
                                        isChecked={isNewReport}
                                        className="saveByoSettingsPopup__radio-checkbox"
                                        label={''}
                                    />
                                    <span
                                        className={`saveByoSettingsPopup__checkbox-icon ${isNewReport ? 'saveByoSettingsPopup__checkbox-icon--checked' : ''}`}
                                    >
                                        <Icon type="small">
                                            <IconCheck/>
                                        </Icon>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="saveByoSettingsPopup__footer">
                        <Button
                            className="saveByoSettingsPopup__btn-cancel"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="saveByoSettingsPopup__btn-apply"
                            isDisabled={!isSaveEnabled}
                            onClick={handleSaveClick}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

SaveByoSettingsPopup.propTypes = {
    shown: PT.bool,
    closeModal: PT.func,
    onSave: PT.func,
    currentByoSetting: PT.object,
};

export default SaveByoSettingsPopup;
