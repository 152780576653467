import { TOP_TRENDS } from '../core/actionNames';
import { deepCopy } from "@core/old_helpers";
import { PermissionLevelEnum } from "@models/PermissionSection";

const { SET_FIELD, SET_DATA, CLEAR } = TOP_TRENDS;
export const TOP_TRENDS_CATEGORIES = [
    {
        id:1,
        name: 'Sauce/Flavor',
        image: '/Sauce.jpg',
    },
    {
        id:2,
        name: 'Spice',
        image: '/Spice.jpeg',
    },
    {
        id:3,
        name: 'Protein',
        image: '/Protein.jpeg',
    },
    {
        id:6,
        name: 'Veggie',
        image: '/Veggie.jpeg',
    },
    {
        id:7,
        name: 'Fruit',
        image: '/Fruit.jpeg',
    },
    {
        id:5,
        name: 'Cheese',
        image: '/Cheese.jpeg',
    },
    {
        id:4,
        name: 'Bread',
        image: '/Bread.jpeg',
    },
    {
        id:9,
        name: 'Noodle',
        image: '/Noodle.jpeg',
    },
    {
        id:8,
        name: 'Nut',
        image: '/Nut.jpeg',
    },
    {
        id:10,
        name: 'Appetizer',
        image: '/Appetizer.jpg',
    },
    {
        id:11,
        name: 'Entree',
        image: '/Entree.jpeg',
    },
    {
        id:12,
        name: 'Side',
        image: '/Side.jpeg',
    },
    {
        id:13,
        name: 'Dessert',
        image: '/Dessert.jpg',
    },
    {
        id:14,
        name: 'Non Alc Bev',
        image: '/Non Alc Bev.jpg',
    },
    {
        id:15,
        name: 'Cocktail',
        image: '/Cocktail.jpeg',
    },
    {
        id:16,
        name: 'Alcohol Type',
        image: '/Alcohol Type.jpeg',
    },
    {
        id:18,
        name: 'Prep Method',
        image: '/Prep Method.jpeg',
    },
    {
        id:19,
        name: 'Healthy',
        image: '/Healthy.jpeg',
    },
    {
        id:20,
        name: 'Descriptor',
        image: '/Descriptor.jpeg',
    },
    {
        id:21,
        name: 'Brand',
        image: '/Brand.jpeg',
    },
    {
        id:22,
        name: 'Location',
        image: '/Location.jpeg',
    }
];

const initialState = {
    isLoading: true,
    totalCount: 0,
    totalRecords: 0,
    searchQuery: '',
    sortingDirection: 'desc',
    sortingColumn: 0,
    growthPeriod: 1,
    drillWords: [],
    data: [],
    flavorPermissionLevel: PermissionLevelEnum.Full,
    showOnlyNewItems: false,
    includeLowPenetrationItems: false,
    includeLessTenMenusCount: true,
    categories: deepCopy(TOP_TRENDS_CATEGORIES),
    selectedCategories: [],
    isLoaded: false,
    hasMoreData: true,
};

export default function topTrends(state = deepCopy(initialState), action) {
    switch (action.type) {
        case SET_FIELD: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case SET_DATA: {
            return {
                ...state,
                ...action.data,
            };
        }
        case CLEAR: {
            return {
                ...state,
                isLoading: true,
                totalCount: 0,
                totalRecords: 0,
                searchQuery: '',
                sortingDirection: 'desc',
                sortingColumn: 0,
                drillWords: [],
                data: [],
                flavorPermissionLevel: PermissionLevelEnum.Full,
                showOnlyNewItems: false,
                includeLowPenetrationItems: false,
                includeLessTenMenusCount: true,
                categories: deepCopy(TOP_TRENDS_CATEGORIES),
                selectedCategories: [],
            };
        }
        default: {
            return { ...state };
        }
    }
}