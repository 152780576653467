import CellNew from "@components/CellNew/CellNew";
import { FC } from "react";

const FoodProfileTableBody: FC<{ data: iTableDataItem[] }> = ({ data }) => {
    return (
        <div className="FoodProfileTable__body">
            {data.map(row => (
                <div className="FoodProfileTable__row" key={row.total.name}>
                    <div className="FoodProfileTable__cell FoodProfileTable__cell--colspan FoodProfileTable__cell--sticky">
                        <CellNew
                            className="FoodProfileTable__cell is-larger"
                            modifiers={["bg-dark", "right"]}
                        >
                            {row.total.name}
                        </CellNew>
                        <CellNew
                            className="FoodProfileTable__cell"
                            modifiers={["bg-dark", "center"]}
                        >
                            {row.total.value}
                        </CellNew>
                    </div>

                    <div className="FoodProfileTable__cell FoodProfileTable__cell--colspan">
                        {row.values.map((value, index) => {
                            return (
                                <CellNew
                                    key={index}
                                    className="FoodProfileTable__cell"
                                    modifiers={["center"]}
                                >
                                    {value}
                                </CellNew>
                            )
                        })}
                    </div>
                </div>
            ))}
        </div>
    )
}

export interface iTableDataItem {
    total: {
        name: string,
        value: string,
    },
    values: string[],
}

export default FoodProfileTableBody;