import React from "react";
import Modal from "../Modal/Modal";
import PT from "prop-types";
import "./ConfirmationPopup.scss";
import Button from "../Button/Button";

const ConfirmationPopup = ({ closeModal, shown, title, text = null, onConfirm, submitButtonText = "Clear" }) => {
    const handleConfirm = () => {
        onConfirm();
        closeModal();
    };

    return (
        <Modal closeModal={closeModal} shown={shown} hasOverlay>
            <div className="Popup__wrapper">
                <div className="Popup Popup--confirm">
                    <div className="Popup__title Popup__title--small">{title}</div>

                    {text && (<div className="Popup__text has-gap">{text}</div>)}

                    <div className="Popup__btn-container">
                        <Button onClick={closeModal} modifiers={["transparent"]}>
                            Cancel
                        </Button>
                        <Button onClick={handleConfirm} modifiers={["blue", "rounded"]}>
                            {submitButtonText}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

ConfirmationPopup.propTypes = {
    closeModal: PT.func,
    shown: PT.bool,
    title: PT.string,
    onConfirm: PT.func,
};

export default ConfirmationPopup;
