import { KEYWORDS } from '../core/actionNames';

const { SET_DATA } = KEYWORDS;

const defaultState = {
    data: [],
};

export default function countries(state = defaultState, action) {
    switch (action.type) {
        case SET_DATA: {
            return {
                ...state,
                data: action.data,
            };
        }
        default: {
            return state;
        }
    }
}