import React, { useState, useEffect } from "react";
import PT from "prop-types";
import ToggleInput from "../ToggleInput/ToggleInput";
import "./Attributes.scss";
import CheckboxInput from "../CheckboxInput/CheckboxInput";

const Attributes = (
    {
        data,
        handleCheckboxGroupChange,
        handleChange,
        fullLayout = false
    }
) => {
    const [isDropDownVisible, setIsDropDownVisible] = useState(false);

    useEffect(() => {
        if (isDropDownVisible) {
            document.addEventListener("click", closeDropdown, true);
        } else {
            document.removeEventListener("click", closeDropdown, true);
        }
    }, [isDropDownVisible]);

    const closeDropdown = ({ target }) => {
        const dropdownElement = document.querySelector(".dropdown-enter-done");
        if (dropdownElement && !dropdownElement.contains(target)) {
            setIsDropDownVisible(false);
        }
    };

    // let ethnicSpecialityMsg = '';
    // const esCheckedItems = data.ethnicSpeciality.filter(option => option.isChecked);
    //
    // if (data.ethnicSpeciality.every(option => option.isChecked)) {
    //     ethnicSpecialityMsg = 'All selected';
    // } else {
    //     ethnicSpecialityMsg = `
    //         ${data.ethnicSpeciality.filter(option => option.isChecked).length} of ${data.ethnicSpeciality.length} selected
    //     `;
    //     if (esCheckedItems.length !== 0) {
    //         if (esCheckedItems.length === 1) {
    //             ethnicSpecialityMsg += `(${data.ethnicSpeciality.find(option => option.isChecked).name})`;
    //         } else if (esCheckedItems.length === 2) {
    //             ethnicSpecialityMsg += `(${esCheckedItems[0].name} and ${esCheckedItems[1].name})`;
    //         } else {
    //             ethnicSpecialityMsg += `(${esCheckedItems[0].name} and ${esCheckedItems[1].name} and ${esCheckedItems.length - 2} others)`;
    //         }
    //     }
    // }

    const isEthnicSpecialityChecked = !!data.ethnicSpeciality.find(item => item.isChecked);

    // const isEthnicSpecialityEnabled = !data.healthyTerms && !data.smallPlates && !data.glutenFree && !data.kidMenus;
    const isHealthyTermsEnabled = !isEthnicSpecialityChecked && !data.smallPlates && !data.kidMenus;
    const isGlutenFreeEnabled = !isEthnicSpecialityChecked && !data.smallPlates && !data.kidMenus;
    const isPlantBasedEnabled = !isEthnicSpecialityChecked && !data.smallPlates && !data.kidMenus;
    const isSmallPlatesEnabled = !isEthnicSpecialityChecked && !data.healthyTerms && !data.plantBased && !data.glutenFree && !data.kidMenus;
    const isKidMenusEnabled = !isEthnicSpecialityChecked && !data.healthyTerms && !data.plantBased && !data.glutenFree && !data.smallPlates;

    const isAllCateringItems = data.catering === null;
    const isOnlyCateringEnabled = isAllCateringItems || data.catering;
    const isNonCateringEnabled = isAllCateringItems || !data.catering;

    return (
        <div className={`attributes ${fullLayout ? 'attributes--is-full' : ''}`}>
            <div className="attributes__header">
                <div className="attributes__col FiltersWidget__header">
                    <CheckboxInput
                        isBlueCheck
                        onChange={() => handleCheckboxGroupChange('dayParts', 'all')}
                        labelText="Dayparts"
                        isChecked={data.dayParts.every(option => option.isChecked)}
                        isPartialChecked={data.dayParts.some(option => option.isChecked) && !data.dayParts.every(option => option.isChecked)}
                        isBold
                        testId="inputFiltersPopupCheckboxDayPartsAll"
                    />
                </div>
                <div className="attributes__col FiltersWidget__header">
                    <CheckboxInput
                        onChange={() => null}
                        labelText="Catering"
                        isChecked
                        noIcon
                        isBold
                        style={{
                            cursor: 'default',
                        }}
                        testId="textFiltersPopupCatering"
                    />
                </div>
                <div className="attributes__col FiltersWidget__header">
                    <CheckboxInput
                        onChange={() => null}
                        labelText="Additional Filters"
                        isChecked
                        noIcon
                        isBold
                        style={{
                            cursor: 'default',
                        }}
                        testId="textFiltersPopupAdditionalFilters"
                    />
                </div>
            </div>
            <div className="attributes__content">
                <div
                    className="attributes__col FiltersWidget__content"
                    data-testid="selectFiltersPopupDayParts"
                >
                    {data.dayParts.map((option, index) => (
                        <div key={index} className="attributes__checkbox">
                            <CheckboxInput
                                isSmall
                                onChange={() => handleCheckboxGroupChange('dayParts', option.id)}
                                labelText={option.name}
                                isChecked={option.isChecked}
                                testId={`inputFiltersPopupCheckboxDayParts-${index}`}
                            />
                        </div>
                    ))}
                </div>
                <div
                    className="attributes__col FiltersWidget__content"
                    data-testid="selectFiltersPopupCatering"
                >
                    <CheckboxInput
                        isSmall
                        className="attributes__checkbox"
                        onChange={() => handleChange('catering', isAllCateringItems ? true : data.catering ? null : true)}
                        labelText="Regular Menu Items"
                        isChecked={isNonCateringEnabled}
                        testId="inputFiltersPopupCheckboxNonCatering"
                    />
                    <CheckboxInput
                        isSmall
                        className="attributes__checkbox"
                        onChange={() => handleChange('catering', isAllCateringItems ? false : data.catering ? false : null)}
                        labelText="Catering Menu Items"
                        isChecked={isOnlyCateringEnabled}
                        testId="inputFiltersPopupCheckboxCatering"
                    />
                </div>
                <div className="attributes__col FiltersWidget__content">
                    <div className="attributes__radio-form">
                        <ToggleInput
                            className="attributes__radio"
                            labelText="Items with Healthy terms"
                            isChecked={data.healthyTerms}
                            disabled={!isHealthyTermsEnabled}
                            onToggle={() => handleChange('healthyTerms', !data.healthyTerms)}
                            testId="inputFiltersPopupCheckboxHealthyTerms"
                        />
                        <ToggleInput
                            className="attributes__radio"
                            labelText="Items with Plant Based Alternatives"
                            isChecked={data.plantBased}
                            disabled={!isPlantBasedEnabled}
                            onToggle={() => handleChange('plantBased', !data.plantBased)}
                            testId="inputFiltersPopupCheckboxPlantBased"
                        />
                        <ToggleInput
                            className="attributes__radio"
                            labelText="Items from Small Plate Menus"
                            isChecked={data.smallPlates}
                            disabled={!isSmallPlatesEnabled}
                            onToggle={() => handleChange('smallPlates', !data.smallPlates)}
                            testId="inputFiltersPopupCheckboxSmallPlates"
                        />
                        <ToggleInput
                            className="attributes__radio"
                            labelText="Items with Gluten Free term(s)"
                            isChecked={data.glutenFree}
                            disabled={!isGlutenFreeEnabled}
                            onToggle={() => handleChange('glutenFree', !data.glutenFree)}
                            testId="inputFiltersPopupCheckboxGlutenFree"
                        />
                        <ToggleInput
                            className="attributes__radio"
                            labelText="Items on Kid's Menus or portioned for Kids"
                            isChecked={data.kidMenus}
                            disabled={!isKidMenusEnabled}
                            onToggle={() => handleChange('kidMenus', !data.kidMenus)}
                            testId="inputFiltersPopupCheckboxKidMenus"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

Attributes.propTypes = {
    data: PT.shape({
        ethnicSpeciality: PT.array,
        minPrice: PT.oneOfType([PT.number, PT.string]),
        maxPrice: PT.oneOfType([PT.number, PT.string]),
        healthyTerms: PT.bool,
        smallPlates: PT.bool,
        glutenFree: PT.bool,
        plantBased: PT.bool,
        kidMenus: PT.bool,
        catering: PT.bool,
        restaurants: PT.array,
        words: PT.array,
    }),
    changePrice: PT.func,
    toggleInput: PT.func,
    toggleCheckbox: PT.func,
    keywords: PT.array,
    onWordsSubmit: PT.func,
    onRestaurantsSubmit: PT.func,
    removeWord: PT.func,
    removeRestaurant: PT.func,
    toggleGroup: PT.func,
};

export default Attributes;
