import React, { useState, useRef, useReducer, useEffect, useContext } from 'react';
import TruncateMarkup from 'react-truncate-markup';
import './LineEllipsis.scss'
import { getJSXFromHTML } from "@core/old_helpers";
import { GlobalContext } from '@components/App/App.context';

const LineEllipsis = ({ children, lines, rowHeight = 64 }) => {
    const [content, setContent] = useReducer((data, newData) => newData, []);
    const [isTruncated, setIsTruncated] = useState(false);
    const expanded = useRef(null);
    const { getRem } = useContext(GlobalContext);
    const lineHeight = rowHeight || getRem(64);

    useEffect(() => {
        setContent(getJSXFromHTML(children));
    }, [children]);

    useEffect(() => {
        if (expanded.current) {
            setIsTruncated(expanded.current.getBoundingClientRect().height > lineHeight);
        }
    }, [expanded.current, lineHeight]);

    return (
        <div className="LineEllipsis">
            <TruncateMarkup lines={lines}>
                <div>
                    {content}
                </div>
            </TruncateMarkup>
            <div
                className={`LineEllipsis__expanded ${isTruncated ? '' : 'LineEllipsis__expanded--hidden'}`}
                ref={expanded}
                dangerouslySetInnerHTML={{ __html: children }}
                data-testid={isTruncated ? "textTruncate" : undefined}
            />
        </div>
    );

};

export default LineEllipsis;
