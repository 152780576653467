import { MQ_VIEWPORT_SIZE } from "@core/getResponsiveScale";
import { prepareForSorting } from "@core/old_helpers";
import { AVAILABLE_COUNTRIES } from "@reducers/countries.reducer";

const sortData = (data, field, compareArray) => {
    const temp = [...data];

    temp.sort((a, b) => {
        const valA = prepareForSorting(a[field]);
        const valB = prepareForSorting(b[field]);

        const matchA = compareArray.find(el => el.name === valA);
        const matchB = compareArray.find(el => el.name === valB);

        if (matchA && matchB) {
            return (matchA.order - matchB.order) * 1;
        } else if (matchA) {
            return matchA.order * -1;
        } else if (matchB) {
            return matchB.order * 1;
        }
        return (valA < valB ? -1 : valA > valB ? 1 : 0) * 1;
    });

    return temp;
};

export const getDefaultCountryCode = (countries = []) => {
    const availableCountries = countries.filter(({hasAccess, countryCode}) => hasAccess && AVAILABLE_COUNTRIES.includes(countryCode));
    const ORDER = [
        {
            name: "usa",
            order: 1,
        },
        {
            name: "canada",
            order: 2
        },
    ];
    const orderedCountries = sortData(availableCountries, "name", ORDER);
    return orderedCountries.length > 0 ? orderedCountries[0].countryCode : null;
}


export const setColorTheme = (isThemeLightEnable) => {
    const body = document.body;
    const THEME_LIGHT = 'theme-light';
    const THEME_DARK = 'theme-dark';

    if ( isThemeLightEnable ) {
        body.classList.add(THEME_LIGHT)
        body.classList.remove(THEME_DARK)
    } else {
        body.classList.remove(THEME_LIGHT)
        body.classList.add(THEME_DARK)
    }
}
