import React from "react";
import AdminTable from "@pages/Admin/Common/AdminTable";
import Head from "./Head";
import Row from "./Row";

const Table = ({
    data,
    isSortDisabled,
    sort,
    handleChangeFilters,
    dataFilters,
    isLoading,
    allowSelectRows,
    handleSelectRestaurant,
    openRemoveConfirmPopup,
    isViewMode,
}) => {
    const TableHeader = (
        <Head
            isSortDisabled={isSortDisabled}
            sort={sort}
            handleChangeFilters={handleChangeFilters}
            dataFilters={dataFilters}
            allowSelectRows={allowSelectRows}
            isViewMode={isViewMode}
        />
    );

    const TableRow = ({ index, style }) => {
        const rowData = data[index];

        return (
            <div style={style} className={`AdminTable__row`}>
                <Row
                    data={rowData}
                    openRemoveConfirmPopup={openRemoveConfirmPopup}
                    allowSelectRows={allowSelectRows}
                    handleSelectRestaurant={handleSelectRestaurant}
                    totalRegions={dataFilters.regions.length}
                    isViewMode={isViewMode}
                />
            </div>
        );
    };

    return (
        <AdminTable
            header={TableHeader}
            length={data.length}
            row={TableRow}
            disableRowTransition
            modifiers={["fluid"]}
            isLoading={isLoading}
        />
    );
};

export default Table;
