import React from 'react';
import './NoMatches.styles.scss';

function NoMatches ({
    style,
}) {
    return (
        <div
            className="NoMatches"
            style={style}
        >
            No matching results found
        </div>
    );
}

export default NoMatches;