import { FC, memo, useContext } from "react";
import { iViewBoxProps } from "./Chart.helpers";
import { iAxisYPoint } from "./ChartAxisY";
import { ChartThemeContext } from "./ChartTheme.context";

interface iProps {
    viewport: iViewBoxProps;
    yAxisData: iAxisYPoint[];
}

const ChartGrid: FC<iProps> = ({
    viewport,
    yAxisData,
}) => {
    const { THEME } = useContext(ChartThemeContext);
    return (
        <g name="chart-grid">
            {yAxisData.map((point,index) => (
                <line
                    key={`ChartGrid-${index}`}
                    strokeWidth={0.5}
                    x1={0}
                    x2={viewport.width}
                    y1={point.y}
                    y2={point.y}
                    stroke={THEME.GRID}
                />
            ))}
        </g>
    )
}

export default memo(ChartGrid);