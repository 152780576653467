import React, {useEffect} from "react";
import {connect} from "react-redux";
import "./FooterContainer.scss";
import {
    get as getByoFilters,
    setActiveRestaurantList,
} from "@actions/filters.actions";

const FooterContainer = (
    {
        children = null,
        footerRightContent,
    }
) => {

    useEffect(() => {
        getByoFilters("ca");
    }, []);

    return (
        <div className='page-footer'>
            {children}
            {footerRightContent}
        </div>
    );
};

const mapStateToProps = state => ({
    currentByoSetting: state.byoSettings.currentByoSetting,
    byoSettingsData: state.byoSettings.data,
    isFiltersPopupShown: state.filters.isFiltersPopupShown
});

const mapDispatchToProps = dispatch => ({
    getFilters: country => dispatch(getByoFilters(country)),
    setActiveRestaurantListId: id => dispatch(setActiveRestaurantList(id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FooterContainer);
