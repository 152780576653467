import { iPenetration } from "@models/FoodProfile";
import { ID } from "@datassential/platform-ui-lib";
import { components } from "@apiSchema";
import { iChartBase } from "@components/Chart/Chart.helpers";

export interface ICategoryFastestGrowingKeyword {
    id: number;
    name: string;
    penetration: number | null;
    predictedFourYearGrowth: number | null;
    fourYearTrend: number | null;
    chartData: iChartBase;
}

export interface ICategoryFastestGrowing {
    id: ID;
    keywords: ICategoryFastestGrowingKeyword[];
}

export type ICategoryFastestGrowingApiModel = components["schemas"]["MenuTrends.Application.Contracts.Models.TopTrendKeywordsModel"];

export class CategoryFastestGrowing implements ICategoryFastestGrowing {
    id = 0;
    keywords: ICategoryFastestGrowingKeyword[] = [];

    constructor(data?: { apiModel: ICategoryFastestGrowingApiModel }) {
        if (data) {
            if (data.apiModel) {
                this.mapFromApiModel(data.apiModel);
            }
        }
    }

    private setData(data: ICategoryFastestGrowing): void {
        Object.assign(this, data);
    }

    static mapPenetration(penetration: components['schemas']['MenuTrends.Application.Contracts.Models.TopTrendKeywordPenetration']): iPenetration {
        return {
            name: penetration.periodName ?? '',
            value: `${penetration.penetration ?? 0}`,
        };
    }

    static getWordData (data: iPenetration[]) {
        return data.map((y, index) => ({
            ...y,
            id: index,
            valueNumeric: parseFloat(y.value) || 0,
            baseSize: '0'
        }))
    };

    static sortByYear (a: iPenetration, b: iPenetration) {
        return  b.name > a.name ? -1 : 1;
    }

    private mapFromApiModel(apiModel: ICategoryFastestGrowingApiModel): void {
        const { topTrendCategoryId, keywords } = apiModel;

        this.setData({
            id: topTrendCategoryId ?? 0,
            keywords: (keywords || []).map((k) => {
                const yearsPenetration = (k.penetrations || []).map(CategoryFastestGrowing.mapPenetration);
                const quartersPenetration = (k.quarterPenetrations || []).map(CategoryFastestGrowing.mapPenetration);
                const yearsPredictedPenetration = (k.predictions || []).map(CategoryFastestGrowing.mapPenetration);
                const quartersPredictedPenetration = (k.quarterPredictions || []).map(CategoryFastestGrowing.mapPenetration);

                const actualData = CategoryFastestGrowing.getWordData(yearsPenetration.sort(CategoryFastestGrowing.sortByYear));
                const actualDataQuarters = CategoryFastestGrowing.getWordData(quartersPenetration.sort(CategoryFastestGrowing.sortByYear));
                const predictedData = CategoryFastestGrowing.getWordData(yearsPredictedPenetration.sort(CategoryFastestGrowing.sortByYear));
                const predictedDataQuarters = CategoryFastestGrowing.getWordData(quartersPredictedPenetration.sort(CategoryFastestGrowing.sortByYear));

                return {
                    id: k.id ?? 0,
                    name: k.name ?? '',
                    penetration: k.currentPenetration ?? null,
                    predictedFourYearGrowth: k.predictedGrowth ?? null,
                    fourYearTrend: k.fourYearTrend ?? null,
                    chartData: {
                        actualData,
                        actualDataQuarters,
                        predictedData,
                        predictedDataQuarters,
                        concatData: [
                            ...actualData,
                            ...actualDataQuarters,
                            ...predictedDataQuarters,
                            ...predictedData,
                        ]
                    },
                };
            }),
        });
    }
}