import { FCX, TextInput } from "@datassential/platform-ui-lib";
import { useCallback, useEffect, useState } from "react";
import Popup from "@components/Popup/Popup";
import { createTagCategory } from "@core/api";
import Loader from "@components/Loader/Loader";
import { iOption } from "@models";

const CreateTagCategoryPopup: FCX<{
    isShown: boolean;
    close: () => void;
    setCategory: (category: iOption) => void;
}> = ({
    isShown,
    setCategory,
    close,
}) => {
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = useCallback(
        () => {
            setIsLoading(true);
            createTagCategory(name)
                .then((data) => {
                    setCategory(data);
                    close();
                })
                .catch((response) => {
                    if (response.status === 409) {
                        setError(`Error: ${response.responseText}`);
                    } else {
                        setError('Error: Something went wrong');
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [name, setCategory, close]
    );

    useEffect(() => {
        setName('');
        setError('');
    }, [isShown]);

    return (
        <Popup
            modifier="confirm"
            shown={isShown}
            closeModal={close}
            title={'Create Tag Category'}
            applyBtnTitle="Save"
            onConfirm={handleSubmit}
            closeOnConfirm={false}
        >
            <Loader isShown={isLoading} />
            <TextInput
                value={name}
                setValue={(value) => {
                    setName(value);
                    setError('');
                }}
                onSubmit={handleSubmit}
                placeholder={'Enter Tag Category Name'}
                autoFocus
            />
            {!!error && <div className="InputError">{error}</div>}
        </Popup>
    );
}

export default CreateTagCategoryPopup;