import { FC, memo, useContext } from "react";
import { PointType } from "@components/Chart/Chart.helpers";
import { ChartThemeContext } from "./ChartTheme.context";

const ChartDot: FC<{
    cx: number;
    cy: number;
    color: string;
    type: PointType;
}> = (
    {
        cy,
        cx,
        color,
        type,
    }
) => {
    const { THEME } = useContext(ChartThemeContext);

    switch (type) {
        case PointType.Empty: {
            return (
                <circle
                    cx={cx}
                    cy={cy}
                    r={3}
                    stroke={color}
                    fill={THEME.BACKGROUND.HAIKU}
                    className="Chart__point"
                />
            );
        }
        case PointType.Solid: {
            return (
                <>
                    <circle
                        cx={cx}
                        cy={cy}
                        r={5}
                        fill={color}
                        className="Chart__point-back"
                    />
                    <circle
                        cx={cx}
                        cy={cy}
                        r={3}
                        fill={color}
                        className="Chart__point"
                    />
                </>
            )
        }
        case PointType.Small: {
            return (
                <>
                    <circle
                        cx={cx}
                        cy={cy}
                        r={4}
                        fill={color}
                        className="Chart__point-back"
                    />
                    <circle
                        cx={cx}
                        cy={cy}
                        r={2}
                        fill={color}
                        className="Chart__point"
                    />
                </>
            )
        }
        default:
            return null;
    }

};

export default memo(ChartDot);