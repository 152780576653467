import { getData as getKeywordSettingsData } from './keywordSettings.actions';
import {  getKeywordNames  } from './adminKeywords.actions';
import { APP } from "../core/actionNames";
import { getAdminAccess } from '../core/api';

const { SET_FIELD, SET_THEME } = APP;

export function setField(key, value) {

    return {
        type: SET_FIELD,
        key,
        value,
    };
}

export function setTheme(value) {

    return {
        type: SET_THEME,
        value,
    };
}

export function getAccess() {
    return (dispatch) => {
        dispatch(setField('isAdminAccessLoading', true));
        getAdminAccess().then((value) => {
            dispatch(setField('hasAdminAccess', value));
        }).finally(() => {
            dispatch(setField('isAdminAccessLoading', false));
        });
    };
}

export function getData() {
    return (dispatch) => {
        dispatch(getKeywordSettingsData())
        dispatch(getKeywordNames());
        dispatch(getAccess());
    }
}
