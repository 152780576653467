import { RefObject, useEffect } from "react";
interface iOutsideClick {
    ref: RefObject<HTMLDivElement>;
    isDisabled: boolean;
    fn: () => void;
}
const useOutsideClick = ({ ref, isDisabled = false, fn }: iOutsideClick) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                ref &&
                ref.current &&
                !ref.current.contains(event.target as Node)
            ) {
                fn();
            }
        };
        if (!isDisabled) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [fn, ref, isDisabled]);
};

export default useOutsideClick;
