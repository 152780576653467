import { FCX } from "@datassential/platform-ui-lib";
import { ReactNode } from "react";
import cn from "classnames";

const HomeWidget: FCX<{
    header: ReactNode;
}> = ({
    header,
    children,
    className,
}) => {
    return (
        <div className={cn(
            "HomeWidget",
            "Home__widget",
            className,
        )}>
            <div className="HomeWidget__header">
                {header}
            </div>
            <div className="HomeWidget__content">
                {children}
            </div>
        </div>
    );
}

export default HomeWidget;