import React, { useContext, useEffect, useState } from "react";
import Cell from "@components/NewAwesomeTable/Cell";
import { Actions, ReportContext } from "./ReportOptions.reducer";
import CheckboxInput from "@components/CheckboxInput/CheckboxInput";
import { IconDelete, IconCheckSuccess, IconCloseFailure } from "@icons";
import Icon from "@components/Icon/Icon";

const AdminReportTableRow = ({ data, isDragging, openConfirmPopup }) => {
    const { optionsData, dispatchOptionsAction } = useContext(ReportContext);
    const [countSelectedFilters, setCountSelectedFilters] = useState(0);
    const [name, setName] = useState(data.name);

    useEffect(() => {
        const count = data.generatorFilter.reduce((previousValue, item) => (parseInt(previousValue) + parseInt(+item.isChecked)), [0])

        setCountSelectedFilters(count);
    }, [data.generatorFilter]);

    const handleName = (id, value) => {
        dispatchOptionsAction({
            type: Actions.SET_FIELD,
            sectionName: "childReports",
            id,
            value: {name: value},
        });

        setName(value);
    }

    const handleToggleExpandedRow = (id, value) => {
        const index = optionsData.childReports.findIndex(item => item.id === id);

        dispatchOptionsAction({
            type: Actions.EDIT,
            sectionName: "childReports",
            index,
            data: { isExpanded: value }
        });
    }

    const handleGeneratorFilter = (data) => {
        dispatchOptionsAction({
            type: Actions.UPDATE_GENERATOR_FILTER,
            data
        });
    }

    return (
        <>
            <div className={`AdminTable__row ${isDragging ? "is-dragging" : ""}`}>
                <Cell className="AdminTable__col AdminTable__col--fluid">
                    <span className="AdminTable__truncated" title={data.instantChartName}>{data.instantChartName}</span>
                </Cell>
                <Cell className="AdminTable__col AdminTable__col--large-extra">
                    <input
                        className="AdminTable__cell-input"
                        value={name}
                        placeholder="Add name of Report"
                        onChange={(e) => handleName(data.id, e.target.value)}
                    />
                </Cell>
                <Cell className="AdminTable__col AdminTable__col--large-extra">
                    <span className="AdminTable__truncated" title={data.category}>
                        {data.category}
                    </span>
                </Cell>
                <Cell className="AdminTable__col AdminTable__col--large-extra">
                    <span className="AdminTable__truncated" title={data.subCategory}>
                        {data.subCategory}
                    </span>
                </Cell>
                <Cell className="AdminTable__col AdminTable__col--regular" options="center">
                    {data.isInstantChartActive ? <Icon><IconCheckSuccess/></Icon> :<Icon><IconCloseFailure/></Icon>}
                </Cell>
                <Cell className="AdminTable__col AdminTable__col--medium AdminTable__col--no-p">
                    <div className={`AdminTable__toggle ${countSelectedFilters ? "is-filled" : ""} ${data.isExpanded ? "is-open" : ""}`} onClick={() => handleToggleExpandedRow(data.id, !data.isExpanded)}>
                        {countSelectedFilters > 0 ? `${countSelectedFilters} items of ${data.generatorFilter.length}` : data.isExpanded ? "Chosen" : "Choose" }
                    </div>
                </Cell>
                <Cell className="AdminTable__col AdminTable__col--regular" options="center no-p">
                    <button className="AdminTable__btn is-danger" onClick={() => openConfirmPopup(data.id, data.instantChartName)}>
                        <Icon type="small">
                            <IconDelete />
                        </Icon>
                    </button>
                </Cell>
            </div>

            {data.isExpanded && (
                <div className={`AdminTable__row ${isDragging ? "is-dragging" : ""}`}>
                    <div className="AdminContainer__checkboxes-group AdminContainer__checkboxes-group--small">
                            {data.generatorFilter && data.generatorFilter.map((item, index) => {
                                const label = item.name.charAt(0).toUpperCase() + item.name.slice(1);

                                return (
                                    <div className="AdminContainer__checkboxes-group-item" key={index}>
                                        <CheckboxInput
                                            isBlueCheck
                                            labelText={label}
                                            isChecked={item.isChecked}
                                            modifiers={["inherited"]}
                                            onChange={() => handleGeneratorFilter({parentId: data.id, index, value: !item.isChecked})}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                </div>
            )}
        </>
    );
};

export default AdminReportTableRow;