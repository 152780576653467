import React, {useRef} from "react";
import {connect} from "react-redux"
import PT from "prop-types";
import "./ChartTooltip.scss";
import {currentCurrencySelector} from "@selectors/countries.selector";

const ChartTooltip = ({
    data,
    activeColumnX1,
    activeColumnX2,
    clientY,
    chartHeight,
    tooltipWidth,
    currentCurrency,
    activeTabId,
}) => {
    const tooltipEl = useRef(null);

    const getTooltipPositionStyles = () => {
        let _tooltipWidth = tooltipWidth;
        if (tooltipEl.current) {
            _tooltipWidth =
                tooltipEl.current &&
                getComputedStyle(tooltipEl.current).width.split("px")[0];
        }
        const tooltipVerticalPadding = 5;
        const tooltipHorizontalPadding = 5;
        const tooltipRowHeight = 25;
        const tooltipHeaderHeight = 20;
        const tooltipHeight =
            data.values && data.values.length * tooltipRowHeight +
            tooltipHeaderHeight +
            tooltipVerticalPadding;
        let left = activeColumnX1 - _tooltipWidth + 10;
        let onTheLeftSide = true;

        if (left <= tooltipHorizontalPadding) {
            left = activeColumnX2 - tooltipHorizontalPadding;
            onTheLeftSide = false;
        }
        const top = clientY - tooltipHeight / 2;

        return {
            styles: {
                transform: `translate(${parseInt(left)}px, ${parseInt(top)}px)`,
                minWidth: tooltipWidth,
            },
            onTheLeftSide,
        };
    };

    const {onTheLeftSide, styles} = getTooltipPositionStyles();
    const classList = new Set([
        "tooltip",
        onTheLeftSide ? "tooltip--left" : "tooltip--right",
    ]);

    return (
        <div
            ref={tooltipEl}
            className={Array.from(classList).join(" ")}
            style={styles}
        >
            <div className="tooltip__header">
                <div className="tooltip__header-text">{data.title}</div>
            </div>
            <div className="tooltip__data">
                {data.values.map((data, idx) => {
                    let valueWithCurrency;

                    if (activeTabId === 3) {
                        valueWithCurrency = (
                            <>
                                <span className="tooltip__currency">
                                     {currentCurrency}
                                </span>
                                <span>
                                     {data.value.indexOf('-') >= 0 ? '0.00' : data.value.replace(/^\D+/g, '')}
                                </span>
                            </>
                        )

                    } else {
                        valueWithCurrency = data.value === `${currentCurrency}-` ? '0.0' : data.value
                    }

                    return (<div key={idx} className="tooltip__list-item">
                        <span
                            className="tooltip__circle"
                            style={{color: data.color}}
                        />
                            <span className="tooltip__list-item-name">
                            {data.name}
                        </span>
                            <span className="tooltip__list-item-value">
                            {valueWithCurrency}
                        </span>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

ChartTooltip.propTypes = {
    activeColumnX1: PT.number,
    activeColumnX2: PT.number,
    clientY: PT.number,
    chartHeight: PT.number,
    title: PT.string,
    data: PT.shape({
        title: PT.string,
        values: PT.arrayOf(
            PT.shape({
                color: PT.string,
                value: PT.string,
                name: PT.string,
            })
        ),
    }),
};

export default connect(state => ({
    activeTabId: state.byo.activeAnalyticsType,
    currentCurrency: currentCurrencySelector(state),
}))(ChartTooltip);
