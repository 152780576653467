import React from "react";
import PT from "prop-types";
import CheckboxInput from "@components/CheckboxInput/CheckboxInput";
import "./Dropdown.scss";

const Dropdown = (
    {
        handleChange,
        options,
        ethnic = false,
        arrowLeft = '',
        withGroups,
        onItemMouseEnter = () => {},
        onItemMouseLeave = null,
        testId,
    }
) => {

    const classList = new Set(["dropdown", ethnic ? "dropdown--ethnic" : ""]);
    const groups = Array.from(
        new Set(
            options.map(({ groupId, groupName }) => JSON.stringify({ id: groupId, name: groupName }))
        )
    ).map((group) => JSON.parse(group)).filter(({ id }) => !!id);

    return (
        <div
            className={Array.from(classList).join(" ")}
            data-testid={testId}
        >
            <div className="dropdown__arrow" style={{ left: arrowLeft, }}/>
            <div className="dropdown__item">
                {withGroups ? (
                    <>
                        {groups.map((group, groupIndex) => {
                            const filteredOptions = options.filter(option => option.groupId === group.id);
                            return (
                                <CheckboxInput
                                    className="dropdown__category"
                                    key={group.id}
                                    onChange={() => handleChange(group.id, true)}
                                    labelText={group.name}
                                    isChecked={filteredOptions.every(o => o.isChecked)}
                                    isPartialChecked={!filteredOptions.every(o => o.isChecked) && filteredOptions.some(o => o.isChecked)}
                                    isWhiteMark
                                    isSmall
                                    isBold
                                    testId={`inputDropdownGroupCheckbox-${groupIndex}`}
                                />
                            );
                        })}
                    </>
                ) : (
                    <CheckboxInput
                        onChange={() => handleChange('all')}
                        labelText="All items"
                        isChecked={options.every(option => option.isChecked)}
                        isPartialChecked={options.some(option => option.isChecked) && !options.every(option => option.isChecked)}
                        isSmall
                        isBold
                        isWhiteMark
                        testId={`inputDropdownGroupCheckbox-0`}
                    />
                )}
            </div>
            {options.map((option, optionIndex) => (
                <div key={option.id} className="dropdown__item">
                    <CheckboxInput
                        onChange={() => handleChange(option.id)}
                        labelText={option.name}
                        isChecked={option.isChecked}
                        isSmall
                        onMouseEnter={() => onItemMouseEnter(option.id)}
                        onMouseLeave={onItemMouseLeave}
                        testId={`inputDropdownItemCheckbox-${optionIndex}`}
                    />
                </div>
            ))}
        </div>
    );
};

Dropdown.propTypes = {
    items: PT.array,
    onLeft: PT.bool,
    handleCheck: PT.func,
    colIndex: PT.number,
    handleCheckGroup: PT.func,
    testId: PT.string,
};

export default Dropdown;
