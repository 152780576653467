import React, { FC, ReactNode, useContext } from "react";
import CircularProgressBar, { iStopPoint } from "@components/CircularProgressBar/CircularProgressBar";
import cn from "classnames";
import { GlobalContext } from "@components/App/App.context";
import { useSelector } from "react-redux";
import Color from "color";

export interface iFlavorNorm {
    title: string;
    description: string;
    value: number,
    position?: 'left' | 'right',
    tooltipPosition?: 'left' | 'right',
}

interface iMetricChartData {
    title: string;
    value: number;
    norm?: iFlavorNorm;
    gradient?: iStopPoint[];
    size?: 'regular' | 'small';
    color?: string;
}

const DEFAULT_COLOR_DARK = '#cdcdcd';
const DEFAULT_COLOR_LIGHT = '#6a6a6a';

function getRankEnding (n: number): string {
    if ([11, 12, 13].includes(n)) return 'th';
    switch (n % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th'
    }
}

function getRenderTitle(title: string): ReactNode {
    switch (title.toLowerCase()) {
        case 'have tried it': return (
            <>
                have
                <br/>
                tried it
            </>
        );
        case 'had many times': return (
            <>
                had
                <br/>
                many times
            </>
        );
        default: return (
            <>
                {title}
            </>
        )
    }
}

const MetricChart: FC<{
    data: iMetricChartData;
}> = (
    {
        data,
    }
) => {
    const { getRem } = useContext(GlobalContext);
    const isThemeLightEnable = useSelector((state: any) => state.app.isThemeLightEnable);
    const {
        title,
        norm,
        value,
        size = 'regular',
        color,
    } = data;

    const defaultColor = isThemeLightEnable ? DEFAULT_COLOR_LIGHT : DEFAULT_COLOR_DARK;

    const width = getRem(size === 'regular' ? 120 : 75);

    return (
        <CircularProgressBar
            id={`CircleBar-${title.replace(/[^a-zA-Z\d]/gi, '')}`}
            value={value}
            width={width}
            customGradient={[
                // { offset: 0, color: Color(color).alpha(0).string() },
                { offset: 0, color: color || defaultColor, },
                { offset: 100, color: color || defaultColor, },
            ]}
            lineWidth={getRem(10)}
        >
            {!!norm && (
                <div className={cn("FoodProfileFlavor__norm", `FoodProfileFlavor__norm--${norm.position || 'right'}`)}>
                    {norm.value}
                    {(norm.title || norm.description) && (
                        <div className={cn(
                            "FoodProfileFlavorTooltip",
                            `FoodProfileFlavorTooltip--${norm.tooltipPosition || 'right'}`
                        )}>
                            <div
                                className="FoodProfileFlavorTooltip__title"
                                dangerouslySetInnerHTML={{ __html: norm.title }}
                            />
                            <div
                                className="FoodProfileFlavorTooltip__description"
                                dangerouslySetInnerHTML={{
                                    __html: norm.description.replace(
                                        new RegExp(`the ${norm.value} percentile`),
                                        `the <span class="rank-ending ${getRankEnding(norm.value)}">${norm.value}</span> percentile`
                                    ),
                            }}
                            />
                        </div>
                    )}
                </div>
            )}
            <div className="FoodProfileFlavor__circle-chart-text">
                <div className={cn(
                    "FoodProfileFlavor__circle-chart-value",
                    `FoodProfileFlavor__circle-chart-value--${size}`,
                )}>
                    {value}%
                </div>
                <div className={cn(
                    "FoodProfileFlavor__circle-chart-title",
                    `FoodProfileFlavor__circle-chart-title--${size}`,
                )}>
                    {getRenderTitle(title)}
                </div>
            </div>
        </CircularProgressBar>
    );
};

export default MetricChart;