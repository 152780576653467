import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setField } from "@actions/adminReports.actions";
import SearchField from "@components/SearchField/SearchField";
import SelectCountryPopup from "@components/SelectCountryPopup/SelectCountryPopup";
import serializeParams from "@core/serializeParams";
import { Routes } from "@routes";
import { searchQuerySelector } from "@selectors/adminReports.selectors";
import AdminContainer from "@pages/Admin/Common/AdminContainer";
import AdminReportsTable from "./AdminReportsTable";

function AdminReports({ countries, searchQuery, setSearchQuery, setSelectedContryIds, tableData, isLoading }) {
    let history = useNavigate();
    const location = useLocation();
    const [isAdding, setIsAdding] = useState(false);

    // Open country popup on redirect from Report
    useEffect(() => {
        if ( location.params ) {
            setIsAdding(location.params.openSelectCountryPopup);
        }
    }, [location]);

    const handleConfirmSelectedCountry = code => {
        if (code) {
            setIsAdding(false);

            history({
                pathname: Routes.AdminSummaryReportAdd,
                search: serializeParams({
                    country: code,
                }),
            });
        }
    };

    // Set all countries as selected by default
    useEffect(() => {
        const ids = countries.map(el => el.countryCode);
        setSelectedContryIds(ids);
    }, [countries]);

    const search = <SearchField searchQuery={searchQuery} setSearchQuery={setSearchQuery} />;

    const actions = (
        <>
            <button
                onClick={() => setIsAdding(true)}
                className="AdminContainer__btn AdminContainer__btn--add AdminContainer__btn--medium">
                + ADD NEW REPORT
            </button>
            <SelectCountryPopup
                data={countries}
                shown={isAdding}
                handleConfirm={code => handleConfirmSelectedCountry(code)}
                closeModal={() => setIsAdding(false)}
                title="Please, choose only 1 country for your report"
            />
        </>
    );

    const content = <AdminReportsTable isLoading={isLoading} />;

    return <AdminContainer title="Summary Tables Reports" search={search} actions={actions} content={content} />;
}

const mapStateToProps = state => {
    return {
        searchQuery: searchQuerySelector(state),
    };
};

const mapDispatchToProps = dispatch => ({
    setSearchQuery: query => dispatch(setField("searchQuery", query)),
    setSelectedContryIds: ids => dispatch(setField("selectedCountryIds", ids)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminReports);
