import { FC, useContext, useMemo } from "react";
import Title from "@components/Title/Title";
import './FoodProfileFlavor.styles.scss';
import ExperienceMetric, { iExperienceMetricData } from "@pages/FoodProfile/FoodProfileFlavor/ExperienceMetric";
import MetricChart from "@pages/FoodProfile/FoodProfileFlavor/MetricChart";
import { GlobalContext } from "@components/App/App.context";
import { iFoodProfileFlavor } from "@models/FoodProfileFlavor";
import { iFlavorMetric } from "@models/FlavorMetric";
import { isAvailable } from "@models/PermissionSection";
import PermissionOverlay from "@components/PermissionOverlay/PermissionOverlay";
import { useDispatch } from "react-redux";
import { addNotification } from "@actions/notifications.actions";
import { requestAccess } from "@core/api";

function mapExperienceMetric(title: string, color: string, data: iFlavorMetric): iExperienceMetricData {
    return {
        title,
        value: data.raw,
        norm: {
            value: data.norm,
            title: data.normHeaderText,
            description: data.normDescriptionText,
        },
        rankHeaderMsg: data.rankHeaderText,
        rankValueMsg: data.rankCountText,
        color,
    };
}

function wrapMatch(
    text: string,
    match: string,
    template: string = '<b class="FoodProfileFlavor__keyword-highlight">$1</b>'
): string {
    return text.replace(
        new RegExp(`(${match})`, 'gi'),
        template
    )
}
const FoodProfileFlavor: FC<{
    keywordName: string;
    data: iFoodProfileFlavor;
    currentCountry: string;
}> = (
    {
        keywordName,
        data,
        currentCountry,
    }
) => {
    const dispatch = useDispatch();
    const sendNotification = (notification: any) => dispatch(addNotification(notification));

    const {
        data: {
            knowIt: rawKnowIt,
            haveTriedIt: rawHaveTriedIt,
            hadManyTimes: rawHadManyTimes,
            loveIt: rawLoveIt,
            hateIt,
            likeIt,
            neutral,
            noOpinion,
            dislike,
        },
    } = data;

    const knowIt: iFlavorMetric = {
        ...rawKnowIt,
        normHeaderText: wrapMatch(rawKnowIt.normHeaderText, keywordName),
        normDescriptionText: wrapMatch(rawKnowIt.normDescriptionText, keywordName),
    };
    const haveTriedIt: iFlavorMetric = {
        ...rawHaveTriedIt,
        normHeaderText: wrapMatch(rawHaveTriedIt.normHeaderText, keywordName),
        normDescriptionText: wrapMatch(rawHaveTriedIt.normDescriptionText, keywordName),
    };
    const hadManyTimes: iFlavorMetric = {
        ...rawHadManyTimes,
        normHeaderText: wrapMatch(rawHadManyTimes.normHeaderText, keywordName),
        normDescriptionText: wrapMatch(rawHadManyTimes.normDescriptionText, keywordName),
    };
    const loveIt: iFlavorMetric = {
        ...rawLoveIt,
        normHeaderText: wrapMatch(rawLoveIt.normHeaderText, keywordName),
        normDescriptionText: wrapMatch(rawLoveIt.normDescriptionText, keywordName),
    };

    const { getRem } = useContext(GlobalContext);
    const experienceMetricsData: iExperienceMetricData[] = useMemo(() => {
        return [
            mapExperienceMetric(
                'Know It',
                '#4fbbf8',
                knowIt,
            ),
            mapExperienceMetric(
                'Have Tried It',
                '#45922a',
                haveTriedIt,
            ),
            mapExperienceMetric(
                'Had Many Times',
                '#bf61ca',
                hadManyTimes,
            ),
        ];
    }, [knowIt, hadManyTimes, haveTriedIt]);

    const handleButtonClick = () => {
        requestAccess(currentCountry, 'Food Profile');
        sendNotification({
            text: "Request sent",
            duration: 4000,
        });
    };

    return (
        <div className="FoodProfile__flavor FoodProfileFlavor">
            <Title>
                <div className="FoodProfile__title">
                    Consumer Perception
                </div>
            </Title>

            <div className="FoodProfileFlavor__section">
                {!isAvailable(data) && (
                    <div className="FoodProfileFlavor__overlay">
                        <PermissionOverlay
                            handleButtonClick={handleButtonClick}
                        />
                    </div>
                )}
                <div className="FoodProfileFlavor__column">
                    <Title>
                        <div className="FoodProfileFlavor__section-header">
                            <div className="FoodProfileFlavor__title">
                                EXPERIENCE
                                <div className="FoodProfileFlavor__sub-title">
                                    How often do you have...
                                </div>
                            </div>
                            <div>AMONG ALL CONSUMERS</div>
                        </div>
                    </Title>
                    <div className="FoodProfileFlavor__section-content">
                        {experienceMetricsData.map(metric => (
                            <ExperienceMetric
                                key={`${metric.title}-${metric.value}`}
                                data={metric}
                            />
                        ))}
                    </div>
                </div>
                <div className="FoodProfileFlavor__column">
                    <Title>
                        <div className="FoodProfileFlavor__section-header">
                            <div className="FoodProfileFlavor__title">
                                AFFINITY
                                <div className="FoodProfileFlavor__sub-title">
                                    How do you feel about...
                                </div>
                            </div>
                            <div>AMONG ALL CONSUMERS</div>
                        </div>
                    </Title>
                    <div className="FoodProfileFlavor__section-content">
                        <div className="FoodProfileFlavor__col">
                            <div
                                className="FoodProfileFlavor__row FoodProfileFlavor__row--spaces"
                                style={{
                                    paddingLeft: getRem(25),
                                }}
                            >
                                <MetricChart data={{
                                    title: 'Love It',
                                    value: loveIt.raw,
                                    norm: {
                                        value: loveIt.norm,
                                        title: loveIt.normHeaderText,
                                        description: loveIt.normDescriptionText,
                                        position: 'left',
                                    },
                                    color: '#f22c9f',
                                }}/>
                                <MetricChart data={{
                                    title: 'Like It',
                                    size: 'small',
                                    value: likeIt,
                                    color: '#fa9c30',
                                }}/>
                                <MetricChart data={{
                                    title: 'Neutral',
                                    size: 'small',
                                    value: neutral,
                                }}/>
                                <MetricChart data={{
                                    title: 'Dislike',
                                    size: 'small',
                                    value: dislike,
                                }}/>
                                <MetricChart data={{
                                    title: 'Hate It',
                                    size: 'small',
                                    value: hateIt,
                                }}/>
                                <div className="FoodProfileFlavor__abs-msg">
                                    {noOpinion}% have no opinion
                                </div>
                            </div>
                            <div className="FoodProfileFlavor__row FoodProfileFlavor__row--col">
                                <div className="FoodProfileFlavor__sub-title">
                                    {loveIt.rankHeaderText}
                                </div>
                                <div className="FoodProfileFlavor__title">
                                    {loveIt.rankCountText}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FoodProfileFlavor;