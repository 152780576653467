import React, { useContext } from "react";
import { connect } from "react-redux";
import { GlobalContext } from "@components/App/App.context";
import Chart from "@components/Chart/Chart";
import AutoSizer from "react-virtualized-auto-sizer";

const TestPage = () => {
    const { getRem } = useContext(GlobalContext);

    return (
        <div
            className="test-page"
            style={{
                height: '100vh',
                paddingTop: getRem(100),
                display: 'flex',
                flexDirection: "column",
                justifyContent: 'center',
                alignItems: "center",
                position: 'relative',
                width: '100%',
            }}>
            <div style={{ width: 1000, height: 400, position: "relative" }}>
                <AutoSizer>
                    {({ height, width}) => (
                        <Chart
                            height={height}
                            width={width}
                            columns={[
                                { title: '2020' },
                                { title: '2021', subColumns: ['q1', 'q2', 'q3']},
                                { title: '2022', subColumns: ['q1', 'q2', 'q3'] },
                            ]}
                            charts={[
                                { title: 'apple', color: "red", data: [[1], [122, 212, 312], [1, 2, 3] ] }
                            ]}
                            printValue={(v) => `${v}%`}
                            printLabel={(v) => `${v}%`}
                            dashStartPointColumnIndex={1}
                            dashStartPointSubColumnIndex={1}
                        />
                    )}
                </AutoSizer>
            </div>
            <br/>
            <div style={{ width: 1000, height: 400, position: "relative" }}>
                <AutoSizer>
                    {({ height, width}) => (
                        <Chart
                            height={height}
                            width={width}
                            columns={[
                                { title: '2020' },
                                { title: '2021' },
                                { title: '2022'},
                            ]}
                            charts={[
                                { title: 'apple', color: "red", data: [[1], [1], [1, 2, 3] ] }
                            ]}
                            printValue={(v) => `${v}%`}
                            printLabel={(v) => `${v}%`}
                            dashStartPointColumnIndex={1}
                            dashStartPointSubColumnIndex={1}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    )
};

export default connect()(TestPage);
