import { downloadBlobFile, getFilenameFromContentDisposition } from "@core/helpers";

const defaultHeaders = [
    { key: "Accept", value: "application/json" },
    { key: "Content-type", value: "application/json; charset=utf-8" },
];

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type AbortablePromise<T = any> = Promise<T> & {
    abort?: () => void;
};

class Http {
    fetch(method: HttpMethod, url: string, sendData: any = {}, headers = defaultHeaders) {
        const xhr = new window.XMLHttpRequest();
        xhr.open(method, url);
        headers.forEach(({ key, value }) => xhr.setRequestHeader(key, value));
        xhr.withCredentials = true;
        const promise: AbortablePromise = new Promise((resolve, reject) => {
            xhr.onload = () => {
                this.handleResponseStatus(
                    xhr.status,
                    () => {
                        try {
                            resolve(JSON.parse(xhr.response));
                        } catch (err) {
                            resolve(xhr.response);
                        }
                    },
                    () => {
                        reject(xhr);
                    }
                );
            };

            xhr.onerror = () => reject(xhr);

            if (sendData) {
                xhr.send(sendData);
            } else {
                xhr.send();
            }
        });

        promise.abort = () => {
            xhr.abort();
        };

        return promise;
    }

    handleResponseStatus (status: number, resolve: () => void, reject: () => void) {
        if (status === 401) {
            window.location.href = `${process.env.REACT_APP_API}/userAuth?returnUrl=${encodeURIComponent(window.location.href)}`;
        } else if (status === 403) {
            window.location.href = process.env.REACT_APP_SNAP ?? '';
        } else if (status >= 200 && status < 300) {
            resolve();
        } else {
            reject();
        }
    }

    get(url: string): AbortablePromise {
        return this.fetch("GET", url);
    }

    post(url: string, body?: any): AbortablePromise {
        return this.fetch("POST", url, JSON.stringify(body));
    }

    put(url: string, body?: any): AbortablePromise {
        return this.fetch("PUT", url, JSON.stringify(body));
    }

    delete(url: string, body?: any): AbortablePromise {
        return this.fetch("DELETE", url, JSON.stringify(body));
    }

    sendFormData(url: string, formData: FormData): AbortablePromise {
        return this.fetch("POST", url, formData, []);
    }

    downloadFileXHRFromUrl(method: HttpMethod, query: string, data?: any) {
        const xhr = new window.XMLHttpRequest();
        xhr.open(method, query);
        xhr.responseType = 'blob';
        xhr.withCredentials = true;
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');

        const promise: AbortablePromise = new Promise((resolve, reject) => {
            xhr.onload = () => {
                this.handleResponseStatus(
                    xhr.status,
                    () => {
                        if (xhr.status !== 204) {
                            let blob = xhr.response;
                            let disposition = xhr.getResponseHeader('Content-Disposition');
                            const filename = getFilenameFromContentDisposition(disposition);
                            downloadBlobFile(blob, filename);
                            resolve(xhr.response);
                        }

                        reject(xhr.statusText);
                    },
                    () => {
                        reject(xhr.statusText);
                    }
                )
            };

            xhr.onerror = () => reject(xhr.statusText);
            xhr.send(data);
        });

        promise.abort = () => {
            xhr.abort();
        };

        return promise;
    }
}

export default new Http();
