import { getCountries } from '../core/api';
import { COUNTRIES } from '../core/actionNames';
import { resetData as resetByoData } from './byo.actions';
import { resetData as resetByoSettingsData } from './byoSettings.actions';
import { reset as resetTopTrendsData } from './topTrends.actions';
import { get as getFilters, resetData as resetFiltersData } from './filters.actions';

const { SET_DATA, SET_CURRENT, SET_FIELD } = COUNTRIES;

export function setCurrent(countryCode) {
    return (dispatch, getState) => {
        if (getState().countries.current !== countryCode) {
            dispatch({
                type: SET_CURRENT,
                countryCode,
            });
            dispatch(resetFiltersData());
            dispatch(getFilters());
            if (getState().byo.isLoaded) {
                dispatch(resetByoSettingsData());
                dispatch(resetByoData(true));
            }
            if (getState().topTrends.isLoaded) {
                dispatch(resetTopTrendsData());
            }
        }
    };
}

export function setData(data) {
    return {
        type: SET_DATA,
        data,
    };
}
export function setField(name, value) {
    return {
        type: SET_FIELD,
        name,
        value
    }
}

export function get() {
    return (dispatch) => {
        getCountries()
            .then((data) => {
                dispatch(setData(data));
            });
    }
}