import { FunctionComponent, SVGProps } from "react";

import { ReactComponent as AustraliaFlag } from "./countries/AUS.svg";
import { ReactComponent as AustraliaDisabledFlag } from "./countries/AUS-gs.svg";
import { ReactComponent as BangladeshFlag } from "./countries/BGD.svg";
import { ReactComponent as BangladeshDisabledFlag } from "./countries/BGD-gs.svg";
import { ReactComponent as CANFlag } from "./countries/CAN.svg";
import { ReactComponent as CANDisabledFlag } from "./countries/CAN-gs.svg";
import { ReactComponent as FranceFlag } from "./countries/FRA.svg";
import { ReactComponent as FranceDisabledFlag } from "./countries/FRA-gs.svg";
import { ReactComponent as GermanyFlag } from "./countries/GER.svg";
import { ReactComponent as GermanyDisabledFlag } from "./countries/GER-gs.svg";
import { ReactComponent as HongKongFlag } from "./countries/HKG.svg";
import { ReactComponent as HongKongDisabledFlag } from "./countries/HKG-gs.svg";
import { ReactComponent as IndiaFlag } from "./countries/IND.svg";
import { ReactComponent as IndiaDisabledFlag } from "./countries/IND-gs.svg";
import { ReactComponent as IrelandFlag } from "./countries/IRL.svg";
import { ReactComponent as IrelandDisabledFlag } from "./countries/IRL-gs.svg";
import { ReactComponent as MalaysiaFlag } from "./countries/MYS.svg";
import { ReactComponent as MalaysiaDisabledFlag } from "./countries/MYS-gs.svg";
import { ReactComponent as PakistanFlag } from "./countries/PAK.svg";
import { ReactComponent as PakistanDisabledFlag } from "./countries/PAK-gs.svg";
import { ReactComponent as PhilippinesFlag } from "./countries/PHL.svg";
import { ReactComponent as PhilippinesDisabledFlag } from "./countries/PHL-gs.svg";
import { ReactComponent as SingaporeFlag } from "./countries/SGP.svg";
import { ReactComponent as SingaporeDisabledFlag } from "./countries/SGP-gs.svg";
import { ReactComponent as ThailandFlag } from "./countries/THA.svg";
import { ReactComponent as ThailandDisabledFlag } from "./countries/THA-gs.svg";
import { ReactComponent as UnitedArabianEmiratesFlag } from "./countries/UAE.svg";
import { ReactComponent as UnitedArabianEmiratesDisabledFlag } from "./countries/UAE-gs.svg";
import { ReactComponent as UnitedKingdomFlag } from "./countries/UK.svg";
import { ReactComponent as UnitedKingdomDisabledFlag } from "./countries/UK-gs.svg";
import { ReactComponent as USAFlag } from "./countries/USA.svg";
import { ReactComponent as USADisabledFlag } from "./countries/USA-gs.svg";
import { ReactComponent as Info } from './Information.svg';

import { ReactComponent as Check } from "./Check.svg";
import { ReactComponent as CheckSuccess } from "./Check_Success.svg";
import { ReactComponent as Close } from "./Close.svg";
import { ReactComponent as CloseFailure } from "./Close_Failure.svg";
import { ReactComponent as Edit } from "./Edit.svg";
import { ReactComponent as ArrowBottom } from "./ArrowBottom.svg";
import { ReactComponent as ArrowLeft } from "./ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "./ArrowRight.svg";
import { ReactComponent as ArrowTop } from "./ArrowTop.svg";
import { ReactComponent as Delete } from "./Delete.svg";
import { ReactComponent as ArrowCircle } from "./ArrowCircle.svg";
import { ReactComponent as Drag } from "./drag.svg";
import { ReactComponent as Export } from "./Upload.svg";
import { ReactComponent as XlsExport } from "./Export XLS.svg";
import { ReactComponent as XlsImport } from "./Import XLS.svg";
import { ReactComponent as View } from "./View.svg";
import { ReactComponent as FilterSecond } from "./filter2.svg";
import { ReactComponent as FilterFourth } from "./filter4.svg";
import { ReactComponent as FilterSixth } from "./filter6.svg";
import { ReactComponent as Chart } from "./Chart.svg";
import { ReactComponent as Gear } from "./gear.svg";
import { ReactComponent as PdfExport } from "./PDF Exp Default.svg";
import { ReactComponent as PdfExportHover } from "./PDF Exp Hover.svg";
import { ReactComponent as PptExport } from "./PPT Exp Default.svg";
import { ReactComponent as PptExportHover } from "./PPT Exp Hover.svg";
import { ReactComponent as Xls2Export } from "./xls-2-export.svg";
import { ReactComponent as Xls2ExportHover } from "./xls-2-export-hover.svg";
import { ReactComponent as Lock } from "./Lock.svg";
import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as MenuTrends } from "./mt-logo-light.svg";
import { ReactComponent as MenuTrendsLight } from "./mt-logo-dark.svg";
import { ReactComponent as PDFStroke } from "./pdf-icon.svg";
import { ReactComponent as PPTStroke } from "./ppt-icon.svg";
import { ReactComponent as Add } from "./Add.svg";
import { ReactComponent as Search } from "./Search.svg";
import { ReactComponent as SortASC } from "./SortASC.svg";
import { ReactComponent as SortDESC } from "./SortDESC.svg";
import { ReactComponent as Haiku } from "./Haiku.svg";
import { ReactComponent as CopySimple } from './CopySimple.svg';
import { ReactComponent as FileSearch } from './FileSearch.svg';
import { ReactComponent as FiltersFunnel } from './FiltersFunnel.svg';
import { ReactComponent as CaretDoubleDown } from './CaretDoubleDown.svg';
import { ReactComponent as LogoLight } from './logo-light.svg';
import { ReactComponent as LogoDark } from './logo-dark.svg';
import { ReactComponent as Ppt } from './ppt.svg';
import { ReactComponent as Xls } from './xls.svg';

type IconType = FunctionComponent<SVGProps<SVGSVGElement>>;

export const Australia = AustraliaFlag;
export const AustraliaDisabled = AustraliaDisabledFlag;
export const Bangladesh = BangladeshFlag;
export const BangladeshDisabled = BangladeshDisabledFlag;
export const CAN = CANFlag;
export const CANDisabled = CANDisabledFlag;
export const France = FranceFlag;
export const FranceDisabled = FranceDisabledFlag;
export const Germany = GermanyFlag;
export const GermanyDisabled = GermanyDisabledFlag;
export const HongKong = HongKongFlag;
export const HongKongDisabled = HongKongDisabledFlag;
export const India = IndiaFlag;
export const IndiaDisabled = IndiaDisabledFlag;
export const Ireland = IrelandFlag;
export const IrelandDisabled = IrelandDisabledFlag;
export const Malaysia = MalaysiaFlag;
export const MalaysiaDisabled = MalaysiaDisabledFlag;
export const Pakistan = PakistanFlag;
export const PakistanDisabled = PakistanDisabledFlag;
export const Philippines = PhilippinesFlag;
export const PhilippinesDisabled = PhilippinesDisabledFlag;
export const Singapore = SingaporeFlag;
export const SingaporeDisabled = SingaporeDisabledFlag;
export const Thailand = ThailandFlag;
export const ThailandDisabled = ThailandDisabledFlag;
export const UnitedArabianEmirates = UnitedArabianEmiratesFlag;
export const UnitedArabianEmiratesDisabled = UnitedArabianEmiratesDisabledFlag;
export const UnitedKingdom = UnitedKingdomFlag;
export const UnitedKingdomDisabled = UnitedKingdomDisabledFlag;
export const USA = USAFlag;
export const USADisabled = USADisabledFlag;

export const IconCheck: IconType = Check;
export const IconCheckSuccess: IconType = CheckSuccess;
export const IconClose: IconType = Close;
export const IconCloseFailure: IconType = CloseFailure;
export const IconEdit: IconType = Edit;
export const IconArrowBottom: IconType = ArrowBottom;
export const IconArrowLeft: IconType = ArrowLeft;
export const IconArrowTop: IconType = ArrowTop;
export const IconArrowRight: IconType = ArrowRight;
export const IconDelete: IconType = Delete;
export const IconDownload: IconType = ArrowCircle;
export const IconDrag: IconType = Drag;
export const IconExport: IconType = Export;
export const IconXlsExport: IconType = XlsExport;
export const IconXlsImport: IconType = XlsImport;
export const IconView: IconType = View;
export const IconFilterSecond: IconType = FilterSecond;
export const IconFilterFourth: IconType = FilterFourth;
export const IconFilterSixth: IconType = FilterSixth;
export const IconChart: IconType = Chart;
export const IconGear: IconType = Gear
export const IconPdfExport: IconType = PdfExport;
export const IconPdfExportHover: IconType = PdfExportHover;
export const IconPptExport: IconType = PptExport;
export const IconPptExportHover: IconType = PptExportHover;
export const IconXls2Export: IconType = Xls2Export;
export const IconXls2ExportHover: IconType = Xls2ExportHover;
export const IconLock: IconType = Lock;
export const IconLogo: IconType = Logo;
export const IconMenuTrends: IconType = MenuTrends;
export const IconMenuTrendsLight: IconType = MenuTrendsLight;
export const IconPDFStroke: IconType = PDFStroke;
export const IconPPTStroke: IconType = PPTStroke;
export const IconAdd: IconType = Add;
export const IconSearch: IconType = Search;
export const IconSortASC: IconType = SortASC;
export const IconSortDESC: IconType = SortDESC;
export const IconHaiku: IconType = Haiku;
export const IconCopySimple: IconType = CopySimple;
export const IconFileSearch: IconType = FileSearch;
export const IconFiltersFunnel: IconType = FiltersFunnel;
export const IconCaretDoubleDown: IconType = CaretDoubleDown;
export const IconLogoLight: IconType = LogoLight;
export const IconLogoDark: IconType = LogoDark;
export const IconPpt: IconType = Ppt;
export const IconXls: IconType = Xls;
export const IconInfo: IconType = Info;
