import { FCX, ID } from "@datassential/platform-ui-lib";
import cn from "classnames";
import CheckboxInput from "@components/CheckboxInput/CheckboxInput";
import Tag from "@components/Tag/Tag";
import React, { useMemo } from "react";
import { iOption } from "@models";
import { toggleInArray } from "@core/old_helpers";

import './MultiSelectButtons.scss';
import { IconArrowRight } from "@icons";

const MultiSelectButtons: FCX<{
    isExpanded: boolean;
    toggleIsExpanded: () => void;
    items: iOption[];
    title: string;
    value: ID[];
    setValue: (value: ID[]) => void;
}> = ({
    isExpanded,
    toggleIsExpanded,
    items,
    title,
    value,
    setValue,
    className,
    style,
    testId,
}) => {
    const counterText = useMemo(
        () => {
            if (items.every((i) => value.includes(i.id)) && items.length > 0) {
                return "All selected";
            } else if (items.some((i) => value.includes(i.id))) {
                return `${items.filter(i => value.includes(i.id)).length} selected`;
            } else {
                return "";
            }
        },
        [value, items]
    )

    return (
        <div
            className={cn(
                "MultiSelectButtons",
                className,
                isExpanded && "is-expanded"
            )}
            data-testid={testId}
            style={style}
        >
            <div className="MultiSelectButtons__header">
                <CheckboxInput
                    onChange={toggleIsExpanded}
                    labelText={(
                        <>
                            <div data-testid={"textMultiSelectButtonsTitle"}>{title}</div>
                            <IconArrowRight className="MultiSelectButtons__dropdown-icon"/>
                        </>
                    )}
                    isChecked
                    noIcon
                    isBold
                />
                <div
                    className="MultiSelectButtons__counter"
                    data-testid={"textMultiSelectButtonsCounter"}
                >
                    {counterText}
                </div>
            </div>
            {isExpanded && (
                <div className="MultiSelectButtons__content">
                    {items.map((item, itemIndex) => (
                        <Tag
                            key={item.id}
                            className="MultiSelectButtons__tag"
                            testId={`buttonMultiSelectButtons-${itemIndex}`}
                            isActive={value.includes(item.id)}
                            isRemoveBtnHidden
                            onClick={() => setValue(toggleInArray(value, item.id))}
                        >
                            {item.title}
                        </Tag>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MultiSelectButtons;