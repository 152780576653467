import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Loader from "@components/Loader/Loader";
import NoMatches from "@components/NoMatches/NoMatches";
import SortableTableList from "./List/SortableTableList";
import "./SortableTable.styles.scss";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

// TODO: add disableAxis XY
function SortableTable({ isLoading, data, Header, Row, virtualMode = false, handleUpdateRowsOrder, disableDragging }) {
    const [items, setItems] = useState(data);
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        setItems(data);
    }, [data]);

    function onDragEnd(result) {
        setIsDragging(false);

        if (!result.destination) {
            return;
        }
        if (result.source.index === result.destination.index) {
            return;
        }

        const newItems = reorder(items, result.source.index, result.destination.index);
        setItems(newItems);
        handleUpdateRowsOrder(newItems.map(({ id }) => id));
    }

    function onDragStart() {
        setIsDragging(true);
    }

    return (
        <div className={`AdminTable ${isDragging ? "has-dragging-row" : ""}`}>
            <div className="AdminTable__header">
                <div className="AdminTable__row AdminTable__row--header">
                    <Header />
                </div>
            </div>

            <div className="AdminTable__body AdminTable__body--small">
                {items.length > 0 ? (
                    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                        <SortableTableList data={items} ID="droppable" Row={Row} disableDragging={disableDragging} virtualMode={virtualMode} />
                    </DragDropContext>
                ) : (
                    <NoMatches />
                )}

                <Loader isShown={isLoading} />
            </div>
        </div>
    );
}

export default SortableTable;
