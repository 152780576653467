import { ApiModel, iSearchResults, SearchResults } from "@models/SearchResults";
import http, { AbortablePromise } from "@core/http";
import apiConfig from "@apiConfig";
import { useDebouncedData } from "@hooks/useDebouncedData";
import { useData } from "@hooks/useData";

let request: AbortablePromise;

async function getSearchResults(countryCode: string, searchQuery: string): Promise<iSearchResults> {
    if (request && request.abort) {
        request.abort();
    }

    request = http.get(
        apiConfig.get('global search', {
            searchText: searchQuery,
            countryCode,
        })
    );
    const response: ApiModel = await request;
    return new SearchResults({ apiModel: response })
}

export function useSearchResults(countryCode: string, searchQuery: string) {
    const fetchFn: (countryCode: string, searchQuery: string) => Promise<iSearchResults> = searchQuery.trim()
        ? getSearchResults
        : () => new Promise(r => r(new SearchResults()));
    return useData( new SearchResults(), fetchFn, countryCode, searchQuery);
}