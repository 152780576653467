import { FC } from 'react'
import { getDate } from "@core/old_helpers"
import { iFoodProfileLTO } from '@models/FoodProfileLTO';
import './RestaurantActivity.scss';
import NewTooltip from '@components/NewTooltip/NewTooltip';

const RestaurantActivity: FC<{ data: iFoodProfileLTO }> = ({ data }) => {
    const { name, date, restaurant, description } = data;

    return (
        <div className='restaurantActivity'>
            <div className='restaurantActivity__header'>
                <div className="restaurantActivity__header-left">
                    <span className='restaurantActivity__title'>
                        {name}
                        <NewTooltip
                            position={['left']}
                            className="restaurantActivity__tooltip"
                        >
                            {name}
                        </NewTooltip>
                    </span>
                </div>
                <div className="restaurantActivity__header-right">
                    <span className='restaurantActivity__restaurant'>
                        {restaurant}
                    </span>
                    <span className='restaurantActivity__date'>
                        {getDate(date)}
                    </span>
                </div>
            </div>
            <div className='restaurantActivity__descr'>
                {description}
            </div>
        </div>
    )
};

export default RestaurantActivity;