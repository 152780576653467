import React from 'react';
import SubTitle from "@components/SubTitle/SubTitle";
import Select from "@components/Select/Select";

function SettingsTopTrends({ handleChange, data, dataConfig, selectsData }) {
    const { fields } = dataConfig;

    return (
        <div className="settings-section settings-section--top-trends">
            <div className="settings-section__top">
                <SubTitle textColor="yellow">{dataConfig.name}</SubTitle>
                <span className='settings-section__hint'>*Please select at least one category</span>
            </div>
            <div className="settings-section__inner">
                {fields.map(({name, field}) => {
                    return (
                        <Select
                            key={field}
                            options={selectsData[field]}
                            value={data[field]}
                            title={name}
                            onChange={(value) => handleChange(field, value)}
                        />
                    )
                })}
            </div>
        </div>
    );
}
export default SettingsTopTrends;