import React from "react";
import PT from "prop-types";
import "./ToggleInput.scss";
import TooltipTabs from "../TooltipTabs/TooltipTabs";

const ToggleInput = ({
    onToggle,
    labelText = "",
    isChecked,
    className='',
    disabled = false,
    tooltipLabel = '',
    tooltipShownInx = -1,
    tooltipInx = -1,
    setTooltipShownInx = () => null,
    isVisible = true,
    testId,
}) => {
    
    const classList = new Set([
        'toggle-input',
        ...className.split(' '),
        isChecked ? 'toggle-input--checked' : '',
        disabled ? 'toggle-input--disabled' : '',
    ]);

    return isVisible ? (
        <div
            className={Array.from(classList).join(' ')}
            onMouseOver={() => tooltipLabel && setTooltipShownInx(tooltipInx)}
            onMouseOut={() => tooltipLabel && setTooltipShownInx(null)}
            data-testid={testId}
        >
            {labelText.length > 0 && (
                <div
                    className="toggle-input__label"
                    data-testid="textToggleInputLabel"
                >
                    {labelText}
                </div>
            )}
            <button onClick={onToggle} className="toggle-input__btn">
                <div className="toggle-input__btn-inner" />
            </button>
            <TooltipTabs
                isShown={tooltipLabel && tooltipShownInx === tooltipInx}
                testId="textToggleInputTooltip"
            >
                <span>{tooltipLabel}</span>
            </TooltipTabs>
        </div>
    ) : null;
};

ToggleInput.propTypes = {
    labelText: PT.string,
    isChecked: PT.bool,
    onToggle: PT.func,
    className: PT.string,
    testId: PT.string,
};

export default ToggleInput;
