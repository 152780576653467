import CellNew from "@components/CellNew/CellNew";
import { iVenueValues } from "@models/FoodProfile";
import { FC } from "react";

const FoodProfileTableHead: FC<{
    data: {
        title: string;
        data: iVenueValues[];
    }[];
}> = ({ data }) => {
    return (
        <div className="FoodProfileTable__head">
            <div className="FoodProfileTable__cell FoodProfileTable__cell--group FoodProfileTable__cell--sticky">
                <CellNew
                    className="FoodProfileTable__cell is-larger"
                    modifiers={["bg-head"]}
                />
                <CellNew className="FoodProfileTable__cell" modifiers={["bg-head"]}>
                    Total
                </CellNew>
            </div>
            <div className="FoodProfileTable__cell FoodProfileTable__cell--group">
                {data.map(({ title, data }, index) => {
                    return (
                        <div className="FoodProfileTable__cell" key={index}>
                            <CellNew
                                modifiers={[
                                    "half",
                                    index % 2 === 0 ? "bg-darken" : "bg-lighter",
                                    "center",
                                ]}
                            >
                                {title}
                            </CellNew>
                            <div className="FoodProfileTable__columns">
                                {data.map(({ name }, idx) => {
                                    return (
                                        <CellNew
                                            key={idx}
                                            className="FoodProfileTable__cell"
                                            modifiers={[
                                                index % 2 === 0 ? "bg-head" : "bg-darken",
                                            ]}
                                        >
                                            {name}
                                        </CellNew>
                                    )
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default FoodProfileTableHead;