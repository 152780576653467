import React from "react";
import {capitalize} from '@core/old_helpers';
import ScrollBar from 'react-scrollbars-custom';
import './KeywordsList.scss';

const KeywordsList = ({removeKeyword, withoutScrollBar, height, keywords = [], className}) => {

    return (
        <div
            style={{
                height: height,
            }}
            className={`KeywordsList ${className}`}>
            <ScrollBar native={withoutScrollBar}>
                {keywords.map((keyword, idx) => {
                    return (
                        <div
                            key={keyword}
                            className='KeywordsList__item'>
                            {keyword.toUpperCase()}
                            <button
                                onClick={() => removeKeyword(keyword)}
                                className='KeywordsList__remove-keyword'/>
                        </div>
                    )
                })}
            </ScrollBar>

        </div>
    )
};

export default KeywordsList
