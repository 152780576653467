import { createSelector } from "reselect";
import { filtersForBackEndSelector } from "./filters.selectors";
import { getFormattedThousand } from "@core/old_helpers"
import { PermissionLevelEnum } from "@models/PermissionSection";
const dataSelector = state => state.topTrends;
export const hasFlavorPermissionSelector = state => state.topTrends.flavorPermissionLevel !== PermissionLevelEnum.None;

export const TAKE = 20;


export const tableDataSelector = createSelector(
    dataSelector,
    (data) => {
        return data.data.map((item) => {

            const rowData = [
                {
                    content: item.word.name.toUpperCase(),
                    keyword: item.word,
                }
            ];
            try {
                rowData.push(`${item.penetration.toFixed(1)}%`);
            } catch (e) {
                rowData.push(item.penetration);
            }
            try {
                rowData.push(`${item.incidence.toFixed(1)}%`);
            } catch (e) {
                rowData.push(item.incidence);
            }
            try {
                rowData.push(getFormattedThousand(item.restaurantCount));
            } catch (e) {
                rowData.push(getFormattedThousand(item.restaurantCount));
            }
            try {
                rowData.push(`${item.growth >= 0 ? '+' : ''}${item.growth.toFixed(1)}%`);
            } catch (e) {
                rowData.push(item.growth);
            }
            try {
                rowData.push(`${item.cagr >= 0 ? '+' : ''}${item.cagr.toFixed(1)}%`);
            } catch (e) {
                rowData.push(item.cagr);
            }
            try {
                rowData.push(`${Math.round(item.flavorData.knowIt)}%`);
            } catch (e) {
                rowData.push('-');
            }
            try {
                rowData.push(`${Math.round(item.flavorData.haveTriedIt)}%`);
            } catch (e) {
                rowData.push('-');
            }
            try {
                rowData.push(`${Math.round(item.flavorData.loveOrLikeIt)}%`);
            } catch (e) {
                rowData.push('-');
            }

            return rowData;
        })
        // }).slice(0, 4)
    }
);

export const categoriesForRenderSelector = createSelector(
    dataSelector,
    (data) => {
        const {categories, selectedCategories} = data;
        return categories.map(category => ({
            ...category,
            isSelected: selectedCategories.includes(category.id),
        }))
    }
);

export const requestDataSelector = state => {
    const filters = filtersForBackEndSelector(state);
    const {
        categories,
        selectedCategories,
        searchQuery,
        sortingDirection,
        sortingColumn,
        drillWords,
        includeLowPenetrationItems,
        includeLessTenMenusCount,
        showOnlyNewItems,
        growthPeriod,
        data,
    } = state.topTrends;
    const topTrendCategories = categories
        .filter(({id}) => selectedCategories.includes(id));
    const requestData = {
        mainFilter: {
            filters,
            topTrendCategories: topTrendCategories.length === 0 ? categories : topTrendCategories,
            includeLessOnePercentPenetration: includeLowPenetrationItems,
            includeLessTenMenusCount,
            isNew: showOnlyNewItems,
            yearsGrowth: growthPeriod,
        },
        tableParameters: {
            sortColumn: sortingColumn,
            sortDir: sortingDirection === 'asc' ? 0 : 1,
            search: searchQuery,
            take: TAKE,
            skip: data.length,
        },
    };
    if (drillWords.length) {
        requestData.mainFilter.drillWords = drillWords;
    }

    return requestData;
};
