import { TAGS } from '../core/actionNames';
const { SET_DATA, SET_FIELD, RESET_DATA } = TAGS;

const defaultState = {
    items: [],
    categories: [],
};

export default function tags(state = defaultState, action) {
    switch (action.type) {
        case SET_FIELD: {
            return {
                ...state,
                [action.name]: action.value,
            };
        }
        case SET_DATA: {
            return {
                ...state,
                ...action.data,
            };
        }
        case RESET_DATA: {
            return { ...defaultState };
        }
        default: {
            return { ...state };
        }
    }
}