import { GlobalContext } from "@components/App/App.context";
import { ThumbHorizontal, TrackHorizontal } from "@components/ScrollbarRenders/ScrollbarRenders";
import { getRoundValue, isSignBeforeNumber } from "@core/old_helpers";
import { iPenetration, iVenueValues, VenueTypesEnum } from "@models/FoodProfile";
import { FC, useContext, useMemo } from "react";
import Scrollbars from "react-custom-scrollbars";
import "./FoodProfileTable.styles.scss";
import FoodProfileTableBody, { iTableDataItem } from "./FoodProfileTableBody";
import FoodProfileTableHead from "./FoodProfileTableHead";


const FoodProfileTable: FC<{
    data: iVenueValues[];
    fourYearsGrowth: string;
    yearsPenetrations: iPenetration[];
    years: string[];
}> = ({
    data,
    fourYearsGrowth,
    yearsPenetrations,
    years,
}) => {
    const { getRem } = useContext(GlobalContext);

    const tableData: iTableData = useMemo(() => {
        if ( data.length === 0 ) {
            return {
                head: [],
                body: [],
            }
        }

        const getFourYearsGrowthValue = (fourYearsGrowth: string): string => {
            return Number.isNaN(+fourYearsGrowth) ? fourYearsGrowth : `${isSignBeforeNumber(+fourYearsGrowth)}%`
        };

        const rows: iTableDataItem[] = years.map(year => {
            const matchPenetration = yearsPenetrations.find(({ name }) => name === year);
            const totalValue = matchPenetration ? matchPenetration.value : "";
            const hasValue = !Number.isNaN(+totalValue);

            return {
                total: {
                    name: year.replace(/-/g, " "),
                    value: hasValue ? getRoundValue(+totalValue) : totalValue,
                },
                values: data.map((col) => {
                    const yearData = col.values.find(({ name }) => name === year);
                    const hasValue = yearData && !Number.isNaN(+yearData.value);

                    return hasValue ? getRoundValue(+yearData.value) : "-"
                })
            }
        });

        return {
            head: [
                {
                    title: "Restaurant segments",
                    data: data.filter(item => item.sliceType === VenueTypesEnum.RestaurantSegments),
                },
                {
                    title: "Restaurant types",
                    data: data.filter(item => item.sliceType === VenueTypesEnum.RestaurantTypes),
                },
                {
                    title: "On-Site",
                    data: data.filter(item => item.sliceType === VenueTypesEnum.OnSite),
                },
            ],
            body: [
                {
                    total: {
                        name: 'Past 4-Year Growth',
                        value: getFourYearsGrowthValue(fourYearsGrowth),
                    },
                    values: data.map(({fourYearsGrowth}) => getFourYearsGrowthValue(fourYearsGrowth)),
                },
                ...rows,
            ],
        }
    }, [data, fourYearsGrowth, years, yearsPenetrations]);

    return (
        <div className="FoodProfileTable">
            <Scrollbars
                autoHeight
                autoHeightMin={200}
                autoHeightMax={900}
                autoHide={false}
                hideTracksWhenNotNeeded
                renderThumbHorizontal={(props) => ThumbHorizontal({ ...props })}
                renderTrackHorizontal={(props) =>
                    TrackHorizontal({
                        ...props,
                        style: {
                            ...props.style,
                            width: `calc(100% - ${getRem(260)}px)`,
                            marginLeft: getRem(260),
                            left: 0,
                            right: 0,
                            bottom: 0,
                            borderRadius: 0,
                        },
                    })
                }
            >
                <FoodProfileTableHead data={tableData.head} />
                <FoodProfileTableBody data={tableData.body} />
            </Scrollbars>
        </div>
    );
};

interface iTableData {
    head: {
        title: string;
        data: iVenueValues[];
    }[];
    body: iTableDataItem[];
}

export default FoodProfileTable;
