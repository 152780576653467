import { createSelector } from "reselect";
import { deepCopy, dynamicSort, getStringMatchRank, StringMatchRanks } from "@core/old_helpers";
import { countriesSelector } from "./countries.selector";
import { highlightMatches } from "@core/helpers";

export const reportsSelector = state => deepCopy(state.adminReports);
export const dataSelector = state => deepCopy(state.adminReports.data);
export const searchQuerySelector = state => state.adminReports.searchQuery;
export const selectedCountryIdsSelector = state => state.adminReports.selectedCountryIds;
export const sortingColumnTypeSelector = state => state.adminReports.sortingColumnType;
export const sortingDirectionSelector = state => state.adminReports.sortingDirection;

export const filteredDataSelector = createSelector(
    dataSelector,
    searchQuerySelector,
    sortingColumnTypeSelector,
    sortingDirectionSelector,
    (data, searchQuery, sortingColumnType, sortingDirection) => {
        if (!data) return [];

        let filteredData = data;
        const trimmedSearchQuery = searchQuery.trim();

        if (trimmedSearchQuery !== "") {
            filteredData = filteredData.filter(
                keyword => getStringMatchRank(keyword.name, trimmedSearchQuery) >= StringMatchRanks.Includes,
            );

            filteredData.sort((a, b) => {
                const aRank = getStringMatchRank(a.name, trimmedSearchQuery);
                const bRank = getStringMatchRank(b.name, trimmedSearchQuery);

                return aRank < bRank ? 1 : aRank > bRank ? -1 : 0;
            });
        } else {
            filteredData.sort(dynamicSort(sortingColumnType, sortingDirection));
        }

        return filteredData;
    },
);

export const getCountriesToOptions = createSelector(countriesSelector, countries => {
    return countries.map(item => {
        let { component } = countries.find(contry => contry.countryCode === item.countryCode);
        return {
            id: item.countryCode,
            name: item.countryCode,
            icon: component,
        };
    });
});

export const tableDataSelector = createSelector(
    filteredDataSelector,
    searchQuerySelector,
    countriesSelector,
    selectedCountryIdsSelector,
    (data, searchQuery, countries, selectedCountryIds) => {
        let result = [];

        data.forEach(({ countryCode, id, name, isActive, childReportsCount }) => {
            const currentCountry = countries.find(item => item.countryCode === countryCode);

            const renderName = highlightMatches(name, searchQuery);
            if (currentCountry && selectedCountryIds.includes(currentCountry.countryCode)) {
                result.push({
                    id,
                    name,
                    renderName,
                    isActive,
                    countryName: currentCountry.name,
                    flag: currentCountry.component,
                    countryCode,
                    childReportsCount,
                });
            }
        });

        return result;
    },
);
