import React from "react";
import { connect } from "react-redux";
import {
    tableDataSelector
} from "@selectors/adminReports.selectors";
import AdminTable from "@pages/Admin/Common/AdminTable";
import AdminReportsTableHead from "./AdminReportsTableHead";
import AdminReportsTableRow from "./AdminReportsTableRow";


function AdminReportsTable({
    tableData,
    isLoading,
}) {
    const TableHeader = <AdminReportsTableHead />;

    const Row = ({ index, style }) => {
        const rowData = tableData[index];

        return (
            <div style={style} className="AdminTable__row">
                <AdminReportsTableRow data={rowData} index={index} />
            </div>
        );
    };

    return (
        <AdminTable
            header={TableHeader}
            length={tableData.length}
            row={Row}
            isLoading={isLoading}
            disableRowTransition
        />
    );
}
const mapStateToProps = state => {
    return {
        tableData: tableDataSelector(state)
    };
};

export default connect(mapStateToProps, null)(AdminReportsTable);
