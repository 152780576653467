import React from 'react';
import './ColorModeRadioBtn.styles.scss';

const ColorModeRadioBtn = ({ isThemeLightEnable, setTheme }) => {

    const classList = new Set([
        "ColorModeRadiobtn",
        isThemeLightEnable ? "ColorModeRadiobtn--light" : "ColorModeRadiobtn--dark",
    ]);

    return (
        <label htmlFor="ColorModeRadiobtn" className={`header-color-mode-btn ${isThemeLightEnable ? "is-light" : "is-dark"}`}>
            <div className="header-color-mode-btn__label">
                Lights {isThemeLightEnable ? 'on' : 'off'}
            </div>
            <span className={Array.from(classList).join(" ")}>
                <input type="checkbox" id="ColorModeRadiobtn" className="ColorModeRadiobtn__input" checked={isThemeLightEnable} onChange={() => setTheme(!isThemeLightEnable)} />
                <span className="ColorModeRadiobtn__icon"></span>
            </span>
        </label>
    );
};

export default ColorModeRadioBtn;