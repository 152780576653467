import { createSelector } from 'reselect';
import { deepCopy, getStringMatchRank, StringMatchRanks } from "@core/old_helpers";
import { RowTypes } from "../pages/Admin/Keywords/AdminKeywordsTable";
import { countriesSelector } from './countries.selector';
import { escapeRegExp } from "@core/helpers";

const dataSelector = state => deepCopy(state.adminKeywords.data);
const expandedKeywordsIdsSelector = state => state.adminKeywords.expandedKeywordsIds;
export const searchQuerySelector = state => state.adminKeywords.searchQuery;



export const filteredDataSelector = createSelector(
    dataSelector,
    searchQuerySelector,
    (data, searchQuery) => {
        let filteredData = data;

        const trimmedSearchQuery = searchQuery.trim();

        if (trimmedSearchQuery !== '') {
            filteredData = filteredData.filter(
                keyword => getStringMatchRank(keyword.name, trimmedSearchQuery) >= StringMatchRanks.Includes
            );

            filteredData.sort((a, b) => {
                const aRank = getStringMatchRank(a.name, trimmedSearchQuery);
                const bRank = getStringMatchRank(b.name, trimmedSearchQuery);

                return aRank < bRank ? 1 : aRank > bRank ? -1 : 0;
            });
        }

        return filteredData;
    }
);
export const tableDataSelector = createSelector(
    filteredDataSelector,
    expandedKeywordsIdsSelector,
    searchQuerySelector,
    countriesSelector,
    countriesSelector,
    (data, expandedKeywordsIds, searchQuery, countries) => {
        const result = [];
        const searchRegExp = new RegExp(`(${escapeRegExp(searchQuery.trim())})`, 'gi');

        data.forEach((keyword) => {
            const isKeywordExpanded = expandedKeywordsIds.includes(keyword.id);
            const renderName = searchQuery.trim() === ''
                ? keyword.name
                : keyword.name.replace(searchRegExp, '<b>$1</b>');
            result.push({
                ...keyword,
                isExpanded: isKeywordExpanded,
                rowType: RowTypes.Keyword,
                renderName,
                properties: keyword.keywordOptions && keyword.keywordOptions.length > 0 && [
                    keyword.keywordOptions[0]?.isTag,
                    keyword.keywordOptions[0]?.isTopTrends,
                    keyword.keywordOptions[0]?.isFoodProfile,
                    keyword.keywordOptions[0]?.isFlavor,
                    keyword.keywordOptions[0]?.isLocal,
                    keyword.keywordOptions[0]?.isInsider,
                ],
            });
            if (isKeywordExpanded) {
                keyword.keywordOptions.forEach(option => {
                    result.push({
                        rowType: RowTypes.Option,
                        id: `option-${option.id}`,
                        properties: [
                            option.isTag,
                            option.isTopTrends,
                            option.isFoodProfile,
                            option.isFlavor,
                            option.isLocal,
                            option.isInsider,
                        ],
                        countryCodes: countries.filter(country => option.countryCodes.includes(country.countryCode)),
                    })
                });
            }
        });

        return result;
    }
);