import React, { memo, useContext, useState } from "react";
import ConfirmationPopup from "@components/ConfirmationPopup/ConfirmationPopup";
import SortableTable from "@pages/Admin/Common/SortableTable/SortableTable";
import { Actions, ReportContext } from "./ReportOptions.reducer";
import AdminReportTableHead from "./ReportTableHead";
import AdminReportTableRow from "./ReportTableRow";

const Head = props => {
    const { optionsData, dispatchOptionsAction } = useContext(ReportContext);
    const { selectedChildReportsCategories, selectedChildReportsSubCategories, childReportsCategories } = optionsData;

    const handleChangeCategories = (type, value) => {
        const selectedChilds = data => {
            return data.filter(item => value.includes(item.id));
        };

        const setSelectedItems = (name, data) => {
            dispatchOptionsAction({
                type: Actions.ADD,
                data: {
                    [name]: selectedChilds(data),
                },
            });
        };

        switch (type) {
            case "category":
                setSelectedItems("selectedChildReportsCategories", childReportsCategories.categories);
                break;
            case "subcategory":
                setSelectedItems("selectedChildReportsSubCategories", childReportsCategories.subCategories);
                break;
            default:
                break;
        }
    };

    const selectedItems = data => {
        return data.map(({ id }) => id);
    };

    return (
        <AdminReportTableHead
            {...props}
            handleChangeCategories={handleChangeCategories}
            selectedCategories={selectedItems(selectedChildReportsCategories)}
            selectedSubCategories={selectedItems(selectedChildReportsSubCategories)}
            categoriesToOptions={childReportsCategories.categories}
            subCategoriesToOptions={childReportsCategories.subCategories}
        />
    );
};

const filterChildReports = ({ childReports, selectedChildReportsCategories, selectedChildReportsSubCategories }) => {
    return childReports.filter(item => {
        return (
            selectedChildReportsCategories.some(i => i.name === item.category) &&
            selectedChildReportsSubCategories.some(i => i.name === item.subCategory)
        );
    });
};


function ReportTable(props) {
    const { optionsData, dispatchOptionsAction } = useContext(ReportContext);
    const [modalTitle, setModalTitle] = useState("");
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [removeChildId, setRemoveChildId] = useState(null);

    const handleUpdateRowsOrder = ids => {
        dispatchOptionsAction({
            type: Actions.UPDATE_ROWS,
            data: ids,
        });
    };

    const handleRemoveChildReport = () => {
        dispatchOptionsAction({
            type: Actions.REMOVE_CHILD_REPORT,
            id: removeChildId,
        });
    };

    const openConfirmPopup = (id, name) => {
        setRemoveChildId(id);
        setModalTitle(`Delete ${name} instant chart`);
        setShowConfirmPopup(true);
    };

    const closeConfirmPopup = () => {
        setRemoveChildId(null);
        setModalTitle("");
        setShowConfirmPopup(false);
    };

    const disableDragging = optionsData.childReports.length !== filterChildReports(optionsData).length;

    const Row = props => {
        return <AdminReportTableRow {...props} openConfirmPopup={openConfirmPopup} />;
    };

    return (
        <div className="ReportTable">
            <SortableTable
                Header={Head}
                data={filterChildReports(optionsData)}
                Row={Row}
                handleUpdateRowsOrder={handleUpdateRowsOrder}
                disableDragging={disableDragging}
            />
            <ConfirmationPopup
                shown={showConfirmPopup}
                text="Are you sure you want to delete this instant chart from this Report?"
                title={modalTitle}
                submitButtonText="Delete"
                onConfirm={handleRemoveChildReport}
                closeModal={closeConfirmPopup}
            />
        </div>
    );
}

export default memo(ReportTable);
