import Icon from "@components/Icon/Icon";
import { IconDelete } from "@icons";
import React, { useState } from "react";
import HightlightChars from "@pages/Admin/Common/HightlightChars/HightlightChars";
import CheckboxInput from "@components/CheckboxInput/CheckboxInput";
import Cell from "@components/NewAwesomeTable/Cell";

const Row = (
    {
        data,
        allowSelectRows,
        handleSelectRestaurant,
        totalRegions,
        openRemoveConfirmPopup,
        isViewMode,
    }
) => {
    const { id, name, renderName, segment, unitsCount, cuisine, regions, isSelected } = data;
    const [isSelectedRow, setIsSelectedRow] = useState(isSelected);

    const toggleSelected = id => {
        handleSelectRestaurant(id, !isSelectedRow);
        setIsSelectedRow(!isSelectedRow);
    };

    const renderRegions = regions.length === totalRegions ? "All regions" : regions.map(({name}) => name).join(", ");

    return (
        <>
            {allowSelectRows && (
                <Cell className="AdminTable__col AdminTable__col--small AdminTable__col--no-p AdminTable__col--justify">
                    <CheckboxInput isBlueCheck isChecked={isSelectedRow} onChange={() => toggleSelected(id)} />
                </Cell>
            )}

            <Cell className="AdminTable__col AdminTable__col--fluid">
                <span className="AdminTable__truncated" title={name}>
                    <HightlightChars name={renderName || name} />
                </span>
            </Cell>

            <Cell className="AdminTable__col AdminTable__col--large AdminTable__col--no-pr">{segment}</Cell>
            <Cell className="AdminTable__col AdminTable__col--regular AdminTable__col--no-pr">{unitsCount}</Cell>
            <Cell className="AdminTable__col AdminTable__col--large">{cuisine}</Cell>
            <Cell className="AdminTable__col AdminTable__col--large">{renderRegions}</Cell>

            {!allowSelectRows && !isViewMode && (
                <Cell className="AdminTable__col AdminTable__col--small AdminTable__col--no-p AdminTable__col--justify">
                    <button className="AdminTable__btn" onClick={() => openRemoveConfirmPopup({id, name})}>
                        <Icon type="small">
                            <IconDelete />
                        </Icon>
                    </button>
                </Cell>
            )}
        </>
    );
};

export default Row;
