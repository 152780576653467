import { COUNTRIES } from '../core/actionNames';

import {
    Australia,
    AustraliaDisabled,
    Bangladesh,
    BangladeshDisabled,
    CAN,
    CANDisabled,
    France,
    FranceDisabled,
    Germany,
    GermanyDisabled,
    HongKong,
    HongKongDisabled,
    India,
    IndiaDisabled,
    Ireland,
    IrelandDisabled,
    IconLogo,
    Malaysia,
    MalaysiaDisabled,
    Pakistan,
    PakistanDisabled,
    Philippines,
    PhilippinesDisabled,
    Singapore,
    SingaporeDisabled,
    Thailand,
    ThailandDisabled,
    UnitedArabianEmirates,
    UnitedArabianEmiratesDisabled,
    UnitedKingdom,
    UnitedKingdomDisabled,
    USA,
    USADisabled,
} from "@icons";

export const AVAILABLE_COUNTRIES = ['USA', 'CAN'];

const EMPTY_COUNTRY = {
    name: "Null",
    countryCode: "Null",
    component: IconLogo,
    componentDisabled: IconLogo,
    currency: 'Null',
    currencyCharComponent: 'Null',
};

export const COUNTRIES_ARRAY = [
    {
        name: "USA",
        countryCode: "USA",
        component: USA,
        componentDisabled: USADisabled,
        currency: '$',
        hasFiltersAll: true,
        hasFilterDescriptions: true,
        hasLongTermTrend: true,
        hasNewItemsOnly: true,
        hasReportData: true,
        hasChart: true,
        hasGlobalSearchAllMenu: true,
        currencyCharComponent: '$',
    },
    {
        name: "Canada",
        countryCode: "CAN",
        component: CAN,
        componentDisabled: CANDisabled,
        currency: 'CAD',
        hasFiltersAll: true,
        hasLongTermTrend: true,
        hasNewItemsOnly: true,
        hasReportData: true,
        hasChart: true,
        hasGlobalSearchAllMenu: true,
        currencyCharComponent: '$',
    },
    {
        name: "UK",
        countryCode: "UK",
        component: UnitedKingdom,
        componentDisabled: UnitedKingdomDisabled,
        currency: 'GBP',
        currencyCharComponent: '£',

    },
    {
        name: "Bangladesh",
        countryCode: "BGD",
        component: Bangladesh,
        componentDisabled: BangladeshDisabled,
        currency: 'BDT',
        currencyCharComponent: '৳',

    },
    {
        name: "UAE",
        countryCode: "UAE",
        component: UnitedArabianEmirates,
        componentDisabled: UnitedArabianEmiratesDisabled,
        currency: 'AED',
        currencyCharComponent: 'د.إ',

    },
    {
        name: "Australia",
        countryCode: "AUS",
        component: Australia,
        componentDisabled: AustraliaDisabled,
        currency: 'AUD',
        currencyCharComponent: '$',

    },
    {
        name: "Hong Kong",
        countryCode: "HKG",
        component: HongKong,
        componentDisabled: HongKongDisabled,
        currency: 'HKD',
        currencyCharComponent: 'HK$',

    },
    {
        name: "India",
        countryCode: "IND",
        component: India,
        componentDisabled: IndiaDisabled,
        currency: 'INR',
        currencyCharComponent: '₹',

    },
    {
        name: "Ireland",
        countryCode: "IRL",
        component: Ireland,
        componentDisabled: IrelandDisabled,
        currency: 'EUR',
        currencyCharComponent: '€',

    },
    {
        name: "Malaysia",
        countryCode: "MYS",
        component: Malaysia,
        componentDisabled: MalaysiaDisabled,
        currency: 'MYR',
        currencyCharComponent: 'RM',

    },
    {
        name: "Pakistan",
        countryCode: "PAK",
        component: Pakistan,
        componentDisabled: PakistanDisabled,
        currency: 'PKR',
        currencyCharComponent: 'Rs',

    },
    {
        name: "Philippines",
        countryCode: "PHL",
        component: Philippines,
        componentDisabled: PhilippinesDisabled,
        currency: 'PHP',
        currencyCharComponent: '₱',

    },
    {
        name: "Thailand",
        countryCode: "THA",
        component: Thailand,
        componentDisabled: ThailandDisabled,
        currency: 'THB',
        currencyCharComponent: '฿',

    },
    {
        name: "Singapore",
        countryCode: "SGP",
        component: Singapore,
        componentDisabled: SingaporeDisabled,
        currency: 'SGD',
        currencyCharComponent: 'S$',

    },
    {
        name: "France",
        countryCode: "FRA",
        component: France,
        componentDisabled: FranceDisabled,
        currency: 'EUR',
        currencyCharComponent: '€',
    },
    {
        name: "Germany",
        countryCode: "GER",
        component: Germany,
        componentDisabled: GermanyDisabled,
        currency: 'EUR',
        currencyCharComponent: '€',
    },
];


// export const COUNTRIES_HAS_FULL_DATA = ['USA', 'CAN'];

const { SET_DATA, SET_CURRENT, SET_FIELD } = COUNTRIES;

const defaultState = {
    current: null,
    data: [],
    requestedCountriesId: [],
    isLoading: true,
};

export default function countries(state = defaultState, action) {
    switch (action.type) {
        case SET_DATA: {
            return {
                ...state,
                isLoading: false,
                data: action.data.map(({ settings, hasTrialAccess, hasAccess, ...country }) => {
                    const localData = COUNTRIES_ARRAY.find(c => country.countryCode === c.countryCode);
                    const permissions = {
                        hasFoodProfile: settings.enableFoodProfile,
                        hasRtuReport: settings.enableRtuReport,
                        hasInsertChart: settings.enableInteractiveList,
                        hasTrialAccess,
                        hasAccess: hasAccess || hasTrialAccess,
                    };
                    return {
                        ...EMPTY_COUNTRY,
                        ...localData,
                        ...country,
                        ...permissions
                    };
                })
                    .sort(country => country.countryCode === 'USA' ? -1 : 1),
            };
        }
        case SET_CURRENT: {
            sessionStorage.setItem('currentCountry', action.countryCode);
            return {
                ...state,
                current: action.countryCode,
            };
        }
        case SET_FIELD: {
            return {
                ...state,
                [action.name]: [...state.requestedCountriesId, action.value]
            }
        }
        default: {
            return state;
        }
    }
}
