import React, { useState } from "react";
import TooltipTabs from "../TooltipTabs/TooltipTabs";
import "./Tabs.scss";
import { FCX, ID } from "@datassential/platform-ui-lib";

interface ITab {
    id: ID;
    name: string;
    tooltip?: string;
    testId?: string;
    onClick?: () => void;
}

const Tabs: FCX<{
    data: ITab[];
    title?: string;
    activeItemId: ID;
    handleClick: (id: ID) => void;
}> = (
    {
        data,
        title = '',
        activeItemId,
        handleClick,
        testId,
        className,
    }
) => {
    const [tooltipShownInx, setTooltipShownInx] = useState<number | null>(null);

    return (
        <div
            className={`tabs ${className}`}
            data-testid={testId}
        >
            {title !== '' && (
                <div
                    className="tabs__title"
                    data-testid={`textTabsTitle`}
                >
                    {title}
                </div>
            )}
            <div className="tabs__nav">
                {data.map((item, inx) => {
                    return (
                        <div
                            key={inx}
                            className={`tabs__item ${activeItemId === item.id ? 'tabs__item--active' : ''}`}
                            onClick={item.onClick || (() => handleClick(item.id))}
                            onMouseOver={() => setTooltipShownInx(inx)}
                            onMouseOut={() => setTooltipShownInx(null)}
                            data-testid={item.testId ?? `buttonTabsItem-${inx}`}
                        >
                            {item.name}
                            <TooltipTabs isShown={!!item.tooltip && tooltipShownInx === inx}>
                                <span>{item.tooltip}</span>
                            </TooltipTabs>
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

export default Tabs;