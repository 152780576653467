import { ID } from '@models';
import { useData } from '@hooks/useData';
import { fetchAdminCategoryReport } from '@core/api';
import { components } from '@apiSchema';
import { CategoryReport, iCategoryReport } from '@models/CategoryReport';
import { useEffect, useState } from 'react';

type Response = components['schemas']['Application.CategoryReports.DTO.CategoryAnalysisExportInputDTO'];

async function getCategoryReportData (id: ID): Promise<CategoryReport> {
    if (id) {
        const response: Response = await fetchAdminCategoryReport(id);
        return new CategoryReport({ apiModel: response });
    }
    return new CategoryReport();
}

export default function useCategoryReport(id: ID) {
    const data = useData(new CategoryReport(), getCategoryReportData, id);
    const [localData, setLocalData] = useState<CategoryReport>(new CategoryReport());

    useEffect(() => {
        setLocalData(data.data);
    }, [data.data])

    const handleChange = (field: keyof iCategoryReport) => (value: any) => {
        setLocalData(
            new CategoryReport({
                model: {
                    ...localData,
                    [field]: value,
                }
            })
        );
    }

    const setData = (newData: Partial<iCategoryReport>) => {
        setLocalData(
            new CategoryReport({
                model: {
                    ...localData,
                    ...newData,
                }
            })
        );
    };

    return {
        ...data,
        data: localData,
        handleChange,
        setData,
    };
}