import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { equalInLC, toggleInArray } from '@core/old_helpers';
import Section from '@pages/Admin/CategoryReport/Section';
import { CategoryReport, iCategoryReport } from '@models/CategoryReport';
import { iAdminDictionaries } from '@apiHooks/useAdminDictionaries';
import { ID } from '@models';
import MultipleInput from '@components/MultipleInput/MultipleInput';
import { uniteArraysUnique } from '@core/helpers';
import Filters from '@components/Filters/Filters';
import SubTitle from '@components/SubTitle/SubTitle';
import SelectInstantChartsMostPopular from '@pages/Admin/CategoryReport/SelectInstantChartsMostPopular';
import SelectionList from '@pages/Admin/Common/SelectionList/SelectionList';
import { InstantChartContext } from '@pages/Admin/Common/SelectInstantCharts/SelectInstantCharts';

enum OptionalSection {
    MacDifferentVarietiesOfCategory,
    MacCategoryIngredients,
    InstantChartsMostPopularKeywords,
    TopTrendCategoriesFastestGrowing,
    InsiderCategory,
    FlavorCategory,
}

function getAllSections(): OptionalSection[] {
    return [
        OptionalSection.MacDifferentVarietiesOfCategory,
        OptionalSection.MacCategoryIngredients,
        OptionalSection.InstantChartsMostPopularKeywords,
        OptionalSection.TopTrendCategoriesFastestGrowing,
        OptionalSection.InsiderCategory,
        OptionalSection.FlavorCategory,
    ];
}

function getOptionalSectionProp(section: OptionalSection): keyof iCategoryReport {
    switch (section) {
        case OptionalSection.MacDifferentVarietiesOfCategory:
            return 'differentVarietiesMAC';
        case OptionalSection.MacCategoryIngredients:
            return 'ingredientsMAC';
        case OptionalSection.InstantChartsMostPopularKeywords:
            return 'mostPopularInstantCharts';
        case OptionalSection.TopTrendCategoriesFastestGrowing:
            return 'topTrendCategories';
        case OptionalSection.InsiderCategory:
            return 'insiderCategories';
        case OptionalSection.FlavorCategory:
            return 'flavorCategories';
    }
}

const OptionalSections: FC<{
    data: iCategoryReport;
    handleChange: (field: keyof iCategoryReport) => (value: any) => void;
    handleToggleEnabledSections: (sections: Array<keyof iCategoryReport>) => void;
    errorFields: Array<keyof iCategoryReport>;
    dictionariesData: iAdminDictionaries;
    isValidationShown?: boolean;
}> = ({
    data,
    handleChange,
    handleToggleEnabledSections,
    errorFields,
    isValidationShown= false,
    dictionariesData,
}) => {
    const {
        items: ICItems,
        categories: ICCategories,
        subCategories: ICSubCategories,
    } = useContext(InstantChartContext);

    const aggregatedEnabledSections: OptionalSection[] = useMemo(() => {
        return getAllSections().filter(section => {
            const field = getOptionalSectionProp(section);
            const sectionData = data[field];

            if (Array.isArray(sectionData)) {
                return sectionData.length > 0;
            } else {
                return Object.keys(sectionData).length > 0;
            }
        });
    }, [data]);

    const [enabledSections, setEnabledSections] = useState<OptionalSection[]>(aggregatedEnabledSections);
    const toggleSection = (section: OptionalSection) => () => {
        if (enabledSections.includes(section)) {
            const field = getOptionalSectionProp(section);
            const defaultValue = CategoryReport.defaultData[field];
            handleChange(field)(defaultValue);
        }
        setEnabledSections(toggleInArray(enabledSections, section));
    };

    useEffect(() => {
        handleToggleEnabledSections(enabledSections.map(i => getOptionalSectionProp(i)));
    }, [enabledSections, handleToggleEnabledSections])

    const macsToOptions: Array<{ id: number; name: string; }> = useMemo(() => {
        return dictionariesData.macs.map(i => ({
            id: i.id || 0,
            name: i.title || '',
        }))
    }, [dictionariesData.macs]);

    return (
        <>
            <Section
                title="Select a MAC(s) - Different Varieties of Category (Category Overview):"
                hasEnableToggle
                isEnabled={enabledSections.includes(OptionalSection.MacDifferentVarietiesOfCategory)}
                toggleIsEnabled={toggleSection(OptionalSection.MacDifferentVarietiesOfCategory)}
                hasError={isValidationShown && errorFields.includes('differentVarietiesMAC')}
                headerContent={(
                    <div>
                        <SelectionList
                            ItemRenderer={({ index }) => (
                                <>
                                    {dictionariesData.macs.find(i => i.id === data.differentVarietiesMAC[index])?.title}
                                </>
                            )}
                            itemsCount={data.differentVarietiesMAC.length}
                            onRemove={(index) => {
                                const targetItemId = data.differentVarietiesMAC[index];
                                if (targetItemId) {
                                    handleChange('differentVarietiesMAC')(toggleInArray(data.differentVarietiesMAC, targetItemId));
                                }
                            }}
                        >
                            {data.differentVarietiesMAC.length} MAC(s)
                        </SelectionList>
                        &nbsp;selected
                    </div>
                )}
            >
                <MultipleInput
                    submittedItems={
                        data.differentVarietiesMAC
                            .filter(id => macsToOptions.some(i => i.id === id))
                            .map(id => macsToOptions.find(i => i.id === id))
                    }
                    onSubmit={(words: iAdminDictionaries['macs']) => {
                        handleChange('differentVarietiesMAC')(uniteArraysUnique(data.differentVarietiesMAC, words.map(i => i.id)));
                    }}
                    removeItem={(name: string) => {
                        const targetItemId = macsToOptions.find(i => equalInLC(name, i.name))?.id;
                        if (targetItemId) {
                            handleChange('differentVarietiesMAC')(toggleInArray(data.differentVarietiesMAC, targetItemId));
                        }
                    }}
                    keywords={macsToOptions.filter(
                        ({ id }) => ![...data.ingredientsMAC, ...data.differentVarietiesMAC].includes(id)
                    )}
                    modifiers={["filters", 'light', 'medium']}
                    onlyFromList
                    placeholder="Type a MAC name"
                    words={macsToOptions.filter(
                        ({ id }) => [...data.ingredientsMAC, ...data.differentVarietiesMAC].includes(id),
                    )}
                />
            </Section>

            <Section
                title="Select a MAC(s) - Category Ingredients (Detail Dive):"
                hasEnableToggle
                isEnabled={enabledSections.includes(OptionalSection.MacCategoryIngredients)}
                toggleIsEnabled={toggleSection(OptionalSection.MacCategoryIngredients)}
                hasError={isValidationShown && errorFields.includes('ingredientsMAC')}
                headerContent={(
                    <div>
                        <SelectionList
                            ItemRenderer={({ index }) => (
                                <>
                                    {dictionariesData.macs.find(i => i.id === data.ingredientsMAC[index])?.title}
                                </>
                            )}
                            itemsCount={data.ingredientsMAC.length}
                            onRemove={(index) => {
                                const targetItemId = data.ingredientsMAC[index];
                                if (targetItemId) {
                                    handleChange('ingredientsMAC')(toggleInArray(data.ingredientsMAC, targetItemId));
                                }
                            }}
                        >
                            {data.ingredientsMAC.length} MAC(s)
                        </SelectionList>
                        &nbsp;selected
                    </div>
                )}
            >
                <MultipleInput
                    submittedItems={
                        data.ingredientsMAC
                            .filter(id => macsToOptions.some(i => i.id === id))
                            .map(id => macsToOptions.find(i => i.id === id))
                    }
                    onSubmit={(words: iAdminDictionaries['macs']) => {
                        handleChange('ingredientsMAC')(uniteArraysUnique(data.ingredientsMAC, words.map(i => i.id)));
                    }}
                    removeItem={(name: string) => {
                        const targetItemId = macsToOptions.find(i => equalInLC(name, i.name))?.id;
                        if (targetItemId) {
                            handleChange('ingredientsMAC')(toggleInArray(data.ingredientsMAC, targetItemId));
                        }
                    }}
                    keywords={macsToOptions.filter(
                        ({ id }) => ![...data.ingredientsMAC, ...data.differentVarietiesMAC].includes(id)
                    )}
                    modifiers={["filters", 'light', 'medium']}
                    onlyFromList
                    placeholder="Type a MAC name"
                    words={macsToOptions.filter(
                        ({ id }) => [...data.ingredientsMAC, ...data.differentVarietiesMAC].includes(id),
                    )}
                />
            </Section>

            <Section
                title="Select at least two Instant Charts - Most Popular Keywords (Detail Dive):"
                hasEnableToggle
                isEnabled={enabledSections.includes(OptionalSection.InstantChartsMostPopularKeywords)}
                toggleIsEnabled={toggleSection(OptionalSection.InstantChartsMostPopularKeywords)}
                hasError={isValidationShown && errorFields.includes('mostPopularInstantCharts')}
                headerContent={(
                    <div>
                        <SelectionList
                            ItemRenderer={({ index }) => {
                                const item = ICItems.find(
                                    i => i.id === data.mostPopularInstantCharts[index].id
                                );
                                const category = ICCategories.find(i => i.id === item?.categoryId);
                                const subCategory = ICSubCategories.find(i => i.id === item?.subCategoryId);
                                return (
                                    <>
                                        {category?.title}: {subCategory?.title}: {item?.title}
                                    </>
                                );
                            }}
                            itemsCount={data.mostPopularInstantCharts.length}
                            onRemove={(index) => {
                                handleChange('mostPopularInstantCharts')([
                                    ...data.mostPopularInstantCharts.slice(0, index),
                                    ...data.mostPopularInstantCharts.slice(index + 1),
                                ]);
                            }}
                        >
                            {data.mostPopularInstantCharts.length} chart(s)
                        </SelectionList>
                        &nbsp;selected
                    </div>
                )}
            >
                <SelectInstantChartsMostPopular
                    value={data.mostPopularInstantCharts}
                    handleChange={handleChange('mostPopularInstantCharts')}
                />
            </Section>

            <Section
                title="Select at least two Top Trends Categories - Fastest Growing (Detail Dive):"
                hasEnableToggle
                isEnabled={enabledSections.includes(OptionalSection.TopTrendCategoriesFastestGrowing)}
                toggleIsEnabled={toggleSection(OptionalSection.TopTrendCategoriesFastestGrowing)}
                hasError={isValidationShown && errorFields.includes('topTrendCategories')}
                headerContent={(
                    <div>
                        <SelectionList
                            ItemRenderer={({ index }) => {
                                const item = dictionariesData.topTrendsCategories.find(
                                    i => i.id === data.topTrendCategories[index]
                                );
                                return (
                                    <>
                                        {item?.name}
                                    </>
                                )
                            }}
                            itemsCount={data.topTrendCategories.length}
                            onRemove={(index) => {
                                const targetItemId = data.topTrendCategories[index];
                                if (targetItemId) {
                                    handleChange('topTrendCategories')(toggleInArray(data.topTrendCategories, targetItemId));
                                }
                            }}
                        >
                            {data.topTrendCategories.length} category(s)
                        </SelectionList>
                        &nbsp;selected
                    </div>
                )}
            >
                <Filters
                    filtersArray={dictionariesData.topTrendsCategories.map(item => ({
                        id: item.id,
                        name: item.name,
                        isSelected: data.topTrendCategories.includes(item.id!),
                    }))}
                    toggleFilter={(id: ID) => handleChange('topTrendCategories')(toggleInArray(data.topTrendCategories, id))}
                />
            </Section>

            <Section
                title="Select an Insider Category(s):"
                hasEnableToggle
                isEnabled={enabledSections.includes(OptionalSection.InsiderCategory)}
                toggleIsEnabled={toggleSection(OptionalSection.InsiderCategory)}
                hasError={isValidationShown && errorFields.includes('insiderCategories')}
                headerContent={(
                    <div>
                        <SelectionList
                            ItemRenderer={({ index }) => {
                                const item = dictionariesData.insiderCategories.find(
                                    i => i.id === data.insiderCategories[index]
                                );
                                return (
                                    <>
                                        {item?.name}
                                    </>
                                )
                            }}
                            itemsCount={data.insiderCategories.length}
                            onRemove={(index) => {
                                const targetItemId = data.insiderCategories[index];
                                if (targetItemId) {
                                    handleChange('insiderCategories')(toggleInArray(data.insiderCategories, targetItemId));
                                }
                            }}
                        >
                            {data.insiderCategories.length} category(s)
                        </SelectionList>
                        &nbsp;selected
                    </div>
                )}
            >
                <Filters
                    filtersArray={dictionariesData.insiderCategories.map(item => ({
                        id: item.id,
                        name: item.name,
                        isSelected: data.insiderCategories.includes(item.id!),
                    }))}
                    toggleFilter={(id: ID) => handleChange('insiderCategories')(toggleInArray(data.insiderCategories, id))}
                />
            </Section>

            <Section
                title="Select a Flavor Category(s):"
                hasEnableToggle
                isEnabled={enabledSections.includes(OptionalSection.FlavorCategory)}
                toggleIsEnabled={toggleSection(OptionalSection.FlavorCategory)}
                hasError={isValidationShown && errorFields.includes('flavorCategories')}
                headerContent={(
                    <div>
                        <SelectionList
                            ItemRenderer={({ index }) => {
                                const item = dictionariesData.flavorCategories
                                    .flatMap(i => i.subCategories)
                                    .find(ii => ii?.id === data.flavorCategories[index]);

                                const category = dictionariesData.flavorCategories.find(
                                    i => i.subCategories?.find(
                                        ii => ii?.id === data.flavorCategories[index]
                                    )
                                );
                                return (
                                    <>
                                        {category?.name}: {item?.name}
                                    </>
                                );
                            }}
                            itemsCount={data.flavorCategories.length}
                            onRemove={(index) => {
                                const targetItemId = data.flavorCategories[index];
                                if (targetItemId) {
                                    handleChange('flavorCategories')(toggleInArray(data.flavorCategories, targetItemId));
                                }
                            }}
                        >
                            {data.flavorCategories.length} category(s)
                        </SelectionList>
                        &nbsp;selected
                    </div>
                )}
            >
                {dictionariesData.flavorCategories.map(parent => (
                    <>
                        <SubTitle textColor="yellow">{parent.name}:</SubTitle>
                        <Filters
                            filtersArray={parent.subCategories!.map(item => ({
                                id: item.id,
                                name: `${parent.name}: ${item.name}`,
                                isSelected: data.flavorCategories.includes(item.id!),
                            }))}
                            toggleFilter={(id: ID) => handleChange('flavorCategories')(toggleInArray(data.flavorCategories, id))}
                        />
                    </>
                ))}
            </Section>
        </>
    );
};

export default OptionalSections;