import { useData } from '@hooks/useData';
import { components } from '@apiSchema';
import { deleteAdminCategoryReport, fetchAdminCategoryReports } from '@core/api';
import { ID } from '@models';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addNotification } from '@actions/notifications.actions';

export interface AdminCategoryReportsItem {
    id: ID;
    name: string;
    countryCode: string;
    isActive: boolean;
}

type Response = components['schemas']['Application.Admin.Models.CategoryReports.CategoryReportAdmin'][];

async function getCategoryReports(): Promise<AdminCategoryReportsItem[]> {
    const data: Response = await fetchAdminCategoryReports();

    return data.map(item => ({
        id: item.id || 0,
        name: item.name || '',
        isActive: item.isActive || false,
        countryCode: item.countryCode || 'USA',
    }));
}

export default function useCategoryReports() {
    const [isLoading, setIsLoading] = useState(false);
    const data = useData([], getCategoryReports);
    const dispatch = useDispatch();

    const pushNotification = (notification: any) => dispatch(addNotification(notification));

    const deleteReport = (id: ID) => {
        setIsLoading(true);
        deleteAdminCategoryReport(id).then(() => {
                pushNotification({
                    text: "The report is successfully removed.",
                    duration: 4000,
                });
                data.reFetch();
            })
            .catch(() => {
                pushNotification({
                    text: 'Something went wrong.',
                    error: true,
                    duration: 4000,
                });
            });
    };

    useEffect(() => {
        setIsLoading(false);
    }, [data.isLoading, setIsLoading]);

    return {
        ...data,
        isLoading: isLoading || data.isLoading,
        deleteReport,
    };
}