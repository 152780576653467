import React, { useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { CSSTransition } from "react-transition-group";
import "./Notification.scss";
import { createPortal } from "react-dom";
import { IconCheck } from "@icons";
import { GlobalContext } from "@components/App/App.context";

const Notification = ({ notifications = [] }) => {
    const { getRem } = useContext(GlobalContext);
    const errorIcon = <div className="notification__error-circle"/>;
    const successIcon = (
      <div className="notification__success-circle">
        <IconCheck width={getRem(14)} />
      </div>
    );

    const renderNotifications = () =>
        notifications.map((elem, index) => {
            const classList = new Set([
                "notification",
                 elem.error ? "notification--error" : "",
                 elem.success ? "notification--success": ""
            ]);
            return (
                <div key={elem.id} className={Array.from(classList).join(' ')}>
                    {elem.error && errorIcon}
                    {elem.success && successIcon}
                    <span className="notification__text">
                        {elem.text || "Notification"}
                    </span>
                </div>
            )
        });
    return createPortal(
        (
            <div className="notification-container">
                {notifications.length > 0 ? renderNotifications() : null}
            </div>
        ),
        document.querySelector("body")
    );
};

Notification.propTypes = {
    isVisible: PropTypes.bool,
    notifications: PropTypes.array,
};

const mapStateToProps = state => ({
    notifications: state.notifications.data,
});

export default connect(mapStateToProps)(Notification);
