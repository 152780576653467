import { GlobalContext } from "@components/App/App.context";
import React, { useContext, useState } from "react";
import Button from "../Button/Button";
import Flag from "../Flag/Flag";
import Modal from "../Modal/Modal";
import RadioInput from "../RadioInput/RadioInput";
import "./SelectCountryPopup.styles.scss";

const SelectCountryPopup = ({ handleConfirm, closeModal, shown, data, title }) => {
    const { getRem } = useContext(GlobalContext);
    const [selectedCountryCode, setSelectedCountryCode] = useState(null);

    const handleCloseModal = () => {
        closeModal();
        setSelectedCountryCode(null);
    }

    return (
        <Modal closeModal={closeModal} shown={shown} hasOverlay>
            <div className="Popup__wrapper" onClick={event => event.stopPropagation()}>
                <div className="Popup Popup--countries">
                    <div className="Popup__title Popup__title--small">
                        {title}
                    </div>
                    <div className="SelectedCountryPopup">
                            <div className="SelectedCountryPopup__list">
                                {data.map(radio => {
                                    const RadioFlag = <Flag height={getRem(24)} compact component={radio.component} />;
                                    return (
                                        <div
                                        key={radio.countryCode}
                                        className="SelectedCountryPopup__item"
                                    >
                                        <div className="radio-input-wrapper">
                                            <RadioInput
                                                name="countries"
                                                modifiers={["blue"]}
                                                hasCustomIcons={RadioFlag}
                                                isChecked={
                                                    radio.countryCode ===
                                                    selectedCountryCode
                                                }
                                                label={radio.countryCode}
                                                onChange={() =>
                                                    setSelectedCountryCode(
                                                        radio.countryCode,
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    )
                                })}
                            </div>
                        </div>
                    <div className="Popup__btn-container">
                        <Button onClick={handleCloseModal} modifiers={["transparent"]}>
                            Cancel
                        </Button>
                        <Button
                            isDisabled={!selectedCountryCode}
                            onClick={() => handleConfirm(selectedCountryCode)}
                            modifiers={["blue", "rounded"]}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SelectCountryPopup;
