import React, { useEffect, useState } from "react";
import { getCustomRestaurantList } from "@core/api";
import Button from "@components/Button/Button";
import CheckboxInput from "@components/CheckboxInput/CheckboxInput";
import Modal from "@components/Modal/Modal";
import TextField from "@components/TextField/TextField";
import "./RestaurantsMenuManage.styles.scss";
import Table from "./Table/Table";
import RestaurantsModal from "./RestaurantsModal/RestaurantsModal";
import { deepCopy, dynamicSort } from "@core/old_helpers";
import { useSearch, useSelectOptions, useSort } from "@core/customHooks";
import ConfirmationPopup from "@components/ConfirmationPopup/ConfirmationPopup";
import Loader from "@components/Loader/Loader";
import { IconArrowLeft } from "@icons";
import Icon from "@components/Icon/Icon";

const initialCustomRestaurantList = {
    id: 0,
    name: "",
    isPublic: false,
    restaurants: [],
    createdDate: null,
    createdById: null,
    createByFullName: null,
};

const POPUP_TRANSITION_TIME = 250;
const FILTER_FIELDS = ["segment", "cuisine", "regions"];

const transformDataToBackend = (data, currentCountry) => {
    const { id, name, isPublic, restaurants, createdDate, createdById } = data;

    return {
        id: id || 0,
        name,
        isPublic,
        countryCode: currentCountry,
        createdDate: createdDate || new Date().toISOString(),
        createdById: createdById || null,
        restaurants,
    };
}

const RestaurantsMenuManage = ({
    closeModal,
    shown,
    restaurantListId,
    currentCountry,
    showNotification,
    handleUpdateCustomList,
    isUpdating,
    isViewMode = false,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(initialCustomRestaurantList);
    const [togglePopupVisible, setTogglePopupVisible] = useState(false);
    const [isRestaurantsModalShown, setIsRestaurantsModalShown] = useState(false);
    const [searchQuery, setSearchQuery, filteredSearchData] = useSearch(data.restaurants);
    const [tableData, setTableData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const sort = useSort();
    const [dataFilters, handleChangeFilters] = useSelectOptions(data.restaurants, FILTER_FIELDS);
    const [includedRestaurantsIds, setIncludedRestaurantsIds] = useState([]);
    const [isShownRemoveConfirmPopup, setIsShownRemoveConfirmPopup] = useState(false);
    const [removingRestaurant, setRemovingRestaurant] = useState({ name: "", id: 0 });

    useEffect(() => {
        if (restaurantListId) {
            setIsEdit(true);
            const dataRequest = getCustomRestaurantList(currentCountry, restaurantListId);
            setIsLoading(true);
            dataRequest
                .then(data => {
                    setData(prevState => ({
                        ...prevState,
                        ...data,
                    }));
                    setTableData(data.restaurants);
                })
                .catch(error => {
                    closeModal(true);
                    showNotification(`Can't GET custom restaurant list by id: ${restaurantListId}`, "error");
                })
                .finally(() => setIsLoading(false));
            return () => {
                dataRequest.abort();
            };
        } else {
            setData(initialCustomRestaurantList);
            setIsEdit(false);
            setIsLoading(false);
        }
    }, [restaurantListId]);

    useEffect(() => {
        return () => {
            setSearchQuery("");
            setData(initialCustomRestaurantList);
        };
    }, [shown]);

    useEffect(() => {
        const ids = data.restaurants.map(({ id }) => id);
        setIncludedRestaurantsIds(ids);
    }, [data]);

    useEffect(() => {
        const { segmentSelectedIds, cuisineSelectedIds, regionsSelectedIds } = dataFilters;

        let restaurants = deepCopy(filteredSearchData);

        const filterItems = data => fnFilter => fnFilter(data);
        const segments = data => (restaurants = data.filter(item => segmentSelectedIds.includes(item.segment)));
        const cuisines = data => (restaurants = data.filter(item => cuisineSelectedIds.includes(item.cuisine)));
        const regions = data => {
            restaurants = data.filter(item => {
                const matched = item.regions.find(el => regionsSelectedIds.includes(el.id));
                return !!matched;
            });
        };

        filterItems(restaurants)(segments);
        filterItems(restaurants)(cuisines);
        filterItems(restaurants)(regions);

        setTableData(restaurants.sort(dynamicSort(sort.column, sort.direction)));
    }, [sort.column, sort.direction, dataFilters, searchQuery, filteredSearchData]);

    const handleConfirm = () => {
        handleUpdateCustomList(transformDataToBackend(data, currentCountry), isEdit);
    };

    const handleChangeField = (field, value) => {
        setData(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleOpenRestaurantsModal = () => {
        if (togglePopupVisible) return;
        setTogglePopupVisible(!togglePopupVisible);
        setTimeout(() => {
            setIsRestaurantsModalShown(!isRestaurantsModalShown);
        }, POPUP_TRANSITION_TIME);
    };

    const handleCloseRestaurantsModal = () => {
        if (!togglePopupVisible) return;
        setIsRestaurantsModalShown(!isRestaurantsModalShown);
        setTogglePopupVisible(!togglePopupVisible);
    };

    const handleApplyRestaurants = restaurants => {
        setData(prevState => {
            return {
                ...prevState,
                restaurants: [...prevState.restaurants, ...restaurants],
            };
        });
    };

    const handleCloseRemoveConfirmPopup = () => {
        setIsShownRemoveConfirmPopup(false);
        setTimeout(() => {
            setRemovingRestaurant({ id: 0, name: "" });
        }, POPUP_TRANSITION_TIME);
    };

    const handleOpenRemoveConfirmPopup = ({ id, name }) => {
        setIsShownRemoveConfirmPopup(true);
        setRemovingRestaurant({ id, name });
    };

    const handleRemoveRestaurant = () => {
        const { id } = removingRestaurant;

        setData(prevState => {
            const removedIndex = prevState.restaurants.findIndex(item => item.id === id);

            return {
                ...prevState,
                restaurants: [
                    ...prevState.restaurants.slice(0, removedIndex),
                    ...prevState.restaurants.slice(removedIndex + 1),
                ],
            };
        });
    };

    return (
        <Modal closeModal={closeModal} shown={shown} hasOverlay>
            <div className={`Popup__wrapper ${togglePopupVisible ? "is-hidden" : ""}`}>
                <div className="Popup Popup--large">
                    <div className="RestaurantsMenuManage">
                        <div className="RestaurantsMenuManage__header">
                            <div className="RestaurantsMenuManage__title">
                                {isViewMode ?
                                    (data.name ? `Restaurants of ${data.name} list` : '')
                                    :
                                    `${isEdit ? "Edit" : "Add"} custom list`
                                }
                            </div>
                            {!isViewMode && (
                                <div
                                    className="RestaurantsMenuManage__checkbox-group RestaurantsMenuManage__checkbox-group--left">
                                    <CheckboxInput
                                        onChange={() => handleChangeField("isPublic", true)}
                                        labelText="Public"
                                        isRadioBtn
                                        isBlueCheck
                                        isChecked={data.isPublic}
                                    />
                                    <CheckboxInput
                                        onChange={() => handleChangeField("isPublic", false)}
                                        labelText="Private"
                                        isRadioBtn
                                        isBlueCheck
                                        isChecked={!data.isPublic}
                                    />
                                </div>
                            )}
                            <div className="RestaurantsMenuManage__backward" onClick={() => closeModal(true)}>
                                <Icon>
                                    <IconArrowLeft className="RestaurantsMenuManage__backward-icon" />
                                </Icon>
                                Back to list selection page
                            </div>
                        </div>

                        <div className="RestaurantsMenuManage__content">
                            <Loader isShown={isUpdating} className="is-nested"/>
                            {!isViewMode && (
                                <div className="RestaurantsMenuManage__row RestaurantsMenuManage__row--left">
                                    <div className="RestaurantsMenuManage__name-field">
                                        <TextField
                                            value={data.name}
                                            onChange={value => handleChangeField("name", value)}
                                            placeholder="Name of restaurant list"
                                            required
                                            isDisabled={isLoading}
                                        />
                                    </div>
                                    <div className="RestaurantsMenuManage__restaurants-add">
                                        <Button
                                            onClick={handleOpenRestaurantsModal}
                                            modifiers={["blue", "rounded", "fluid"]}
                                            isDisabled={isLoading}
                                        >
                                            Add restaurants
                                        </Button>
                                        {isRestaurantsModalShown && (
                                            <RestaurantsModal
                                                shown={isRestaurantsModalShown}
                                                closeModal={handleCloseRestaurantsModal}
                                                handleConfirm={handleApplyRestaurants}
                                                currentCountry={currentCountry}
                                                includedRestaurantsIds={includedRestaurantsIds}
                                            />
                                        )}
                                    </div>

                                </div>
                            )}
                            {/*
                            <div className="RestaurantsMenuManage__row">
                                <div className="RestaurantsMenuManage__title RestaurantsMenuManage__title--small">
                                    INCLUDED RESTAURANTS
                                </div>
                            </div>
                            <div className="RestaurantsMenuManage__row">
                                <div className="RestaurantsMenuManage__search">
                                    <Search
                                        key="search"
                                        searchQuery={searchQuery}
                                        setSearchQuery={setSearchQuery}
                                        placeholder="Find a restaurant list"
                                        showIcon
                                    />
                                </div>
                            </div>
                            */}

                            <div className="RestaurantsMenuManage__row">
                                <div className="RestaurantsMenuManage__table">
                                    <Table
                                        data={tableData}
                                        isSortDisabled={!!searchQuery}
                                        sort={sort}
                                        handleChangeFilters={handleChangeFilters}
                                        dataFilters={dataFilters}
                                        isLoading={isLoading}
                                        openRemoveConfirmPopup={handleOpenRemoveConfirmPopup}
                                        isViewMode={isViewMode}
                                    />
                                </div>
                            </div>
                        </div>

                        {!isViewMode && (
                            <div className="RestaurantsMenuManage__footer">
                                <Button isDisabled={isLoading || !data.name.length || !data.restaurants.length}
                                        onClick={handleConfirm} modifiers={["blue", "rounded"]}>
                                    Save
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <ConfirmationPopup
                closeModal={handleCloseRemoveConfirmPopup}
                shown={isShownRemoveConfirmPopup}
                title={`Delete ${removingRestaurant.name} restaurant`}
                text="Are you sure you want to delete this restaurant?"
                onConfirm={handleRemoveRestaurant}
                submitButtonText="Delete"
            />
        </Modal>
    );
};

export default RestaurantsMenuManage;
