import React, {useState} from 'react';
import Dropzone from "./DropZone/DropZone";
import Loader from "@components/Loader/Loader";

function SettingsAddImages({ onDrop, images, handleRemoveImg, isLoaderActive }) {
    const [isRejectedImage, setIsRejectedImage] = useState(false)

    return (
        <div className="settings-add-images">
            <div className="settings-add-images__title">Images:</div>
            <div className="settings-add-images__subtitle">Available formats: JPEG, PNG. <span className={`${isRejectedImage ? 'settings-add-images__subtitle--red' : '' }`}>Maximum file size - 5 mb</span> </div>
            <div className="settings-add-images__content">
                <div className="settings-add-images__images">
                    <Loader isShown={isLoaderActive} />
                    <Dropzone
                        setIsRejectedImage={setIsRejectedImage}
                        handleRemoveImg={handleRemoveImg}
                        images={images}
                        onDrop={onDrop}
                        accept={"image/*"}/>

                </div>

            </div>
        </div>
    );
}

export default SettingsAddImages;