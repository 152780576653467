
import { createSelector } from "reselect";
import { countriesSelector } from "./countries.selector";
import { deepCopy, dynamicSort, flatten, getStringMatchRank, StringMatchRanks } from "@core/old_helpers";
import { escapeRegExp } from "@core/helpers";

export const dataSelector = state => state.adminInstantCharts.data.instantCharts;
export const initialDataSelector = state => deepCopy(state.adminInstantCharts.data.initialData);
export const searchQuerySelector = state => state.adminInstantCharts.searchQuery;
export const sortingDirectionSelector = state => state.adminInstantCharts.sortingDirection;
export const sortingColumnTypeSelector = state => state.adminInstantCharts.sortingColumnType;
export const selectedCategoryIdsSelector = state => state.adminInstantCharts.selectedCategoryIds;
export const selectedSubCategoryIdsSelector = state => state.adminInstantCharts.selectedSubCategoryIds;
export const visibleSubCategoryIdsSelector = state => state.adminInstantCharts.visibleSubCategoryIds;
export const selectedCountryIdsSelector = state => state.adminInstantCharts.selectedCountryIds;

export const sortedDataSelector = createSelector(
    dataSelector, sortingDirectionSelector, sortingColumnTypeSelector,
    (instantCharts, sortingDirection, sortingColumnType) => {
        return (sortingDirection && sortingColumnType) ? instantCharts.sort(dynamicSort(sortingColumnType, sortingDirection)) : instantCharts;
    }
);

export const categoriesSelector = createSelector(
    initialDataSelector,
    (initialData) => {
        if ( !initialData.hasOwnProperty("categoriesData") ) return [];
        const categories = deepCopy(initialData.categoriesData);
        return categories.filter(item => item.name).map(({ id, name }) => ({ id, name }));
    }
);

export const subCategoriesSelector = createSelector(initialDataSelector, (initialData) => {
    if ( !initialData.categoriesData ) return [];

    let categoriesNames = initialData.categoriesData && initialData.categoriesData.map(item => {
        return item.subCategories.map(sub => {
            let { parentId, order, ...subDetails } = sub;
            return subDetails;
        });
    });

    return flatten(categoriesNames);
});

export const filteredDataSelector = createSelector(
    sortedDataSelector,
    searchQuerySelector,
    sortingDirectionSelector,
    sortingColumnTypeSelector,
    initialDataSelector,
    subCategoriesSelector,
    (instantCharts, searchQuery, sortingDirection, sortingColumnType, initialData, subCategories) => {

        let filteredData = instantCharts;

        const trimmedSearchQuery = searchQuery.trim();

        if (trimmedSearchQuery !== "") {

            const allCategories = deepCopy(initialData.categoriesData);
            const allSubCategories = deepCopy(subCategories);

            let categoriesToShow = allCategories.filter(category => {
                return category.name && getStringMatchRank(category.name, trimmedSearchQuery) >= StringMatchRanks.Includes
            });

            let subCategoriesToShow = allSubCategories.filter(subcategory => {
                return subcategory.name && getStringMatchRank(subcategory.name, trimmedSearchQuery) >= StringMatchRanks.Includes
            });


            filteredData = filteredData.filter(keyword => {
                let matchWord = getStringMatchRank(keyword.name, trimmedSearchQuery) >= StringMatchRanks.Includes;
                let matchCategory = categoriesToShow.map(({id}) => id).includes(keyword.categoryId);
                let matchSubCategory = subCategoriesToShow.map(({id}) => id).includes(keyword.subCategoryId);
                return matchWord || matchCategory || matchSubCategory;
            }).sort((a, b) => {
                const aWord = a.name;
                const bWord = b.name;

                return (aWord < bWord ? -1 : aWord > bWord ? 1 : 0);
            });

            filteredData.sort((a, b) => {
                const aRank = getStringMatchRank(a.name, trimmedSearchQuery);
                const bRank = getStringMatchRank(b.name, trimmedSearchQuery);

                return aRank < bRank ? 1 : aRank > bRank ? -1 : 0;
            });
        } else if (sortingDirection && sortingColumnType) {
            filteredData.sort(dynamicSort(sortingColumnType, sortingDirection));
        }

        return filteredData;
    },
);



export const tableDataSelector = createSelector(
    filteredDataSelector,
    initialDataSelector,
    searchQuerySelector,
    countriesSelector,
    selectedCategoryIdsSelector,
    selectedSubCategoryIdsSelector,
    selectedCountryIdsSelector,
    (instantCharts, initialData, searchQuery, countries, selectedCategoryIds, selectedSubCategoryIds, selectedCountryIds) => {
        let result = [];
        const searchRegExp = new RegExp(`(${escapeRegExp(searchQuery.trim())})`, "gi");

        instantCharts.forEach(({ categoryId, countryCode, subCategoryId, id, name, isActive }) => {
            const currentCountry = initialData.countries.find(item => item.countryCode === countryCode);
            const currentCategory = initialData.categoriesData.find(item => item.id === categoryId);
            const currentSubCategory = currentCategory.subCategories.find(item => item.id === subCategoryId);
            const currentFlagComponent = countries.find(item => item.countryCode === countryCode);

            const renderName = searchQuery.trim() === "" ? name : name.replace(searchRegExp, "<b>$1</b>");
            const renderCategoryName = searchQuery.trim() === "" ? currentCategory.name : currentCategory.name.replace(searchRegExp, "<b>$1</b>");
            const renderSubCategoryName = searchQuery.trim() === "" ? currentSubCategory.name : currentSubCategory.name.replace(searchRegExp, "<b>$1</b>");

            if (
                selectedCategoryIds.includes(currentCategory.id) &&
                selectedSubCategoryIds.includes(currentSubCategory.id) &&
                selectedCountryIds.includes(currentCountry && currentCountry.countryCode)
            ) {
                result.push({
                    id,
                    name,
                    renderName,
                    renderCategoryName,
                    renderSubCategoryName,
                    isActive,
                    countryName: currentCountry.name,
                    categoryName: currentCategory.name,
                    subCategoryName: currentSubCategory.name,
                    flag: currentFlagComponent.component,
                    categoryId,
                    subCategoryId,
                    countryCode,
                });
            }
        });

        return result;
    },
);

export const optionsSubCategoriesToOptionsSelector = createSelector(visibleSubCategoryIdsSelector, visibleSubCategoryIds => {
    let result = [];
    visibleSubCategoryIds.forEach(id => {
        result.push({ id, name: "name #" + id });
    });
    return result;
});

export const getSubcategoryNames = createSelector(visibleSubCategoryIdsSelector, subCategoriesSelector, (visibleSubCategoryIds, subCategories) => {
    if (subCategories.length === 0) return [];

    return visibleSubCategoryIds.map(id => {
        let list = subCategories.find(item => {
            return item.id === id;
        });
        return list;
    });
});

export const getCountriesToOptions = createSelector(
    initialDataSelector,
    countriesSelector,
    (initialData, countries) => {
        if (!initialData.countries) return [];

        let options = initialData.countries.map(item => {
            let { component } = countries.find(contry => contry.countryCode === item.countryCode);
            return {
                id: item.countryCode,
                name: item.countryCode,
                icon: component,
            };
        });

        return options;
    },
);
