import { FC } from "react";

export type ID = number | string;
export type SortingDirection = "asc" | "desc";

export interface iOption {
    id: ID;
    title: string;
}

export enum ReportFileExtension {
    Xls = 0,
    Ppt = 1,
    Pdf = 2,
}

export enum ReportTypeEnum {
    Summary,
    Category
}

export  interface ICountry {
    name: string;
    countryCode: string;
    component: FC;
    componentDisabled: FC;
    currency: string;
    hasAccess: boolean;
    hasFiltersAll: boolean;
    hasFilterDescriptions: boolean;
    hasLongTermTrend: boolean;
    hasNewItemsOnly: boolean;
    hasReportData: boolean;
    hasChart: boolean;
    hasGlobalSearchAllMenu: boolean;
    currencyCharComponent: FC;
}

export interface ICountryPermissions {
    hasFoodProfile: boolean;
    hasRtuReport: boolean;
    hasInsertChart: boolean;
    hasFiltersAll: boolean;
    hasLongTermTrend: boolean;
    hasNewItemsOnly: boolean;
    hasReportData: boolean;
    hasChart: boolean;
    hasGlobalSearchAllMenu: boolean;
    isTrial: boolean;
}