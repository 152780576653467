import {
    cssX, equalInLC,
    FCX,
    ID,
    includesInLC,
    markMatch,
    SearchBox,
    splitIntoChunks,
    useUuid
} from "@datassential/platform-ui-lib";
import cn from "classnames";
import React, { useCallback, useMemo, useState, ClipboardEvent } from "react";
import { iOption } from "@models";
import './Tags.scss';
import { IDropdownColumn } from "@datassential/platform-ui-lib/dist/components/Dropdown/Dropdown";
import { startsFromInLC, toggleInArray } from "@core/old_helpers";
import Tag from "@components/Tag/Tag";
import CheckboxInput from "@components/CheckboxInput/CheckboxInput";
import MultiSelectButtons from "@components/MultiSelectButtons/MultiSelectButtons";

export function useIsFilterByTagsFeatureEnabled() {

    return true;
}

interface ITag extends iOption {
    categoryId: ID;
}

function getSortScore(tag: ITag, query: string) {
    if (startsFromInLC(tag.title, query)) {
        return 100;
    } else if (includesInLC(tag.title, query)) {
        return 10;
    } else {
        return 1;
    }
}

const Tags: FCX<{
    categories: iOption[];
    tags: ITag[];
    value: ID[];
    setValue: (value: ID[]) => void;
}> = ({
    className,
    testId,
    style,
    categories,
    tags,
    value,
    setValue,
    ...props
}) => {
    const id = useUuid();
    const [query, setQuery] = useState("");
    const [openedCategoriesIds, setOpenedCategoriesIds] = useState<ID[]>([]);

    const selectedTags = useMemo(
        () => tags.filter((tag) => value.includes(tag.id)),
        [tags, value]
    );

    const notSelectedTags = useMemo(
        () => tags.filter((tag) => !value.includes(tag.id)),
        [tags, value]
    );

    const searchResults = useMemo(
        (): IDropdownColumn['items'] => {
            const matches = notSelectedTags.filter((tag) => includesInLC(tag.title, query.trim()));

            matches.sort((a, b) => {
                const aScore = getSortScore(a, query);
                const bScore = getSortScore(b, query);

                if (aScore === bScore) {
                    return a.title < b.title ? -1 : 1;
                }

                return bScore - aScore;
            });

            return matches.map((tag) => ({
                id: tag.id,
                content: (
                    <div
                        className={cn(
                            cssX('Dropdown__item'),
                            "Tags__search-item",
                        )}
                        onClick={() => {
                            setValue([...value, tag.id]);
                            setQuery("");
                        }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: markMatch(tag.title, query.trim()) }}/>
                    </div>
                ),
            }));
        },
        [notSelectedTags, query, value]
    );

    const isAllCollapsed = useMemo(
        () => openedCategoriesIds.length === 0,
        [openedCategoriesIds]
    );

    const toggleAllCategoriesIsOpened = useCallback(
        () => {
            if (isAllCollapsed) {
                setOpenedCategoriesIds(categories.map((category) => category.id));
            } else {
                setOpenedCategoriesIds([]);
            }
        },
        [categories, isAllCollapsed]
    );

    const categoriesWithTags = useMemo(
        () => categories.map((category) => ({
            ...category,
            tags: tags.filter((tag) => tag.categoryId === category.id),
        })),
        [categories, tags]
    );

    const columns = useMemo(
        () => splitIntoChunks(categoriesWithTags, 3),
        [categoriesWithTags]
    );

    const handlePaste = (event: ClipboardEvent) => {
        const text = event.clipboardData?.getData('Text') ?? '';
        if (text.indexOf('\n') >= 0) {
            const lines = Array.from(new Set(
                text.split('\n')
                    .map(line => line.trim().toLowerCase())
                    .filter(line => !!line)
            ));

            const matchItems = notSelectedTags.filter(
                tag => lines.some(line => equalInLC(tag.title.trim(), line.trim()))
            );

            if (matchItems.length > 0) {
                setTimeout(() => {
                    setValue([
                        ...value,
                        ...matchItems.map(item => item.id)
                    ]);

                    setQuery('');
                },0)
            }
        }
    };

    return (
        <div
            className={cn("Tags", className)}
            data-testid={testId}
            style={style}
            {...props}
        >
            <div className="Tags__header">
                <SearchBox
                    tooltipId={id}
                    inputProps={{
                        placeholder: "Search for tags",
                        value: query,
                        setValue: (value) => setQuery(value.toUpperCase()),
                        onSubmit: () => {
                            const exactMatch = notSelectedTags.find((tag) => equalInLC(tag.title.trim(), query.trim()));
                            if (exactMatch) {
                                setValue([...value, exactMatch.id]);
                                setQuery('');
                            }
                        },
                        onPaste: handlePaste,
                        testId: "inputFiltersTagsSearch",
                    }}
                    dropdownProps={{
                        isOpened: query.trim() !== '' && searchResults.length > 0,
                        id,
                        testId: "selectFiltersTagsSearch",
                        groups: query.trim().length > 0 && searchResults.length > 0 ? [{
                            id: 'searchResults',
                            items: searchResults,
                        }] : undefined,
                        className: "Tags__search-dropdown",
                    }}
                    className={cn("Tags__search")}
                />
                {selectedTags.length > 0 && (
                    <div className="Tags__selected-items">
                        <CheckboxInput
                            onChange={() => null}
                            labelText="Selected Tags"
                            isChecked
                            noIcon
                            isBold
                            testId={"textFiltersPopupSelectedTags"}
                        />
                        <div
                            className="Tags__selected-items-content"
                            data-testid="selectFiltersPopupSelectedTags"
                        >
                            {selectedTags.map((tag) => (
                                <Tag
                                    key={tag.id}
                                    isActive
                                    onRemove={() => setValue(value.filter((id) => id !== tag.id))}
                                >
                                    {tag.title}
                                </Tag>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className="FiltersContainer__sub-header">
                <span
                    className={`FiltersContainer__select-btn ${!isAllCollapsed ? "FiltersContainer__select-btn--active" : ""}`}
                    onClick={toggleAllCategoriesIsOpened}
                    data-testid="buttonFiltersPopupMenuItemsClearAll"
                >
                    expand all
                </span>
                &nbsp;/&nbsp;
                <span
                    className={`FiltersContainer__select-btn ${isAllCollapsed ? "FiltersContainer__select-btn--active" : ""}`}
                    onClick={toggleAllCategoriesIsOpened}
                    data-testid="buttonFiltersPopupMenuItemsSelectAll"
                >
                    collapse all
                </span>
            </div>
            <div className="Tags__content FiltersWidget">
                <div className="Tags__columns">
                    {columns.map((column, columnIndex) => (
                        <div
                            key={columnIndex}
                            className="Tags__column"
                        >
                            {column.map((category) => (
                                <MultiSelectButtons
                                    key={category.id}
                                    isExpanded={openedCategoriesIds.includes(category.id)}
                                    toggleIsExpanded={() => setOpenedCategoriesIds(toggleInArray(openedCategoriesIds, category.id))}
                                    items={category.tags}
                                    title={category.title}
                                    value={value}
                                    setValue={setValue}
                                />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Tags;